import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { motion, AnimatePresence } from "framer-motion";

import { SimpleTextPage } from "./SurveyScreens";
import { SurveyOptionContainer, SurveyOptionButton } from "./SurveyComponents";
import { useApplication } from "../../contexts/ApplicationContext";


export default function VideoScreen() {
  const [isTypingDone, setIsTypingDone] = useState(true);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const navigate = useNavigate();
  const typingSpeed = 90;
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  const { formData, updateFormData } = useApplication();

  useEffect(() => {
    if (!formData.uniqueId) {
      const uniqueId = uuidv4();
      sessionStorage.setItem("unique_id", uniqueId);

      // Use sendBeacon if supported for better reliability
      const data = new FormData();
      data.append("unique_id", uniqueId);
      const BASE_URL = process.env.REACT_APP_API_URL
      navigator.sendBeacon(`${BASE_URL}/api/track-application/`, data);
    
    }
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "black";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const handleClick = () => {
    setIsFadingOut(true);
    setTimeout(() => navigate("/berkeley/application/8"), 600); // Wait for animation before navigating
  };

  
  return (
    <AnimatePresence>
      {!isFadingOut && (
        <motion.div
          key="screen-one"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }} // Smooth fade-out
          transition={{ duration: 0.6, ease: "easeOut" }} // Smooth easing
        >
          <VideoContainer isLoaded={isVideoLoaded}>
            <video controls autoPlay playsInline onLoadedData={() => setIsVideoLoaded(true)}>
              <source src="https://images.prospinity.com/videos/pool-tutorial.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </VideoContainer>
  
          {/* Buttons (only appear after typing is done) */}
          {isTypingDone && isVideoLoaded && (
            <motion.div
              key="buttons"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 0 }} // Ensure buttons fade with text
              transition={{ duration: 0.6 }}
              style={{ width: "100%" }}
            >
              <SurveyOptionContainer isMdOrLarger={isMdOrLarger}>
                <SurveyOptionButton
                  key="NEXT"
                  onClick={handleClick}
                  isMdOrLarger={isMdOrLarger}
                >
                  {"NEXT"}
                </SurveyOptionButton>
              </SurveyOptionContainer>
            </motion.div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}  


const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;

  video {
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(255, 255, 255, 0.1);
    display: ${({ isLoaded }) => (isLoaded ? "block" : "none")};
    text-align: center;
  }
`;