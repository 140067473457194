import { useTheme } from "styled-components";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

// From my app
import { RegistrationContainer } from "../../components/common/Containers";
import { Form } from "../../components/forms/Form";
import FormInput from "../../components/forms/FormInput";
import FormTextarea from "../../components/forms/FormTextarea";
import MultiSelect from "../../components/forms/MultiSelect";
import PictureUpload from "../../components/forms/PictureUpload";
import { PrimaryButton, SecondaryButton, HorizontalButtonContainer } from "../../components/common/Buttons";
import { RegistrationHeader, Subtitle, MobileBottomSection } from "../../components/forms/MiscComponents";
import { myAxiosInstance } from "../../api/axiosConfig";


function FinishCustomize() {
    const [profilePicture, setProfilePicture] = useState(null);
    const [tagLine, setTagLine] = useState("");
    const [about, setAbout] = useState("");
    const [club, setClub] = useState([]);
    const [errors, setErrors] = useState({ tagLine: "", about: "", club: "" });
    const [loading, setLoading] = useState(false);

    // Erros
    const { showBoundary } = useErrorBoundary();

    // Get the theme
    const theme = useTheme();

    // Navigate
    const navigate = useNavigate();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });


    // Retrieve the data
    useEffect(() => {
        const getStageDetails = async () => {
            try {
                setLoading(true);
                const response = await myAxiosInstance.get('/api/finish-registration-five/');
                
                setProfilePicture(response.data.profile_picture_url || "");
                setTagLine(response.data.tag_line || "");
                setAbout(response.data.bio || "");
                setClub(response.data.club || []);

            } catch (error) {
                showBoundary(error);
            } finally {
                setLoading(false); // Set isLoading to false regardless of success or error
            }
        };

        getStageDetails();

    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        let newErrors = { profilePicture: "", tagLine: "", about: "", club: "" };

        // Mandatory profile pictures
        if (!profilePicture) newErrors.profilePicture = "Please upload a profile picture.";
    
        if (newErrors.profilePicture || newErrors.tagLine || newErrors.about ||newErrors.club) {
            setErrors(newErrors);
            return; // Stop execution if frontend validation fails
        }
                  
        try {
            // Send API request
            setLoading(true);

            const formData = new FormData();
            if (profilePicture instanceof File) {
                formData.append("profile_picture", profilePicture);
            }

            formData.append("tag_line", tagLine);
            formData.append("bio", about);
            
            if (!club) {
                club = []; // Ensure it's an empty array if null
            }
            
            // Append each club separately
            club.forEach(clubName => {
                formData.append("club", clubName);
            });

            await myAxiosInstance.put("/api/finish-registration-five/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            // Redirect to verify email
            window.location.href = "/finish-registration/verify-email";

        } catch (error) {
            // Handle API errors
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data.profile_picture) {
                        setErrors({ ...newErrors, profilePicture: error.response.data.profile_picture });
                    } else if (error.response.data.tag_line) {
                        setErrors({ ...newErrors, tagLine: error.response.data.tag_line });
                    } else if (error.response.data.bio) {
                        setErrors({ ...newErrors, about: error.response.data.bio });
                    } else if (error.response.data.club) {
                        setErrors({ ...newErrors, club: error.response.data.club });
                    } else {
                        setErrors({ ...newErrors, club: "Something went wrong. Please try again later." });
                    }
                } else {
                    setErrors({ ...newErrors, club: "Something went wrong. Please try again later." });
                }
            } else {
                setErrors({ ...newErrors, club: "Something went wrong. Please try again later." });
            }
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <RegistrationContainer isMobile={isMobile}>
            <RegistrationHeader isMobile={isMobile} style={{ marginTop: isMobile ? "2rem" : "auto", marginBottom: isMobile ? "0rem" : "auto" }}>
                ENHANCE YOUR PROFILE
            </RegistrationHeader>

            <Form onSubmit={handleSubmit}>
                <div style={{ marginTop: isMobile ? "1.4rem" : "1.8rem" }}>
                    <PictureUpload
                        image={profilePicture}
                        setImage={(file) => { 
                            setProfilePicture(file); 
                            setErrors((prev) => ({ ...prev, profilePicture: "" }));
                        }} 
                        error={errors.profilePicture} 
                    />
                </div>

                <div style={{ marginTop: isMobile ? "1.4rem" : "1.8rem" }}>
                    <MultiSelect 
                        label="Clubs" 
                        endpoint='search-clubs'
                        value={club}
                        onChange={(value) => {
                            setClub(value);
                            setErrors((prev) => ({ ...prev, club: "" })); // Clear error when typing
                        }}
                        error={errors.club}
                    />
                </div>

                <div style={{ marginTop: isMobile ? "1.4rem" : "1.8rem" }}>
                    <FormInput 
                        label="Tag" 
                        type="text" 
                        value={tagLine}
                        onChange={(e) => {
                            setTagLine(e.target.value);
                            setErrors((prev) => ({ ...prev, tagLine: "" })); // Clear error when typing
                        }} 
                        error={errors.tagLine}
                    />
                </div>

                <div style={{ marginTop: isMobile ? "1.4rem" : "1.8rem" }}>
                    <FormTextarea 
                        label="About" 
                        type="text" 
                        value={about}
                        onChange={(e) => {
                            setAbout(e.target.value);
                            setErrors((prev) => ({ ...prev, about: "" })); // Clear error when typing
                        }} 
                        error={errors.about}
                    />
                    <Subtitle isMobile={isMobile} theme={theme} style={{ margin: 0, lineHeight: isMobile ? "1.25rem" : "auto", fontSize: isMobile ? "0.875rem" : "auto" }}>(Automatically generated using AI based on your resume or LinkedIn profile.)</Subtitle>
                </div>

                {/* Mobile bottom section */}
                {isMobile ?
                    <MobileBottomSection>
                        <HorizontalButtonContainer>
                            <SecondaryButton size="lg" type="button" disabled={loading} style={{ flex: "1", padding: "1rem" }} onClick={() => navigate("/finish-registration/phone")} >
                                BACK
                            </SecondaryButton>

                            <PrimaryButton size="lg" type="submit" disabled={loading} style={{ flex: "1", padding: "1rem" }} >
                                {loading ? "Checking profile..." : "CONTINUE"}
                            </PrimaryButton>
                        </HorizontalButtonContainer>
                    </MobileBottomSection>
                    :
                    <HorizontalButtonContainer>
                        <SecondaryButton size="lg" type="button" disabled={loading} style={{ flex: "1", padding: "1rem", marginTop: "2rem" }} onClick={() => navigate("/finish-registration/phone")} >
                            BACK
                        </SecondaryButton>

                        <PrimaryButton size="lg" type="submit" disabled={loading} style={{ flex: "1", padding: "1rem", marginTop: "2rem" }} >
                            {loading ? "Checking profile..." : "CONTINUE"}
                        </PrimaryButton>
                    </HorizontalButtonContainer>
                }

            </Form>
            
        </RegistrationContainer>
    );
}

export default FinishCustomize;
