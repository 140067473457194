import { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { useNavigate } from 'react-router-dom';

import { MainContainer } from '../components/common/Containers';
import { useNotifications } from "../contexts/NotificationsContext";


export default function NotificationsSmall() {
    const { notifications, markAllAsRead, readNotification } = useNotifications();
    const theme = useTheme();
    const navigate = useNavigate();

    const getTimeAgo = (utcTime) => {
        const now = new Date();
        const createdDate = new Date(utcTime); // Convert string to Date object
        const diffInSeconds = Math.floor((now - createdDate) / 1000); // Difference in seconds
    
        if (diffInSeconds < 60) return "now"; // Less than a minute
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        if (diffInMinutes < 60) return `${diffInMinutes}m`; // Less than an hour
        const diffInHours = Math.floor(diffInMinutes / 60);
        if (diffInHours < 24) return `${diffInHours}h`; // Less than a day
        const diffInDays = Math.floor(diffInHours / 24);
        return `${diffInDays}d`; // More than a day
    };

    return (
        <MainContainer theme={theme} style={{ gap: "1rem" }}>
            {/* Header */}
            <Title theme={theme}>NOTIFICATIONS</Title>

            <Section>
                {/* Filter + Mark All as Read */}
                <ActionRow>
                    <AllActivity theme={theme}>All activity</AllActivity>
                    <MarkAllRead theme={theme}>
                        Mark all as read
                    </MarkAllRead>
                </ActionRow>

                {/* List of Notifications */}
                <NotificationList>
                    {notifications.map(notification => (
                        <NotificationItem key={notification.pk} theme={theme} onClick={() => { readNotification(notification.pk); navigate("/" + notification.redirect_link) }}>
                            <NotificationContent key={notification.pk} theme={theme}>

                                {/* Time & Title Row */}
                                <MetaInfo>
                                    {!notification.read && <UnreadDot theme={theme} />}
                                    <NotificationTime theme={theme}>{getTimeAgo(notification.created_at)}</NotificationTime>
                                    <NotificationTitle theme={theme}>{notification.title}</NotificationTitle>
                                </MetaInfo>

                                {/* Message */}
                                <NotificationMessage theme={theme}> {notification.message} </NotificationMessage>
                            </NotificationContent>
                        </NotificationItem>
                    ))}
                </NotificationList>

            </Section>
        </MainContainer>
    );
}

// Styled Components
const Section = styled.div`
    width: "100%";
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    background: ${({ theme }) => theme.white};;
    padding: 1.5rem 0rem;
    border-radius: 1.5rem;
    flex-wrap: wrap; /* Allow items to wrap on small screens */
`;

const Title = styled.h3`
    font-size: 1.75rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
    margin: 0;
`;

const ActionRow = styled.div`
    width: 100%;
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
`;

const AllActivity = styled.span`
    color: ${({ theme }) => theme.textSecondary};
    font-size: 0.875rem;
`;

const MarkAllRead = styled.span`
    color: ${({ theme }) => theme.primary};
    font-size: 0.875rem;
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.primaryHover};
    }
`;

const NotificationList = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    gap: 0.5rem;
`;

const NotificationItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 1.5rem;
    gap: 1rem;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.backgroundLight};
    }
`;

const NotificationContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const MetaInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 0.625rem; /* Space between time and title */
`;

const NotificationTime = styled.span`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.textSecondary};
`;

const NotificationTitle = styled.strong`
    font-size: 0.875rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
`;

const NotificationMessage = styled.p`
    font-size: 0.875rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.textSecondary};
    margin: 0;
`;

const UnreadDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
`;




// import { useState, useEffect } from "react";
// import { useTheme } from "styled-components";
// import { myAxiosInstance } from "../api/axiosConfig";
// import { StyledDropdown, StyledH5, StyledButton } from "../components/common/StyledBootstrap";
// import { Nav } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBell } from "@fortawesome/free-solid-svg-icons";
// import { useErrorBoundary } from "react-error-boundary";
// import { Tooltip, OverlayTrigger } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { timeSince } from "../components/common/CriticalFunctions";




// function NotificationsPageMobile() {
//     const themeMode = useTheme();
//     const { showBoundary } = useErrorBoundary();
//     const pollingInterval = 3600000;
//     const [notifications, setNotifications] = useState([]);
//     const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
//     const [shouldFetchNotifications, setShouldFetchNotifications] = useState(true);
//     const [dropdownOpen, setDropdownOpen] = useState(false);

//     const notificationCountStyle = {
//         position: 'absolute',
//         top: '0.25rem',
//         right: '0.25rem',
//         padding: '0rem 0rem',
//         borderRadius: '50%',
//         background: themeMode.secondary,
//         height: '1.5rem',
//         width: '1.5rem',
//         color: themeMode.onSecondary,
//         fontSize: '1rem',
//         fontWeight: 'bold',

//         // Flexbox properties to center content
//         display: 'flex',
//         alignItems: 'center',  // Vertical alignment
//         justifyContent: 'center', // Horizontal alignment
//     };


//     const fetchNotifications = async () => {

//         try {
//             const response = await myAxiosInstance.get('/api/notifications/');
//             setNotifications(response.data);
//             setUnreadNotificationsCount(response.data.filter(notification => !notification.read).length)

//         } catch (error) {
//             showBoundary(error);
//         }


//     };


//     useEffect(() => {
//         // Function to fetch notifications


//         // Initial fetch
//         fetchNotifications();

//         // Set up polling with setInterval
//         const intervalId = setInterval(fetchNotifications, pollingInterval);

//         // Clear interval on component unmount
//         return () => clearInterval(intervalId);
//     }, [shouldFetchNotifications]);



//     const handleNotificationClick = async (notificationId) => {
//         try {
//             // Call the API to mark the notification as read
//             await myAxiosInstance.post(`/api/notifications/mark-read/${notificationId}/`);

//             // Update the state to reflect the read status of the notification
//             setNotifications(notifications.map(notification =>
//                 notification.pk === notificationId
//                     ? { ...notification, read: true }
//                     : notification
//             ));
//             fetchNotifications();
//         } catch (error) {}
//     };


//     const handleMarkAllAsRead = async () => {
//         try {
//             // Call the API to mark the notification as read
//             await myAxiosInstance.post("/api/read-notifications/");

//             // Update the state to reflect the read status of the notification
//             setNotifications(notifications.map(notification =>

//                 ({ ...notification, read: true })

//             ));
//             fetchNotifications();
//         } catch (error) {}
//     };


//     const handleNotificationDropdownToggle = async (isOpen) => {
//         setDropdownOpen(isOpen);
//         setShouldFetchNotifications(!isOpen);
//     };



//     return (
//         <>
//             <div>
//                 {notifications.map((notification, index) => (
//                     <OverlayTrigger
//                         key={index}
//                         placement="left"
//                         overlay={
//                             <Tooltip id={`tooltip-${index}`}>
//                                 {notification.message}
//                             </Tooltip>
//                         }
//                     >
//                         <div key={index} as={Link} to={"/" + notification.redirect_link} onClick={() => handleNotificationClick(notification.pk)} className={notification.read ? '' : 'unread-notification'} title={notification.message}>
//                             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px' }}>
//                                 <div style={{ flex: '1 1 auto', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
//                                     {notification.message}
//                                 </div>
//                                 <div style={{ flex: '0 0 auto', fontSize: '0.75rem', color: '#007bff', whiteSpace: 'nowrap' }}>
//                                     {timeSince(notification.created_at)}
//                                 </div>
//                             </div>
//                         </div>
//                     </OverlayTrigger>
//                 ))}</div>
//         </>);


// }

// export default NotificationsPageMobile;


// import { useState, useEffect } from "react";
// import { useTheme } from "styled-components";
// import { myAxiosInstance } from "../../api/axiosConfig";
// import { StyledDropdown, StyledH5, StyledButton } from "../common/StyledBootstrap";
// import { Nav } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBell } from "@fortawesome/free-solid-svg-icons";
// import { useErrorBoundary } from "react-error-boundary";
// import { Tooltip, OverlayTrigger } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { timeSince } from "../common/CriticalFunctions";




// function NotificationsSmall() {
//     const themeMode = useTheme();
//     const {showBoundary} = useErrorBoundary();
//     const pollingInterval = 30000;
//     const [notifications, setNotifications] = useState([]);
//     const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
//     const [shouldFetchNotifications, setShouldFetchNotifications] = useState(true);
//     const [dropdownOpen, setDropdownOpen] = useState(false);

//     const notificationCountStyle = {
//         position: 'absolute',
//         top: '0.25rem',
//         right: '0.25rem',
//         padding: '0rem 0rem',
//         borderRadius: '50%',
//         background: themeMode.secondary,
//         height: '1.5rem',
//         width: '1.5rem',
//         color: themeMode.onSecondary,
//         fontSize: '1rem',
//         fontWeight: 'bold',

//         // Flexbox properties to center content
//         display: 'flex',
//         alignItems: 'center',  // Vertical alignment
//         justifyContent: 'center', // Horizontal alignment
//     };


//     const fetchNotifications = async () => {

//         try {
//             const response = await myAxiosInstance.get('/api/notifications/');
//             setNotifications(response.data);
//             setUnreadNotificationsCount(response.data.filter(notification => !notification.read).length)

//         } catch (error) {
//             showBoundary(error);
//         }


//     };


//     useEffect(() => {
//         // Function to fetch notifications


//         // Initial fetch
//         fetchNotifications();

//         // Set up polling with setInterval
//         const intervalId = setInterval(fetchNotifications, pollingInterval);

//         // Clear interval on component unmount
//         return () => clearInterval(intervalId);
//     }, [shouldFetchNotifications]);



//     const handleNotificationClick = async (notificationId) => {
//         try {
//             // Call the API to mark the notification as read
//             await myAxiosInstance.post(`/api/notifications/mark-read/${notificationId}/`);

//             // Update the state to reflect the read status of the notification
//             setNotifications(notifications.map(notification =>
//                 notification.pk === notificationId
//                     ? { ...notification, read: true }
//                     : notification
//             ));
//             fetchNotifications();
//         } catch (error) {}
//     };


//     const handleMarkAllAsRead = async () => {
//         try {
//             // Call the API to mark the notification as read
//             await myAxiosInstance.post("/api/read-notifications/");

//             // Update the state to reflect the read status of the notification
//             setNotifications(notifications.map(notification =>

//                 ({ ...notification, read: true })

//             ));
//             fetchNotifications();
//         } catch (error) {}
//     };


//     const handleNotificationDropdownToggle = async (isOpen) => {
//         setDropdownOpen(isOpen);
//         setShouldFetchNotifications(!isOpen);
//     };



//     return (
//         <StyledDropdown onToggle={handleNotificationDropdownToggle} show={dropdownOpen} className='d-flex flex-column justify-content-center align-items-center'>
//             <StyledDropdown.Toggle as={Nav.Link} className='no-caret-icon'>
//                 <FontAwesomeIcon icon={faBell} fontSize={'1.9rem'} />
//                 {unreadNotificationsCount > 0 && (
//                     <span style={notificationCountStyle}>{
//                         unreadNotificationsCount
//                     }</span>
//                 )}
//             </StyledDropdown.Toggle>
//             <StyledDropdown.Menu align={'end'} style={{ maxWidth: '19rem', overflow: 'hidden', maxHeight: '40rem', overflowY: 'auto' }}>
//                 <>
//                     <div className='d-flex justify-content-between align-items-center' style={{ textAlign: 'center', width: '100%' }}><StyledH5 className='ml-3'>Notifications</StyledH5><StyledButton onClick={handleMarkAllAsRead} size="sm" className='mr-1' inline>Mark All As Read</StyledButton></div>
//                     {notifications.length === 0 ? (
//                         <StyledDropdown.Item>No notifications</StyledDropdown.Item>
//                     ) : (
//                         notifications.map((notification, index) => (
//                             <OverlayTrigger
//                                 key={index}
//                                 placement="left"
//                                 overlay={
//                                     <Tooltip id={`tooltip-${index}`}>
//                                         {notification.message}
//                                     </Tooltip>
//                                 }
//                             >
//                                 <StyledDropdown.Item key={index} as={Link} to={"/" + notification.redirect_link} onClick={() => handleNotificationClick(notification.pk)} className={notification.read ? '' : 'unread-notification'} title={notification.message}>
//                                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px' }}>
//                                         <div style={{ flex: '1 1 auto', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
//                                             {notification.message}
//                                         </div>
//                                         <div style={{ flex: '0 0 auto', fontSize: '0.75rem', color: '#007bff', whiteSpace: 'nowrap' }}>
//                                             {timeSince(notification.created_at)}
//                                         </div>
//                                     </div>
//                                 </StyledDropdown.Item>
//                             </OverlayTrigger>
//                         ))
//                     )}</>
//             </StyledDropdown.Menu>
//         </StyledDropdown>);

// }

// export default NotificationsSmall;