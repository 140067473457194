import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { styled, useTheme } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { ArrowDown } from "../../assets/icons/Icons";
import { ReactComponent as ProfileIcon } from '../../assets/icons/profileIcon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settingIcon.svg';
import { ReactComponent as FAQIcon } from '../../assets/icons/faqIcon.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logoutIcon.svg';


export default function ProfileWithPopup({ onShowSettings }) {
    // Auth Context
    const { userSlug, handleLogout, userFirstName, userLastName, userGraduationDate, profilePictureLink } = useAuth();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const popupRef = useRef(null);

    const togglePopup = () => setIsOpen((prev) => !prev);
    const closePopup = () => setIsOpen(false);

    // Theme
    const theme = useTheme();

    const logOutNavigate = () => {
        handleLogout();
        navigate('/signing-out');
    }

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            closePopup();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div style={{ position: 'relative' }} ref={popupRef}>
            {/* Profile Trigger */}
            <NavbarProfile onClick={togglePopup}>
                <ProfilePictureWrapper theme={theme}>
                    <ProfilePicture
                        src={profilePictureLink}
                        alt={userFirstName}
                    />
                </ProfilePictureWrapper>
                {!isMobile && <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: "0.3rem" }} >
                    <ProfileName theme={theme}>{userFirstName} {userLastName.charAt(0)}.</ProfileName>
                    <ArrowDown fill={theme.textSecondary} />
                </div>}
            </NavbarProfile>

            {/* Popup */}
            {isOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '52px', // Adjusted to be further below
                        right: 0,
                        width: '230px', // Match the sidebar width
                        backgroundColor: theme.white,
                        borderRadius: '1.5rem',
                        zIndex: 1002,
                        padding: '1rem 0',
                        border: `1px solid ${theme.divider}`
                    }}
                >
                    {/* Links */}
                    <PopupLink label="Profile" icon={ProfileIcon} theme={theme} onClick={() => { navigate(`user/${userSlug}`); closePopup(); }} />
                    <PopupLink label="Settings" icon={SettingsIcon} theme={theme} onClick={() => { onShowSettings(); navigate('settings'); closePopup(); }} />
                    <PopupLink label="FAQs" icon={FAQIcon} theme={theme} onClick={() => { navigate('faqs'); closePopup(); }} />
                    <PopupLink label="Logout" icon={LogoutIcon} theme={theme} onClick={() => { logOutNavigate(); closePopup(); }} />
                </div>
            )}
        </div>
    );
}

const PopupLink = ({ label, icon: Icon, theme, onClick }) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0.7rem 1.5rem',
            cursor: 'pointer',
            color: theme.textSecondary,
            transition: 'background-color 0.3s ease',
        }}
        onClick={onClick}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = theme.backgroundLight)}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
    >
        <Icon width={16} height={16} fill={theme.textSecondary} style={{ marginRight: '1rem' }} />
        <span style={{ color: theme.textSecondary, fontSize: "0.875rem" }}>{label}</span>
    </div>
);


const NavbarProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  cursor: pointer;
`;

const ProfilePictureWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.border};
  overflow: hidden;
  flex-shrink: 0;

  @media (max-width: 768px) {
        width: 30px;
        height: 30px;
        border: none;
    }
`;

const ProfilePicture = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const ProfileName = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textPrimary};
`;