import { useTheme } from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// From my app
import { RegistrationContainer } from "../../components/common/Containers";
import FormInput from "../../components/forms/FormInput";
import { Form } from "../../components/forms/Form";
import { PrimaryButton } from "../../components/common/Buttons";
import { RegistrationHeader, Subtitle, ForgotPassword, Prompt, PromptWrapper, MobileBottomSection } from "../../components/forms/MiscComponents";
import { myAxiosInstance } from "../../api/axiosConfig";
import { useAuth } from "../../contexts/AuthContext";

function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({ email: "", password: "" });
    const [loading, setLoading] = useState(false);

    // Login Auth
    const { handleLogin } = useAuth();

    // Get the theme
    const theme = useTheme();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // Email validation function
    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        let newErrors = { email: "", password: "", general: "" };
    
        // Frontend validation
        if (!email.trim()) {
            newErrors.email = "Please enter a valid email address.";
        } else if (!isValidEmail(email)) {
            newErrors.email = "Please enter a valid email address.";
        }
    
        if (password.trim().length < 4) {
            newErrors.password = "Please enter a valid email password.";
        }
    
        if (newErrors.email || newErrors.password) {
            setErrors(newErrors);
            return; // Stop execution if frontend validation fails
        }
                  
        try {
            // Send API request
            setLoading(true);

            await myAxiosInstance.post("/prospinity-auth/login/", { email, password });
            handleLogin();

        } catch (error) {
            // Handle API errors
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data.non_field_errors) {
                        setErrors({ ...newErrors, password: "Invalid login credentials" });
                    } else {
                        setErrors({ ...newErrors, password: "Invalid login credentials" });
                    }
                } else {
                    setErrors({ ...newErrors, password: "Something went wrong. Please try again later." });
                }
            } else {
                setErrors({ ...newErrors, password: "Something went wrong. Please try again later." });
            }
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <RegistrationContainer isMobile={isMobile}>
            <RegistrationHeader isMobile={isMobile}>WELCOME BACK</RegistrationHeader>
            <Subtitle isMobile={isMobile} theme={theme}>Please log in or sign up below</Subtitle>

            <Form onSubmit={handleSubmit}>
                <FormInput 
                    label="Email address" 
                    type="email" 
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setErrors((prev) => ({ ...prev, email: "" })); // Clear error when typing
                    }}
                    error={errors.email}
                />

                <div style={{ marginTop: "2rem" }}>
                    <FormInput 
                        label="Password" 
                        type="password" 
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setErrors((prev) => ({ ...prev, password: "" })); // Clear error when typing
                        }} 
                        showEyeIcon
                        error={errors.password}
                    />
                </div>

                <ForgotPassword theme={theme}>
                    <Link to="/join/password-reset-email">Forgot the password?</Link>
                </ForgotPassword>

                {/* Mobile bottom section */}
                {isMobile ?
                    <MobileBottomSection>
                        <PrimaryButton size="lg" type="submit" disabled={loading} style={{ padding: "1rem", width: "100%"}} >
                            {loading ? "Logging in..." : "LOG IN"}
                        </PrimaryButton>
                        <PromptWrapper isMobile={isMobile}>
                            <Prompt theme={theme}>
                            Don't have an account? <Link to="/join/signup">Sign up</Link>
                            </Prompt>
                        </PromptWrapper>
                    </MobileBottomSection>
                    :
                    <PrimaryButton size="lg" type="submit" disabled={loading} style={{ padding: "1rem", marginTop: "2rem" }} >{loading ? "Logging in..." : "LOG IN"}</PrimaryButton>
                }

            </Form>

            {/* Secondary prompt onlly for desktop */}
            {!isMobile && 
                <PromptWrapper>
                    <Prompt theme={theme}>
                        Don't have an account? <Link to="/join/signup">Sign up</Link>
                    </Prompt>
                </PromptWrapper>
            }
            
        </RegistrationContainer>
    );
}

export default SignIn;
