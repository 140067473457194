import React, { useEffect } from "react";
import styled from "styled-components";
import { FaCloudUploadAlt } from "react-icons/fa";
import logo from "../../assets/images/logo_white.png"; // Make sure the file path is correct
import { useState } from "react";
import ProcessingScreen from "./ProcessingScreen";
import ResultsScreen from "./ResultsScreen";
import { myAxiosInstance } from "../../api/axiosConfig";



const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #000000; /* Pure black */
  font-family: "Neue Montreal", sans-serif;
  text-align: center;
  padding: 20px;
  color: #ffffff;
`;

const Logo = styled.img`
  width: 200px; /* Adjust logo size as needed */
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 44px;
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 16px;
`;

const Subtitle = styled.p`
  font-size: 24px;
  max-width: 800px;
  margin-bottom: 26px;
  color: #c0c7d6;
  line-height: 1.6;
`;

const UploadBox = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 850px;
  height: 420px; /* Large box */
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.05); /* Subtle transparency */
  backdrop-filter: blur(10px);
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.1); /* Soft white shadow */

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: scale(1.02);
    box-shadow: 0px 4px 25px rgba(255, 255, 255, 0.15);
  }
`;

const UploadIcon = styled(FaCloudUploadAlt)`
  font-size: 90px;
  color: #ffffff;
  margin-bottom: 22px;
`;

const UploadText = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
`;

const HiddenFileInput = styled.input`
  display: none;
`;


const StatusMessage = styled.p`
  font-size: 20px;
  color: ${({ error }) => (error ? "#ff4d4d" : "#76FF03")};
  margin-top: 20px;
`;

const ResultsContainer = styled.div`
  font-size: 24px;
  color: #ffffff;
  margin-top: 30px;
`;

const Input = styled.input`
  width: 300px;
  padding: 10px;
  font-size: 18px;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  text-align: center;
  outline: none;
  margin-top: 20px;

  &::placeholder {
    color: #c0c7d6;
  }
`;

const Button = styled.button`
  background: linear-gradient(90deg, #0052cc, #4361ee);
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  padding: 15px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background: #2546bf;
  }
`;


const CareerTool = () => {
  const [view, setView] = useState("upload"); // Tracks which screen to show
  const [uploadStatus, setUploadStatus] = useState("");
  const [emailError, setEmailError] = useState("");
  const [results, setResults] = useState(null);
  const [email, setEmail] = useState("");
  const [resumeId, setResumeId] = useState(null);
  const [evaluation, setEvaluation] = useState(null);
  const MAX_FILE_SIZE_MB = 2; // 2MB file size limit
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

  // Set the background
    useEffect(() => {
      // Set background color for the body when the SurveyPage is rendered
      document.body.style.backgroundColor = "#000000"; // Your desired background color

      // Cleanup function to reset the background color
      return () => {
          document.body.style.backgroundColor = ""; // Reset to default
      };
  }, []);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > MAX_FILE_SIZE_BYTES) {
        setUploadStatus(`❌ File is too large! Max size: ${MAX_FILE_SIZE_MB}MB.`);
        return;
    }

    setView("processing");

    const formData = new FormData();
    formData.append("resume", file);

    setUploadStatus("Uploading... ⏳");
// const API_URL = "https://backenddev.prospinity.com/api/upload-resume/"; // Adjust if using production
// const EMAIL_API_URL = "https://backenddev.prospinity.com/api/collect-email/";

    try {
      const response = await myAxiosInstance.post('api/upload-resume/', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setResumeId(response.data.resume.unique_resume_id);
      setUploadStatus(`✅ Success! Resume uploaded & ranked.`);
      setView("email");
      
    } catch (error) {
      setUploadStatus("❌ Network error. Please try again.");
      setView("upload")
    }
  };

  const handleEmailSubmit = async () => {
    if (!email || !resumeId) return;

    try {
      const response = await myAxiosInstance.post(`api/collect-email/${resumeId}/`, { email }, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // const response = await fetch(`${EMAIL_API_URL}${resumeId}/`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ email }),
      // });

      setResults(response.data.percentiles);
      setEvaluation(response.data.resume);
      setView("results");

    } catch (error) {
      setEmailError(error?.response?.data?.error ?? "Something went wrong. Please try again.");
    }
  };

  if (view === "processing") {
    return (
      <ProcessingScreen />
    );
  }

  if (view === "email") {
    return (
      <Container>
        <Logo src={logo} alt="Prospinity Logo" />
        <h1>✅ Resume Processed</h1>
          <h1>📩 What's Your Berkeley Email?</h1>
        <Subtitle>
          We need to verify that you are a Berkeley student before we can rank your resume in our database.
        </Subtitle>
        <Input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailError && <StatusMessage error={true}>{emailError}</StatusMessage>}
        <Button onClick={handleEmailSubmit}>Submit</Button>
        </Container>
    );
  }

  if (view === "results") {
    return (
      <ResultsScreen overall={results.overall} longTerm={results.starting_salary} riskVolatility={results.risk} evaluationData={evaluation}/>
    );
  }

  return (
    <Container>
      <Logo src={logo} alt="Prospinity Logo" />
      <Subtitle>
        🚀 Drop your resume and see how your earning potential stacks up against other Berkeley students.
      </Subtitle>
      <Subtitle>
        💡 Our AI analyzes resumes in seconds to give you a personalized ranking—something no economist or generic salary report can do.
      </Subtitle>
      <Subtitle>
        🔒 This tool is usually for internal use, but we’re opening it up for select students.
      </Subtitle>
      <UploadBox htmlFor="file-upload">
        <UploadIcon />
        <UploadText>👉 Upload your resume to continue.</UploadText>
        <HiddenFileInput
          id="file-upload"
          type="file"
          accept=".pdf"
          onChange={handleFileUpload}
        />
      </UploadBox>
      {uploadStatus && <StatusMessage error={uploadStatus.startsWith("❌")}>{uploadStatus}</StatusMessage>}
    </Container>
  );
};

export default CareerTool;