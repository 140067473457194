import { createContext, useContext, useRef, useState, useEffect } from "react";

// Create the context
const SettingsContext = createContext();

// Custom hook for easy access
export const useSettingsContext = () => useContext(SettingsContext);

// Provider Component
export const SettingsProvider = ({ children }) => {
    // Refs for each settings section
    const personalInfoRef = useRef(null);
    const notificationsRef = useRef(null);
    const passwordRef = useRef(null);
    const deleteAccountRef = useRef(null);

    // Active section state
    const [activeSetting, setActiveSetting] = useState("personal-info");

    // Store refs using unique IDs (instead of labels)
    const sections = {
        "personal-info": personalInfoRef,
        "notifications": notificationsRef,
        "password": passwordRef,
        "delete-account": deleteAccountRef,
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight / 3; // ✅ Adjusted sensitivity
    
            let closestSection = null;
            let minDistance = Infinity;
    
            for (const [id, ref] of Object.entries(sections)) {
                if (ref.current) {
                    const { top, bottom } = ref.current.getBoundingClientRect();
                    const distance = Math.abs(top - scrollPosition); // ✅ Find closest section
    
                    if (top <= scrollPosition && bottom >= scrollPosition) {
                        closestSection = id;
                        break; // ✅ Stop once we find the section in view
                    }
    
                    // If no section is directly in view, find the closest one
                    if (distance < minDistance) {
                        minDistance = distance;
                        closestSection = id;
                    }
                }
            }
    
            if (closestSection && activeSetting !== closestSection) {
                setActiveSetting(closestSection); // ✅ Only update if it changes
            }
        };
    
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [activeSetting]); // ✅ Rerun when active section changes
    

    return (
        <SettingsContext.Provider value={{ sections, activeSetting, setActiveSetting }}>
            {children}
        </SettingsContext.Provider>
    );
};
