import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useSwipeable } from "react-swipeable";

const MobileScrollCarousel = ({ items, autoScroll = false }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const scrollContainerRef = useRef(null);

    // Handle swipe gestures
    const handlers = useSwipeable({
        onSwiping: (eventData) => {
        const { deltaX, deltaY } = eventData;
    
        // Define a strict threshold for horizontal vs. vertical swipes
        const isHorizontal = Math.abs(deltaX) > Math.abs(deltaY) * 1.5; // Prioritize horizontal movement
    
        if (isHorizontal && Math.abs(deltaX) > 10) {
            document.body.style.overflowY = "hidden"; // Lock vertical scrolling
        } else {
            document.body.style.overflowY = "auto"; // Allow vertical scrolling
        }
        },
        onSwiped: (eventData) => {
        const { deltaX, deltaY, dir } = eventData;
    
        // Restore vertical scrolling when swipe ends
        document.body.style.overflowY = "auto";
    
        const isHorizontal = Math.abs(deltaX) > Math.abs(deltaY) * 1.5; // Ensure it's a true horizontal swipe
    
        if (isHorizontal && Math.abs(deltaX) > 20) {
            if (dir === "Left") {
                setCurrentIndex((prev) => (prev < items.length - 1 ? prev + 1 : prev));
            } else if (dir === "Right") {
                setCurrentIndex((prev) => (prev > 0 ? prev - 1 : prev));
            }
        }
        },
        trackMouse: true, // Enable mouse swiping
        trackTouch: true, // Enable touch swiping
        preventScrollOnSwipe: false, // Allow slight vertical movement
    });

    // Detect if carousel is in view
    useEffect(() => {
        const observer = new IntersectionObserver(
        ([entry]) => setIsVisible(entry.isIntersecting),
        { threshold: 0.5 }
        );

        if (scrollContainerRef.current) {
        observer.observe(scrollContainerRef.current);
        }

        return () => observer.disconnect();
    }, []);

    // Auto-scroll if in view
    useEffect(() => {
        if (!autoScroll || !isVisible) return;
        const autoSlide = setInterval(() => {
        setCurrentIndex((prev) => (prev < items.length - 1 ? prev + 1 : 0));
        }, 4000);

        return () => clearInterval(autoSlide);
    }, [currentIndex, isVisible, autoScroll, items.length]);


    return (
        <CarouselContainer ref={scrollContainerRef} {...handlers}>
            <ScrollSection ref={scrollContainerRef} currentIndex={currentIndex}>
                {items.map((item, index) => (
                    <CarouselItem key={index}>{item}</CarouselItem>
                ))}
            </ScrollSection>

            <DotsContainer>
                {getDots(items.length, currentIndex).map((dotIndex) => (
                    <Dot key={dotIndex} active={dotIndex === currentIndex} />
                ))}
            </DotsContainer>
        </CarouselContainer>
    );
};

export default MobileScrollCarousel;


// Function to determine dot positions with max 3 visible
const getDots = (total, current) => {
    if (total <= 3) return [...Array(total).keys()];
  
    if (current === 0) return [0, 1, 2]; // Start
    if (current === total - 1) return [total - 3, total - 2, total - 1]; // End
  
    return [current - 1, current, current + 1]; // Middle sliding effect
  };


// Styled Components
const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  gap: 1rem;

  /* Blurred Edges */
  &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1rem;
      z-index: 2;
      pointer-events: none;
      transition: opacity 0.3s ease;
    }

    &:before {
      left: 0;
      background: ${({ theme }) => theme.backgroundLight};
      opacity: ${({ currentIndex }) => (currentIndex > 0 ? "0.8" : "0")};
      border-top-right-radius: 1.19rem; /* Ensure left side rounding */
      border-bottom-right-radius: 1.19rem;
    }

    &:after {
      right: 0;
      background: ${({ theme }) => theme.backgroundLight};
      opacity: ${({ currentIndex, cardLength }) => (currentIndex < cardLength - 1 ? "0.8" : "0")};
      border-top-left-radius: 1.19rem; /* Ensure right side rounding */
      border-bottom-left-radius: 1.19rem;
    }
`;

const ScrollSection = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  transition: transform 0.4s ease-in-out;
  transform: ${({ currentIndex }) => `translateX(calc(-${currentIndex * 100}% - ${currentIndex}rem))`};
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const CarouselItem = styled.div`
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.4rem;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ active, theme }) => (active ? theme.primary : theme.border)};
  opacity: ${({ active }) => (active ? "1" : "0.4")};
  transition: background 0.3s ease;
`;
