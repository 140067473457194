import { createContext, useContext, useState } from "react";

const ApplicationContext = createContext();

export const ApplicationProvider = ({ children }) => {
    const [formData, setFormData] = useState({
        email: sessionStorage.getItem("email") || "",
        uniqueId: sessionStorage.getItem("unique_id") || "",
        referral_code: sessionStorage.getItem("referral_code") || "",
    });

    const updateFormData = (key, value) => {
        setFormData((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    return (
        <ApplicationContext.Provider value={{ formData, updateFormData }}>
            {children}
        </ApplicationContext.Provider>
    );
};

export const useApplication = () => useContext(ApplicationContext);
