import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useMediaQuery } from "react-responsive";

import { MainContainer } from '../components/common/Containers';
import { ArrowDown } from '../assets/icons/Icons';


const faqs = [
    // {
    //     question: "How does Prospinity work?",
    //     answer: "Every year, members contribute a certain percentage (the “Rate”) of their income to a shared pool. The shared pool is then split evenly among everyone in the group. This agreement lasts for a set number of years after graduation (the “Time Horizon”). This process occurs in the month of November, and applies to income made in the previous calendar year (but will only begin the calendar year after you graduate).",
    // },
    // {
    //     question: "Why should I pool with someone younger/older than me?",
    //     answer: "Your contribution term is tied to your graduation date. This ensures that contributions are fair and tied to earning terms in the professional world. While they are still in school, a junior may receive distributions from a senior who just joined the workforce (and is not earning anything). But they will continue to contribute one year later than the senior, as the senior's contribution term expires (but the senior still gets to collect the distributions). We hope that connections across years will benefit pools, as different members contribute different ideas and perspectives.",
    // },
    // {
    //     question: "Why should I join if I think I will make more money than everyone else?",
    //     answer: "Many of the other people in this group are also thinking the same thing. They are joining the pool because they understand that life is full of uncertainty, and outcomes are tied to factors other than perceived “talent”. “High earning” jobs now may not be high earning in the future, and vice versa. An array of events beyond your control will likely play a large role in your success, from economic shifts to personal challenges to technological advances.\n\nSuccess pooling provides something you can’t achieve on your own - a way to invest in multiple life paths, both in terms of financial and social capital, hedging against the downside and capturing the upside of all of these unpredictable factors. In economic terms, we believe that the expected utility for each each individual member, at the moment of joining, is positive. The total expected dollar value of the pool is neutral (or if members take more risk and help each other because of aligned financial incentives, it can also be positive).\n\nTreat this like an early-stage venture fund that only you have access to. You should be betting on the people themselves, rather than any sort of precise salary progression prediction. Remember, Peter Thiel was a philosophy major who clerked in a federal court before he founded PayPal and Palantir.",
    // },
    // {
    //     question: "How are Success Pools taxed?",
    //     answer: "We ensure through our platform that contributions and distributions are netted. We will pre-calculate your owed contribution and owed distribution with the service fee included, and only transfer income once (instead of paying you back). Thus, there is never a situation where you put money into the pool and then get it back in the same year. If you are a net “gainer” from the pool, money from the pool is taxed like any other income you receive (most conservatively as ordinary income). If you are a net “contributor” to the pool, you write off a minuscule amount of your earnings relative to your overall income.",
    // },
    // {
    //     question: "What counts as income?",
    //     answer: "For our standard pools, we define income as “total income” (line 9 of the Form 1040 for the 2023 Tax Year or substantial equivalents). This includes wages, royalties, bonus, carried interest, interest, dividends, and realized capital gains.\n\nExamples of things that are not counted: inheritance, illiquid assets, or income from your spouse.\n\nWe have versions of the Agreement that capture other forms of income, but we chose this transparent definition for our standard pools, because we believe it is comprehensive enough to achieve their primary goals - creating diverse communities of professionals with aligned financial incentives.",
    // },
    // {
    //     question: "Who will enforce this? How does the legal side work?",
    //     answer: "While we expect that the community and trust we build long-term will prevent people from violating the terms, we are prepared for scenarios where someone refuses to pay.\n\nWe have developed an enforceable legal agreement in partnership with a law firm (Orrick) and specialists in their FinTech practice that every member agrees to. It is our role as a company to verify income and enforce these agreements (and why it is impossible to do this independently with your friends).\n\nWe have a series of dispute mechanisms in the agreement itself. We carefully balanced two things: fairness to the person who is disputing a payment, but also strength, to make sure the integrity of the agreement is not breached by a malicious actor. This is our business, and we have a vested interest in making the pool work. We verify income through a combination of tax returns for the relevant jurisdiction and other supporting documentation.\n\nConfidentiality and privacy are our bedrock values as a company. Contributions and distributions will always stay anonymous.",
    // },
    // {
    //     question: "How do I know who I am signing with?",
    //     answer: "Pools have a “Minimum Size” to activate. In our standard pools, without any customization, this is equal to two people. After the deadline to join, you will be able to see who else finally decided to join your pool. If you feel it isn't a good fit, you have a “Trial Period” of 30 days to withdraw (see below).",
    // },
    // {
    //     question: "How does the trial period work?",
    //     answer: "If for some reason, you would like to withdraw from the pool, you have 30 days to exercise a “Cancellation Right” after the pool becomes active. This feature is to make people more comfortable signing and give them more time to fully make the decision. To exercise the right, send an email to support@prospinity.com with your name and the pool name in the body of the email. This is sufficient to withdraw completely, and we will notify you that you have been withdrawn.\n\nDuring the Cancellation Period, if the number of members in the pool drops below the Minimum Size due to cancellations, the Pool does not activate. This way, we ensure that you always signing with the Minimum Size of listed members.",
    // },
    // {
    //     question: "What is the Minimum Threshold?",
    //     answer: 'If a member’s income dips below the Minimum Threshold in a given year, they are excluded from distributions for that year. This ensures that if someone “hits it big”, other members of the pool are still incentivized to produce and contribute, and not "freeride". Our standard Minimum Threshold is $50,000.',
    // },
    // {
    //     question: "How does Prospinity make money?",
    //     answer: "There are no upfront payments. We take 5% of calculated contributions to the pool (this means 5% of the 1/2/5%). A small stake in the game aligns our incentives with yours, ensuring that the success pooling journey runs smoothly and seamlessly. It helps us cover costs such as verifying income and managing the financial infrastructure. This commission is embedded into the Agreement and will stay constant throughout the duration of the pool (we cannot change it).",
    // },
];


export default function FAQs() {
    // Theme
    const theme = useTheme();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 600 });

    const [openIndex, setOpenIndex] = useState(null); // Track which FAQ is open

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Collapse if open, expand if closed
    };

    return (
        <MainContainer theme={theme}>
            <Section>
                <Header>
                    <h2 style={{ color: theme.textPrimary, fontSize: isMobile ? "1.15rem" : "1.5rem" }}>
                        MOST COMMON QUESTIONS
                    </h2>
                </Header>

                <FAQList>
                    {faqs.map((faq, index) => (
                        <FAQItem key={index} onClick={() => toggleFAQ(index)}>
                            <QuestionRow>
                                <QuestionText>{faq.question}</QuestionText>
                                <ArrowIcon open={openIndex === index}>
                                    <ArrowDown width={18} height={18} />
                                </ArrowIcon>
                            </QuestionRow>

                            {openIndex === index && (
                                <Answer>
                                    <p style={{ whiteSpace: "pre-line", margin: 0 }}>{faq.answer}</p>
                                </Answer>
                            )}
                        </FAQItem>
                    ))}
                </FAQList>
            </Section>
        </MainContainer>
    );
}


// Styled Components
const Section = styled.div`
    width: "100%";
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem;
    background: ${({ theme }) => theme.white};;
    padding: 2rem;
    border-radius: 1.5rem;
    flex-wrap: wrap; /* Allow items to wrap on small screens */

    @media (max-width: 600px) {
        padding: 2rem 1rem;
        gap: 1rem;
    }
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;

    @media (max-width: 600px) {
        align-items: center;
    }
`;

const FAQList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const FAQItem = styled.div`
    width: 100%;
    padding: 1rem 1.25rem;
    /* border-bottom: 1px solid ${({ theme }) => theme.divider}; */
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    border-radius: 1.5rem;
    
    &:hover {
        background: ${({ theme }) => theme.backgroundLight};
    }

    @media (max-width: 600px) {
        padding: 1rem 1rem;
    }
`;

const QuestionRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
`;

const QuestionText = styled.p`
    font-size: 1.125rem;
    color: ${({ theme }) => theme.textPrimary};
    font-weight: 500;
    margin: 0;

    @media (max-width: 600px) {
        font-size: 1rem;
    }
`;

const ArrowIcon = styled.div`
    transition: transform 0.3s ease-in-out;
    transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0)")};
`;

const Answer = styled.p`
    margin: 0.5rem 0 0 0;
    font-size: 1rem;
    color: ${({ theme }) => theme.textSecondary};
    line-height: 1.6;

    @media (max-width: 600px) {
        font-size: 0.875rem;
    }
`;




// import { StyledH2, StyledH1, StyledH3, StyledH4, StyledParagraph, StyledCard } from "../components/common/StyledBootstrap";
// import { useTheme } from "styled-components";
// import { Row, Col, Accordion } from "react-bootstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStar, faAngleDown } from '@fortawesome/free-solid-svg-icons';

// function FAQs() {
//     const themeMode = useTheme();
//     return (
//         <Row className='justify-content-center my-2' style={{ minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>
//             <Col className='col-md-9'>
//                 <StyledCard className='mb-3'>
//                     <StyledCard.Body>
//                         <StyledH2 className="text-center mb-2">FAQs</StyledH2>
//                         <Accordion>
//                             <Accordion.Item eventKey="0" className='mt-1'>
//                                 <Accordion.Header>
//                                     <StyledH4>How does a Success Pool work?</StyledH4>
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                     <StyledParagraph>
//                                         Every year, members contribute a certain percentage (the “Rate”) of their income to a shared pool. The shared pool is then split evenly among everyone in the group. This agreement lasts for a set number of years after graduation (the “Time Horizon”). This process occurs in the month of November, and applies to income made in the previous calendar year (but will only begin the calendar year after you graduate).
//                                     </StyledParagraph>
//                                 </Accordion.Body>
//                             </Accordion.Item>

//                             <Accordion.Item eventKey="1">
//                                 <Accordion.Header>
//                                     <StyledH4>Why these terms?</StyledH4>
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                     <StyledParagraph>
//                                         Success pools have different functions depending on their terms. We have three standard pool types. Our Gamma pools are community focused, with a shorter time-frame and a lower percentage. Their primary benefit are the insights, networks, opportunities, and resources that members will share with each other throughout the horizon of the pool. Our Delta pools have higher percentages and longer time horizons. They offer more financial diversification and potential for “capturing upside”. An Alpha pool is a good balance between the two and is our suggested option if you are unsure about which to go with. If you would like more customization beyond these options, please feel free to email pools@prospinity.com to set up a custom solution.
//                                     </StyledParagraph>
//                                 </Accordion.Body>
//                             </Accordion.Item>

//                             <Accordion.Item eventKey="2">
//                                 <Accordion.Header>
//                                     <StyledH4>Why should I pool with someone younger/older than me?</StyledH4>
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                     <StyledParagraph>
//                                         Your contribution term is tied to your graduation date. This ensures that contributions are fair and tied to earning terms in the professional world. While they are still in school, a junior may receive distributions from a senior who just joined the workforce (and is not earning anything). But they will continue to contribute one year later than the senior, as the senior’s contribution term expires (but the senior still gets to collect the distributions). We hope that connections across years will benefit pools, as different members contribute different ideas and perspectives.
//                                     </StyledParagraph>
//                                 </Accordion.Body>
//                             </Accordion.Item>

//                             <Accordion.Item eventKey="3">
//                                 <Accordion.Header>
//                                     <StyledH4>Why should I join if I think I will make more money than everyone else?</StyledH4>
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                     <StyledParagraph>
//                                         Many of the other people in this group are also thinking the same thing. They are joining the pool because they understand that life is full of uncertainty, and outcomes are tied to factors other than perceived “talent”. “High earning” jobs now may not be high earning in the future, and vice versa. An array of events beyond your control will likely play a large role in your success, from economic shifts to personal challenges to technological advances.
//                                     </StyledParagraph>
//                                     <StyledParagraph>
//                                         Success pooling provides something you can’t achieve on your own - a way to invest in multiple life paths, both in terms of financial and social capital, hedging against the downside and capturing the upside of all of these unpredictable factors. In economic terms, we believe that the expected utility for each each individual member, at the moment of joining, is positive. The total expected dollar value of the pool is neutral (or if members take more risk and help each other because of aligned financial incentives, it can also be positive).
//                                     </StyledParagraph>
//                                     <StyledParagraph>
//                                         Treat this like an early-stage venture fund that only you have access to. You should be betting on the people themselves, rather than any sort of precise salary progression prediction. Remember, Peter Thiel was a philosophy major who clerked in a federal court before he founded PayPal and Palantir.
//                                     </StyledParagraph>
//                                 </Accordion.Body>
//                             </Accordion.Item>

//                             <Accordion.Item eventKey="4">
//                                 <Accordion.Header>
//                                     <StyledH4>How are Success Pools taxed?</StyledH4>
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                     <StyledParagraph>
//                                     We ensure through our platform that contributions and distributions are netted. We will pre-calculate your owed contribution and owed distribution with the service fee included, and only transfer income once (instead of paying you back). Thus, there is never a situation where you put money into the pool and then get it back in the same year. If you are a net “gainer” from the pool, money from the pool is taxed like any other income you receive (most conservatively as ordinary income). If you are a net “contributor” to the pool, you write off a minuscule amount of your earnings relative to your overall income.
//                                     </StyledParagraph>
//                                 </Accordion.Body>
//                             </Accordion.Item>

//                             <Accordion.Item eventKey="5">
//                                 <Accordion.Header>
//                                     <StyledH4>What counts as income?</StyledH4>
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                     <StyledParagraph>
//                                     For this pool, we define income as “total income” (line 9 of the Form 1040 for the 2023 Tax Year or substantial equivalents). This includes wages, royalties, bonus, carried interest, interest, dividends, and realized capital gains.
//                                     </StyledParagraph>
//                                     <StyledParagraph>
//                                     Examples of things that are not counted: inheritance, illiquid assets, or income from your spouse.
//                                   </StyledParagraph>
//                                     <StyledParagraph>
//                                     We have versions of the Agreement that capture other forms of income, but we chose this transparent definition for our standard pools, because we believe it is comprehensive enough to achieve their primary goals - creating diverse communities of professionals with aligned financial incentives.
//                                     </StyledParagraph>
//                                 </Accordion.Body>
//                             </Accordion.Item>

//                             <Accordion.Item eventKey="6">
//                                 <Accordion.Header>
//                                     <StyledH4>Who will enforce this? How does the legal side work?</StyledH4>
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                     <StyledParagraph>
//                                     While we expect that the community and trust we build long-term will prevent people from violating the terms, we are prepared for scenarios where someone refuses to pay.
//                                     </StyledParagraph>
//                                     <StyledParagraph>
//                                     We have developed an enforceable legal agreement in partnership with a law firm (Orrick) and specialists in their FinTech practice that every member agrees to. It is our role as a company to verify income and enforce these agreements (and why it is impossible to do this independently with your friends).
//                                     </StyledParagraph>
//                                     <StyledParagraph>
//                                     We have a series of dispute mechanisms in the agreement itself. We carefully balanced two things: fairness to the person who is disputing a payment, but also strength, to make sure the integrity of the agreement is not breached by a malicious actor. This is our business, and we have a vested interest in making the pool work. We verify income through a combination of tax returns for the relevant jurisdiction and other supporting documentation. 
//                                     </StyledParagraph>
//                                     <StyledParagraph>
//                                     Confidentiality and privacy are our bedrock values as a company. Contributions and distributions will always stay anonymous.
//                                     </StyledParagraph>
//                                 </Accordion.Body>
//                             </Accordion.Item>

//                             <Accordion.Item eventKey="7">
//                                 <Accordion.Header>
//                                     <StyledH4>How do I know who I am signing with?</StyledH4>
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                     <StyledParagraph>
//                                     Pools have a “Minimum Size” to activate. In our standard pools, without any customization, this is equal to two people. After the deadline to join, you will be able to see who else finally decided to join your pool. If you feel it isn’t a good fit, you have a “Trial Period” of 30 days to withdraw (see below).
//                                     </StyledParagraph>
//                                 </Accordion.Body>
//                             </Accordion.Item>

//                             <Accordion.Item eventKey="8">
//                                 <Accordion.Header>
//                                     <StyledH4>How does Prospinity make money?</StyledH4>
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                     <StyledParagraph>
//                                     There are no upfront payments. We take 5% of calculated contributions to the pool (this means 5% of the 1/2/5%). A small stake in the game aligns our incentives with yours, ensuring that the success pooling journey runs smoothly and seamlessly. It helps us cover costs such as verifying income and managing the financial infrastructure. This commission is embedded into the Agreement and will stay constant throughout the duration of the pool (we cannot change it).
//                                     </StyledParagraph>
//                                 </Accordion.Body>
//                             </Accordion.Item>

//                             <Accordion.Item eventKey="9">
//                                 <Accordion.Header>
//                                     <StyledH4>What is the Minimum Threshold?</StyledH4>
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                     <StyledParagraph>
//                                     If a member’s income dips below the Minimum Threshold in a given year, they are excluded from distributions for that year. This ensures that if someone “hits it big”, other members of the pool are still incentivized to produce and contribute, and not "freeride". Our standard Minimum Threshold is $50,000.
//                                     </StyledParagraph>
//                                 </Accordion.Body>
//                             </Accordion.Item>

//                             <Accordion.Item eventKey="10">
//                                 <Accordion.Header>
//                                     <StyledH4>How does the trial period work?</StyledH4>
//                                 </Accordion.Header>
//                                 <Accordion.Body>
//                                     <StyledParagraph>
//                                     If for some reason, you would like to withdraw from the pool, you have 30 days to exercise a “Cancellation Right” after the pool becomes active. This feature is to make people more comfortable signing and give them more time to fully make the decision. To exercise the right, send an email to support@prospinity.com with your name and the pool name in the body of the email. This is sufficient to withdraw completely, and we will notify you that you have been withdrawn.
//                                     </StyledParagraph>
//                                     <StyledParagraph>
//                                     During the Cancellation Period, if the number of members in the pool drops below the Minimum Size due to cancellations, the Pool does not activate. This way, we ensure that you always signing with the Minimum Size of listed members.
//                                     </StyledParagraph>
//                                 </Accordion.Body>
//                             </Accordion.Item>

//                         </Accordion>
//                     </StyledCard.Body>
//                 </StyledCard>
//             </Col>
//         </Row>
//     );
// }

// export default FAQs;