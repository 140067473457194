import VerificationInput from "react-verification-input";
import { ErrorText } from "./MiscComponents";
import "../../styles/VerificationCodeInput.css"; // ✅ Import the CSS file

export default function VerificationCodeInput({ value, onChange, error }) {
    return (
        <div className="verification-container-wrapper">
            <div className="verification-container">
                <VerificationInput
                    value={value}
                    onChange={onChange}
                    type="text"
                    placeholder=""
                    length={6}
                    classNames={{
                        container: "verification-container",
                        character: error ? "verification-character verification-character-error" : "verification-character",
                        characterInactive: "verification-character-inactive",
                        characterSelected: "verification-character-selected",
                        characterFilled: "verification-character-filled",
                    }}
                    autoFocus
                />
            </div>
            {error && <ErrorText>{error}</ErrorText>}
        </div>
    );
}
