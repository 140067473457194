import styled from 'styled-components';

export const PrimaryButton = styled.button`
  border: none;
  padding: 0.625rem 1.7rem;
  font-size: ${({ size }) => (size === "sm" ? "0.6875rem" : size === "lg" ? "0.875rem" : "0.8125rem")};
  font-weight: 500;
  border-radius: 3.125rem;
  background: ${({ theme }) => theme.buttonPrimary};
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: 'transform 0.3s ease';

  &:hover {
    opacity: 0.94;
  }
`;

export const SecondaryButton = styled.button`
  border: ${({ theme }) => `1px solid ${theme.textPrimary}`};
  padding: 0.55rem 1.7rem;
  font-size: ${({ size }) => (size === "sm" ? "0.6875rem" : size === "lg" ? "0.875rem" : "0.8125rem")};
  font-weight: 500;
  border-radius: 3.125rem;
  background: transparent;
  color: ${({ theme }) => theme.textPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: 'transform 0.3s ease';

  &:hover {
    opacity: 0.8;
  }
`;

export const PrimaryButtonLight = styled.button`
  border: none;
  padding: 0.625rem 1.7rem;
  font-size: ${({ size }) => (size === "sm" ? "0.6875rem" : size === "lg" ? "0.875rem" : "0.8125rem")};
  font-weight: 500;
  border-radius: 3.125rem;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.textPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: 'transform 0.3s ease';

  &:hover {
    opacity: 0.94;
  }
`;

export const SecondaryButtonLight = styled.button`
  border: ${({ theme }) => `1px solid ${theme.white}`};
  padding: 0.55rem 1.7rem;
  font-size: ${({ size }) => (size === "sm" ? "0.6875rem" : size === "lg" ? "0.875rem" : "0.8125rem")};
  font-weight: 500;
  border-radius: 3.125rem;
  background: transparent;
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: 'transform 0.3s ease';

  &:hover {
    opacity: 0.8;
  }
`;

export const HorizontalButtonContainer = styled.div`
    display: flex;
    gap: 1rem; /* Space between buttons */
    width: 100%;
    justify-content: center; /* Centers the buttons */
    align-items: center; /* Ensures they align properly */
`;


const ToggleButton = ({ isChecked, onToggle }) => {
  return (
      <ToggleContainer onClick={onToggle}>
          <ToggleSwitch isChecked={isChecked}>
              <ToggleCircle isChecked={isChecked} />
          </ToggleSwitch>
          <ToggleLabel isChecked={isChecked}>{isChecked ? "On" : "Off"}</ToggleLabel>
      </ToggleContainer>
  );
};

export { ToggleButton }

// ✅ Styled Components
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  user-select: none;
`;

const ToggleSwitch = styled.div`
  width: 40px;
  height: 22px;
  border-radius: 999px;
  background-color: ${({ isChecked, theme }) => (isChecked ? theme.primary : theme.border)};
  display: flex;
  align-items: center;
  padding: 2px;
  transition: background-color 0.3s ease-in-out;
`;

const ToggleCircle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isChecked }) => (isChecked ? "translateX(18px)" : "translateX(0)")};
`;

const ToggleLabel = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.textPrimary };
`;