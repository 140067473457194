import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import logoBlack from "../assets/icons/logo.svg";


// Styled Components
const AuthContainer = styled.div`
    display: flex;
    height: 100vh;
    position: fixed;  /* Fix the container */
    width: 100vw;     /* Ensure full width */
    overflow: hidden; /* Prevents unwanted scrolling */
    user-select: none; /* Prevents text selection */
`;

const Logo = styled.img`
    position: absolute;
    top: 2.5rem;
    left: 2.5rem;
    width: 147px;
    z-index: 10;
`;

const LeftSection = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4.8rem 2.5rem 2.5rem 2.5rem;
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;   /* Centers everything horizontally */
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 2rem;
`;

// Styled Components
const BackgroundContainer = styled.div`
    position: relative;
    width: 50%;
    height: 100vh;
    overflow: hidden;
    background: #0a1128;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    pointer-events: none; /* Prevents accidental dragging */
    
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const TextContainer = styled(motion.div)`
    position: absolute;
    bottom: 3rem;
    color: white;
    text-align: center;
    max-width: 80%;
    z-index: 2;
    min-height: 6rem;  /* Prevents sudden layout shifts */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    h3 {
        font-size: 1.5rem;
        font-weight: 600;
    }
    
    p {
        font-size: 1rem;
    }
`;


export function RegistrationLayoutDesktop() {
    const location = useLocation();

    // If the route is /get-started, just return the Outlet (skip the layout)
    if (location.pathname === "/finish-registration/get-started") {
        return <Outlet />;
    }

    return (
        <AuthContainer>
            {/* Fixed Logo */}
            <Logo src={logoBlack} alt="Prospinity Logo" />

            {/* Left Section (Form) */}
            <LeftSection>
                <FormWrapper>
                    <Outlet />
                </FormWrapper>
            </LeftSection>

            {/* Right Section (Background + Text) */}
            <RegistrationBackground />
        </AuthContainer>
    );
}



export default RegistrationLayoutDesktop;



export function RegistrationBackground() {
    const [index, setIndex] = useState(0);

    const images = [
        "/assets/signupGraphic1.png",
        "/assets/signupGraphic2.png",
        "/assets/signupGraphic3.png"
    ];
    
    const texts = [
        {
            title: "Multiply Your Chances of Extraordinary Success",
            description: "Being in the right place at the right time matters. A pool increases your chances, multiplying opportunities."
        },
        {
            title: "Gain an Unparalleled Network",
            description: "Pool members have a direct stake in each other's success, leading to shared resources, opportunities, and lasting connections."
        },
        {
            title: "Build a Diversified Portfolio of Life Paths",
            description: "Careers are unpredictable. A pool reduces risk by giving you exposure to multiple life journeys."
        }
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prev) => (prev + 1) % images.length);
        }, 5000); // Change every 5 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <BackgroundContainer>
            <AnimatePresence>
                <motion.img
                    key={index}
                    src={images[index]}
                    draggable="false"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 2 }}
                />
                {/* <img key={index} src={images[index]} draggable="false" /> */}
            </AnimatePresence>

            <AnimatePresence mode="wait">
                <TextContainer
                    key={index}
                    initial={{ opacity: 0, y: 10, height: 0 }}
                    animate={{ opacity: 1, y: 0, height: "auto" }}
                    exit={{ opacity: 0, y: -10, height: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <motion.h3
                        key={texts[index].title}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.8 }}
                    >
                        {texts[index].title}
                    </motion.h3>

                    <motion.p
                        key={texts[index].description}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 0.64, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                    >
                        {texts[index].description}
                    </motion.p>
                </TextContainer>
            </AnimatePresence>

        </BackgroundContainer>
    );
}
