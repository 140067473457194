import { Outlet } from "react-router-dom";
import styled, { useTheme } from 'styled-components';
import logo from "../assets/images/logo_white.png";


export function RegistrationLayoutMobile() {
    // Get the theme 
    const theme = useTheme();

    return (
        <MobileContainer>
            {/* Top Section with Blue Background and Logo */}
            <TopSection>
                <Logo src={logo} alt="Prospinity Logo" />
            </TopSection>

            {/* Form Section */}
            <FormWrapper>
                <Outlet />
            </FormWrapper>
        </MobileContainer>
    );
}

// Styled Components
const MobileContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100dvh; /* Ensures full height on all devices */
    width: 100vw;
    overflow: hidden;

    //touch-action: none;
`;

const TopSection = styled.div`
    position: relative;
    width: 100%;
    height: 124px; /* ✅ Increased from 124px to push down */
    overflow: hidden;
    background: url("/assets/MobileBackground.png") no-repeat center;
    background-size: 100% 330px; /* ✅ Adjusted height */
    background-position: top;
    display: flex;
    justify-content: center;
    align-items: flex-start; /* ✅ Moves content lower */
`;


const Logo = styled.img`
    height: 2rem;
    position: absolute;
    top: 1rem;
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: calc(100dvh - 124px); /* Ensures full screen minus the top */
    padding: 1rem;
    z-index: 3;
`;

export default RegistrationLayoutMobile;
