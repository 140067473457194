import React from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useTheme, styled } from 'styled-components';

import logoBlack from '../../assets/icons/logo.svg';
import { HomeIcon, PoolsIcon, PeopleIcon, ChatIcon, FinancesIcon, HomeIconActive, PoolsIconActive, PeopleIconActive, ChatIconActive, FinancesIconActive } from '../../assets/icons/Icons';

export default function SideBar() {
    // Navigation
    const navigate = useNavigate();
    const location = useLocation();

    // Theme
    const theme = useTheme();

    const links = [
        { label: 'Home', to: '/dashboard/home', icon: HomeIcon, iconActive: HomeIconActive },
        { label: 'Pools', to: '/dashboard/pools', icon: PoolsIcon, iconActive: PoolsIconActive },
        { label: 'People', to: '/dashboard/portfolio', icon: PeopleIcon, iconActive: PeopleIconActive},
        { label: 'Inbox', to: '/dashboard/inbox', icon: ChatIcon, iconActive: ChatIconActive},
        { label: 'Finances', to: '/dashboard/finances', icon: FinancesIcon, iconActive: FinancesIconActive },
    ];

    return (
        <div
            style={{
                width: '260px',
                height: '100vh',
                backgroundColor: theme.white,
                display: 'flex',
                flexDirection: 'column',
                padding: '1.5rem',
                borderRight: `1px solid ${theme.divider}`,
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 1000,
            }}
        >
            {/* Logo */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '2.3rem',
                    marginRight: '3.5rem',
                }}
            >
                <Link to="/dashboard/home">
                    <img
                        src={logoBlack}
                        alt="Prospinity Logo"
                        style={{
                            width: '150px',
                            height: 'auto',
                            cursor: 'pointer',
                        }}
                    />
                </Link>
            </div>
            {/* Navigation Links */}
            {links.map((link) => (
                <SideBarLink
                    key={link.to}
                    label={link.label}
                    to={link.to}
                    icon={link.icon}
                    iconActive={link.iconActive}
                    active={
                        link.to === '/dashboard/home'
                            ? location.pathname === '/dashboard' ||
                              location.pathname === '/dashboard/' ||
                              location.pathname === '/dashboard/home'
                            :
                        link.to === '/dashboard/portfolio'
                            ? location.pathname === '/dashboard/portfolio' ||
                              location.pathname.startsWith('/dashboard/user') || 
                              location.pathname.startsWith('/dashboard/search')
                            :location.pathname.startsWith(link.to)
                    }
                    onClick={() => navigate(link.to)}
                    theme={theme}
                />
            ))}
            {/* ✅ Fixed Promo Card */}
            <PromoCard>
                <PromoContent>
                    <PromoText>HAVE A GROUP IN MIND?</PromoText>
                    <PromoSubtext>Set up a custom pool</PromoSubtext>
                    <PromoButton theme={theme} onClick={() => window.open("https://o0bf65aidiq.typeform.com/to/cAMyJdcD", "_blank")}>CREATE A POOL</PromoButton>
                </PromoContent>
            </PromoCard>
        </div>
    );
}

const SideBarLink = ({ label, to, icon: Icon, iconActive: IconActive, active, onClick, theme }) => {
    return (
        <div
            className="nav-item d-flex align-items-center justify-content-start py-2 mb-2"
            style={{
                borderRadius: '1.5rem',
                padding: '0.7rem 0.9375rem',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                color: active ? theme.textPrimary : theme.textSecondary, 
                backgroundColor: active ? theme.primaryLight : 'transparent', 
            }}
            onMouseEnter={(e) => {
                if (!active) {
                    e.currentTarget.style.backgroundColor = theme.primaryLight;
                }
            }}
            onMouseLeave={(e) => {
                if (!active) {
                    e.currentTarget.style.backgroundColor = 'transparent';
                }
            }}
            onClick={onClick}
        >
            <div
                style={{
                    width: '100%',
                    borderRadius: '2rem', // Makes the rounded hover bigger
                    padding: '0.2rem 0.2rem', // More spacing inside
                    backgroundColor: active ? theme.primaryLight : 'transparent', 
                    display: 'flex',
                    alignItems: 'center',
                    transition: 'background-color 0.3s ease',
                    fontSize: "0.875rem",
                }}
            >
                <IconWrapper>
                    {active ? 
                        <IconActive fill={theme.primary} />
                        :
                        <Icon fill={theme.textSecondary} />
                    }
                </IconWrapper>
                {/* <img src={active ? iconActive : icon} alt="Sidebar Icon" /> */}
                <span 
                    style={{
                        color: active ? theme.textPrimary : theme.textSecondary,
                        fontWeight: active ? '500' : 'normal',
                        marginLeft: "1rem",
                    }}
                >
                    {label}
                </span>
            </div>
        </div>
    );
};

export { SideBarLink };


// ✅ Sidebar Promo Card
const PromoCard = styled.div`
  position: absolute;
  bottom: 1rem; /* Keeps it at the bottom */
  left: 50%;
  transform: translateX(-50%); /* Centers it */
  width: 228px; /* Adjust width relative to sidebar */
  background: url('/assets/promoBanner.svg') center/cover no-repeat;
  border-radius: 1rem;
  min-height: 229px;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* ✅ Pushes content to the bottom */
  align-items: center;
`;

// ✅ Text Wrapper to keep them grouped at the bottom
const PromoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end; /* ✅ Pushes text & button down */
  margin-bottom: 1rem;
  width: 85%;
`;

// ✅ Promo Text
const PromoText = styled.p`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  text-align: center;
`;

// ✅ Promo Subtext
const PromoSubtext = styled.p`
  font-size: 0.875rem;
  opacity: 0.8;
  margin-bottom: 1rem;
  text-align: center;
`;

// ✅ Promo Button
const PromoButton = styled.button`
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.textPrimary};;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.375rem 1rem;
  border-radius: 2rem;
  border: none;
  pointer-events: all; /* ✅ Overrides pointer-events from parent */
  transition: background 0.3s ease, transform 0.2s ease;

  &:hover {
    opacity: 0.94;
  }
`;


const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;