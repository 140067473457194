import styled, { useTheme } from "styled-components";
import { ErrorText } from "./MiscComponents";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; // ✅ Import default styles

export default function CustomPhoneInput({ label, value, onChange, error }) {
    const theme = useTheme();

    return (
        <InputContainer>
            <StyledPhoneInput
                international
                defaultCountry="US" // ✅ Set default country
                value={value}
                onChange={onChange} // ✅ Updates parent state
                placeholder="Enter your phone number"
                theme={theme}
            />
            <FloatingLabel theme={theme}>{label}</FloatingLabel>

            {error && <ErrorText>{error}</ErrorText>}
        </InputContainer>
    );
}

// Styled Components
const InputContainer = styled.div`
    width: 100%;
    position: relative;
`;

const StyledPhoneInput = styled(PhoneInput)`
    width: 100%;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 1rem;
    outline: none;
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.textPrimary};

    /* Ensure flag and country code stay aligned */
    .PhoneInputCountry {
        margin-right: 0.625rem;
    }

    /* Remove border from the inner input field */
    .PhoneInputInput {
        border: none !important;  /* ✅ Removes inner input border */
        outline: none !important;
    }

    ::placeholder {
        color: ${({ theme }) => theme.textSecondary};
        opacity: 0.6;
    }
`;


const FloatingLabel = styled.label`
    position: absolute;
    top: -0.6rem;
    left: 1.5rem;
    background: ${({ theme }) => theme.white};
    padding: 0 0.3rem;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.textSecondary};
`;

