import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2.5rem;
    height: 100%;
    box-sizing: border-box;
    font-size: "1rem"; /* Base font size for rem scaling */
    color: ${({ theme }) => theme.textPrimary}; /* Default text color */
    background-color: inherit; /* Matches dashboard background */
    overflow-y: auto; /* Scrollable content */
    touch-action: manipulation; /* For smoother scrolling */

    @media (max-width: 768px) {
        padding: 1.5rem 1rem 7rem 1rem;
        font-size: 0.875rem;
    }
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => direction || 'row'};
    justify-content: ${({ justify }) => justify || 'flex-start'};
    align-items: ${({ align }) => align || 'stretch'};
    flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
    gap: ${({ gap }) => gap || '0'};
`;

export const RegistrationContainer = styled.div`
    display: flex;
    align-items: ${({ isMobile }) => (isMobile ? "center" : "flex-start")}; 
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    margin-top: ${({ isMobile }) => (isMobile ? "-124px" : "0")};
`;

export const StyledHeader = styled.h1`
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.5rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.textPrimary};
    margin-bottom: 0;

    @media (max-width: 768px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
`;
