import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie";
import { motion, AnimatePresence } from "framer-motion";
import { myAxiosInstance } from "../../api/axiosConfig";

import { SimpleTextPage } from "./SurveyScreens";
import { SurveyOptionContainer, SurveyOptionButton } from "./SurveyComponents";
import { useApplication } from "../../contexts/ApplicationContext";


export default function ScreenTwo() {
  const [isTypingDone, setIsTypingDone] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const navigate = useNavigate();
  const typingSpeed = 85;
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  const { formData, updateFormData } = useApplication();
  const email = useRef(formData.email);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  
  useEffect(() => {
    if (!formData.uniqueId) {
      const uniqueId = uuidv4();
      sessionStorage.setItem("unique_id", uniqueId);

      // Use sendBeacon if supported for better reliability
      const data = new FormData();
      data.append("unique_id", uniqueId);
      const BASE_URL = process.env.REACT_APP_API_URL
      navigator.sendBeacon(`${BASE_URL}/api/track-application/`, data);
    
    }
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "black";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const handleTypingDone = () => {
    setIsTypingDone(true);
  };

  // Handle Input Change
  const handleAnswerChange = (e) => {
    const value = e.target.value;
    email.current = value;

    // Clear error if user starts typing
    if (error) {
      setError("");
    };

  };

  const handleYes = async () => {
    // Checks
    if (!email.current || !email.current.endsWith("berkeley.edu")) {
        setError("Must be a valid Berkeley email.");
        return;
    }

    // Track
    let uniqueId = formData.uniqueId;

    if (!uniqueId) {
      uniqueId = uuidv4();
      sessionStorage.setItem("unique_id", uniqueId);
    }

    // Save the email
    updateFormData("email", email.current);
    sessionStorage.setItem("email", email.current);

    // Use sendBeacon if supported for better reliability
    const data = new FormData();
    data.append("unique_id", uniqueId);
    data.append("screen2", email.current);

    // Set loading state
    setIsSubmitting(true);

    try {
      // Send request using Axios
      const response = await myAxiosInstance.post('api/track-application/', data);

    } catch (error) {

    } finally {
      // Remove loading state
      setIsSubmitting(false);
    }

    setIsFadingOut(true);
    setTimeout(() => navigate("/berkeley/application/3"), 600); // Wait for animation before navigating
  };

  const handleGlobalKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default behavior
      handleYes(); // Call the same logic as handleNext
    } 
  };

  useEffect(() => {
    window.addEventListener("keydown", handleGlobalKeyPress);
    return () => {
      window.removeEventListener("keydown", handleGlobalKeyPress);
    };
  }, [navigate]);


  const firstPageText = [
    {
      sequences: [
        { content: 700, wrapper: "span", style: {} },
        { content: "What's your Berkeley email?", wrapper: "span", style: {} },
        { content: 500, wrapper: "span", style: {} },
      ]
    },
  ];

  return (
    <AnimatePresence>
      {!isFadingOut && (
        <motion.div
          key="screen-one"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }} // Smooth fade-out
          transition={{ duration: 0.6, ease: "easeOut" }} // Smooth easing
          style={{ width: isMdOrLarger ? "65%" : "100%" }}
        >
          {/* Text content */}
          <SimpleTextPage
            paragraphs={firstPageText}
            typing={true}
            typingSpeed={typingSpeed}
            onTypingDone={handleTypingDone} // Ensures we track typing completion
          />
  
          {/* Buttons (only appear after typing is done) */}
          {isTypingDone && (
            <motion.div
              key="buttons"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 0 }} // Ensure buttons fade with text
              transition={{ duration: 0.6 }}
              style={{ width: "100%" }}
            >
                <FormSection isMdOrLarger={isMdOrLarger} style={{ width: "90%" }}>
                  <SurveyInput
                      key={`input-${email}`}
                      autoFocus
                      placeholder="Type your Berkeley email here..."
                      isMdOrLarger={isMdOrLarger}
                      defaultValue={email.current}
                      onChange={handleAnswerChange}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      variants={fadeVariants}
                      transition={{ duration: 0.6 }}
                    />

                  {isMdOrLarger &&
                    <HintText
                      key={`hint-1`}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      variants={fadeVariants}
                      transition={{ duration: 0.6 }}
                    >
                      (Press Enter to continue)
                    </HintText>
                  }

                  <AnimatePresence>
                    {error && (
                      <ErrorText
                        key={`error-1`}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        {error}
                      </ErrorText>
                    )}
                  </AnimatePresence>
                </FormSection>

                <SurveyOptionContainer isMdOrLarger={isMdOrLarger}> 
                    <SurveyOptionButton
                            key={"NO"}
                            onClick={isSubmitting ? null : handleYes}
                            isMdOrLarger={isMdOrLarger}
                        >
                            <span style={{ opacity: isSubmitting ? 0.5 : 1 }}>
                              {isSubmitting ? (
                                <LoaderContainer>
                                  <LoaderDot variants={loaderVariants} animate="animate" />
                                  <LoaderDot variants={loaderVariants} animate="animate" style={{ animationDelay: "0.2s" }} />
                                  <LoaderDot variants={loaderVariants} animate="animate" style={{ animationDelay: "0.4s" }} />
                                </LoaderContainer>
                              ) : "CHECK EMAIL"}
                            </span>
                    </SurveyOptionButton>   
                </SurveyOptionContainer>
            </motion.div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}



const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ isMdOrLarger }) => (isMdOrLarger ? "60%" : "100%")};;
`;

const QuestionText = styled(motion.div)`
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.8rem" : "1.2rem")};
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;
  color: rgba(255, 255, 255, 0.9);
`;

const SurveyInput = styled(motion.input)`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.5rem" : "1rem")};
  padding: 0.5rem 0;
  width: 100%;
  color: white;
  outline: none;

  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    border-bottom: 2px solid white;
  }
`;

const HintText = styled(motion.div)`
  margin-top: 0.5rem;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.5);
  font-style: italic;
`;

const ErrorText = styled(motion.div)`
  margin-top: 0.5rem;
  font-size: 1rem;
  color: red;
`;

const fadeVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const LoaderContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* ✅ Ensures full height to center dots */
  margin-top: 0; /* ✅ Removes extra spacing */
`;

const LoaderDot = styled(motion.div)`
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  margin: 0 4px;
`;

const loaderVariants = {
  animate: {
    opacity: [0.2, 1, 0.2], // Glow effect
    transition: {
      repeat: Infinity,
      duration: 1,
      ease: "easeInOut",
    },
  },
};