import React from 'react';
import { useTheme } from 'styled-components';

const CustomAvatar = ({ name, image, size = 40 }) => {
    const theme = useTheme();

    const bgColor = theme.textPrimary;
    const initials = name?.charAt(0)?.toUpperCase() || "?";
  
    if (image) {
      return (
        <img
            src={image}
            alt={name}
            onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop
                e.target.src = "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW_20250320180317.webp";
            }}
            style={{
                width: size,
                height: size,
                borderRadius: "6px",
                objectFit: "cover",
            }}
        />
      );
    }
  
    return (
      <div
        style={{
          width: size,
          height: size,
          borderRadius: "6px",
          backgroundColor: bgColor,
          color: "white",
          fontWeight: 600,
          fontSize: size * 0.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textDecoration: "none",
        }}
      >
        <span style={{ textDecoration: "none" }}>{initials}</span>
      </div>
    );
};
  
export default CustomAvatar;