import React, { useState, useEffect } from 'react';

const MeetingTimer = ({ meetingTime, onTime}) => {
    const ogNow = new Date();
    const [pastTime, setPastTime] = useState(ogNow >= new Date(meetingTime));

    
    const calculateTimeRemaining = (deadline) => {
        const now = new Date();
        const deadlineDate = new Date(deadline);
        const timeDiff = deadlineDate - now;

        if (timeDiff > 0) {
            const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
            const seconds = Math.floor((timeDiff / 1000) % 60);

            if (days >= 7) {
                // More than a week
                return `${days} ${days === 1 ? 'day' : 'days'}`;
            } else if (days > 0) {
                // More than a day but less than a week
                return `${days} ${days === 1 ? 'day' : 'days'}`;
            } else {
                // Less than a day
                return [
                    hours.toString().padStart(2, '0'),
                    minutes.toString().padStart(2, '0'),
                    seconds.toString().padStart(2, '0')
                ].join(':');
            }
        } else {
            return <></>;
        }
    };



    const checkDeadlines = () => {
        const now = new Date();
        const newPastTime = now >= new Date(meetingTime);

        // Trigger the callback if a deadline has just passed
        if (newPastTime && !pastTime) {
            setPastTime(true);
            onTime();
        }
    };

    

    const getRemainingTimeText = () => {
        checkDeadlines();
        if (pastTime) {
            return <></>;
        } else {
            return (
                <>
                    <strong>{calculateTimeRemaining(meetingTime)}</strong>
                </>
            );
        }
    };
    

    const [remainingTime, setRemainingTime] = useState(getRemainingTimeText());

    useEffect(() => {
        const interval = setInterval(() => {
            setRemainingTime(getRemainingTimeText());
        }, 1000);

        return () => clearInterval(interval);
    }, [meetingTime]);

    return (
        <>
            {remainingTime}
        </>
    );
};



export default MeetingTimer;
