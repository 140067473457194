import React from 'react';
import { useChannelPreviewInfo } from 'stream-chat-react';
import { useChannelStateContext } from 'stream-chat-react';
import { useChatContext } from 'stream-chat-react';
import { useTranslationContext } from 'stream-chat-react';
import { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-bootstrap-icons';
import styled from 'styled-components';


import CustomAvatar from './CustomAvatar';


const DefaultMenuIcon = ({ title }) => {
  const { t } = useTranslationContext('MenuIcon');

  return (
    <svg data-testid='menu-icon' viewBox='0 0 448 512' xmlns='http://www.w3.org/2000/svg'>
      <title>{title ?? t('Menu')}</title>
      <path
        d='M0 88C0 74.75 10.75 64 24 64H424C437.3 64 448 74.75 448 88C448 101.3 437.3 112 424 112H24C10.75 112 0 101.3 0 88zM0 248C0 234.7 10.75 224 24 224H424C437.3 224 448 234.7 448 248C448 261.3 437.3 272 424 272H24C10.75 272 0 261.3 0 248zM424 432H24C10.75 432 0 421.3 0 408C0 394.7 10.75 384 24 384H424C437.3 384 448 394.7 448 408C448 421.3 437.3 432 424 432z'
        fill='currentColor'
      />
    </svg>
  );
};

const UnMemoizedChannelHeader = ({ setChatVisible, backToChannelList, ...props }) => {
  const {
    MenuIcon = DefaultMenuIcon,
    image: overrideImage,
    live,
    title: overrideTitle,
  } = props;

  const themeMode = useTheme();
  const { channel, watcher_count } = useChannelStateContext('ChannelHeader');
  const { openMobileNav } = useChatContext('ChannelHeader');
  const { t } = useTranslationContext('ChannelHeader');
  const { displayImage, displayTitle } = useChannelPreviewInfo({
    channel,
    overrideImage,
    overrideTitle,
  });

  const { member_count, subtitle } = channel?.data || {};

  const default_img = "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW_20250320180317.webp";

  const members = Object.values(channel.state.members);
  const isGroup = members.length > 2;
  const currentUserID = channel.state.membership.user.id;
  const other_user = members.length > 2 ? null : members.find((member) => member.user.id !== currentUserID)?.user;

  let redirectUrl = "/";
  if ( members.length === 2 && other_user) {
    // Format the name
    const nameParts = other_user.name.split(" ");
    const formattedName = nameParts.length > 1 
        ? `${nameParts[0]}-${nameParts.slice(1).join("")}` 
        : nameParts[0];

    redirectUrl = `/dashboard/user/${formattedName.toLowerCase()}-${other_user.id}`;
  } else if (isGroup) {
    redirectUrl = `/dashboard/pools/${channel.id}`;
  }

  // Get the img
  const imageSrc = isGroup ? default_img : other_user?.image;

  // Get the title
  const title = isGroup
    ? channel.data.name || "Pool"
    : displayTitle


  return (
    <div className='str-chat__header-livestream str-chat__channel-header' style={{ padding: "1.5rem 2rem 0rem 1rem" }}>
      <button
        className='str-chat__header-hamburger'
        onClick={openMobileNav}
      >
        <MenuIcon />
      </button>
      <div
        style={{ cursor: 'pointer' }}
        onClick={backToChannelList}
      >
        <ArrowLeft style={{ fontSize: "1.3rem", width: "1.3rem", height: "1.3rem" }}/>
      </div>
      <NoUnderlineLink to={redirectUrl}>
        <CustomAvatar
          image={imageSrc}
          name={title}
          size={channel?.type === 'commerce' ? 60 : 40}
        />
      </NoUnderlineLink>
      <div className='str-chat__header-livestream-left str-chat__channel-header-end'>
        <p className='str-chat__header-livestream-left--title str-chat__channel-header-title'>
          <Link to={redirectUrl} style={{ textDecoration: 'none', color: 'inherit' }}>
            {title}
          </Link>{' '}
          {live && (
            <span className='str-chat__header-livestream-left--livelabel'>{t('live')}</span>
          )}
        </p>
        {subtitle && <p className='str-chat__header-livestream-left--subtitle'>{subtitle}</p>}
        <p className='str-chat__header-livestream-left--members str-chat__channel-header-info'>
          {!live && !!member_count && member_count > 0 && (
            <>
              {t('{{ memberCount }} members', {
                memberCount: member_count,
              })}
              ,{' '}
            </>
          )}
          {t('{{ watcherCount }} online', { watcherCount: watcher_count })}
        </p>
      </div>
    </div>
  );
};


export const CustomChannelHeader = React.memo(UnMemoizedChannelHeader);


const NoUnderlineLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;
