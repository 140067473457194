import React, { useState, useEffect, useRef } from 'react';
import { styled, useTheme } from "styled-components";
import { useNavigate } from 'react-router-dom';
import { isMotionValue, motion } from "framer-motion";
import { useMediaQuery } from 'react-responsive';

import CenteredModal from '../common/Modals';
import { myAxiosInstance } from '../../api/axiosConfig';
import { PrimaryButton, SecondaryButton } from '../common/Buttons';
import { ReactComponent as MessageIcon } from "../../assets/icons/messageIcon.svg";
import { ReactComponent as ThreeDots } from "../../assets/icons/threeDots.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/addIcon.svg";


export default function PortfolioPreviewCard({ user, onRemove }) {
    const theme = useTheme();
    const navigate = useNavigate();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 600 });

    // dropdown menue
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    // Modals
    const [showRemoveModal, setShowRemoveModal] = useState(false);

    // Profile picture
    const profilePicture = user.profile_picture_url?.trim() !== ""
    ? user.profile_picture_url
    : "https://storage.googleapis.com/prosp_profile_img/favicons/favicon.svg";

    // Portfolio Status
    const [portfolioStatus, setPortfolioStatus] = useState(user.portfolio_status);

    // Remove modal open
    const handleRemoveModal = (e) => {
      e.stopPropagation();
      setMenuOpen(false);
      setShowRemoveModal(true);
    };

    // Removing from your portfolio
    const handleRemove = () => {
      onRemove(user);   
      setMenuOpen(false);   
    };

    // Button Actions
  const handleSendRequest = async (e) => {
    e.stopPropagation(); // Prevents navigation when clicking the button

    try {
      await myAxiosInstance.post(`/api/make-portfolio-request/`, {
        receiving_user: user.slug,
      });

      setPortfolioStatus("portfolio_request_sent");

    } catch (error) {
      console.error("Error sending request:", error);
    }

  };

  const handleUndoRequest = async (e) => {
    e.stopPropagation();

    try {
      await myAxiosInstance.post(`/api/manage-portfolio-request/${user.slug}/`);
  
      // Reset state back to not_portfolio
      setPortfolioStatus("not_porfolio");
  
    } catch (error) {
      console.error("Error undoing request:", error);
    }
  }

  const handleMessageButtonClick = async (e) => {
     e.stopPropagation();

      try {
          const response = await myAxiosInstance.post('/api/create-channel/', { recipient_slug: user.slug });
          const { channel_id } = response.data;
          navigate("/dashboard/inbox/", { state: { openChannelId: channel_id } });
      }
      catch (error) {
      }
  };

  const renderActionButton = () => {
      switch (portfolioStatus) {
        case "self":
          return (
            <PortfolioButtonsContainer>
                <SecondaryButton size={isMobile ? "md" : "lg"} theme={theme} style={{ padding: "0.625rem 2rem", flex: "1" }} onClick={() => navigate(`/dashboard/user/${user.slug}`)}>
                    VIEW
                </SecondaryButton>
            </PortfolioButtonsContainer>
          );
          
        case "in_portfolio":
          return (
              <PortfolioButtonsContainer>
                    <SecondaryButton size={isMobile ? "md" : "lg"} style={{ padding: "0.5rem 1rem", flex: "1" }} onClick={handleMessageButtonClick}>
                        <MessageIcon />
                        MESSAGE
                    </SecondaryButton>
                    <SecondaryButton 
                      style={{ borderRadius: "50%", width: "2.3rem", height: "2.3rem", padding: "0", aspectRatio: "1" }}
                      onClick={(e) => {
                          e.stopPropagation(); // Prevent navigating when clicking dots
                          setMenuOpen(!menuOpen);
                      }}
                      ref={buttonRef}
                    >
                        <ThreeDots />
                    </SecondaryButton>

                    {/* Dropdown Menu */}
                    {menuOpen && (
                        <PortfolioDropdownContainer theme={theme} ref={menuRef}>
                            <PortfolioDropdownOption style={{ paddingTop: "1.1rem", paddingBottom: "1.1rem" }} onClick={handleRemoveModal}>Remove</PortfolioDropdownOption>
                        </PortfolioDropdownContainer>
                    )}

              </PortfolioButtonsContainer>
          );

        case "portfolio_request_pending":
          return (
            <PortfolioButtonsContainer>
              <SecondaryButton size={isMobile ? "md" : "lg"} theme={theme} style={{ padding: "0.625rem 2rem", marginLeft: "0.3rem", flex: "1" }} onClick={() => navigate(`/dashboard/user/${user.slug}`)}>
                VIEW
              </SecondaryButton>
            </PortfolioButtonsContainer>
          );

        case "not_porfolio":
          return (
            <PortfolioButtonsContainer>
              <SecondaryButton size={isMobile ? "md" : "lg"} style={{ flex: "1" }} theme={theme} onClick={handleSendRequest}>
                <AddIcon fill={theme.textPrimary} />
                ADD
              </SecondaryButton>
            </PortfolioButtonsContainer>
          );

        case "portfolio_request_sent":
          return (
            <PortfolioButtonsContainer>
              <SecondaryButton size={isMobile ? "md" : "lg"} style={{ flex: "1" , pointerEvents: "none" }} theme={theme} disabled={true}>
                SENT
              </SecondaryButton>
            </PortfolioButtonsContainer>
          );

        default:
          return null;
      }
    };

    // Close dropdown when clicking outside
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          menuRef.current &&
          !menuRef.current.contains(event.target) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target)
        ) {
          setMenuOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <PortfolioCardContainer onClick={() => { navigate(`/dashboard/user/${user.slug}`); }} theme={theme}>
            <PortfolioImageContainer>
                <PortfolioProfileImage 
                    src={profilePicture} 
                    alt={`${user.first_name} ${user.last_name}`} 
                    onError={(e) => {
                        e.target.onerror = null; // Prevent infinite loop
                        e.target.src = "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW_20250320180317.webp";
                    }} 
                />
            </PortfolioImageContainer>

            <BottomSection>
                <PortfolioUserInfo>
                    <PortfolioUserName>{user.first_name} {user.last_name}</PortfolioUserName>
                    <PortfolioUserUniversity>{user.university}</PortfolioUserUniversity>
                </PortfolioUserInfo>

                {renderActionButton()}

            </BottomSection>

          {/* Remove Modal */}
          <CenteredModal 
              show={showRemoveModal} 
              onClose={(e) => setShowRemoveModal(false)}
              title={"REMOVE CONNECTION"} 
              subtitle={`Are you sure you want to remove ${user.first_name} ${user.last_name} from your portfolio?`} 
              buttonsType={"two"} 
              primaryText={"REMOVE"}
              onPrimaryClick={() => {handleRemove(); setShowRemoveModal(false)} }
              secondaryText = 'CANCEL'
              onSecondaryClick={() => setShowRemoveModal(false)}
              alignCenter={false}
          />

        </PortfolioCardContainer>
    );
}

const PortfolioCardContainer = styled.div`
  background: ${({ theme }) => theme.backgroundLight};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;
  position: relative;

  &:hover {
    transform: scale(1.02); /* Slightly enlarges */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); /* Soft shadow */
  }
`;

const PortfolioImageContainer = styled.div`
  width: 100%;
  /* height: 15.4375rem; */
  overflow: hidden; /* Ensures no overflow */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PortfolioProfileImage = styled.img`
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover; /* Fills the container while maintaining aspect ratio */
  border-radius: 1.5rem 1.5rem 0 0;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 1.25rem 1.25rem 1.25rem;
  gap: 1rem;
`;

const PortfolioUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  width: 100%;
`;

const PortfolioUserName = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textPrimary};
  white-space: nowrap;  
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;  /* Ensure it doesn’t overflow */
  display: block; /* Prevents flex issues */
`;

const PortfolioUserUniversity = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.textSecondary};
  margin: 0;
  white-space: nowrap;  
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;  /* Ensure it doesn’t overflow */
  display: block; /* Prevents flex issues */
`;

const PortfolioButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.6rem;
  width: 100%;
`;

const PortfolioDropdownContainer = styled.div`
  position: absolute;
  bottom: 15%; /* Moves it upwards instead of down */
  right: 1rem; /* Aligns it with the three dots button */
  background: ${({ theme }) => theme.white};
  border-radius: 1rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  width: 55%;
  padding: 0;
  z-index: 100; /* Ensures it's always on top */
  overflow: hidden; /* Prevents the hover effect from overflowing */
`;

const PortfolioDropdownOption = styled.button`
  width: 100%;
  padding: 0.6rem 0.7rem;
  font-size: 0.875rem;
  background: none;
  border: none;
  color: ${({ theme }) => theme.textPrimary};
  text-align: left;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.backgroundDarker}; /* Apply hover */
  }
`;
