import React from 'react';
import styled, { useTheme } from 'styled-components';


const PoolTimeGraphic = () => {
    const theme = useTheme();
    
    return (
        <Section>
            <Title>YOUR POOL</Title>
            <TimelineWrapper>
                {["2025", "2026", "2027", "2028", "2029"].map((year, index) => (
                    <YearColumn key={year}>
                        <GridLine />
                        <YearLabel>{year}</YearLabel>
                    </YearColumn>
                ))}

                {/* Marker */}
                <Marker>
                    <VerticalGroup>
                        <Tooltip>
                            <strong>Pool Is Active</strong>
                            <span>You can follow the trajectory of your pool here.</span>
                        </Tooltip>

                        <HorizontalRow>
                            <Circle />
                            <Line />
                        </HorizontalRow>

                        <BlueFade />
                    </VerticalGroup>
                </Marker>

            </TimelineWrapper>
        </Section>
    );
};  

export { PoolTimeGraphic }


// Styled Components
const Section = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${({ theme }) => theme.white};;
    padding: 1.5rem;
    border-radius: 1.5rem;
    flex-wrap: wrap; /* Allow items to wrap on small screens */
    gap: 1rem;
    z-index: 0;

    @media (max-width: 600px) {
        padding: 1.25rem;
    }
`;

const Title = styled.div`
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textSecondary};

    @media (max-width: 600px) {
        margin-bottom: 1.5rem 1.25rem;
    }
`;

const TimelineWrapper = styled.div`
    padding: 1.375rem 2rem 0rem 1rem;
    box-sizing: border-box; 
    display: grid;
    grid-template-columns: repeat(4, 1fr) 1rem; /* ✅ 4 equal, 1 small */
    align-items: end;
    width: 100%;
    position: relative;
`;

const YearColumn = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
`;

const GridLine = styled.div`
    height: 12.5rem;
    width: 1px;
    background-image: repeating-linear-gradient(
        to bottom,
        ${({ theme }) => theme.border},
        ${({ theme }) => theme.border} 8px,
        transparent 8px,
        transparent 16px
    );
`;

const YearLabel = styled.div`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.textSecondary};
`;

const Marker = styled.div`
    position: absolute;
    top: 50%;
    left: 0.6rem;
    transform: translateY(-45%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    pointer-events: none;

    @media (max-width: 600px) {
        transform: translateY(-25%);
    }
`;

const VerticalGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform: translateY(-50%); /* offset from vertical center */
`;

const HorizontalRow = styled.div`
    display: flex;
    align-items: center;
`;

const Circle = styled.div`
    width: 1rem;
    height: 1rem;
    border: 3px solid ${({ theme }) => theme.primary};
    border-radius: 50%;
    background: white;
`;

const Line = styled.div`
    width: 15rem;
    height: 3px;
    background: ${({ theme }) => theme.primary};
    box-shadow: 0px 2px 8px ${({ theme }) => theme.primary}40;
    transform: translateY(0.25rem) translateX(-0.14rem); /* pull it back toward the circle */

    /* Fade out on the right */
    mask-image: linear-gradient(to right, black 0%, transparent 100%);
    -webkit-mask-image: linear-gradient(to right, black 0%, transparent 100%);
`;

const Tooltip = styled.div`
    background: ${({ theme }) => theme.surface};
    padding: 1rem 1.25rem;
    border-radius: 1rem;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
    transform: translateY(0.2rem) translateX(1rem); /* pull it back toward the circle */
    z-index: 1;
    max-width: 500px;

    strong {
        font-size: 1rem;
        font-weight: 500;
        color: ${({ theme }) => theme.textPrimary};

        @media (max-width: 600px) {
            font-size: 0.875rem;
        }
    }

    span {
        font-size: 0.875rem;
        margin-top: 0.1rem;
        color: ${({ theme }) => theme.textSecondary};
        display: block;
        line-height: 1.4;

        @media (max-width: 600px) {
            font-size: 0.75rem;
        }
    }

    @media (max-width: 600px) {
        max-width: 260px;
        padding: 0.75rem 1rem;
    }
`;

const BlueFade = styled.div`
    position: absolute;
    top: 50%;
    left: 0.6rem; /* ✅ align with the circle (same as Marker) */
    transform: translateY(-25%) translateX(-0.16rem);
    width: 20rem;
    height: 10rem;

    background: radial-gradient(
        ellipse at left center,
        ${({ theme }) => theme.primary}33 0%,   /* strong center: ~20% opacity */
        ${({ theme }) => theme.primary}1A 30%,  /* ~10% mid */
        transparent 70%
    );

    pointer-events: none;
    z-index: 0;
`;
