import React, { useEffect } from "react";
import styled from "styled-components";
import { useQueryClient } from "@tanstack/react-query";

function SigningCongratulations() {
    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.invalidateQueries(['userPools']);
        queryClient.invalidateQueries(['homeData']);
        queryClient.invalidateQueries(['poolData']);
    }, [queryClient]);
    
    return (
        <CenteredContainer>
        <MessageCard>
            <Heading>CONGRATULATIONS</Heading>
            <Text>
            You have successfully signed your Success Pooling Agreement.
            <br />
            Wait until the end of the signing period for the pool to activate.
            </Text>
        </MessageCard>
        </CenteredContainer>
    );
}

export default SigningCongratulations;

// Styled Components
const CenteredContainer = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
`;

const MessageCard = styled.div`
    padding: 2.5rem 3rem;
    border-radius: 1.5rem;
    text-align: center;
    max-width: 700px;

    @media (max-width: 600px) {
        max-width: 100%;
        padding: 2.5rem 1rem;
    }
`;

const Heading = styled.h1`
    font-size: 2rem;
    margin-bottom: 1.25rem;
    font-weight: 500;

    @media (max-width: 600px) {
        font-size: 1.625rem;
    }
`;

const Text = styled.p`
    font-size: 1.25rem;
    line-height: 1.6;

    @media (max-width: 600px) {
        font-size: 1.125rem;
    }
`;
