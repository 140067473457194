import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import PoolTerms from './PoolTerms';
import { SecondaryButton } from '../common/Buttons';
import { SearchIcon } from "../../assets/icons/Icons";
import NotFound from "../../assets/images/NotFound.png";


const PoolMeeting = () => {

    return (
        <Section style={{ position: "relative", overflow: "hidden" }}>
            <GridOverlay />
            <ContentWrapper>
                <Content>
                    <IconWrapper>
                        <CalendarIcon src={NotFound} />
                    </IconWrapper>
                    
                    <TextSection>
                        <Subtext>Next pool meeting</Subtext>
                        <Text style={{  }}>Mar 24, 2025・11:30 AM</Text>
                    </TextSection>
                
                </Content>

                <SecondaryButton size="md" style={{ background: "white" }} onClick={() => console.log("")}>ADD TO GOOGLE CALENDAR</SecondaryButton>
            </ContentWrapper>
        </Section>
    );
}


const PoolGroupMe = () => {

    return (
        <Section style={{ position: "relative", overflow: "hidden" }}>
            <GridOverlay />
            <ContentWrapper>
                <Content>
                    <IconWrapper>
                        <CalendarIcon src={NotFound} />
                    </IconWrapper>
                    
                    <TextSection>
                        <Subtext>GroupMe chat</Subtext>
                        <Text>CONTACT POOL MEMBERS</Text>
                    </TextSection>
                    
                </Content>

                <SecondaryButton size="md" style={{ background: "white" }} onClick={() => console.log("")}>GO TO THE GROUP CHAT</SecondaryButton>

            </ContentWrapper>
        </Section>
    );
}


const SectionLocked = ({ text="This section is locked", subText="Wait until the pool activates to access all pool benefits" }) => {

    return (
        <Section>
            <ContentWrapper>
                <Content>
                    <SearchIconWrapper>
                        <SearchIcon width="20" height="20" />
                    </SearchIconWrapper>
                    
                    <TextSection>
                        <Text style={{ fontSize: "1.0625rem", margin: "0", marginBottom: "0.2rem" }}>{text}</Text>
                        <Subtext style={{ fontSize: "1rem", margin: "0" }}>{subText}</Subtext>
                    </TextSection>
                
                </Content>

            </ContentWrapper>
        </Section>
    );
}

const AboutPool = ({ pool }) => {

    return (
        <Section>
            <AboutContentWrapper>
                <AboutContent>
                    <Title>About</Title>

                    <Text style={{ fontSize: "1.5rem", lineHeight: "2rem", textTransform: "uppercase" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</Text>
                    <Subtext style={{ lineHeight: "1.5rem" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</Subtext>
                </AboutContent>

                <PoolTermsWrapper>
                    <PoolTerms poolDetails={pool?.public_details || {}} vertical={true} />
                </PoolTermsWrapper>
            </AboutContentWrapper>
        </Section>
    );
}


export { PoolMeeting, PoolGroupMe, SectionLocked, AboutPool };


// Styled Components
const Section = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${({ theme }) => theme.white};;
    padding: 1.5rem;
    border-radius: 1.5rem;
    flex-wrap: wrap; /* Allow items to wrap on small screens */
    gap: 1rem;
    z-index: 0;

    @media (max-width: 600px) {
        padding: 1.25rem;
    }
`;

const Title = styled.div`
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textSecondary};
    margin-bottom: 2rem;

    @media (max-width: 600px) {
        margin-bottom: 1.25rem;
    }
`;

const ContentWrapper = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    @media (max-width: 600px) {
        justify-content: flex-start;
        align-items: flex-start;

        button {
            flex: 1;
            width: 100%; /* optional if you want full width */
            max-width: 350px;
        }
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    @media (max-width: 600px) {
        flex-direction: row;
        gap: 0.75rem;
        justify-content: flex-start;
        text-align: left;
    }
`;

const TextSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    @media (max-width: 600px) {
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
    }
`;

const Text = styled.span`
    font-size: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
    margin-bottom: 1rem;
    z-index: 1;

    @media (max-width: 600px) {
        font-size: 1.0625rem;
    }
`;

const Subtext = styled.span`
    font-size: 1rem;
    color: ${({ theme }) => theme.textSecondary};
    margin-bottom: 0.1rem;
    z-index: 1;

    @media (max-width: 600px) {
        font-size: 1rem;
    }
`;

const IconWrapper = styled.div`
    margin-bottom: 1rem;
`;

const SearchIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.25rem;
    height: 3.25rem;
    aspect-ratio: 1;
    border-radius: 50%;
    background: ${({ theme }) => theme.backgroundDarker};
    margin-bottom: 1rem;
`;

const CalendarIcon = styled.img`
    width: 4.5rem;
    height: 4.5rem;
    aspect-ratio: 1;

    @media (max-width: 600px) {
        width: 3.8rem;
        height: 3.8rem;
    }
`;

const AboutContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    justify-content: "space-between";
    width: 100%;

    @media (max-width: 600px) {
        justify-content: flex-start;
        flex-direction: column;
        gap: 1.25rem;
    }
`;

const AboutContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
`;

const PoolTermsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
`;

const GridOverlay = styled.div`
    position: absolute;
    inset: 0;
    z-index: 0;
    border-radius: 1.5rem;

    background-image: 
        repeating-linear-gradient(
            to bottom,
            ${({ theme }) => theme.border},
            ${({ theme }) => theme.border} 0.954px,
            transparent 0.954px,
            transparent 6rem
        ),
        repeating-linear-gradient(
            to right,
            ${({ theme }) => theme.border},
            ${({ theme }) => theme.border} 0.954px,
            transparent 0.954px,
            transparent 6rem
        );

    background-position: 0.7rem 0.7rem;
    background-size: 100% 100%;

    mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.6) 40%,
        rgba(0, 0, 0, 0) 60%
    );
    -webkit-mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.6) 40%,
        rgba(0, 0, 0, 0) 60%
    );

    @media (max-width: 768px) {
        background-image: 
            repeating-linear-gradient(
                to bottom,
                ${({ theme }) => theme.border},
                ${({ theme }) => theme.border} 0.954px,
                transparent 0.954px,
                transparent 5rem
            ),
            repeating-linear-gradient(
                to right,
                ${({ theme }) => theme.border},
                ${({ theme }) => theme.border} 0.954px,
                transparent 0.954px,
                transparent 5rem
            );

        mask-image: linear-gradient(
            to left,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.6) 40%,
            rgba(0, 0, 0, 0) 60%
        );
        -webkit-mask-image: linear-gradient(
            to left,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.6) 40%,
            rgba(0, 0, 0, 0) 60%
        );
  }
`;


