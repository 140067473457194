import { useTheme } from "styled-components";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import parsePhoneNumber from 'libphonenumber-js'

// From my app
import { RegistrationContainer } from "../../components/common/Containers";
import PhoneInput from "../../components/forms/PhoneInput";
import VerificationCodeInput from "../../components/forms/VerificationCodeInput";
import { Form } from "../../components/forms/Form";
import { PrimaryButton, SecondaryButton, HorizontalButtonContainer } from "../../components/common/Buttons";
import { RegistrationHeader, Subtitle, MobileBottomSection } from "../../components/forms/MiscComponents";
import { myAxiosInstance } from "../../api/axiosConfig";

function FinishPhone() {
    const [phone, setPhone] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [sessionUUID, setSessionUUID] = useState("");
    const [showVerification, setShowVerification] = useState(false);
    const [errors, setErrors] = useState({ email: "", password: "" });
    const [loading, setLoading] = useState(false);

    // Erros
    const { showBoundary } = useErrorBoundary();

    // Get the theme
    const theme = useTheme();

    // Navigate
    const navigate = useNavigate();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // Retrieve the data
    useEffect(() => {
        const getStageDetails = async () => {
            try {
                setLoading(true);
                const response = await myAxiosInstance.get('/api/finish-registration-four/');
                setPhone(response.data.phone);
            } catch (error) {
                showBoundary(error);
            } finally {
                setLoading(false); // Set isLoading to false regardless of success or error
            }
        };

        getStageDetails();

    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        let newErrors = { phone: "", verificationCode: "" }
    
        // Frontend validation
        try {
            const parsed = parsePhoneNumber(phone); 

            // Define the regex for a valid phone number
            if (!parsed.isValid()) {
                newErrors.phone = "Please select a valid phone number.";
            }
        }
        catch {
            newErrors.phone = "Please select a valid phone number.";
        }

    
        if (newErrors.phone || newErrors.verificationCode) {
            setErrors(newErrors);
            return; // Stop execution if frontend validation fails
        }
                  
        try {
            // Send API request
            setLoading(true);

            if (showVerification) {
                const response = await myAxiosInstance.put('/prospinity-auth/phone-number/verify/', { "session_uuid": sessionUUID, "verification_code": verificationCode });

                navigate("/finish-registration/customize");

            } else {
                const response = await myAxiosInstance.put("/api/finish-registration-four/", { "phone": phone });
                
                if (response.status === 204) { // Phone already exists
                    console.log(response.status);
                    navigate("/finish-registration/customize");
                } else {
                    setSessionUUID(response.data.session_uuid);
                    setShowVerification(true);
                }
                
            }

        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data.phone) {
                        setErrors({ ...newErrors, phone: error.response.data.phone });
                    } else if (error.response.data.verification_code) {
                        setErrors({ ...newErrors, verificationCode: error.response.data.verification_code });
                    } else if (error.response.data.non_field_errors) {
                        setErrors({ ...newErrors, [showVerification ? "verificationCode" : "phone"]: error.response.data.non_field_errors });
                    } else {
                        setErrors({ ...newErrors, [showVerification ? "verificationCode" : "phone"]: "Something went wrong. Please try again later." });
                    }
                } else {
                    setErrors({ ...newErrors, [showVerification ? "verificationCode" : "phone"]: "Something went wrong. Please try again later." });
                }
            } else {
                setErrors({ ...newErrors, [showVerification ? "verificationCode" : "phone"]: "Something went wrong. Please try again later." });
            }
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <RegistrationContainer isMobile={isMobile}>
            <RegistrationHeader isMobile={isMobile}>WHAT'S YOUR PHONE NUMBER?</RegistrationHeader>
            <Subtitle isMobile={isMobile} theme={theme}>Please enter your phone number below</Subtitle>

            <Form onSubmit={handleSubmit}>
                <PhoneInput 
                    label="Phone" 
                    type="text" 
                    value={phone}
                    onChange={(phoneNumber) => {
                        setPhone(phoneNumber); // Directly set the phone number
                        setShowVerification(false); // No verification if changing the number
                        setErrors((prev) => ({ ...prev, phone: "", verificationCode: "" })); // Clear error
                    }}
                    error={errors.phone}
                />

                {showVerification && (

                    <div style={{ marginTop: "1.5rem" }}>
                        <Subtitle isMobile={isMobile} theme={theme}>Enter the six digit code we sent to your device</Subtitle>
                        <VerificationCodeInput 
                            value={verificationCode}
                            onChange={setVerificationCode}
                            error={errors.verificationCode}
                        />
                    </div>
                )}


                {/* Mobile bottom section */}
                {isMobile ?
                    <MobileBottomSection>
                        <HorizontalButtonContainer>
                            <SecondaryButton size="lg" type="button" disabled={loading} style={{ flex: "1", padding: "1rem" }} onClick={() => navigate("/finish-registration/company")} >
                                BACK
                            </SecondaryButton>

                            <PrimaryButton size="lg" type="submit" disabled={loading} style={{ flex: "1", padding: "1rem" }} >
                                {loading ? (showVerification ? "Verifying..." : "Checking phone...") : (showVerification ? "VERIFY" : "CONTINUE")}
                            </PrimaryButton>
                        </HorizontalButtonContainer>
                    </MobileBottomSection>
                    :
                    <HorizontalButtonContainer>
                        <SecondaryButton size="lg" type="button" disabled={loading} style={{ flex: "1", padding: "1rem", marginTop: "2rem" }} onClick={() => navigate("/finish-registration/company")} >
                            BACK
                        </SecondaryButton>

                        <PrimaryButton size="lg" type="submit" disabled={loading} style={{ flex: "1", padding: "1rem", marginTop: "2rem" }} >
                            {loading ? (showVerification ? "Verifying..." : "Checking phone...") : (showVerification ? "VERIFY" : "CONTINUE")}
                        </PrimaryButton>
                    </HorizontalButtonContainer>
                }

            </Form>
            
        </RegistrationContainer>
    );
}

export default FinishPhone;
