import React from "react";
import { useTheme } from "styled-components";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { useNotifications } from "../../contexts/NotificationsContext";
import { SecondaryButton } from '../common/Buttons';


export default function NotificationsSidebar({ onClose }) {

    const { notifications, markAllAsRead, readNotification } = useNotifications();
    const theme = useTheme();
    const navigate = useNavigate();

    const getTimeAgo = (utcTime) => {
        const now = new Date();
        const createdDate = new Date(utcTime); // Convert string to Date object
        const diffInSeconds = Math.floor((now - createdDate) / 1000); // Difference in seconds
    
        if (diffInSeconds < 60) return "now"; // Less than a minute
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        if (diffInMinutes < 60) return `${diffInMinutes}m`; // Less than an hour
        const diffInHours = Math.floor(diffInMinutes / 60);
        if (diffInHours < 24) return `${diffInHours}h`; // Less than a day
        const diffInDays = Math.floor(diffInHours / 24);
        return `${diffInDays}d`; // More than a day
    };

    return (
        <SidebarContainer theme={theme}>
            {/* Header */}
            <Header>
                <Title theme={theme}>Notifications</Title>
                <CloseButton onClick={onClose}>✕</CloseButton>
            </Header>

            {/* Filter + Mark All as Read */}
            <ActionRow>
                <AllActivity theme={theme}>All activity</AllActivity>
                <MarkAllRead theme={theme} onClick={markAllAsRead}>
                    Mark all as read
                </MarkAllRead>
            </ActionRow>

            {/* List of Notifications */}
            <NotificationList>
                {notifications.map(notification => (
                    <NotificationItem key={notification.pk} theme={theme} onClick={() => readNotification(notification.pk)}>
                        <NotificationContent key={notification.pk} theme={theme}>

                            {/* Time & Title Row */}
                            <MetaInfo>
                                {!notification.read && <UnreadDot theme={theme} />}
                                <NotificationTime theme={theme}>{getTimeAgo(notification.created_at)}</NotificationTime>
                                <NotificationTitle theme={theme}>{notification.title}</NotificationTitle>
                            </MetaInfo>

                            {/* Message */}
                            <NotificationMessage theme={theme}> {notification.message} </NotificationMessage>
                        </NotificationContent>

                        <SecondaryButton 
                            style={{ 
                                padding: "0.2rem 1.2rem",  // 🔹 Remove vertical padding completely
                                fontSize: "0.65rem",     // 🔹 Smaller font
                                height: "24px",          // 🔹 Explicitly set a smaller height
                                minHeight: "unset",      // 🔹 Ensure no forced min-height
                                lineHeight: "1",         // 🔹 Reduce extra vertical spacing
                                display: "flex",         // 🔹 Align text properly
                                alignItems: "center",    // 🔹 Center text vertically
                                justifyContent: "center",// 🔹 Center text horizontally
                                borderRadius: "1.25rem", // 🔹 Adjust border-radius to fit height
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                readNotification(notification.pk);
                                navigate("/" + notification.redirect_link);
                                onClose();
                            }}
                        >
                            VIEW
                        </SecondaryButton>
                    </NotificationItem>
                ))}
            </NotificationList>
        </SidebarContainer>
    );
}

// Styled Components
const SidebarContainer = styled.div`
    width: 520px; /* Default width */
    height: 100vh;
    background-color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    border-right: 1px solid ${({ theme }) => theme.divider};
    position: fixed;
    top: 0;
    left: 260px; /* Next to main sidebar */
    z-index: 1001;
    transition: left 0.3s ease;

    @media (max-width: 1000px) {
        width: 390px; /* Smaller sidebar for narrow screens */
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0 1.625rem;
`;

const Title = styled.h3`
    font-size: 1.75rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
`;

const CloseButton = styled.span`
    cursor: pointer;
    color: ${({ theme }) => theme.textSecondary};
`;

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    padding: 0 1.625rem;
`;

const AllActivity = styled.span`
    color: ${({ theme }) => theme.textSecondary};
    font-size: 1rem;
`;

const MarkAllRead = styled.span`
    color: ${({ theme }) => theme.primary};
    font-size: 1rem;
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.primaryHover};
    }
`;

const NotificationList = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    gap: 0.5rem;
`;

const NotificationItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 1.625rem;
    gap: 1rem;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.backgroundLight};
    }
`;

const NotificationContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const MetaInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 0.625rem; /* Space between time and title */
`;

const NotificationTime = styled.span`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.textSecondary};
`;

const NotificationTitle = styled.strong`
    font-size: 0.875rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
`;

const NotificationMessage = styled.p`
    font-size: 0.875rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.textSecondary};
    margin: 0;
`;

const UnreadDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
`;
