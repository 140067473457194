import React, { useEffect, useState } from "react";

const TimeRemaining = ({ deadline, onExpire }) => {
    const [timeLeft, setTimeLeft] = useState("");

    useEffect(() => {
        const target = new Date(deadline); // parse ISO string natively

        const updateTimeLeft = () => {
            const now = new Date();
            const msRemaining = target - now;

            if (msRemaining <= 0) {
                setTimeLeft("0s");
                if (onExpire) onExpire();
                return;
            }

            let seconds = Math.floor(msRemaining / 1000);
            const days = Math.floor(seconds / (60 * 60 * 24));
            seconds %= 60 * 60 * 24;

            const hours = Math.floor(seconds / (60 * 60));
            seconds %= 60 * 60;

            const minutes = Math.floor(seconds / 60);
            seconds %= 60;

            let result = "";
            if (days > 0) result += `${days}d `;
            if (hours > 0 || days > 0) result += `${hours}h `;
            if (minutes > 0 || hours > 0 || days > 0) result += `${minutes}m `;
            if (days === 0 && hours === 0) result += `${seconds}s`;

            setTimeLeft(result.trim());
        };

        updateTimeLeft(); // run once immediately
        const interval = setInterval(updateTimeLeft, 1000);

        return () => clearInterval(interval);
    }, [deadline, onExpire]);

    return <>{timeLeft}</>;
};

export default TimeRemaining;
