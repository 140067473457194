import styled, { useTheme } from "styled-components";
import React, { useState, useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { useAuth } from "../../contexts/AuthContext";
import { SuggestedUserCarousel } from "../../components/users/UserCarousel";
import MobileScrollCarousel from "../../components/common/Carousels";
import { RequestingUserPreviewCard } from "../../components/users/UserPreviewCard";
import { RegistrationContainer } from "../../components/common/Containers";
import { PrimaryButton, SecondaryButton } from "../../components/common/Buttons";
import { RegistrationHeader, Subtitle, MobileBottomSection } from "../../components/forms/MiscComponents";
import { myAxiosInstance } from "../../api/axiosConfig";
import Loading from "../../components/common/Loading";

import logoBlack from "../../assets/icons/logo.svg";


// Overall get started
function GetStarted() {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return isMobile ? <GetStartedMobile /> : <GetStartedDesktop />;
}
export default GetStarted;


// Desktop
function GetStartedDesktop() {
    const { reload } = useAuth();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // Erors
    const { showBoundary } = useErrorBoundary();

    // Get the theme
    const theme = useTheme();

    // Navigate
    const navigate = useNavigate();

    useEffect(() => {
        const getHomeData = async () => {
            try {
                const response = await myAxiosInstance.get("/api/home/");
                setUsers(response.data?.daily_picks ?? []);
            } catch (error) {
                showBoundary(error);
            } finally {
                setIsLoading(false);
            }
        };

        getHomeData();
    }, [reload]);

    const handleSendRequest = async (user) => {
        try {
            await myAxiosInstance.post(`/api/make-portfolio-request/`, {
              receiving_user: user.slug,
            });
            setUsers((prev) => prev.filter((u) => u.slug !== user.slug)); // Remove
            
        } catch (error) {
            console.error("Failed to accept request:", error);
        }
     };


    // Loading
    if (isLoading) {
        return (<div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh", // Full viewport height
            width: "100vw",  // Full width
        }}>
            <Loading />
        </div>);
    }

    return (
        <OuterContainer theme={theme}>
            {/* Logo */}
            <Logo src={logoBlack} alt="Prospinity Logo" />

            {/* Inner Content */}
            <InnerContainer>
                {/* Header Section */}
                <HeaderWrapper>
                    <RegistrationHeader style={{ textAlign: "center" }}>
                        LET’S GET STARTED
                    </RegistrationHeader>
                    <Subtitle style={{ textAlign: "center", marginBottom: "0" }}>
                        Let's start making your first connections on Prospinity.
                    </Subtitle>
                </HeaderWrapper>

                {/* Card Section */}
                <SuggestedUserCarousel users={users} onAccept={handleSendRequest} />

                {/* Button Section */}
                <ButtonWrapper>
                    <SecondaryButton size="lg" onClick={() => navigate('/dashboard/home')} style={{ width: "232px", padding: "0.75rem 3.5rem" }}>SKIP</SecondaryButton>
                    <PrimaryButton size="lg" onClick={() => navigate('/dashboard/home')} style={{ width: "232px", padding: "0.75rem 3.5rem" }}>CONTINUE</PrimaryButton>
                </ButtonWrapper>
            </InnerContainer>
        </OuterContainer>
    );
}


// Mobile
function GetStartedMobile() {
    const { reload } = useAuth();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Erors
    const { showBoundary } = useErrorBoundary();

    // Get the theme
    const theme = useTheme();

    // Navigate
    const navigate = useNavigate();

    useEffect(() => {
        const getHomeData = async () => {
            try {
                const response = await myAxiosInstance.get("/api/home/");
                setUsers(response.data?.daily_picks ?? []);
            } catch (error) {
                showBoundary(error);
            } finally {
                setIsLoading(false);
            }
        };

        getHomeData();
    }, [reload]);

    const handleSendRequest = async (user) => {
        try {
            await myAxiosInstance.post(`/api/make-portfolio-request/`, {
              receiving_user: user.slug,
            });
            setUsers((prev) => prev.filter((u) => u.slug !== user.slug)); // Remove
            
        } catch (error) {
            console.error("Failed to accept request:", error);
        }
    };

    // Loading
    if (isLoading) {
        return (<div style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            height: "100vh", // Full viewport height
            width: "100vw",  // Full width
        }}>
            <Loading />
        </div>);
    };

    return (
        <RegistrationContainer isMobile={true}>
            <RegistrationHeader isMobile={true} style={{ fontSize: "1.8rem" }}>LET’S GET STARTED</RegistrationHeader>
            <Subtitle isMobile={true} theme={theme} style={{ margin: "0 0 0.8rem 0", fontSize: "0.9rem" }}>Started with your first portfolio connections</Subtitle>

            {/* Card Section */}
            <MobileScrollCarousel 
                items={users.map((user) => (
                  <RequestingUserPreviewCard 
                    key={user.slug}
                    user={user}
                    onAccept={() => handleSendRequest(user)}
                    allowReject={false}
                    addPrimary={false}
                    whiteBG={false}
                  />
                ))} 
                autoScroll={true} // Optional: Enable auto-scrolling
              />

            {/* Button */}
            <MobileBottomSection>
                <PrimaryButton size="lg" onClick={() => navigate('/dashboard/home')} disabled={isLoading} style={{ padding: "1rem", width: "100%"}} >
                    {"CONTINUE"}
                </PrimaryButton>
            </MobileBottomSection>
        </RegistrationContainer>
    )
}


// Styled Components
const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: relative;
    background: ${({ theme }) => theme.backgroundDarker}; /* Full-screen background */
`;

const Logo = styled.img`
    position: absolute;
    top: 2.5rem;
    left: 2.5rem;
    width: 147px;
    z-index: 10;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    margin: 0 auto;
`;

const HeaderWrapper = styled.div`
    margin-bottom: 1.5rem; /* Reduced space by 1/3 */
`;


const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between; /* Aligns buttons to the left and right edges */
    width: 100%;
    margin-top: 2rem;
    padding: 0 5.5rem;
    gap: 1.5rem;
`;
