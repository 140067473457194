import React, { useState, useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";
import { useMediaQuery } from 'react-responsive';

import CenteredModal from "../common/Modals";
import { myAxiosInstance } from "../../api/axiosConfig";


const SigningInterface = ({ pool, onClose }) => {
    const [step, setStep] = useState(1);
    const [docUrl, setDocUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    const handleStart = async () => {
        setLoading(true);
        try {
            const response = await myAxiosInstance.get(`/api/get-document/${pool.public_details.slug}/`);
            setDocUrl(response.data.document_link);
            setStep(2);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <CenteredModal 
            show={true}
            onClose={step === 2 ? () => window.location.reload() : onClose}
            title={step === 1 ? "OVERVIEW OF THE AGREEMENT" : "SUCCESS POOLING AGREEMENT"} 
            buttonsType={step === 1 ? "two" : "none"}
            primaryText={step === 1 ? loading ? "Preparing..." : "REVIEW" : "CLOSE"}
            secondaryText="CANCEL"
            onPrimaryClick={step === 1 ? handleStart : onClose}
            onSecondaryClick={onClose}
            alignCenter={false}
            modalStyle={step== 1 || isMobile ? { maxHeight: "95dvh" } : { width: "75%", maxWidth: "75%", maxHeight: "95dvh" }}
            modalBodyStye={step== 1 ? {} : { maxHeight: "100dvh", marginTop: isMobile ? "0px" : "1rem" }}
            modalHeaderStyle={{ fontSize: isMobile ? "1.25rem" : "1.5rem" }}
        >
            {step === 1 ? (
                <>
                    <p>
                        Below is a recap of the most important things to remember about your Success Pooling Agreement! For more questions, please see our <StyledLink href="/dashboard/faqs" target="_blank" rel="noopener noreferrer" title="Right-click and select 'Open Link in New Tab'">FAQs</StyledLink>.
                    </p>
                    <p>
                        <Bold>Terms: </Bold> This pool has {pool.public_details.member_count} members, a {pool.public_details.contribution_percent}% rate, and a {pool.public_details.time_horizon} year horizon.
                    </p>
                    <p>
                        <Bold>Service Fee: </Bold> Our service fee is {pool.public_details.contribution_percent * 0.05}% (5% of the {pool.public_details.contribution_percent}% Rate). This helps us cover costs such as verifying earnings every year and managing the financial infrastructure.
                    </p>
                    <p>
                        <Bold>Post-Graduation Kick-In: </Bold> The contribution term only kicks in when you leave school. If you have people who are a grade below you in your pool, it's fair to everyone, because they only start their term when they start earning (so older members won't "contribute" for a longer period than younger members).
                    </p>
                    <p>
                        <Bold>Trial Period: </Bold> If you are on the fence about this pool and need more time to decide, go ahead and join! Every member has a month-long trial period to leave the pool if they change their mind.
                    </p>
                    <p>
                        <Bold>Confidentiality: </Bold> Accessing the Success Pooling Agreement is a privilege. It is Prospinity's intellectual property and may not be shared or used for commercial purposes. For full details, see our <StyledLink href="/agreement-confidentiality" target="_blank" rel="noopener noreferrer" title="Right-click and select 'Open Link in New Tab'">Confidentiality and Intellectual Property Agreement</StyledLink>.
                    </p>
                </>
            ) : (
                <IframeContainer>
                    {docUrl ? (
                        <iframe title="Agreement" src={docUrl} frameBorder="0" width="100%" height="100%" />
                    ) : (
                        <p>Unable to load the agreement. Please try again later.</p>
                    )}
                </IframeContainer>
            )}
        </CenteredModal>
    );
};
  
export default SigningInterface;


const Bold = styled.span`
    font-weight: 500;
`;

const StyledLink = styled.a`
    color: ${({ theme }) => theme.primary};
    text-decoration: none;

    &:hover {
        color: ${({ theme }) => theme.primaryHover};
        text-decoration: none;
    }
`;

const IframeContainer = styled.div`
    width: 100%;
    height: 90dvh; // Enough room for padding/buttons
    overflow: hidden;

    iframe {
        width: 100%;
        height: 89%;
        border: none;

        @media (max-width: 600px) {
            height: 88%;
        }
  }
`;