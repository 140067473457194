import { useTheme } from "styled-components";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

// From my app
import { RegistrationContainer } from "../../components/common/Containers";
import SingleSelect from "../../components/forms/SingleSelect";
import FormInput from "../../components/forms/FormInput";
import { Form } from "../../components/forms/Form";
import { PrimaryButton, SecondaryButton, HorizontalButtonContainer } from "../../components/common/Buttons";
import { RegistrationHeader, Subtitle, MobileBottomSection } from "../../components/forms/MiscComponents";
import { myAxiosInstance } from "../../api/axiosConfig";


function FinishCompany() {
    const [company, setCompany] = useState("");
    const [role, setRole] = useState("");
    const [errors, setErrors] = useState({ university: "", major: "", graduation: "" });
    const [loading, setLoading] = useState(false);

    // Erros
    const { showBoundary } = useErrorBoundary();

    // Get the theme
    const theme = useTheme();

    // Navigate
    const navigate = useNavigate();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });


    // Retrieve the data
    useEffect(() => {
        const getStageDetails = async () => {
            try {
                setLoading(true);
                const response = await myAxiosInstance.get('/api/finish-registration-three/');
                
                setCompany(response.data.company || "");
                setRole(response.data.role || "");

            } catch (error) {
                showBoundary(error);
            } finally {
                setLoading(false); // Set isLoading to false regardless of success or error
            }
        };

        getStageDetails();

    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        let newErrors = { company: "", role: "" };
    
        if (newErrors.company || newErrors.role) {
            setErrors(newErrors);
            return; // Stop execution if frontend validation fails
        }
                  
        try {
            // Send API request
            setLoading(true);

            await myAxiosInstance.put("/api/finish-registration-three/", { "company": company, "role": role });

            navigate("/finish-registration/phone");

        } catch (error) {
            // Handle API errors
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data.company) {
                        setErrors({ ...newErrors, company: "Please select a valid company from the list." });
                    } else if (error.response.data.major) {
                        setErrors({ ...newErrors, role: "Please select a valid role." });
                    } else if (error.response.data.non_field_errors) {
                        setErrors({ ...newErrors, role: "Something went wrong. Please try again later." });
                    } else {
                        setErrors({ ...newErrors, role: "Something went wrong. Please try again later." });
                    }
                } else {
                    setErrors({ ...newErrors, role: "Something went wrong. Please try again later." });
                }
            } else {
                setErrors({ ...newErrors, role: "Something went wrong. Please try again later." });
            }
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <RegistrationContainer isMobile={isMobile}>
            <RegistrationHeader isMobile={isMobile}>TELL US ABOUT YOUR PROFESSIONAL EXPERIENCE</RegistrationHeader>
            <Subtitle isMobile={isMobile} theme={theme}>Highlight your most significant professional experience (optional)</Subtitle>

            <Form onSubmit={handleSubmit}>
                <SingleSelect 
                    label="Company" 
                    endpoint='search-companies'
                    value={company}
                    onChange={(value) => {
                        setCompany(value);
                        setErrors((prev) => ({ ...prev, company: "" })); // Clear error when typing
                    }}
                    error={errors.company}
                />

                <div style={{ marginTop: "2rem" }}>
                    <FormInput 
                        label="Role" 
                        type="text" 
                        value={role}
                        onChange={(e) => {
                            setRole(e.target.value);
                            setErrors((prev) => ({ ...prev, role: "" })); // Clear error when typing
                        }} 
                        error={errors.role}
                    />
                </div>

                {/* Mobile bottom section */}
                {isMobile ?
                    <MobileBottomSection>
                        <HorizontalButtonContainer>
                            <SecondaryButton size="lg" type="button" disabled={loading} style={{ flex: "1", padding: "1rem"}} onClick={() => navigate("/finish-registration/university")} >
                                BACK
                            </SecondaryButton>

                            <PrimaryButton size="lg" type="submit" disabled={loading} style={{ flex: "1", padding: "1rem"}} >
                                {loading ? "Checking company..." : "CONTINUE"}
                            </PrimaryButton>
                        </HorizontalButtonContainer>
                    </MobileBottomSection>
                    :
                    <HorizontalButtonContainer>
                        <SecondaryButton size="lg" type="button" disabled={loading} style={{ flex: "1", padding: "1rem", marginTop: "2rem" }} onClick={() => navigate("/finish-registration/university")} >
                            BACK
                        </SecondaryButton>

                        <PrimaryButton size="lg" type="submit" disabled={loading} style={{ flex: "1", padding: "1rem", marginTop: "2rem" }} >
                            {loading ? "Checking company..." : "CONTINUE"}
                        </PrimaryButton>
                    </HorizontalButtonContainer>
                }

            </Form>
            
        </RegistrationContainer>
    );
}

export default FinishCompany;
