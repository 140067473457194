import { createContext, useContext, useState, useEffect } from "react";
import { myAxiosInstance } from "../api/axiosConfig";

const NotificationsContext = createContext();

export function NotificationsProvider({ children }) {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('userToken');

        if (!token) {
            return; // Not logged in
        }

        fetchNotifications();
        const interval = setInterval(fetchNotifications, 1 * 60 * 1000); // Poll every 5 minutes
        return () => clearInterval(interval);
    }, []);

    const fetchNotifications = async () => {
        try {
            const response = await myAxiosInstance.get("/api/notifications/");
            setNotifications(response.data);
        } catch (error) {
            
        }
    };

    const markAllAsRead = async () => {
        try {
            await myAxiosInstance.post("/api/read-notifications/");
            setNotifications(notifications.map(n => ({ ...n, read: true })));
        } catch (error) {

        }
    };

    const readNotification = async (notificationId) => {
        try {
            await myAxiosInstance.post(`/api/notifications/mark-read/${notificationId}/`);
            setNotifications(notifications.map(notification =>
                notification.pk === notificationId ? { ...notification, read: true } : notification
            ));
        } catch (error) {

        }
    };

    return (
        <NotificationsContext.Provider value={{ notifications, fetchNotifications, markAllAsRead, readNotification }}>
            {children}
        </NotificationsContext.Provider>
    );
}

export function useNotifications() {
    return useContext(NotificationsContext);
}
