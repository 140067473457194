import React, { useState, useEffect } from "react";
import styled from "styled-components";
import slugify from 'slugify';
import { useMediaQuery } from "react-responsive";
import { useErrorBoundary } from "react-error-boundary";
import { useQueryClient } from '@tanstack/react-query';

import { Form } from "../forms/Form"
import FormInput from "../forms/FormInput"
import SingleSelect, { SingleSelectFixedOptions } from "../forms/SingleSelect"
import MultiSelect from "../forms/MultiSelect"
import FormTextarea from "../forms/FormTextarea";
import PictureUpload from "../forms/PictureUpload";
import CenteredModal from "../common/Modals"
import Loading from "../common/Loading";
import { myAxiosInstance } from "../../api/axiosConfig";
import { useAuth } from "../../contexts/AuthContext";

const EditProfile = ({ show, onClose, user, updateUser }) => {
  // Responsive
  const isMobile = useMediaQuery({ maxWidth: 768 });

  // Form State
  const [formData, setFormData] = useState({});
  const fields = { profile_picture: "", first_name: "", last_name: "", tag_line: "", bio: "", university: "", major_or_grad_program: "", club: "", company: "", role: "", linkedin_url: "" };

  // Auth
  const { userSlug, userFirstName, setUserFirstName, userLastName, setUserLastName } = useAuth();

  // Errors
  const [errors, setErrors] = useState(fields);

  // Loading
  const [loading, setLoading] = useState(false);

  // Graduation years
  const graduationYears = Array.from({ length: 51 }, (_, i) => (2000 + i).toString());

  // Queries
  const queryClient = useQueryClient();

  // Error Boundary
  const { showBoundary } = useErrorBoundary();

  // Gets the data
  useEffect(() => {
      setFormData(user);
  }, [user]);

  // Handle Input Change
  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear errors on change
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = fields;

    // Validation
    if (!formData.profile_picture) newErrors.profile_picture = "Please upload a profile picture.";
    if (formData.first_name.trim().length < 2) newErrors.first_name = "Please enter a valid first name.";
    if (formData.last_name.trim().length < 2) newErrors.last_name = "Please enter a valid last name.";
    if (formData.tag_line.trim().length > 50) newErrors.tag_line = "50 characters max.";
    if (formData.bio.trim().length > 1000) newErrors.bio = "1000 characters max.";
    if (formData.university.trim().length < 2) newErrors.university = "Please enter a valid university.";
    if (formData.major_or_grad_program.length == 0) newErrors.major_or_grad_program = "Please enter a valid major.";

    // If errors exist, stop submission
    if (Object.values(newErrors).some((err) => err !== "")) {
      setErrors(newErrors);
      return;
    }

    try {
      // Send API request
      setLoading(true);

      const data = new FormData();
        
        // Append all fields
        Object.keys(formData).forEach((key) => {
          if (key === "profile_picture") {
              if (formData[key] instanceof File) {
                data.append(key, formData[key]); // ✅ Append file correctly
              }
          } else if (Array.isArray(formData[key])) {  
              // ✅ Ensure M2M fields (like 'club' and 'major_or_grad_program') are sent as lists
              formData[key].forEach(item => data.append(`${key}`, item));
          } else {
              data.append(key, formData[key] ?? ""); // ✅ Prevent null issues
          }
      });

      const response = await myAxiosInstance.put("/api/update-user-profile/", data);

      if (response.status === 200) {
          // ✅ Extract the profile picture URL from the response
          const updatedProfilePicture = response.data.profile_picture_url;

          // ✅ Update user state with the new profile picture URL
          updateUser({ ...formData, profile_picture: updatedProfilePicture });

          // Invalidate queries
          queryClient.invalidateQueries(['homeData']);

          if (formData.first_name !== userFirstName || formData.last_name !== userLastName) {
              // Update names
              setUserFirstName(formData.first_name);
              setUserLastName(formData.last_name);

              // Redirect
              // Extract ID from current slug
              const userIdPart = userSlug.split('-').slice(-1)[0];

              // Create new slug
              const newSlug = `${slugify(formData.first_name, { lower: true })}-${slugify(formData.last_name, { lower: true })}-${userIdPart}`;

              // Redirect if changed
              window.location.href = `/dashboard/user/${newSlug}`;

          }

      }

      // ✅ Close the modal
      onClose();

    } catch (error) {
        // Handle API errors
        if (error.response) {
            if (error.response.status === 400) {
                if (error.response.data.profile_picture) {
                    setErrors({ ...newErrors, profile_picture: error.response.data.profile_picture });
                } else if (error.response.data.first_name) {
                    setErrors({ ...newErrors, first_name: error.response.data.first_name });
                } else if (error.response.data.last_name) {
                    setErrors({ ...newErrors, last_name: error.response.data.last_name });
                } else if (error.response.data.tag_line) {
                    setErrors({ ...newErrors, tag_line: error.response.data.tag_line });
                } else if (error.response.data.bio) {
                    setErrors({ ...newErrors, bio: error.response.data.bio });
                } else if (error.response.data.club) {
                    setErrors({ ...newErrors, club: error.response.data.club });
                } else if (error.response.data.company) {
                    setErrors({ ...newErrors, company: error.response.data.company });
                } else if (error.response.data.role) {
                    setErrors({ ...newErrors, role: error.response.data.role });
                } else if (error.response.data.major_or_grad_program) {
                    setErrors({ ...newErrors, major_or_grad_program: "Please select a valid major from the list." });
                } else if (error.response.data.graduation_date) {
                    setErrors({ ...newErrors, graduation_date: "Please select a valid graduation date from the list." });
                } else if (error.response.data.non_field_errors) {
                    setErrors({ ...newErrors, linkedin_url: "Something went wrong. Please try again later." });
                } else {
                    setErrors({ ...newErrors, linkedin_url: "Something went wrong. Please try again later." });
                }
            } else {
                setErrors({ ...newErrors, linkedin_url: "Something went wrong. Please try again later." });
            }
        } else {
            setErrors({ ...newErrors, gradulinkedin_urlation: "Something went wrong. Please try again later." });
        }
    } finally {
        setLoading(false);
    }
  };

  const handleClose = (e) => {
      setErrors(fields);
      setFormData(user);
      onClose();
  }

  return (
    <CenteredModal
      show={show}
      onClose={handleClose}
      title="UPDATE YOUR PROFILE"
      subtitle="This is your information that was collected by Prospinity. Let's make sure everything is up to date."
      buttonsType="two"
      primaryText={loading ? "Saving..." : "SAVE"}
      onPrimaryClick={handleSubmit}
      secondaryText="CANCEL"
      onSecondaryClick={handleClose}
      alignCenter={false}
    >
      <Form onSubmit={handleSubmit}>
          <FormContainer>

              <ProfileSection>
                  {/* Profile Picture */}
                  <PictureUpload
                      image={formData.profile_picture}
                      setImage={(file) => handleChange("profile_picture", file)} 
                      error={errors.profile_picture}
                      showExplainText={false}
                  />

                  {/* First Name & Last Name Fields */}
                  <HorizontalAlign>
                      <FormInput 
                          label="First Name"
                          type="text"
                          value={formData.first_name}
                          onChange={(e) => handleChange("first_name", e.target.value)}
                          error={errors.first_name}
                      />

                      <FormInput 
                          label="Last Name"
                          type="text"
                          value={formData.last_name}
                          onChange={(e) => handleChange("last_name", e.target.value)}
                          error={errors.last_name}
                      />
                  </HorizontalAlign>

                  <FormInput 
                        label="Tagline" 
                        type="text" 
                        value={formData.tag_line}
                        onChange={(e) => handleChange("tag_line", e.target.value)}
                        error={errors.tag_line}
                    />

                  <FormTextarea 
                        label="About" 
                        type="text" 
                        value={formData.bio}
                        onChange={(e) => handleChange("bio", e.target.value)}
                        error={errors.bio}
                    />

                  <SingleSelect 
                    label="University" 
                    endpoint='search-universities'
                    value={formData.university}
                    onChange={(value) => handleChange("university", value)}
                    error={errors.university}
                />

                <HorizontalAlign>
                    <MultiSelect 
                        label="Major" 
                        endpoint='search-degrees'
                        value={formData.major_or_grad_program}
                        onChange={(value) => handleChange("major_or_grad_program", value)}
                        error={errors.major_or_grad_program}
                    />

                    <SingleSelectFixedOptions 
                        label="Graduation year" 
                        options={graduationYears}
                        value={formData.graduation_date}
                        onChange={(value) => handleChange("graduation_date", value)}
                        error={errors.graduation_date}
                    />
                </HorizontalAlign>

                <MultiSelect 
                    label="Clubs" 
                    endpoint='search-clubs'
                    value={formData.club}
                    onChange={(value) => handleChange("club", value)}
                    error={errors.club}
                />


                <SingleSelect 
                    label="Company" 
                    endpoint='search-companies'
                    value={formData.company}
                    onChange={(value) => handleChange("company", value)}
                    error={errors.company}
                />

                <FormInput 
                    label="Role" 
                    type="text" 
                    value={formData.role}
                    onChange={(e) => handleChange("role", e.target.value)}
                    error={errors.role}
                />

                <FormInput 
                    label="LinkedIn" 
                    type="text" 
                    value={formData.linkedin_url}
                    onChange={(e) => handleChange("linkedin_url", e.target.value)}
                    error={errors.linkedin_url}
                />

              </ProfileSection>

          </FormContainer>
      </Form>
    </CenteredModal>
  );
};

export default EditProfile;


// ---------------------- 🔹 Styled Components 🔹 ----------------------

const FormContainer = styled.div`
  display: flex;
  flex-direction: "column";
  gap: 2rem;

  > * {
    flex: 1; /* Ensures all inputs expand evenly */
    width: 100%;
  }

  @media (max-width: 768px) {
    gap: 1.25rem;
  }
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
  }
`;

/* ✅ First Name & Last Name Fields */
const HorizontalAlign = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;

  > * { 
    flex: 1; /* Makes both fields take equal space */
    min-width: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
  }
`;


// import React, { useState, useEffect } from 'react';
// import { Modal, Button, Form } from 'react-bootstrap';
// import MultiSelect from '../forms/MultiSelect';
// import SingleSelect from '../forms/SingleSelect';
// import UserProfile from '../../pages/UserProfile';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { StyledForm, StyledFormGroup, StyledFormControl, StyledFormLabel, StyledFormSelect, StyledTextArea } from '../common/StyledBootstrap';
// import CustomModal from '../common/CustomModal';
// import { apiP } from '../common/CriticalFunctions';
// import ProfilePictureUpload from '../forms/ProfilePictureUpload';


// function Edit({ modalTitle, bottomReload, setBottomReload, show, setShow, modalMessage, setModalMessage, handleClose, reload, setReload, modalEditData, setModalEditData }) {


//   const navigate = useNavigate();
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setModalEditData({ ...modalEditData, [name]: value });
//   };

//   const handleSaveChanges = async (e) => {
//     let data = {
//       ...modalEditData,
//       university: modalEditData.university.length === 0 ? '' : modalEditData.university[0], // Spread operator to copy all properties of userInfo,
//       company: modalEditData.company.length === 0 ? '' : modalEditData.company[0]
//     };

//     const response = await apiP(data, "update-user-profile/", setModalMessage, "Done!", null, null, true, bottomReload, setBottomReload, "put");
//     if (response && (modalTitle === 'Edit Name')) {
//       navigate('/dashboard/user/' + response.data.slug + '/');
//       setReload(reload + 1);

//     }
//   };



//   return (
//     <>
//       <CustomModal title={"Update Profile"} actionText={"Save Changes"} actionFunction={handleSaveChanges} show={show} setShow={setShow} message={modalMessage} setMessage={setModalMessage} handleClose={handleClose}>
//         <StyledForm>

          
//             <>
//               <StyledFormGroup controlId="formInput">
//                 <StyledFormLabel>First Name</StyledFormLabel>
//                 <StyledFormControl
//                   type="text"
//                   name="first_name"
//                   value={modalEditData.first_name}
//                   onChange={handleInputChange}
//                 />
//               </StyledFormGroup>
//               <StyledFormGroup controlId="formInput">
//                 <StyledFormLabel>Last Name</StyledFormLabel>
//                 <StyledFormControl
//                   type="text"
//                   name="last_name"
//                   value={modalEditData.last_name}
//                   onChange={handleInputChange}
//                 />
//               </StyledFormGroup>
//             </>
            
//             <ProfilePictureUpload />


          
//           <SingleSelect
//             endpoint='search-universities'
//             value={modalEditData.university}
//             onChange={(selected) => {
//               setModalEditData({ ...modalEditData, university: selected })
//             }}
//             label="University"
//           />

          
//             <MultiSelect
//               endpoint='search-degrees'
//               value={modalEditData.major_or_grad_program}
//               onChange={(selected) => {
//                 setModalEditData({ ...modalEditData, major_or_grad_program: selected })
//               }}
//               label="Program(s) of Study"
//             />
//             <SingleSelect
//             endpoint='search-companies'
//             value={modalEditData.company}
//             onChange={(selected) => {
//               setModalEditData({ ...modalEditData, company: selected })
//             }}
//             label="Highlighted Work Experience"
//           />



//           <StyledFormGroup>
//             <StyledFormLabel>Graduation Date</StyledFormLabel>
//             <StyledFormControl
//               type="date"
//               name="graduation_date"
//               value={modalEditData.graduation_date}
//               onChange={handleInputChange}
//             />
//           </StyledFormGroup>

          
//             <>
//               <StyledFormGroup controlId="formInput">
//                 <StyledFormLabel>This bio serves as your introduction to potential poolmates and people you will meet. Make it strong!</StyledFormLabel>
//                 <StyledTextArea
//                   as="textarea"
//                   name="bio"
//                   value={modalEditData.bio}
//                   onChange={handleInputChange}
//                   rows={4}
//                 />
//               </StyledFormGroup>
//             </>
            
//             <>
//               <StyledFormGroup controlId="formInput">
//                 <StyledFormLabel>LinkedIn</StyledFormLabel>
//                 <StyledFormControl
//                   type="text"
//                   name="linkedin_url"
//                   value={modalEditData.linkedin_url}
//                   onChange={handleInputChange}
//                 />
//               </StyledFormGroup>
//             </>
//         </StyledForm>
//       </CustomModal>
//     </>
//   );
// }

// export default Edit;
