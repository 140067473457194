import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { createPortal } from "react-dom";

import { PrimaryButton, SecondaryButton } from './Buttons';
import { ReactComponent as XIcon } from '../../assets/icons/X.svg';
import Loading from "./Loading";


const CenteredModal = ({ 
    show, 
    onClose, 
    media,
    title,
    subtitle,
    children, 
    alignCenter = true,
    buttonsType = 'one', 
    primaryText = 'Confirm', 
    onPrimaryClick, 
    secondaryText = 'Cancel', 
    onSecondaryClick,
    modalStyle={},
    modalBodyStye={},
    modalHeaderStyle={}
  }) => {

  // Loading for media
  const [isMediaLoaded, setIsMediaLoaded] = useState(false);

  // Responsive
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return show
      ? createPortal(
        <Overlay onClick={(e) => e.target === e.currentTarget && onClose()}>
            <ModalContainer onClick={(e) => e.stopPropagation()} style={modalStyle}>
              {media && (
                <MediaContainer>              
                  {media.endsWith('.mp4') ? (
                      <video src={media} controls playsInline onLoadedData={() => setIsMediaLoaded(true)} />
                    ) : (
                      <img src={media} alt="Img" onLoad={() => setIsMediaLoaded(true)} />
                    )
                  }
                </MediaContainer>
              )}

              <CloseButton onClick={onClose}>
                <XIcon />
              </CloseButton>

              <ContentContainer alignCenter={alignCenter} isMobile={isMobile}>

                <ModalHeader style={modalHeaderStyle} alignCenter={alignCenter}>{title}</ModalHeader>

                {subtitle && <ModalSubHeader>{subtitle}</ModalSubHeader>}

                {children && <ModalBody style={modalBodyStye}>{children}</ModalBody>}
                {/* {children && <>{children}</>} */}

                {buttonsType !== "none" && <ButtonContainer buttonsType={buttonsType} isMobile={isMobile}>
                    {buttonsType !== "one" && (
                      <SecondaryButton size="lg" onClick={onSecondaryClick || onClose}>
                        {secondaryText}
                      </SecondaryButton>
                    )}
                    <PrimaryButton size="lg" onClick={onPrimaryClick || onClose}>
                      {primaryText}
                    </PrimaryButton>

                  </ButtonContainer>
                }

              </ContentContainer>
            </ModalContainer>
          </Overlay>,
          document.body
  ) : null;
};

export default CenteredModal;



const Overlay = styled.div`
  position: fixed; /* Change from fixed to absolute */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background: ${({ theme }) => theme.textPrimary}A3; /* 64% opacity */
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  will-change: transform; /* Force GPU acceleration */
`;


const ModalContainer = styled.div`
  background: ${({ theme }) => theme.white};
  border-radius: 1.5rem;
  width: 92%;
  max-width: 560px;
  max-height: 80vh; /* Limits modal height */
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden; /* Prevents overflow beyond max-height */
`;


const MediaContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  background-color: #000000;

  img, video {
    width: 100%;
    height: auto;
    display: block;
    max-height: 320px;
  }

`;

const CloseButton = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.2s;
  z-index: 10;

  &:hover {
    opacity: 0.6;
  }

`;

const ContentContainer = styled.div`
  padding: ${({ isMobile }) => isMobile ? "1.5rem" : "2rem"};
  padding-bottom: 0;
  text-align: ${({ alignCenter }) => alignCenter ? "center" : "left"}; /* Align dynamically */
`;

const ModalHeader = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textPrimary};
  margin: 0;
  margin-right: 3rem;
  text-align: ${({ alignCenter }) => alignCenter ? "center" : "left"};
`;

const ModalSubHeader = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.textSecondary};
  margin-top: 0.5rem;
  margin-bottom: 0;
`;

const ModalBody = styled.div`
  font-size: 0.875rem;
  /* margin-top: 2rem; */
  flex-grow: 1;
  overflow-y: auto; /* Enables scrolling */
  max-height: 50vh; /*  Ensures it doesn’t push buttons too far down */
  padding: 0.5rem 0rem; /* Prevents content from touching edges */
  margin-top: 1rem;

`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: ${({ buttonsType }) => (buttonsType === 'one' ? 'row' : 'row')};
  justify-content: ${({ buttonsType }) => (buttonsType === 'one' ? 'center' : 'space-between')};
  gap: 1rem;
  padding: 1.5rem 0rem;
  position: sticky;
  bottom: 0;

  button {
    flex: ${({ buttonsType, isMobile }) => (isMobile || buttonsType !== 'one' ? 1 : 'unset')}
  }
`;