import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import { Chat, Channel, ChannelList, Window, MessageList, MessageInput as DefaultMessageInput, Thread } from 'stream-chat-react';
import '../styles/StreamChatLayout.css';
import { useTheme } from 'styled-components';
import { useAuth } from '../contexts/AuthContext';
import { StreamChat } from 'stream-chat';
import { CustomChannelPreview } from '../components/chat/CustomChannelPreview';
import { CustomChannelHeader } from '../components/chat/CustomChannelHeader';
import CustomAvatar from '../components/chat/CustomAvatar';

import { SearchIcon, ArrowDown } from "../assets/icons/Icons";
import { ReactComponent as NothingYetIcon } from "../assets/icons/nothingYet.svg"; // Import SVG as ReactComponent


const CustomMessageInput = (props) => {
  return <DefaultMessageInput {...props} grow />;
};


const ChatPage = () => {
  const { userFirstName, userLastName, userSlug, chatToken, profilePictureLink, activeChannel, setActiveChannel, isChatVisible, setChatVisible, chatID } = useAuth();
  const apiKey = process.env.REACT_APP_STREAM_API_KEY;
  const themeMode = useTheme();
  const filters = { members: { $in: [chatID] } };
  const options = { presence: true, state: true };
  const sort = { last_message_at: -1 };

  const [chatClient, setChatClient] = useState(null);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [viewingChannelList, setViewingChannelList] = useState(true);

  const location = useLocation();

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [searchText, setSearchText] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [filteredChannels, setFilteredChannels] = useState([]);
  

  const initializeClient = useCallback(async () => {
    const client = StreamChat.getInstance(apiKey);
    const userObject = { id: chatID };
    await client.connectUser(userObject, chatToken);
    setChatClient(client);
    checkUnreadMessages(client);
  }, [apiKey, chatID, userFirstName, userLastName, chatToken, profilePictureLink]);

  const checkUnreadMessages = async (client) => {
    const channels = await client.queryChannels(filters, sort, { watch: true, state: true });
    let unreadCount = 0;
    channels.forEach(channel => {
      unreadCount += channel.countUnread();
    });
    setUnreadMessageCount(unreadCount);
    if (unreadCount > 0) {
      setChatVisible(true);
    }
  };

  useEffect(() => {
    if (!chatClient && chatID && chatToken) {
      initializeClient();
    }

    // Try opening specific channel if `openChannelId` is passed
    if (chatClient && location?.state?.openChannelId) {
      const openTargetChannel = async () => {
        try {
          const targetChannel = chatClient.channel('messaging', location.state.openChannelId);
          await targetChannel.watch();
          setActiveChannel(targetChannel);
          setViewingChannelList(false);
        } catch (err) {
          console.error("Failed to open the target channel", err);
        }
      };
      openTargetChannel();
    }

    return () => {
      if (chatClient) {
        chatClient.disconnectUser();
        setChatClient(null);
      }
    };
  }, [chatClient, chatID, chatToken, initializeClient]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      const runSearch = async () => {
        if (!chatClient) return;
  
        if (!searchText.trim()) {
          setFilteredChannels([]); // show all
          return;
        }
  
        const channels = await chatClient.queryChannels(
          { members: { $in: [chatID] } },
          { last_message_at: -1 },
          { watch: true, state: true }
        );
  
        const matches = channels.filter((channel) => {
          const other = Object.values(channel.state.members).find(
            (m) => m.user.id !== chatID
          )?.user;
  
          return other?.name?.toLowerCase().includes(searchText.toLowerCase());
        });
  
        setFilteredChannels(matches);
      };
  
      runSearch();
    }, 1500); // adjust as needed
  
    return () => clearTimeout(debounceTimer);
  }, [searchText, chatClient, chatID]);


  const onChannelSelect = (channel) => {
    setActiveChannel(channel);
    setViewingChannelList(false);
    const currentUnread = channel.countUnread();
    setUnreadMessageCount(unreadMessageCount - currentUnread);
  };

  const backToChannelList = () => {
    setViewingChannelList(true);
    setActiveChannel(null);
  };

  const channelListProps = useMemo(() => ({
    filters,
    sort,
    options,
    Preview: (props) => <CustomChannelPreview {...props} setActiveChannel={onChannelSelect} />,
    ...(activeChannel && { customActiveChannel: activeChannel }),
    renderChannels: (channels) => {
      const visibleChannels = searchText.trim()
        ? channels.filter((channel) => {
            const other = Object.values(channel.state.members).find(
              (m) => m.user.id !== chatID
            )?.user;
            return other?.name?.toLowerCase().includes(searchText.toLowerCase());
          })
        : channels;
    
      return visibleChannels.length > 0 ? (
        visibleChannels.map((channel) => {
          const unread = channel.countUnread();
          const messages = channel.state.messages || [];
          const lastMessage = messages[messages.length - 1]?.text || '';
          const members = Object.values(channel.state.members);
          const currentUserID = chatID;
          const displayUser = members.find((m) => m.user.id !== currentUserID)?.user;
    
          return (
            <CustomChannelPreview
              key={channel.id}
              active={channel.id === activeChannel?.id}
              channel={channel}
              displayTitle={displayUser?.name || 'User'}
              latestMessage={lastMessage}
              setActiveChannel={onChannelSelect}
              unread={unread}
              watchers={channel.state.watchers}
            />
          );
        })
      ) : (
        <NoResults>
          <NothingYetIcon width={68} height={68} />
          <span style={{ fontSize: "1.5rem" }}>No results found.</span>
        </NoResults>
      );
    }
    
  }), [filters, sort, options, activeChannel, searchText, chatID]);
  
  

  return (
    <>
      {chatClient && (
        <div>
          <Chat client={chatClient} theme={'str-chat__theme-light'}>
            <div style={{height: "100dvh"}}>
              {viewingChannelList ? (
                <>
                   {!isMobile && <SearchContainer>
                      <SearchBar theme={themeMode} isActive={isFocused}>
                        <SearchIcon theme={themeMode} />
                        <SearchInput
                          type="text"
                          placeholder="Search"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          theme={themeMode}
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => setIsFocused(false)}
                        />
                      </SearchBar>
                    </SearchContainer>}

                    <ChannelList key={searchText || 'default-list'} {...channelListProps} />
                </>
              ) : (
                <Channel channel={activeChannel} >
                  <Window>
                    <CustomChannelHeader setChatVisible={setChatVisible} backToChannelList={backToChannelList} />
                    <MessageList
                      Avatar={CustomAvatar} 
                      hasMore={false}
                      loadMore={() => {}}
                      loadingMore={false}
                    />
                    <CustomMessageInput />
                  </Window>
                  <Thread />
                </Channel>
              )}
            </div>
          </Chat>
        </div>
      )}
    </>
  );
};

export default ChatPage;


const SearchContainer = styled.div`
    position: relative;
    width: 100%;
    padding: 1.125rem 1.25rem;
    background-color: ${({ theme }) => theme.white};
`;

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 1.3rem;
    border-radius: 80px;
    background-color: ${({ theme }) => theme.white};
    gap: 0.75rem;
    border: 1px solid ${({ theme, isActive }) => (isActive ? theme.primary : theme.divider)};
`;

const SearchInput = styled.input`
    flex: 1;
    border: none;
    outline: none;
    font-size: 0.95rem;
    font-weight: 400;
    color: ${({ theme }) => theme.textPrimary};
    background-color: transparent;
`;

const NoResults = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  padding: 2rem;
  color: ${({ theme }) => theme.textSecondary};
  transform: translateY(-2.5rem);
`;




// import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import { Chat, Channel, ChannelList, Window, MessageList, MessageInput as DefaultMessageInput, Thread } from 'stream-chat-react';
// import './StreamChatLayout.css';
// import { StyledButton } from '../StyledBootstrap';
// import { useTheme } from 'styled-components';
// import { useAuth } from '../AuthContext';
// import { StreamChat } from 'stream-chat';
// import { CustomChannelPreview } from './CustomChannelPreview';
// import { CustomChannelHeader } from './CustomChannelHeader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
// import { faHome, faHandshake, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear, faMessage } from '@fortawesome/free-solid-svg-icons'; // Example icons

// const CustomMessageInput = (props) => {
//   return <DefaultMessageInput {...props} grow />;
// };

// const Messaging = () => {
//   const { userFirstName, userLastName, userSlug, chatToken, profilePictureLink, activeChannel, setActiveChannel, isChatVisible, setChatVisible, chatID } = useAuth();
//   const apiKey = process.env.REACT_APP_STREAM_API_KEY;
//   const themeMode = useTheme();
//   const filters = { members: { $in: [chatID] } };
//   const options = { presence: true, state: true };
//   const sort = { last_message_at: -1 };

//   const [chatClient, setChatClient] = useState(null);
//   const [unreadMessageCount, setUnreadMessageCount] = useState(0);
//   const [viewingChannelList, setViewingChannelList] = useState(true);

//   const initializeClient = useCallback(async () => {
//     const client = StreamChat.getInstance(apiKey);
//     const userObject = { id: chatID, name: `${userFirstName} ${userLastName}`, image: profilePictureLink };
//     await client.connectUser(userObject, chatToken);
//     setChatClient(client);
//     checkUnreadMessages(client);
//   }, [apiKey, chatID, userFirstName, userLastName, chatToken, profilePictureLink]);

//   const checkUnreadMessages = async (client) => {
//     const channels = await client.queryChannels(filters, sort, { watch: true, state: true });
//     let unreadCount = 0;
//     channels.forEach(channel => {
//       unreadCount += channel.countUnread();
//     });
//     setUnreadMessageCount(unreadCount);
//     /*if (unreadCount > 0) {
//       setChatVisible(true);
//     }*/
//   };

//   useEffect(() => {
//     if (!chatClient && chatID && chatToken) {
//       initializeClient();
//     }
//     return () => {
//       if (chatClient) {
//         chatClient.disconnectUser();
//         setChatClient(null);
//       }
//     };
//   }, [chatClient, chatID, chatToken, initializeClient]);

//   const notificationCountStyle = {
//     position: 'absolute',
//     top: '-0.5rem',
//     right: '-0.5rem',
//     background: themeMode.secondary,
//     height: '1.5rem',
//     width: '1.5rem',
//     borderRadius: '50%',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     color: themeMode.onSecondary,
//     fontSize: '0.75rem',
//     fontWeight: 'bold',
//     zIndex: 1001
//   };

//   const toggleChat = () => {
//     setChatVisible(!isChatVisible);
//   };

//   const onChannelSelect = (channel) => {
//     setActiveChannel(channel);
//     setViewingChannelList(false);
//     const currentUnread = channel.countUnread();
//     setUnreadMessageCount(unreadMessageCount - currentUnread);
//   };

//   const backToChannelList = () => {
//     setViewingChannelList(true);
//     setActiveChannel(null);
//   };

//   const channelListProps = useMemo(() => ({
//     filters,
//     sort,
//     options,
//     showChannelSearch: true,
//     additionalChannelSearchProps: { searchForChannels: true, popupResults: true },
//     Preview: (props) => <CustomChannelPreview {...props} setActiveChannel={onChannelSelect} />,
//     ...(activeChannel && { customActiveChannel: activeChannel }),
//   }), [filters, sort, options, activeChannel]);

//   return (
//     <>
//       <StyledButton onClick={toggleChat} style={{ position: 'fixed', bottom: '2vh', right: 20, zIndex: 1000, fontSize: '1.5rem' }} className='chat-joyrride'>
//         {isChatVisible ? <><FontAwesomeIcon icon={faMessage}></FontAwesomeIcon></> : <><FontAwesomeIcon icon={faMessage}></FontAwesomeIcon></>}
//         {!isChatVisible && unreadMessageCount > 0 && (
//           <div style={notificationCountStyle}>{unreadMessageCount}</div>
//         )}
//       </StyledButton>

//       {isChatVisible && chatClient && (
//         <div style={{ position: 'fixed', bottom: 60, right: 20, maxWidth: '50vw', zIndex: 1000, border: '7px solid', borderColor: themeMode.surface, borderRadius: '15px', backgroundColor: themeMode.surface }}>
//           <Chat client={chatClient} theme={themeMode.theme === 'dark' ? 'str-chat__theme-dark' : 'str-chat__theme-light'}>
//             <div style={{ height: '70vh' }}>
//               {viewingChannelList ? (
//                 <ChannelList {...channelListProps} />
//               ) : (
//                 <Channel channel={activeChannel}>
//                   <Window>
//                     <CustomChannelHeader setChatVisible={setChatVisible} backToChannelList={backToChannelList} />
//                     <MessageList />
//                     <CustomMessageInput />
//                   </Window>
//                   <Thread />
//                 </Channel>
//               )}
//             </div>
//           </Chat>
//         </div>
//       )}
//     </>
//   );
// };

// export default Messaging;
