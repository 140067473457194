import React from 'react';
import { styled, useTheme } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import SearchUsers from './SearchUsers';
import Notifications from './Notifications';
import ProfileWithPopup from './ProfilePopup';
import { ReactComponent as ArrowLeft } from '../../assets/icons/ArrowLeft.svg';


export default function DesktopNavbar({ onShowNotifications, onShowSettings }) {
    // Theme
    const theme = useTheme();

    // Check if it is pool details
    const location = useLocation();
    const navigate = useNavigate();
    const isPoolDetailsPage = location.pathname.startsWith('/dashboard/pools/') && location.pathname !== '/dashboard/pools' && location.pathname !== '/dashboard/pools/';

    return (
        <NavbarContainer theme={theme}>
            {/* Search Bar (unless it's pool details page) */}
            {isPoolDetailsPage ? (
                <BackToPools onClick={() => navigate('/dashboard/pools')}>
                    <ArrowLeft width={20} height={20} fill={theme.textPrimary} /> {/* ✅ Left arrow */}
                    <span>Back to pools</span> {/* ✅ Text */}
                </BackToPools>
            ) : (
                <SearchUsers /> // ✅ Show search normally elsewhere
            )}

            {/* Right Section */}
            <NavbarRight>
                {/* Notifications */}
                <Notifications onShowNotifications={onShowNotifications} />

                {/* User Profile */}
                <ProfileWithPopup onShowSettings={onShowSettings} />
            </NavbarRight>
        </NavbarContainer>
    );
}



const NavbarContainer = styled.div`
  position: fixed;
  top: 0;
  width: calc(100% - 260px); /* Adjust for sidebar */
  height: 80px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5rem;
  background-color: inherit;
  box-sizing: border-box;
`;

/* Right Section */
const NavbarRight = styled.div`
  display: flex;
  align-items: center;
  gap: 0rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
`;

// ✅ Clickable div for "Back to Pools"
const BackToPools = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textPrimary};

  &:hover {
    opacity: 0.85;
  }
`;


// import axios from 'axios';
// import React, { useState, useEffect, useRef } from 'react';
// import { Outlet, Link, useNavigate } from 'react-router-dom';
// import { Nav, FormControl, Row, Col, Container, InputGroup, Dropdown, Navbar, Button } from 'react-bootstrap';
// import { useAuth } from './AuthContext';
// import './Dashboard.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
// import SearchUsers from './SearchUsers';
// import { faHome, faHandshake, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear, faChevronDown } from '@fortawesome/free-solid-svg-icons'; // Example icons
// import { useMediaQuery } from 'react-responsive';
// import { timeSince } from './CriticalFunctions';
// import { Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { useErrorBoundary } from "react-error-boundary";
// import { StyledNavbar, StyledDropdown, StyledButton, StyledH5, StyledNavLink } from './StyledBootstrap';
// import { styled, useTheme } from 'styled-components';
// import { myAxiosInstance } from './axiosConfig';
// import Notifications from './Notifications';

// import { SearchIcon, NotificationsIcon, ArrowDown } from './Icons';


// function NavbarLargeScreen({ setShowReferModal, setWhyModalShow }) {
//     const themeMode = useTheme();
//     const navigate = useNavigate();
//     const { userSlug, handleLogout, userFirstName, userLastName, userGraduationDate, profilePictureLink } = useAuth();

//     const logOutNavigate = () => {
//         handleLogout();
//         navigate('/signing-out');

//     }

//     const getBaseFontSize = () => {
//         // Get the font-size of the body element
//         const fontSize = window.getComputedStyle(document.body).fontSize;
//         // Parse the font size and return it as a number
//         return parseFloat(fontSize);
//     };



//     return (
//         <StyledNavbar expand="sm" sticky='top'>
//             <Container fluid>
//                 <div className='w-100 d-flex justify-content-between'>
                    
//                         <Navbar.Brand className="d-flex ml-2 align-items-center" onClick={() => navigate('/home')} style={{ cursor: 'pointer' }}>
//                             <img src={themeMode.theme == 'dark' ? logo_white : logo_navy} height="40rem" className="d-inline-block align-top" alt="Logo" />
//                         </Navbar.Brand>
                    

//                     <div className='w-50'>
//                         <SearchUsers />
//                     </div>

//                     <div className="d-flex align-items-center">

//                         {/*<StyledButton onClick={() => setWhyModalShow(true)} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "60%", marginRight: "0.5em", paddingInline: "0.8em", backgroundColor: themeMode.theme==='dark' ? themeMode.onSurface : themeMode.primary, color: themeMode.theme==='dark' ? themeMode.surface : themeMode.onPrimary}}>Why?</StyledButton>*/}
//                         {/*<StyledButton className='invite-joyrride' onClick={() => setShowReferModal(true)} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", height: "60%", marginRight: "0.5em", paddingInline: "0.8em", backgroundColor: themeMode.theme === 'dark' ? themeMode.onSurface : themeMode.primary, color: themeMode.theme === 'dark' ? themeMode.surface : themeMode.onPrimary }}>Invite</StyledButton>*/}
//                         <Notifications />
//                         <StyledDropdown >
//                             <StyledDropdown.Toggle as={Nav.Link} className="no-caret-icon profile-dropdown">
//                                 {/*<FontAwesomeIcon icon={faUser} />*/}
//                                 <div className='profile-dropdown-joyrride'>
//                                     <ProfilePicture name={userFirstName + " " + userLastName} size={getBaseFontSize() * 3} graduation_date={userGraduationDate} picture_link={profilePictureLink} />
//                                 </div>
//                             </StyledDropdown.Toggle>
//                             <StyledDropdown.Menu align={'end'}>
//                                 <StyledDropdown.Item as={Link} to={"user/" + userSlug} >
//                                     <Row>
//                                         <Col xs={2}><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></Col>
//                                         <Col>Profile</Col>

//                                     </Row>
//                                 </StyledDropdown.Item>

//                                 <StyledDropdown.Item as={Link} to="settings">
//                                     <Row>
//                                         <Col xs={2}><FontAwesomeIcon icon={faGear}></FontAwesomeIcon></Col>
//                                         <Col>Settings</Col>

//                                     </Row>
//                                 </StyledDropdown.Item>

//                                 <StyledDropdown.Item as={Link} to="faqs">
//                                     <Row className='faqs-joyrride'>
//                                         <Col xs={2}><FontAwesomeIcon icon={faQuestion}></FontAwesomeIcon></Col>
//                                         <Col>FAQs</Col>

//                                     </Row>
//                                 </StyledDropdown.Item>
//                                 <StyledDropdown.Item onClick={logOutNavigate}>
//                                     <Row>
//                                         <Col xs={2}><FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon></Col>
//                                         <Col>Logout</Col>

//                                     </Row>
//                                 </StyledDropdown.Item>
//                             </StyledDropdown.Menu>
//                         </StyledDropdown>
//                     </div>
//                 </div>
//             </Container>

//         </StyledNavbar>);
// }
