import { useState, useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";
import { ChevronDown, ChevronUp, X } from "react-bootstrap-icons";
import { myAxiosInstance } from "../../api/axiosConfig";

import { ErrorText } from "./MiscComponents";

export default function MultiSelect({ label, endpoint, value=[], onChange, placeholder, error }) {
    const [inputValue, setInputValue] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [selectedItems, setSelectedItems] = useState(value);
    const theme = useTheme();
    const dropdownRef = useRef(null);

    // Ensure inputValue updates when the prop value changes
    useEffect(() => {
        setSelectedItems(value || []); // Update inputValue when value prop changes
    }, [value]);

    // Fetch suggestions from API (empty string fetches default list)
    useEffect(() => {
        if (isOpen) fetchOptions(inputValue);
    }, [isOpen]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            fetchOptions(inputValue);
        }, 200);

        return () => clearTimeout(debounceTimer);
    }, [inputValue]);

    const fetchOptions = async (query) => {
        try {
            const response = await myAxiosInstance.get(`/api/${endpoint}/?q=${query}`);
            let results = response.data;

            if (!query) {
                const selectedItemsObjects = selectedItems.map(name => ({ name }));
                
                // Filter out selected items from the response
                results = selectedItemsObjects.concat(results.filter(item => !selectedItems.includes(item.name)));
            } else {
                // Sort: Selected items first
                const selectedNames = new Set(selectedItems);
                results = results.sort((a, b) => selectedNames.has(b.name) - selectedNames.has(a.name));
            }

            setSuggestions(results);
        } catch (error) {
            setSuggestions([]);
        }
    };

    // Handle selection (dropdown stays open)
    const handleSelect = (item) => {
        if (!item || !item.name) return;
    
        if (selectedItems.includes(item.name)) {
            // If item is already selected, remove it
            handleRemove(item.name);
        } else {
            // Otherwise, add it to the selection
            const newSelection = [...selectedItems, item.name];
            setSelectedItems(newSelection);
            onChange(newSelection);
        }
    
        setInputValue(""); // Clear input, dropdown remains open
    };

    // Handle removal of selected item
    const handleRemove = (itemToRemove) => {
        const newSelection = selectedItems.filter((item) => item !== itemToRemove);
        setSelectedItems(newSelection);
        onChange(newSelection);
    };

    // Handle clearing all selections
    const handleClear = () => {
        setSelectedItems([]);
        onChange([]);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <Container ref={dropdownRef}>
            <Wrapper theme={theme} isOpen={isOpen} onClick={() => setIsOpen(true)}>
                <SelectedItems>
                    {selectedItems.length > 0 && (
                        <Chip theme={theme}>
                            <ChipText>{selectedItems[0]}</ChipText> {/* Only the text gets truncated */}
                            <RemoveIcon onClick={() => handleRemove(selectedItems[0])}><X /></RemoveIcon>
                        </Chip>
                    )}
                    {selectedItems.length > 1 && (
                        <MoreIndicator theme={theme}>+{selectedItems.length - 1} more</MoreIndicator>
                    )}
                </SelectedItems>

                <StyledInput
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder={selectedItems.length ? "" : placeholder || ""}
                    theme={theme}
                />

                {selectedItems.length > 0 && <ClearIcon onClick={handleClear}><X /></ClearIcon>}

                <DropdownIcon onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click
                    setIsOpen((prev) => !prev); // Toggle dropdown
                }}>
                    {isOpen ? <ChevronUp /> : <ChevronDown />}
                </DropdownIcon>
                <FloatingLabel theme={theme}>{label}</FloatingLabel>
            </Wrapper>

            {isOpen && suggestions.length > 0 && (
                <Dropdown theme={theme}>
                    {suggestions.map((item, index) => (
                        <DropdownItem key={index} theme={theme} onClick={() => handleSelect(item)}>
                            {item.name}
                            {selectedItems.includes(item.name) && <Checkmark src="/assets/checkMark.svg" alt="Selected" />}
                        </DropdownItem>
                    ))}
                </Dropdown>
            )}

            {error && <ErrorText>{error}</ErrorText>}

        </Container>
    );
}

// Styled Components
const Container = styled.div`
    width: 100%;
    position: relative;
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 1rem;
    background: ${({ theme }) => theme.white};
    display: flex;
    align-items: center;
    padding: 0.8rem 1.5rem;
    cursor: pointer;
    flex-wrap: nowrap; /* Prevents wrapping */
`;

const SelectedItems = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Chip = styled.div`
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.backgroundHover};
    color: ${({ theme }) => theme.textPrimary};
    border-radius: 1rem;
    font-size: 0.875rem;
    max-width: 220px; /* Prevent it from growing too much */
    overflow: hidden;
    margin-right: 0.3rem;
    flex-shrink: 0; /* Prevent shrinking */
`;

const ChipText = styled.span`
    flex-grow: 1; /* Allows text to take available space */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; /* Proper truncation */
    min-width: 0; /* Prevents overflowing content */
`;

const MoreIndicator = styled.span`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.textSecondary};
    margin-left: 4px;
`;

const RemoveIcon = styled.div`
    cursor: pointer;
    flex-shrink: 0; /* Prevents shrinking */
    margin-left: 0.4rem;
    margin-bottom: 0.1rem;
    z-index: 2; /* Ensures it stays visible */
    font-size: 1rem;
`;

const StyledInput = styled.input`
    flex: 1;
    min-width: 50px;
    margin-left: 0.3rem;
    border: none;
    outline: none;
    background: transparent;
    font-size: 1rem;
    color: ${({ theme }) => theme.textPrimary};
    text-align: left;
`;

const FloatingLabel = styled.label`
    position: absolute;
    top: -0.6rem;
    left: 1.5rem;
    background: ${({ theme }) => theme.white};
    padding: 0 0.3rem;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.textSecondary};
`;

const ClearIcon = styled.div`
    cursor: pointer;
    margin-bottom: 0.2rem;
`;

const DropdownIcon = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding-left: 8px;
    font-size: 1.125rem;
`;

const Dropdown = styled.div`
    position: absolute;
    top: 110%;
    width: 99%;
    left: 2px;
    background: ${({ theme }) => theme.white};
    border-radius: 1rem;
    max-height: 210px;
    overflow-y: auto;
    z-index: 10;
    padding: 0 0.8rem;
    box-shadow: 0px 2px 10px rgba(29, 29, 38, 0.1);
`;

const DropdownItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between; /* Ensures checkmark is on the right */
    align-items: center;
    padding: 0.8rem 1rem;
    cursor: pointer;
    font-size: 0.875rem;
    border-bottom: 1px solid ${({ theme }) => theme.divider};
    color: ${({ theme }) => theme.textPrimary};

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background: ${({ theme }) => theme.backgroundLight};
        border-radius: 0.5rem;
    }
`;

const Checkmark = styled.img`
    margin-left: auto; /* Pushes checkmark to the right */
`;


//maybe put in the form control itself
//better grid navigation
//border selected elements with something

// import React, { useState, useEffect } from 'react';
// import { FormControl, Form } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import { stringToColor } from '../common/CriticalFunctions';
// import { useTheme } from 'styled-components';
// import { StyledFormLabel, StyledFormControl, StyledFormGroup } from '../common/StyledBootstrap';
// import { myAxiosInstance } from '../../api/axiosConfig';

// const bubbleStyle = (color) => ({
//   display: 'inline-block',
//   padding: '5px 10px',
//   margin: '5px',
//   borderRadius: '15px',
//   backgroundColor: color,
//   color: 'black',
//   fontSize: '0.8rem',
// });

// const closeButtonStyle = {
//   marginLeft: '0.3rem',
//   cursor: 'pointer'
// };

// const suggestionListStyle = {
//   listStyleType: 'none',
//   padding: 0,
//   margin: 0,
//   display: 'flex',
//   flexWrap: 'wrap'
// };






// function MultiSelect({ endpoint, value, onChange, label, extra="" }) {
//   const [inputValue, setInputValue] = useState('');
//   const [suggestions, setSuggestions] = useState([]);
//   const [selectedItems, setSelectedItems] = useState(
//     value.map(item => ({ name: item, color: stringToColor(item) }))
//   );
//   const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
//   const fetchOptions = async (query) => {
//     try {
//       const response = await myAxiosInstance.get('/api/' + endpoint + '/' + '?q=' + query);
//       return response.data;
//       /*return endpoint.filter(item =>
//         item.label.toLowerCase().startsWith(query.toLowerCase())

//       );*/


//     } catch (error) {
//       return [];
//     }
//   };

//   const themeMode = useTheme();


//   useEffect(() => {
//     const debounceTimer = setTimeout(() => {
//       if (inputValue) {

//         fetchOptions(inputValue).then(data => {
//           const filteredData = data.filter(item =>
//             !selectedItems.some(selectedItem => selectedItem.name === item.name)
//           );
//           setSuggestions(filteredData);
//         });
//       } else {
//         setSuggestions([]);
//       }
//     }, 100); // 300ms debounce time

//     return () => {
//       clearTimeout(debounceTimer);
//     };
//   }, [inputValue]);



//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//     setActiveSuggestionIndex(0);
//   };

//   const handleSelect = (item) => {
//     if (!selectedItems.some(selectedItem => selectedItem.name === item.name)) {
//       const updatedItems = [...selectedItems, { ...item, color: stringToColor(item.name) }];
//       setSelectedItems(updatedItems);
//       setInputValue('');
//       setActiveSuggestionIndex(0);
//       onChange(updatedItems.map(item => item.name));
//     }

//     /*const updatedItems = [...selectedItems, { ...item, color: stringToColor(item.label) }];
//     setSelectedItems(updatedItems);
//     setInputValue('');
//     //setSuggestions([]);
//     setActiveSuggestionIndex(0);
//     onChange(updatedItems.map(item => item.label)); // Call the onChange prop with new value*/
//   };


//   const handleRemoveItem = (name) => {
//     const updatedItems = selectedItems.filter(item => item.name !== name);
//     setSelectedItems(updatedItems);
//     onChange(updatedItems.map(item => item.name)); // Update the parent component
//   };

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter') {
//       event.preventDefault();
//       if (suggestions.length > 0)
//       {
//         handleSelect(suggestions[activeSuggestionIndex]);
//       }
//     } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
//       setActiveSuggestionIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
//     } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
//       setActiveSuggestionIndex((prevIndex) => (prevIndex + suggestions.length - 1) % suggestions.length);
//     }
//   };

//   return (
//     <StyledFormGroup>
//       <StyledFormLabel>{label}{extra}</StyledFormLabel>
//       <div>

//         <StyledFormControl
//           type="text"
//           value={inputValue}
//           placeholder={"Search " + label}
//           onChange={handleInputChange}
//           onKeyDown={handleKeyDown}
//         />
//         {suggestions.length > 0 && (
//           <ul style={suggestionListStyle}>
//             {suggestions.map((item, index) => (
//               <li key={index}
//                 style={{
//                   display: 'flex', // Using flex to center the content
//                   justifyContent: 'flex-start',
//                   alignItems: 'center',
//                   marginBottom: '5px',
//                 }}
//                 onClick={() => handleSelect(item)}
//               >
//                 <span
//                   style={{
//                     ...bubbleStyle(stringToColor(item.name)),
//                     cursor: 'pointer',
//                     border: index === activeSuggestionIndex ? `2px solid ${themeMode.onSurface}` : 'none',
//                     boxShadow: index === activeSuggestionIndex ? '0px 0px 10px rgba(0, 0, 0, 0.2)' : 'none',
//                   }}
//                 >
//                   {item.name}
//                 </span>
//               </li>
//             ))}
//           </ul>


//         )}
//         <div>
//           {selectedItems.map((item, index) => (
//             <span key={index} style={bubbleStyle(item.color)}>
//               {item.name}
//               <span
//                 style={closeButtonStyle}
//                 onClick={() => handleRemoveItem(item.name)}
//               >
//                 <FontAwesomeIcon icon={faTimes} />
//               </span>
//             </span>
//           ))}
//         </div>

//       </div>
//     </StyledFormGroup>
//   );
// }

// export default MultiSelect;