import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { motion, AnimatePresence } from "framer-motion";
import { useApplication } from "../../contexts/ApplicationContext";
import { IoShareOutline } from "react-icons/io5";
import { FaRegCopy } from "react-icons/fa"; // FontAwesome copy icon
import md5 from "crypto-js/md5"; // Hashing library for uniqueness



export default function ThankYouScreen() {
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  const [copied, setCopied] = useState(false); // Track if copied message should show
  const { formData, updateFormData } = useApplication();

  // Generate unique referral link
  const getReferralLink = (email) => {
    if (!email) return "https://app.prospinity.com/ref/";

    const username = email.split("@")[0]; // Extracts part before '@'
    const hash = md5(email).toString().slice(0, 4); // Shortened hash for uniqueness
    return `https://app.prospinity.com/ref/${username}-${hash}`;
  };

  const forwardingLink = useRef(getReferralLink(formData.email));

  const handleCopy = () => {
    navigator.clipboard.writeText(forwardingLink.current).then(() => {
      setCopied(true); // Show "Copied!" message
      setTimeout(() => setCopied(false), 2000); // Hide after 2 seconds
    });
  };


  return (
      <AnimatePresence>
        <CenteredContainer isMdOrLarger={isMdOrLarger}>
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.6 }}
            style={{ width: "100%" }}
          >
            <ApplicationText isMdOrLarger={isMdOrLarger}>
              <p>Thanks for your time.</p>
              <p>Since you made it to the end, you have successfully entered into our $2000 giveaway.</p>
              <p>We will be in touch with more details via email.</p>
              <p>If you know someone who might be interested, share this link with them.</p>
              <p>For each person who finishes the walkthrough using your link, you will receive an extra entry in the giveaway.</p>
              <CopyableContainer>
                {/* Link + Copy Icon Centered */}
                <LinkRow onClick={handleCopy}>
                  <CopyIcon />
                  <ReferralLink>{forwardingLink.current}</ReferralLink>
                </LinkRow>
                {copied && <CopiedMessage show={copied}>Copied!</CopiedMessage>}

                {/* Share Button Centered on New Line */}
                <ShareButton link={forwardingLink.current} />
              </CopyableContainer> 
            </ApplicationText>
          </motion.div>
        </CenteredContainer>
      </AnimatePresence>
  );
}


const ApplicationText = styled(motion.div)`
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.9rem" : "1.2rem")};
  line-height: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.6" : "1.4")};
  color: rgba(255, 255, 255, 0.9); /* Slightly softer white */
  padding: 1rem;
`;


const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height:${({ isMdOrLarger }) => (isMdOrLarger ? "50vh" : "70vh")};
  text-align: center; /* Ensures text is centered */
  width: 100%;
  padding: 1rem;
`;

const CopyableContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack elements */
  align-items: center; /* Center everything */
  justify-content: center;
  text-align: center;
  font-weight: 550;
  gap: 0.5rem;
  width: 100%;
  cursor: pointer;
`;

const LinkRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  gap: 0.5rem;
  flex-wrap: nowrap; /* Prevent wrapping */
  max-width: 100%;
  cursor: pointer;
`;

const ReferralLink = styled.span`
  display: inline-block;
  word-break: break-word;
  text-align: center;
  overflow-wrap: break-word;
  max-width: 100%;
  cursor: pointer;
`;



const CopyIcon = styled(FaRegCopy)`
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.3s;
  flex-shrink: 0; /* Prevents icon from resizing */
  line-height: 1; /* Ensures it sits at the same baseline */

  ${CopyableContainer}:hover & {
    color: white;
  }
`;

const ReferralLinkWrapper = styled.div`
  display: flex;
  align-items: center; /* Ensures vertical alignment */
  gap: 0.5rem;
  flex-wrap: nowrap; /* Ensures it stays in a single line */
  max-width: 100%; /* Prevents overflow */
`;



const CopiedMessage = styled.span`
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 0.8rem;
  padding: 0.3rem 0.6rem;
  border-radius: 5px;
  display: ${({ show }) => (show ? "inline-block" : "none")}; /* Fix visibility */
  margin-top: 5px;
  text-align: center;
`;


function ShareButton({ link }) {
  const [copied, setCopied] = useState(false);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "",
          text: "",
          url: link,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      // Fallback: Copy to clipboard
      navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  return (
    <CopyableContainer style={{flexDirection:"row"}} onClick={handleShare}>
      <IoShareOutline />
      <span>Share</span>
    </CopyableContainer>
  );
}