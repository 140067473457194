import React from "react";
import styled from "styled-components";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import logo from "../../assets/images/logo_white.png"; // Make sure the file path is correct

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #000000;
  font-family: "Neue Montreal", sans-serif;
  color: #ffffff;
  text-align: center;
  padding: 40px;
`;

const Logo = styled.img`
  width: 180px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 42px;
  font-weight: 800;
  margin-bottom: 30px;
`;

const GraphContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 90%;
  max-width: 1200px;
  margin-bottom: 5px;
`;

const GraphWrapper = styled.div`
  width: 28%;
  text-align: center;
  position: relative;
`;

const PercentileText = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${({ color }) => color};
  margin-top: 15px;
`;

const GraphDescription = styled.p`
  font-size: 16px;
  color: #c0c7d6;
  margin-top: 10px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  max-width: 800px;
  margin-bottom: 30px;
  color: #c0c7d6;
  line-height: 1.5;
`;

const CTAButton = styled.button`
  background: linear-gradient(90deg, #0052cc, #4361ee);
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  padding: 15px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #2546bf;
  }
`;

// Updated Resume Evaluation Section: moved up and only two fields
const EvaluationContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 1rem;
  padding: 20px;
  border: 1px solid #c0c7d6;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  text-align: center;
  background: #121212;
  color: #ffffff;
`;

const EvaluationTitle = styled.h2`
  font-size: 28px;
  margin-bottom: 10px;
`;

const EvaluationText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  color: #c0c7d6;
`;

// Generate a bell curve that maps x values from -3 to 3 onto 0 to 100
const generateBellCurve = () => {
  const data = [];
  for (let x = -3; x <= 3; x += 0.1) {
    const y = Math.exp(-0.5 * x * x) / Math.sqrt(2 * Math.PI);
    // Adjust scaling so that x spans 0 to 100
    const xVal = (x + 3) * (100 / 6);
    data.push({
      x: xVal,
      y: y * 140,
    });
  }
  return data;
};

const ResultsScreen = ({ overall, longTerm, riskVolatility, evaluationData }) => {
  const categories = [
    {
      label: "Overall Earnings",
      value: overall,
      description: "Projected total earnings percentile.",
      color: "#4A90E2",
    },
    {
      label: "Long-Term Earnings",
      value: longTerm,
      description: "Growth of earnings over time.",
      color: "#F5A623",
    },
    {
      label: "Risk & Volatility",
      value: riskVolatility,
      description: "How stable your earnings potential is.",
      color: "#D0021B",
    },
  ];

  // Pre-calculate the bell curve data once since it's shared across charts
  const bellCurveData = generateBellCurve();

  return (
    <Container>
      <Logo src={logo} alt="Prospinity Logo" />
      <Title>📊 Here’s where you stand among Berkeley students:</Title>

      {/* Graphs Section */}
      <GraphContainer>
        {categories.map((cat, index) => (
          <GraphWrapper key={index}>
            <ResponsiveContainer width="100%" height={100}>
              <AreaChart data={bellCurveData}>
                <defs>
                  <linearGradient id={`gradient-${index}`} x1="0" y1="0" x2="1" y2="0">
                    <stop offset="0%" stopColor={cat.color} stopOpacity={0.7} />
                    <stop offset={`${cat.value}%`} stopColor={cat.color} stopOpacity={0.7} />
                    <stop offset={`${cat.value}%`} stopColor={cat.color} stopOpacity={0} />
                    <stop offset="100%" stopColor={cat.color} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="x" hide />
                <YAxis hide />
                <Area
                  type="monotone"
                  dataKey="y"
                  stroke={cat.color}
                  fill={`url(#gradient-${index})`}
                  isAnimationActive={false}
                />
                <ReferenceLine
                  x={cat.value}
                  stroke={cat.color}
                  strokeWidth={2}
                  label={{
                    value: `${cat.value}th percentile`,
                    position: "top",
                    fill: cat.color,
                  }}
                />
              </AreaChart>
            </ResponsiveContainer>
            <PercentileText color={cat.color}>{cat.value}th percentile</PercentileText>
            <GraphDescription>{cat.description}</GraphDescription>
          </GraphWrapper>
        ))}
      </GraphContainer>

      {/* Resume Evaluation Section */}
      {evaluationData && (
        <EvaluationContainer>
          <EvaluationText>
            <strong>Long-Term Earning Potential:</strong>{" "}
            {evaluationData.evaluation.long_term_earning_potential}
          </EvaluationText>
          <EvaluationText>
            <strong>Starting Salary Potential:</strong>{" "}
            {evaluationData.evaluation.starting_salary_potential}
          </EvaluationText>
        </EvaluationContainer>
      )}

      {/* Copy Section */}
      <Subtitle>
        💡 Our AI is powerful, but it will always be imperfect. Financial success is inherently uncertain—factors outside your control, technologies shifting, industries evolving.
      </Subtitle>
      <Subtitle>
        🚀 But what if there was a way to turn all this uncertainty into an advantage?
      </Subtitle>
      <Subtitle>
        💡 A completely new idea, backed by the first investors in Sam Altman, Robinhood, and Venmo. And it’s available to college students at Berkeley.
      </Subtitle>

      {/* CTA Butto */}
      <CTAButton>Learn More About Prospinity</CTAButton>
    </Container>
  );
};

export default ResultsScreen;