import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../assets/images/logo_white.png"; // Make sure the file path is correct

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #000000;
  font-family: "Neue Montreal", sans-serif;
  color: #ffffff;
  text-align: center;
  padding: 40px;
`;

const Logo = styled.img`
  width: 180px; /* Adjust as needed */
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 20px;
`;

const Loader = styled.div`
  width: 50px;
  height: 50px;
  border: 6px solid rgba(255, 255, 255, 0.2);
  border-top: 6px solid #3f82ef;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const FlashingText = styled.div`
  font-size: 22px;
  font-weight: 500;
  min-height: 30px;
  transition: opacity 0.5s ease-in-out;
  margin-bottom: 40px;
`;

const WhyBetterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 1000px;
`;

const Card = styled.div`
  width: 300px;
  background: rgba(255, 255, 255, 0.08);
  padding: 15px;
  border-radius: 10px;
  text-align: left;
  cursor: pointer;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const CardTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

const CardContent = styled.p`
  font-size: 16px;
  margin-top: 5px;
  color: #c0c7d6;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const initialMessages = [
  "⌛ Scanning your resume…",
  "🧠 Extracting skills, experience, and career trajectory…",
  "📊 Mapping your career trajectory to real-world outcomes…",
  "🔍 Detecting key career signals hidden in your experience…",
  "📈 Evaluating industry trends and market demand…",
  "💾 Processing billions of career paths to identify yours…"
];

const staticMessages = [
  {
    title: "🔹 It Sees Beyond Labels",
    content: "Your major & GPA don’t define you. Your real trajectory is shaped by what’s between the lines.",
  },
  {
    title: "🔹 It Doesn’t Need Past Examples",
    content: "Unlike traditional models, AI can predict even for unique career paths that haven't existed before.",
  },
  {
    title: "🔹 Context is Everything",
    content: "Two CS majors with the same GPA can have radically different futures—AI understands why.",
  },
  {
    title: "🔹 It Adapts in Real Time",
    content: "Human models are stuck in the past. AI adjusts as industries shift and new careers emerge.",
  },
  {
    title: "🔹 Peer Ranking > Salary Estimates",
    content: "Knowing where you stand among top students matters more than a static salary projection.",
  },
];

const ProcessingScreen = () => {
  const [currentMessage, setCurrentMessage] = useState(initialMessages[0]);
  const [expanded, setExpanded] = useState(Array(staticMessages.length).fill(false));

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      index = (index + 1) % initialMessages.length;
      setCurrentMessage(initialMessages[index]);
    }, 15000); // Show each message for 15 seconds

    return () => clearInterval(interval);
  }, []);

  const toggleExpand = (index) => {
    setExpanded((prev) =>
      prev.map((item, i) => (i === index ? !item : item))
    );
  };

  return (
    <Container>
      <Logo src={logo} alt="Prospinity Logo" />
      <Title>⚡Analyzing Your Resume. This May Take A Minute....</Title>
      <Loader />
      <FlashingText>{currentMessage}</FlashingText>

      <WhyBetterContainer>
        {staticMessages.map((item, index) => (
          <Card key={index} onClick={() => toggleExpand(index)}>
            <CardTitle>{item.title}</CardTitle>
            <CardContent isVisible={expanded[index]}>{item.content}</CardContent>
          </Card>
        ))}
      </WhyBetterContainer>
    </Container>
  );
};

export default ProcessingScreen;