import React, { useState, useEffect, useRef } from 'react';
import { styled, useTheme } from "styled-components";
import { useNavigate } from 'react-router-dom';
import { isMotionValue, motion } from "framer-motion";
import { useMediaQuery } from 'react-responsive';

import CenteredModal from '../common/Modals';
import MutualConnections from './MutualConncetions';
import { myAxiosInstance } from '../../api/axiosConfig';
import { PrimaryButton, SecondaryButton } from '../common/Buttons';
import { ReactComponent as MessageIcon } from "../../assets/icons/messageIcon.svg";
import { ReactComponent as ThreeDots } from "../../assets/icons/threeDots.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/addIcon.svg";


// Requesting user cards
export default function UserPoolCard({ user }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 600 });
  
    const profilePicture = user.profile_picture_url?.trim() !== ""
        ? user.profile_picture_url
        : "https://storage.googleapis.com/prosp_profile_img/favicons/favicon.svg";

    // Modals
    const [showRemoveModal, setShowRemoveModal] = useState(false);

  
    const handleMessageButtonClick = async (e) => {
        e.stopPropagation();
  
        try {
            const response = await myAxiosInstance.post('/api/create-channel/', { recipient_slug: user.slug });
            const { channel_id } = response.data;
            navigate("/dashboard/inbox/", { state: { openChannelId: channel_id } });
        }
        catch (error) {
        }
    };

    const renderActionButton = () => {
        return (
            <PortfolioButtonsContainer>
                <SecondaryButton size={isMobile ? "md" : "lg"} style={{ padding: "0.5rem 1rem", flex: "1" }} onClick={handleMessageButtonClick}>
                    <MessageIcon />
                    MESSAGE
                </SecondaryButton>
            </PortfolioButtonsContainer>
        );
    };



    return (
        <UserCardContainer onClick={() => { navigate(`/dashboard/user/${user.slug}`); }}>
            <Header style={{ backgroundImage: `url(${profilePicture})`, height: "4rem" }}>
                <BlurOverlay />
            </Header>

            <ContentWrapper>
                <MainContentWrapper>

                    {/* Profile Image & Name */}
                    <TopSection>
                        <ProfileImage 
                            src={profilePicture} 
                            alt="Profile" 
                            onError={(e) => {
                                e.target.onerror = null; // Prevent infinite loop
                                e.target.src = "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW_20250320180317.webp";
                            }} 
                        />

                        <UserInfoContainer>
                            <UserName>{user.first_name} {user.last_name}</UserName>
                            <UserUniversity>
                                {user.university}
                                {isMobile && (
                                    <>
                                    <span
                                        style={{
                                        display: "inline-block",
                                        color: theme.divider,
                                        transform: "scaleY(1.3)",
                                        lineHeight: "1",
                                        margin: "0 0.2rem",
                                        }}
                                    >
                                        |
                                    </span>
                                    {user.company ? `${user.company}` : `Exp. ${user.graduation_date} graduate`}
                                    </>
                                )}
                            </UserUniversity>

                        </UserInfoContainer>
                    </TopSection>   

                    {!isMobile && <DividerLine theme={theme} style={{ margin: "1rem 0 0.75rem 0" }} />}

                    {/* Degree for desktop */}
                    <DegreeContainer style={{ marginTop: isMobile ? "0.5rem" : "auto", marginBottom: "0.5rem", gap: "0.5rem", flexDirection: "row" }}>
                        {user.major_or_grad_program.slice(0, 1).map((degree, index) => (
                            <DegreeTag key={index} theme={theme}>
                                {degree}
                            </DegreeTag>
                        ))}
                        {!isMobile && 
                            <DegreeTag key={"grad"} theme={theme} style={{ color: theme.textSecondary, background: `${theme.textSecondary}1A`, alignSelf: "flex-start" }}>
                                {user.company ? <span>{user.company}</span> : <span>Exp. {user.graduation_date} graduate</span>}
                            </DegreeTag>
                        }
                    </DegreeContainer>

                    {isMobile && <DividerLine theme={theme} style={{ margin: "0.75rem auto" }} /> }

                    <Tagline style={{ marginBottom: !isMobile ? "0.75rem" : "auto" }} theme={theme}>{user.tag_line ? `${user.tag_line}` : user.company ? user.role ? `${user.role} at ${user.company}` : user.company !== "Founder" ? `Work experience at ${user.company}` : `${user.company}` : `Student @ ${user.university}`}</Tagline>

                    {isMobile && <DividerLine theme={theme} style={{ margin: "0.75rem auto" }} /> }

                    <div style={isMobile ? { margin: "0.25rem 0 1rem 0" } : {}}><MutualConnections mutuals={user.mutual_connections} themeStyle="light" /></div>

                    {!isMobile && <DividerLine theme={theme} style={{ margin: "0.75rem 0 1rem 0" }} />}

                    {renderActionButton()}

                </MainContentWrapper>
            </ContentWrapper>
        </UserCardContainer>
    );
}


const UserCardContainer = styled.div`
    background: ${({ theme }) => theme.white};
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    cursor: pointer;
    position: relative;

    &:hover {
        transform: scale(1.02); /* Slightly enlarges */
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); /* Soft shadow */
    }
`;

const Header = styled.div`
    width: 100%;
    height: 5rem;
    background-size: cover;
    background-position: center;
`;

const BlurOverlay = styled.div`
    width: 100%;
    height: 100%;
    inset: 0;
    backdrop-filter: blur(1.25rem);
    -webkit-backdrop-filter: blur(1.25rem);
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
`;

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const MainContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.8rem 1.25rem 1.5rem 1.25rem;
    width: 100%;

    @media (max-width: 600px) {
        margin-top: -1.25rem;
        padding: 0rem 1.25rem 1.25rem 1.5rem;
    }
`;

const TopSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    min-width: 0;

    @media (max-width: 600px) {
        flex-direction: column;
        gap: 0.75rem;
    }
`;

const ProfileImage = styled.img`
    align-self: flex-start;
    width: 4.5rem;
    height: 4%.5;
    aspect-ratio: 1;
    border-radius: 1rem;
    margin-top: -1.8rem;
    z-index: 2;
    object-fit: cover;

    @media (max-width: 600px) {
        width: 5rem;
        height: 5rem;
        margin-top: 0;
    }
`;

const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    flex: 1;      /* Important to allow shrinking */
    min-width: 0; /* Required for ellipsis to work in flex */
`;

const UserName = styled.span`
    font-size: 1.25rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
    white-space: nowrap;  
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;  /* Ensure it doesn’t overflow */
    display: block; /* Prevents flex issues */

    @media (max-width: 600px) {
        font-size: 1.5rem;
        white-space: wrap;
    }
`;

const UserUniversity = styled.p`
    font-size: 1rem;
    color: ${({ theme }) => theme.textSecondary};
    margin: 0;
    white-space: nowrap;  
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;  /* Ensure it doesn’t overflow */
    display: block; /* Prevents flex issues */

    @media (max-width: 600px) {
        font-size: 0.875rem;
        white-space: wrap;
    }
`;

const DividerLine = styled.hr`
    width: 95%;
    height: 1px; /* Thin line */
    border: none;
    background: linear-gradient(90deg, 
        ${({ theme }) => theme.divider} 0%, 
        ${({ theme }) => theme.divider} 15%, 
        ${({ theme }) => theme.divider} 50%, 
        ${({ theme }) => theme.divider} 85%, 
        ${({ theme }) => theme.divider} 100%
    );
    opacity: 1; /* Ensure visibility */
    margin: 0.9rem auto; /* Adjust spacing as needed */
`;

const DegreeContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: nowrap;
    width: 100%;
    overflow: hidden;
`;

const DegreeTag = styled.span`
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.primary};
    background: ${({ theme }) => theme.primaryLight};
    padding: 0.25rem 0.625rem;
    border-radius: 2.75rem;
    display: inline-block;

    flex-shrink: 1;
    flex-grow: 0;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Tagline = styled.p`
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.textPrimary};
    margin: 0; /* ✅ More space before the dashed line */
    text-align: left;

    white-space: nowrap;         /* Prevent wrap */
    overflow: hidden;            /* Enable ellipsis */
    text-overflow: ellipsis;     /* Show ... */
    min-width: 0;                /* Critical in flex layouts */
    max-width: 100%;             /* Prevent overflow */
    align-self: flex-start;      /* Optional: align properly in flex column */

    @media (max-width: 600px) {
        font-size: 0.875rem;
        white-space: wrap;
    }
`;



const PortfolioButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.6rem;
    width: 100%;
`;

const PortfolioDropdownContainer = styled.div`
    position: absolute;
    bottom: 15%; /* Moves it upwards instead of down */
    right: 1rem; /* Aligns it with the three dots button */
    background: ${({ theme }) => theme.white};
    border-radius: 1rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    width: 55%;
    padding: 0;
    z-index: 100; /* Ensures it's always on top */
    overflow: hidden; /* Prevents the hover effect from overflowing */
`;

const PortfolioDropdownOption = styled.button`
    width: 100%;
    padding: 0.6rem 0.7rem;
    font-size: 0.875rem;
    background: none;
    border: none;
    color: ${({ theme }) => theme.textPrimary};
    text-align: left;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.backgroundDarker}; /* Apply hover */
    }

    &:first-of-type {
        padding-top: 0.6rem;
    }

    &:last-of-type {
        padding-bottom: 0.6rem;
    }

`;