import React, { useEffect, useState, useRef } from 'react';
import { useTheme, styled } from 'styled-components';
import { useErrorBoundary } from "react-error-boundary";
import { useMediaQuery } from 'react-responsive';

import { MainContainer } from '../components/common/Containers';
import { PrimaryButton, SecondaryButton } from '../components/common/Buttons';
import { Form } from '../components/forms/Form';
import { SingleSelectFixedOptions } from '../components/forms/SingleSelect';
import Loading from "../components/common/Loading";
import CenteredModal from '../components/common/Modals';
import { myAxiosInstance } from '../api/axiosConfig';
import financesImage from "../assets/images/finances.png";
import { ReactComponent as PaymentMethodIcon } from "../assets/icons/paymentMethod.svg";
import { ReactComponent as NothingYetIcon } from "../assets/icons/nothingYet.svg"; // Import SVG as ReactComponent


export default function Finances() {

    // States
    const [activeTab, setActiveTab] = useState("transactions");
    const [financeData, setFinanceData] = useState(null); 
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(1);

    const [balance, setBalance] = useState(null)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
    const [venmoHandle, setVenmoHandle] = useState('')
    const [paypalUsername, setPaypalUsername] = useState('')

    // Temp payment methods
    const [tempPaymentMethod, setTempPaymentMethod] = useState('');
    const [tempVenmoHandle, setTempVenmoHandle] = useState('');
    const [tempPaypalUsername, setTempPaypalUsername] = useState('');

    // Modals
    const [showPaymentChoiseModal, setShowPaymentChoiseModal] = useState(false); // To show or hide the payment choice modal
    const [modalErrorMessage, setModalErrorMessage] = useState(null);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false); // To show or hide the tutorial modal

    // Errors
    const { showBoundary } = useErrorBoundary();

    // Get the theme
    const theme = useTheme();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 600 });

    // Button Clicks

    const handlePaymentChoiseClick = () => {
        setShowWithdrawModal(false); // Close the withdrawal modal
        setShowPaymentChoiseModal(true);
    };

    const handleWithdrawClick = () => {
        setShowWithdrawModal(true);
    };

    const handleSavePaymentChoice = async () => {
        if (tempPaymentMethod === "venmo" && tempVenmoHandle == ""){
            setModalErrorMessage("Venmo handle is required when payment method is set to Venmo.");
            return;
        }
        if (tempPaymentMethod === "paypal" && tempPaypalUsername == ""){
            setModalErrorMessage("PayPal username is required when payment method is set to Paypal.");
            return;
        }
        try {
            const response = await myAxiosInstance.put('/api/update-payment-method/', {
                venmo_handle: tempVenmoHandle,
                paypal_username: tempPaypalUsername,
                payment_method: tempPaymentMethod !== "other" ? tempPaymentMethod : ""
            });
            
            // If successful, reset error message, save temp vars, and close modal
            setSelectedPaymentMethod(tempPaymentMethod);
            setVenmoHandle(tempVenmoHandle);
            setPaypalUsername(tempPaypalUsername);
            setModalErrorMessage(null);
            setShowPaymentChoiseModal(false);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.venmo_handle) {
                // Set specific error message if Venmo handle is invalid
                setModalErrorMessage(error.response.data.venmo_handle[0]);
            } else if (error.response && error.response.data && error.response.data.paypal_username) {
                // Set specific error message if Paypal username is invalid
                setModalErrorMessage(error.response.data.paypal_username[0]);
            } else if (error.response && error.response.data && error.response.data.payment_method && tempPaymentMethod === "plaid"){
                setModalErrorMessage("Click above to connect using Plaid.");
            } else {
                setModalErrorMessage("An error occurred. Please try again.");
            }
        }
    };

    const handleWithdraw = async () => {
        try {
            setIsLoading(true);

            const response = await myAxiosInstance.post('/api/withdraw/')
                        
            // If successful, reset error message and close modal
            setModalErrorMessage(null);
            setShowPaymentChoiseModal(false);
            setShowWithdrawModal(false);
            setReload(reload+1);
        } catch (error) {
            if (error.response && error.response.data && (error.response.data.venmo_handle || error.response.data.paypal_username)) {
                const errorData = error.response.data;
                const firstErrorKey = Object.keys(errorData)[0]; // Get the first key
                setModalErrorMessage(errorData[firstErrorKey][0]); // Get the first error message
            } else {
                setModalErrorMessage("An error occurred. Please try again.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        setModalErrorMessage(null);
        setShowPaymentChoiseModal(false);
        setShowWithdrawModal(false);

        // reset temp variables
        setTempPaymentMethod(selectedPaymentMethod);
        setTempVenmoHandle(venmoHandle);
        setTempPaypalUsername(paypalUsername);
    };

    // Get the data
    useEffect(() => {
        const getPaymentData = async () => {
            try {
                
                // Call the api
                const response = await myAxiosInstance.get('/api/retrieve-finances/');

                // Save the variables
                setFinanceData(response.data);
                setBalance(response.data.balance);
                setSelectedPaymentMethod(response.data.payment_method);
                setTempPaymentMethod(response.data.payment_method);
                setVenmoHandle(response.data.venmo_handle);
                setTempVenmoHandle(response.data.venmo_handle);
                setPaypalUsername(response.data.paypal_username);
                setTempPaypalUsername(response.data.paypal_username);

            } catch (error) {
                showBoundary(error);
            } finally {
                setIsLoading(false);
            }
        };

        getPaymentData();
    }, [reload]);

    if (isLoading) {
        return (
            <div style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <Loading />
            </div>
        );
    }

    return (
        <MainContainer theme={theme} style={{ gap: "1.5rem" }}>

            {/* Balance */}
            <Section>
                <BalanceInfo>
                    <BalanceImage src={financesImage} alt="Prospinity Balance" />
                    <BalanceDetails>
                        <BalanceLabel>Prospinity balance</BalanceLabel>
                        <BalanceAmount>${balance.toLocaleString("en-US", { minimumFractionDigits: 2 })}</BalanceAmount>
                    </BalanceDetails>
                </BalanceInfo>
                <SecondaryButton size="md" style={isMobile ? { flex: "1", minWidth: "200px" } : {}} onClick={handleWithdrawClick}>WITHDRAW</SecondaryButton>
            </Section>

            {/* Payment Method */}
            <Section style={{ flexDirection: "column", alignItems: "flex-start", paddingBottom: "2.625rem" }}>
                <Title>PAYMENT METHOD</Title>
                <ContentWrapper>
                    <Content>
                        <IconWrapper onClick={handlePaymentChoiseClick}>
                            <PaymentMethodIcon />
                        </IconWrapper>
                        
                        <Text style={{ fontWeight: "500" }}>No payment method</Text>
                        <Subtext>Add your payment method to redeem your balance.</Subtext>
                        <PrimaryButton size="md" onClick={handlePaymentChoiseClick}>ADD PAYMENT METHOD</PrimaryButton>
                    </Content>
                </ContentWrapper>
            </Section>

            {/* Transaction History / Invoices */}
            <Section style={{ padding: "0.7rem 0 1.5rem 0" }}>
                {/* Tabs */}
                <Tabs>
                    <Tab 
                        isActive={activeTab === "transactions"} 
                        onClick={() => setActiveTab("transactions")}
                    >
                        TRANSACTIONS HISTORY
                    </Tab>
                    <Tab 
                        isActive={activeTab === "invoices"} 
                        onClick={() => setActiveTab("invoices")}
                    >
                        INVOICES
                    </Tab>
                </Tabs>

                {/* Content */}
                <ContentWrapper style={{ marginTop: "1rem" }}>
                    <Content>
                        <NothingYetIcon />
                        <Text style={{ fontWeight: "500" }}>
                            {activeTab === "transactions" ? "No transactions yet" : "No invoices yet"}
                        </Text>
                        <Subtext>Your future {activeTab} will appear here</Subtext>
                    </Content>
                </ContentWrapper>
            </Section>

            {/* Withdraw Modal */}
            <CenteredModal
                show={showWithdrawModal}
                onClose={handleClose}
                title="WITHDRAW YOUR BALANCE"
                subtitle=""
                buttonsType={balance > 0 ? "two" : "one"}
                primaryText={balance > 0 ? (((selectedPaymentMethod === 'venmo' && venmoHandle !== '') || (selectedPaymentMethod === 'paypal' && paypalUsername !== '')) ? 'WITHDRAW' : 'CONNECT') : 'CANCEL'}
                onPrimaryClick={balance > 0 ? (((selectedPaymentMethod === 'venmo' && venmoHandle !== '') || (selectedPaymentMethod === 'paypal' && paypalUsername !== '')) ? handleWithdraw : handlePaymentChoiseClick) : handleClose}
                secondaryText="CANCEL"
                onSecondaryClick={handleClose}
                alignCenter={false}
                >

                    {balance > 0 ? (
                        (selectedPaymentMethod === 'venmo' && venmoHandle !== '') ? (
                            <>
                                <Text>
                                    You are about to withdraw <span style={{ fontWeight: "500" }}>${balance}</span> to your connected Venmo account.<br />
                                </Text>
                                <Text>
                                    <span>Venmo Handle:</span> @{venmoHandle}
                                </Text>
                            </>
                        ) : (selectedPaymentMethod === 'paypal' && paypalUsername !== '') ? (
                            <>
                                <Text>
                                    You are about to withdraw <span style={{ fontWeight: "500" }}>${balance}</span> to your connected PayPal account.<br />
                                </Text>
                                <Text>
                                    <span>PayPal Username:</span> {paypalUsername}
                                </Text>
                            </>
                        ) : (
                            <>
                                <Text>
                                    You need to connect a payment method before withdrawing your balance.
                                </Text>
                            </>
                        )
                    ) : (
                        <Text>Your balance is 0. You cannot withdraw funds at this time.</Text>
                    )}
            </CenteredModal>

            {/* Payment Choice Modal */}
            <CenteredModal
                show={showPaymentChoiseModal}
                onClose={handleClose}
                title="PAYMENT METHODS"
                subtitle=""
                buttonsType="one"
                primaryText="CANCEL"
                onPrimaryClick={handleClose}
                alignCenter={false}
                >

                <Text>You must be part of a pool to add a payment method.</Text>

            </CenteredModal>
        </MainContainer>
    );
}
           



// Styled Components
const Section = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${({ theme }) => theme.white};;
    padding: 1.5rem;
    border-radius: 1.5rem;
    flex-wrap: wrap; /* Allow items to wrap on small screens */
    gap: 1rem;
`;

const BalanceInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
`;

const BalanceImage = styled.img`
    width: 4rem;
    height: 4rem;
    aspect-ratio: 1;
`;

const BalanceDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const BalanceLabel = styled.span`
    font-size: 1;
    color: ${({ theme }) => theme.textSecondary};
`;

const BalanceAmount = styled.span`
    font-size: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
`;

const Title = styled.div`
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textSecondary};
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    @media (max-width: 600px) {
        margin-top: 1rem;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const Text = styled.span`
    font-size: 1rem;
    color: ${({ theme }) => theme.textPrimary};
`;

const Subtext = styled.span`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.textSecondary};
    margin-bottom: 1rem;
    margin-top: 0.25rem;
`;


const Tabs = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 1px solid ${({ theme }) => theme.divider};
    padding: 0 1.5rem;

    @media (max-width: 600px) {
        padding: 0 1rem;
        gap: 1.5rem;
    } 

`;

const Tab = styled.div`
    font-size: 1rem;
    padding: 1rem 0.75rem;
    color: ${({ isActive, theme }) => (isActive ? theme.textPrimary : theme.textSecondary)};
    font-weight: ${({ isActive }) => (isActive ? 500 : 400)};
    /* border-bottom: ${({ isActive, theme }) => (isActive ? `2px solid ${theme.primary}` : "none")}; */
    cursor: pointer;
    position: relative;

    &:after {
        content: "";
        display: ${({ isActive }) => (isActive ? "block" : "none")};
        height: 2px;
        background: ${({ theme }) => theme.primary};
        width: 100%;
        position: absolute;
        bottom: -1px;
        left: 0;
    }

    @media (max-width: 600px) {
        padding: 1rem 0;
    } 
`;

const IconWrapper = styled.div`
    cursor: pointer; /* 🔥 Makes it clickable */

    &:hover {
        transform: scale(1.05); /* 🔥 Slight shrink when clicked */
    }

    &:active {
        transform: scale(0.95); /* 🔥 Slight shrink when clicked */
    }
`;


// import React, { useState, useEffect } from 'react';
// import { useErrorBoundary } from "react-error-boundary";
// import { useMediaQuery } from 'react-responsive';
// import { InputGroup } from 'react-bootstrap';
// import styled from 'styled-components';

// import { myAxiosInstance } from '../api/axiosConfig';
// import { useAuth } from '../contexts/AuthContext';
// import { useTheme } from 'styled-components';
// import { StyledCard, StyledH5, StyledH4, StyledH3, StyledH2, StyledParagraph, StyledButton, Container, StyledFormGroup, StyledFormLabel, StyledFormSelect, StyledFormControl, StyledFormControlNoFocus} from "../components/common/StyledBootstrap";
// import Loading from '../components/common/Loading';
// import CustomModal from '../components/common/CustomModal';
// import plaidLogo from '../assets/images/plaid_logo.png';


// const PlaidBox = styled.div`
//     display: flex;
//     align-items: center;
//     padding: 1rem;
//     border: 1px solid #e0e0e0; /* Lighter border color */
//     border-radius: 0.5rem;
//     background-color: ${({ theme }) => theme.surface};
//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05); /* Softer shadow */
//     cursor: pointer;
//     transition: box-shadow 0.2s;

//     &:hover {
//     box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow on hover */
//     }

//     img {
//     width: 8%; /* Adjusted size to match the logo in the image */
//     margin-right: 1rem;
//     }

//     .text-content {
//     display: flex;
//     flex-direction: column;

//     .title {
//         font-weight: 500; /* Slightly bolded for title */
//         font-size: 1.075rem;
//         color: #000000; /* Black for a clean look */
//         margin-bottom: 0.25rem;
//     }

//     .subtitle {
//         font-weight: 300; /* Regular weight for subtitle */
//         font-size: 0.875rem;
//         color: #6c757d; /* Subtle gray for subtitle */
//     }
//     }
// `;



// const PlaidConnectBox = () => {
//     const [showBalanceMessage, setShowBalanceMessage] = useState(false);
  
//     const handlePlaidClick = () => {
//       setShowBalanceMessage(true);
//     };
  
//     return (
//       <div>
//         <PlaidBox onClick={handlePlaidClick}>
//           <img src={plaidLogo} alt="Plaid Logo" />
//           <div className="text-content">
//             <div className="title">Connect with Plaid</div>
//             <div className="subtitle">Connect your bank account to Prospinity with Plaid</div>
//           </div>
//         </PlaidBox>
        
//         {/* Display the "Not enough balance" message if the card is clicked */}
//         {showBalanceMessage && (
//           <StyledParagraph style={{ marginTop: '0.6rem' }}>Not enough balance to connect Plaid.</StyledParagraph>
//         )}
//       </div>
//     );
//   };


// function Finances() {

//     // Auth Context
//     const { userSlug } = useAuth();

//     // Backend Variables
//     const [balance, setBalance] = useState(null)
//     const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
//     const [venmoHandle, setVenmoHandle] = useState('')
//     const [paypalUsername, setPaypalUsername] = useState('')
//     const [transactionsArray, setTransactionsArray] = useState([]);

//     // Temporary State for Modal
//     const [tempPaymentMethod, setTempPaymentMethod] = useState('');
//     const [tempVenmoHandle, setTempVenmoHandle] = useState('');
//     const [tempPaypalUsername, setTempPaypalUsername] = useState('');

//     // Modals
//     const [showInvoiceModal, setShowInvoiceModal] = useState(false); // To show or hide the invoices modal
//     const [showPaymentChoiseModal, setShowPaymentChoiseModal] = useState(false); // To show or hide the payment choice modal
//     const [modalErrorMessage, setModalErrorMessage] = useState(null);
//     const [showWithdrawModal, setShowWithdrawModal] = useState(false); // To show or hide the tutorial modal

//     // Loading
//     const [reload, setReload] = useState(1);
//     const [isBottomLoading, setIsBottomLoading] = useState(true);
//     const { showBoundary } = useErrorBoundary();

//     // Theme
//     const themeMode = useTheme();

//     // Screen size 
//     const isMdOrLarger = useMediaQuery({ minWidth: 768 });


//     // Button Clicks
//     const handleInvoiceClick = () => {
//         setShowInvoiceModal(true);
//     };

//     const handlePaymentChoiseClick = () => {
//         setShowWithdrawModal(false); // Close the withdrawal modal
//         setShowPaymentChoiseModal(true);
//     };

//     const handleWithdrawClick = () => {
//         setShowWithdrawModal(true);
//     };

//     const handleSavePaymentChoice = async () => {
//         if (tempPaymentMethod === "venmo" && tempVenmoHandle == ""){
//             setModalErrorMessage("Venmo handle is required when payment method is set to Venmo.");
//             return;
//         }
//         if (tempPaymentMethod === "paypal" && tempPaypalUsername == ""){
//             setModalErrorMessage("PayPal username is required when payment method is set to Paypal.");
//             return;
//         }
//         try {
//             const response = await myAxiosInstance.put('/api/update-payment-method/', {
//                 venmo_handle: tempVenmoHandle,
//                 paypal_username: tempPaypalUsername,
//                 payment_method: tempPaymentMethod !== "other" ? tempPaymentMethod : ""
//             });
            
//             // If successful, reset error message, save temp vars, and close modal
//             setSelectedPaymentMethod(tempPaymentMethod);
//             setVenmoHandle(tempVenmoHandle);
//             setPaypalUsername(tempPaypalUsername);
//             setModalErrorMessage(null);
//             setShowPaymentChoiseModal(false);
//         } catch (error) {
//             if (error.response && error.response.data && error.response.data.venmo_handle) {
//                 // Set specific error message if Venmo handle is invalid
//                 setModalErrorMessage(error.response.data.venmo_handle[0]);
//             } else if (error.response && error.response.data && error.response.data.paypal_username) {
//                 // Set specific error message if Paypal username is invalid
//                 setModalErrorMessage(error.response.data.paypal_username[0]);
//             } else if (error.response && error.response.data && error.response.data.payment_method && tempPaymentMethod === "plaid"){
//                 setModalErrorMessage("Click above to connect using Plaid.");
//             } else {
//                 setModalErrorMessage("An error occurred. Please try again.");
//             }
//         }
//     };

//     const handleWithdraw = async () => {
//         try {
//             const response = await myAxiosInstance.post('/api/withdraw/')
                        
//             // If successful, reset error message and close modal
//             setModalErrorMessage(null);
//             setShowPaymentChoiseModal(false);
//             setShowWithdrawModal(false);
//             setReload(reload+1);
//         } catch (error) {
//             if (error.response && error.response.data && (error.response.data.venmo_handle || error.response.data.paypal_username)) {
//                 const errorData = error.response.data;
//                 const firstErrorKey = Object.keys(errorData)[0]; // Get the first key
//                 setModalErrorMessage(errorData[firstErrorKey][0]); // Get the first error message
//             } else {
//                 setModalErrorMessage("An error occurred. Please try again.");
//             }
//         }
//     };

//     const handleClose = () => {
//         setModalErrorMessage(null);
//         setShowInvoiceModal(false);
//         setShowPaymentChoiseModal(false);
//         setShowWithdrawModal(false);

//         // reset temp variables
//         setTempPaymentMethod(selectedPaymentMethod);
//         setTempVenmoHandle(venmoHandle);
//         setTempPaypalUsername(paypalUsername);
//     };
    
    
//     // Get the data
//     useEffect(() => {
//         const getPaymentData = async () => {
//             try {

//                 // Set up loading
//                 setIsBottomLoading(true);
//                 setShowInvoiceModal(false);
//                 setShowPaymentChoiseModal(false);
                
//                 // Call the api
//                 const response = await myAxiosInstance.get('/api/retrieve-finances/');

//                 // Save the variables
//                 const data = response.data;
//                 setBalance(data.balance);

//                 setSelectedPaymentMethod(data.payment_method);
//                 setTempPaymentMethod(data.payment_method);

//                 setVenmoHandle(data.venmo_handle);
//                 setTempVenmoHandle(data.venmo_handle);

//                 setPaypalUsername(data.paypal_username);
//                 setTempPaypalUsername(data.paypal_username);

//                 setTransactionsArray(data.transactions);

//             } catch (error) {
//                 showBoundary(error);
//             } finally {
//                 setIsBottomLoading(false);
//             }
//         };

//         getPaymentData();
//     }, [reload]);

    
//     // Return the page
//     return (
//         isBottomLoading ?
//             <Loading />
//             :
//             <>
//                 <div className='d-flex justify-content-center' style={{ backgroundColor: themeMode.background, minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>
//                     <div className='home-joyrride mx-2' style={{ minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>

//                         {/* Header */}
//                         <div className='d-flex w-100 justify-content-between mt-3'>
//                             <div>
//                                 <StyledH5><strong>{isMdOrLarger ? "Prospinity Balance" : "Balance"}</strong></StyledH5>
//                             </div>
//                             <div>
//                                 <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.3rem'}}>
//                                     <div style={{
//                                         width: '0.5rem',
//                                         height: '0.5rem',
//                                         backgroundColor: ((selectedPaymentMethod === 'venmo' && venmoHandle !== '') || (selectedPaymentMethod === 'paypal' && paypalUsername !== '')) ? 'green' : 'orange',
//                                         borderRadius: '50%',
//                                         marginRight: '0.2rem',
//                                     }}
//                                     />
//                                     <div style={{
//                                         overflow: 'hidden',
//                                         textOverflow: 'ellipsis',
//                                         whiteSpace: 'nowrap',
//                                         minWidth: 0,
//                                         fontSize: '0.8rem',
//                                         marginRight: '0.8rem',
//                                     }}>
//                                         <span style={{ fontStyle: 'italic' }}>
//                                             {(selectedPaymentMethod === 'venmo' && venmoHandle !== '') ? 'Venmo connected' 
//                                             : (selectedPaymentMethod === 'paypal' && paypalUsername !== '') ? 'PayPal connected' 
//                                             : 'No payment method'}
//                                         </span>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         {/* Balance card */}
//                         <StyledCard>
//                             <StyledCard.Body>
//                                 <div className='d-flex w-100 justify-content-between align-items-center mt-2 mb-2' style={{ height: '100%' }}>
//                                     <div className='d-flex align-items-center ml-3' style={{ height: '100%' }}>  
//                                         <StyledH3 style={{ margin: 0, fontSize: isMdOrLarger ? '2rem' : '1.5rem', }}>
//                                             ${balance}
//                                         </StyledH3>            
//                                     </div>

//                                     {/* Container for the Withdraw button */}
//                                     <div className='d-flex align-items-center mr-3' style={{ height: '100%' }}>
//                                         <StyledButton onClick={handleWithdrawClick} className={isMdOrLarger ? 'px-5' : 'px-4'} style={{
//                                             borderRadius: '2rem',
//                                             fontSize: isMdOrLarger ? '1rem' : '0.8rem', 
//                                             padding: isMdOrLarger ? '0.5rem 1rem' : '0.5rem 1rem',
//                                             minWidth: isMdOrLarger ? 'auto' : '100%',
//                                             marginLeft: '1rem'}}>
//                                             Withdraw
//                                         </StyledButton>
//                                     </div>
//                                 </div>
//                             </StyledCard.Body>
//                         </StyledCard>
                        
//                         {/* Invoice / Payment Method buttons */}
//                         <div className='d-flex w-100 justify-content-start mt-3'>
//                             <StyledButton 
//                                 onClick={handleInvoiceClick}
//                                 className={isMdOrLarger ? 'px-5 mr-4' : 'px-4 mr-4'} 
//                                 style={{ 
//                                     flexBasis: '30%', // Each button takes 30% of the container width
//                                     flexGrow: isMdOrLarger ? 0 : 1, // Prevents them from growing to occupy the full width
//                                     fontSize: isMdOrLarger ? '1rem' : '0.8rem',
//                                     // padding: `0.38rem ${isMdOrLarger ? '5%' : '0%'}`, 
//                                     borderRadius: '2rem', 
//                                     backgroundColor: themeMode.background, 
//                                     color: themeMode.onBackground, 
//                                     borderWidth: '0.11rem', 
//                                     borderStyle: 'solid',
//                                     minWidth: '1%',
//                                 }}
//                             >
//                                 Invoices
//                             </StyledButton>
//                             <StyledButton 
//                                 onClick={handlePaymentChoiseClick} 
//                                 className={isMdOrLarger ? 'px-5' : 'px-4'}
//                                 style={{ 
//                                     flexBasis: '30%', // Each button takes 30% of the container width
//                                     flexGrow: isMdOrLarger ? 0 : 1, // Prevents them from growing to occupy the full width
//                                     fontSize: isMdOrLarger ? '1rem' : '0.8rem',
//                                     //padding: `0.38rem ${isMdOrLarger ? '5%' : '0%'}`, 
//                                     borderRadius: '2rem', 
//                                     backgroundColor: themeMode.background, 
//                                     color: themeMode.onBackground, 
//                                     borderWidth: '0.11rem', 
//                                     borderStyle: 'solid',
//                                     minWidth: '1%',
//                                 }}
//                             >
//                                 Payment Methods
//                             </StyledButton>
//                         </div>


//                         {/* Payment schedule */}

//                         {/* Header */}
//                         <div className='d-flex w-100 justify-content-between mt-4'>
//                             <div>
//                                 <StyledH5><strong>Payment Schedule</strong></StyledH5>
//                             </div>
//                         </div>

//                         {/* Balance card */}
//                         <StyledCard>
//                             <StyledCard.Body>
//                                 <div className='d-flex w-100 justify-content-between align-items-center mt-2 mb-2' style={{ height: '100%' }}>
//                                     <div className='d-flex align-items-center ml-3' style={{ height: '100%' }}>  
//                                         <StyledH5 style={{ margin: 0}}>
//                                             No Payments Due
//                                         </StyledH5>            
//                                     </div>
//                                 </div>
//                             </StyledCard.Body>
//                         </StyledCard>


//                         {/* Transaction History */}

//                         {/* Header */}
//                         <div className='d-flex w-100 justify-content-between mt-4'>
//                             <div>
//                                 <StyledH5><strong>Transaction History</strong></StyledH5>
//                             </div>
//                         </div>

//                         {/* Balance card */}
//                         {transactionsArray.length == 0 ? 
//                             <>
//                                 <StyledCard>
//                                     <StyledCard.Body>
//                                         <div className='d-flex w-100 justify-content-between align-items-center mt-2 mb-2' style={{ height: '100%' }}>
//                                             <div className='d-flex align-items-center ml-3' style={{ height: '100%' }}>  
//                                                 <StyledH5 style={{ margin: 0}}>
//                                                     No Transactions Yet
//                                                 </StyledH5>            
//                                             </div>
//                                         </div>
//                                     </StyledCard.Body>
//                                 </StyledCard>
//                             </> 
//                             :
//                             <>
//                                 {transactionsArray.map((transaction) => (
//                                     <StyledCard className='mb-2'>
//                                         <StyledCard.Body>
//                                             <div className='d-flex justify-content-between align-items-center'>
//                                                 {/* Left Section: Date and Title */}
//                                                 <div className='d-flex flex-column'>
//                                                     {/* Date */}
//                                                     <small style={{ color: 'inherit', marginTop: '-0.5rem' }}>
//                                                         {new Date(transaction.created_at).toLocaleDateString('en-US', {
//                                                             year: 'numeric',
//                                                             month: 'short',
//                                                             day: 'numeric',
//                                                             hour: '2-digit',
//                                                             minute: '2-digit'
//                                                         })}
//                                                     </small>
//                                                     {/* Title */}
//                                                     <StyledH5 style={{ margin: '0.25rem 0 0' }}>
//                                                         {transaction.title}
//                                                     </StyledH5>
//                                                 </div>

//                                                 {/* Right Section: Amount and Status */}
//                                                 <div className='d-flex flex-column align-items-end'>
//                                                     {/* Amount */}
//                                                     <StyledH5 
//                                                         style={{ 
//                                                             margin: 0, 
//                                                             color: transaction.status === 'pending' ? '#adb5bd' : 'inherit' 
//                                                         }}
//                                                     >
//                                                         {parseFloat(transaction.amount) >= 0 ? `${transaction.amount}` : transaction.amount}
//                                                     </StyledH5>
                                                    
//                                                     {/* Status */}
//                                                     {transaction.status === 'pending' && (
//                                                         <small style={{
//                                                             color: '#adb5bd', // Light gray for "Pending" status
//                                                             fontWeight: 'bold',
//                                                             textTransform: 'capitalize',
//                                                         }}>
//                                                             Pending
//                                                         </small>
//                                                     )}
//                                                 </div>
//                                             </div>
//                                         </StyledCard.Body>
//                                     </StyledCard>
//                                 ))}
//                             </>
//                         }
                        
//                     </div>
//                 </div>

//                 {/* Withdraw */}
//                 <CustomModal
//                     show={showWithdrawModal}
//                     setShow={setShowWithdrawModal}
//                     title={"Withdraw Your Balance"}
//                     message={""}
//                     actionText={balance > 0 ? (((selectedPaymentMethod === 'venmo' && venmoHandle !== '') || (selectedPaymentMethod === 'paypal' && paypalUsername !== '')) ? 'Confirm and Withdraw' : 'Connect Payment Method') : ''}
//                     actionFunction={balance > 0 ? (((selectedPaymentMethod === 'venmo' && venmoHandle !== '') || (selectedPaymentMethod === 'paypal' && paypalUsername !== '')) ? handleWithdraw : handlePaymentChoiseClick) : null}
//                     handleClose={handleClose}
//                     showActionButton={balance > 0} // Only show the action button if balance is greater than 0
//                     showCloseButton={balance===0}
//                 >
//                     {balance > 0 ? (
//                         (selectedPaymentMethod === 'venmo' && venmoHandle !== '') ? (
//                             <>
//                                 <p>
//                                     You are about to withdraw <strong>${balance}</strong> to your connected Venmo account.
//                                 </p>
//                                 <p>
//                                     <strong>Venmo Handle:</strong> @{venmoHandle}
//                                 </p>
//                             </>
//                         ) : (selectedPaymentMethod === 'paypal' && paypalUsername !== '') ? (
//                             <>
//                                 <p>
//                                     You are about to withdraw <strong>${balance}</strong> to your connected PayPal account.
//                                 </p>
//                                 <p>
//                                     <strong>PayPal Username:</strong> {paypalUsername}
//                                 </p>
//                             </>
//                         ) : (
//                             <>
//                                 <p>
//                                     You need to connect a payment method before withdrawing your balance.
//                                 </p>
//                             </>
//                         )
//                     ) : (
//                         <p>Your balance is 0. You cannot withdraw funds at this time.</p>
//                     )}
//                 </CustomModal>

//                 {/* Invoices */}
//                 <CustomModal show={showInvoiceModal}
//                     setShow={setShowInvoiceModal}
//                     title={"Invoices"}
//                     message={""}
//                     setMessage={setShowInvoiceModal}
//                     actionText=''
//                     actionFunction={handleClose}
//                     handleClose={handleClose}
//                     showActionButton={false}>
//                         No invoices yet.
//                 </CustomModal>

//                 {/* Payment Options */}
//                 <CustomModal
//                     show={showPaymentChoiseModal}
//                     setShow={setShowPaymentChoiseModal}
//                     title={"Payment Options"}
//                     message={""}
//                     setMessage={setShowPaymentChoiseModal}
//                     actionText="Save"
//                     actionFunction={handleSavePaymentChoice}
//                     handleClose={handleClose}
//                     showCloseButton={false}
//                 >
//                     <StyledFormGroup>
//                         <StyledFormLabel>Payment Method</StyledFormLabel>
//                         <StyledFormSelect
//                             value={tempPaymentMethod} // Use temporary state here
//                             onChange={(e) => {
//                                 setTempPaymentMethod(e.target.value); // Update temp state
//                                 setModalErrorMessage(null); // Clear the error message
//                             }}
//                         >
//                             <option value="">Select a payment method</option>
//                             <option value="venmo">Venmo</option>
//                             <option value="paypal">PayPal</option>
//                             <option value="plaid">Plaid</option>
//                             <option value="other">Other</option>
//                         </StyledFormSelect>
//                     </StyledFormGroup>

//                     {/* Conditionally render the Venmo handle input if Venmo is selected in temp state */}
//                     {tempPaymentMethod === 'venmo' && (
//                         <StyledFormGroup className="mt-3">
//                             <StyledFormLabel>Venmo Handle</StyledFormLabel>
//                             <InputGroup>
//                                 <InputGroup.Text>@</InputGroup.Text>
//                                 <StyledFormControlNoFocus
//                                     style={{ borderTopRightRadius: "0.5rem", borderBottomRightRadius: "0.5rem" }}
//                                     type="text"
//                                     value={tempVenmoHandle} // Use temporary state here
//                                     onChange={(e) => setTempVenmoHandle(e.target.value)} // Update temp handle
//                                     placeholder="Enter your Venmo handle"
//                                 />
//                             </InputGroup>
//                         </StyledFormGroup>
//                     )}

//                     {tempPaymentMethod === 'paypal' && (
//                         <StyledFormGroup className="mt-3">
//                             <StyledFormLabel>PayPal Username</StyledFormLabel>
//                             <InputGroup>
//                                 <InputGroup.Text>@</InputGroup.Text>
//                                 <StyledFormControlNoFocus
//                                     style={{ borderTopRightRadius: "0.5rem", borderBottomRightRadius: "0.5rem" }}
//                                     type="text"
//                                     value={tempPaypalUsername} // Use temporary state here
//                                     onChange={(e) => setTempPaypalUsername(e.target.value)} // Update temp handle
//                                     placeholder="Enter your PayPal username"
//                                 />
//                             </InputGroup>
//                         </StyledFormGroup>
//                     )}

//                     {/* Conditionally render a message if Plaid is selected in temp state */}
//                     {tempPaymentMethod === 'plaid' && <PlaidConnectBox />}

//                     {/* {Other -> email support} */}
//                     {tempPaymentMethod === 'other'&& (
//                         <div style={{ marginTop: '1rem' }}>If you don't have any of these payment methods, please contact our support team at support@prospinity.com for assistance.</div>
//                     )}

//                     {/* Display error message if present */}
//                     {modalErrorMessage && (
//                         <div style={{ color: 'red', marginTop: '1rem' }}>{modalErrorMessage}</div>
//                     )}
//                 </CustomModal>

//             </>
//     );
    
// }

// export default Finances;




//                 {/* Payment Options */}
//                 <CustomModal
//                     show={showPaymentChoiseModal}
//                     setShow={setShowPaymentChoiseModal}
//                     title={"Payment Options"}
//                     message={""}
//                     setMessage={setShowPaymentChoiseModal}
//                     actionText="Save"
//                     actionFunction={handleSavePaymentChoice}
//                     handleClose={handleClose}
//                     showCloseButton={false}
//                 >
//                     <StyledFormGroup>
//                         <StyledFormLabel>Payment Method</StyledFormLabel>
//                         <StyledFormSelect
//                             value={tempPaymentMethod} // Use temporary state here
//                             onChange={(e) => {
//                                 setTempPaymentMethod(e.target.value); // Update temp state
//                                 setModalErrorMessage(null); // Clear the error message
//                             }}
//                         >
//                             <option value="">Select a payment method</option>
//                             <option value="venmo">Venmo</option>
//                             <option value="paypal">PayPal</option>
//                             <option value="plaid">Plaid</option>
//                             <option value="other">Other</option>
//                         </StyledFormSelect>
//                     </StyledFormGroup>

//                     {/* Conditionally render the Venmo handle input if Venmo is selected in temp state */}
//                     {tempPaymentMethod === 'venmo' && (
//                         <StyledFormGroup className="mt-3">
//                             <StyledFormLabel>Venmo Handle</StyledFormLabel>
//                             <InputGroup>
//                                 <InputGroup.Text>@</InputGroup.Text>
//                                 <StyledFormControlNoFocus
//                                     style={{ borderTopRightRadius: "0.5rem", borderBottomRightRadius: "0.5rem" }}
//                                     type="text"
//                                     value={tempVenmoHandle} // Use temporary state here
//                                     onChange={(e) => setTempVenmoHandle(e.target.value)} // Update temp handle
//                                     placeholder="Enter your Venmo handle"
//                                 />
//                             </InputGroup>
//                         </StyledFormGroup>
//                     )}

//                     {tempPaymentMethod === 'paypal' && (
//                         <StyledFormGroup className="mt-3">
//                             <StyledFormLabel>PayPal Username</StyledFormLabel>
//                             <InputGroup>
//                                 <InputGroup.Text>@</InputGroup.Text>
//                                 <StyledFormControlNoFocus
//                                     style={{ borderTopRightRadius: "0.5rem", borderBottomRightRadius: "0.5rem" }}
//                                     type="text"
//                                     value={tempPaypalUsername} // Use temporary state here
//                                     onChange={(e) => setTempPaypalUsername(e.target.value)} // Update temp handle
//                                     placeholder="Enter your PayPal username"
//                                 />
//                             </InputGroup>
//                         </StyledFormGroup>
//                     )}

//                     {/* Conditionally render a message if Plaid is selected in temp state */}
//                     {tempPaymentMethod === 'plaid' && <PlaidConnectBox />}

//                     {/* {Other -> email support} */}
//                     {tempPaymentMethod === 'other'&& (
//                         <div style={{ marginTop: '1rem' }}>If you don't have any of these payment methods, please contact our support team at support@prospinity.com for assistance.</div>
//                     )}

//                     {/* Display error message if present */}
//                     {modalErrorMessage && (
//                         <div style={{ color: 'red', marginTop: '1rem' }}>{modalErrorMessage}</div>
//                     )}
//                 </CustomModal>