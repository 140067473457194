import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';


// Main Pages
import Home from './pages/Home';
import Pools from './pages/Pools';
import PortfolioPage from './pages/PortfolioPage';
import Finances from './pages/Finances.js';
import FAQs from './pages/FAQs';
import UserProfile from './pages/UserProfile';
import SearchPage from './pages/SearchPage.js';
import MeetingPage from './pages/MeetingPage';
import PoolPage from './pages/PoolPage';
import Settings from './pages/Settings';
import NotificationsSmall from './pages/MobileNotifications.js';
import ChatPage from './pages/ChatPage';
import SigningCongratulations from './pages/SigningCongratulations';
import NotFoundPage from './pages/NotFoundPage';

// Regiatration Pages
import SignIn from './pages/registration/SignIn';
import SignUp from './pages/registration/SignUp.js';
import PasswordReset from './pages/registration/PasswordReset.js';
import PasswordResetEmail from './pages/registration/PasswordResetEmail.js';

import FinishName from './pages/registration/FinishName.js';
import FinishUniversity from './pages/registration/FinishUniversity.js';
import FinishCompany from './pages/registration/FinishCompany.js';
import FinishPhone from './pages/registration/FinishPhone.js';
import FinishCustomize from './pages/registration/FinishCustomize.js';
import FinishVerifyEmail from './pages/registration/FinishVerifyEmail.js';

import EmailVerification from './pages/registration/EmailVerification.js';
import GetStarted from './pages/registration/GetStarted.js';

// Legal Pages
import TermsofUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy'
import MemberCodeOfConduct from './pages/MemberCodeofConduct';
import ConfidentialityAndIPAcknowledgment from './pages/ConfidentialityAndIPAcknowledgment';

// Survey + Application + CareerAi
import SurveyPage from './components/survey/SurveyPage.js';
import Application from './components/application/Application.js';
import ApplicationRedirect from './components/application/ApplicationRedirect';
import BerkeleyRedirect from './routes/Redirect.js';
import ScreenOne from './components/application/ScreenOne.js';
import ScreenTwo from './components/application/ScreenTwo.js';
import ScreenThree from './components/application/SectionThree.js';
import ScreenReadOne from './components/application/ReadScreenOne.js';
import ScreenReadTwo from './components/application/ReadScreenTwo.js';
import ScreenReadThree from './components/application/ReadScreenThree.js';
import ScreenReadFour from './components/application/ReadScreenFour.js';
import ScreenEight from './components/application/ScreenEight.js';
import ScreenNine from './components/application/ScreenNine.js';
import VideoScreen from './components/application/VideoScreen.js';
import ThankYouScreen from './components/application/ThankYouScreen.js';
import CareerTool from './components/careertool/CareerTool.js';
import InviteEnvelopeWrapper from './components/invitations/InviteEnvelopeWrapper.js';

// Layouts
import Dashboard from './layouts/Dashboard';
import RegistrationLayout from './layouts/RegistrationLayout.js';
import ApplicationLayout from './layouts/ApplicationLayout.js';

// Other
import { MainLoading, UnderMaintenance } from './components/common/Loading';
import { PrimaryButton, SecondaryButton } from './components/common/Buttons.js';
import { ProtectedRoute } from './routes/ProtectedRoute.js';

// Contexts
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { NotificationsProvider } from './contexts/NotificationsContext.js';
import { ApplicationProvider } from './contexts/ApplicationContext.js';

// Other
import { ErrorBoundary } from 'react-error-boundary';
import { useTheme } from 'styled-components';
import { useTracking } from 'react-tracking';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'; // optional but helpful
import { Mixpanel } from './Mixpanel';
import GlobalStyle from './styles/GlobalStyle';

import './api/axiosConfig';


function ErrorFallback({ error, resetErrorBoundary }) {
    const theme = useTheme();

    const errorLogout = () => {

        localStorage.removeItem('userToken');
        window.location.href = '/signin';

    }
    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center text-center vh-100 w-100"
            style={{ backgroundColor: theme.backgroundDarker, padding: "2rem" }}
        >
            <div style={{ maxWidth: "400px" }}>
                <h2 style={{ color: theme.textPrimary, fontSize: "1.75rem", fontWeight: "500", marginBottom: "0.5rem" }}>
                    Something Went Wrong
                </h2>
                <p style={{ color: theme.textSecondary, fontSize: "1rem", lineHeight: "1.5", marginBottom: "1.5rem" }}>
                    We encountered an unexpected issue. Please return to the home page or log out and sign back in.
                </p>
            </div>

            <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", justifyContent: "center" }}>
                <PrimaryButton size="lg" onClick={resetErrorBoundary}>RETURN HOME</PrimaryButton>
                <SecondaryButton size="lg" onClick={errorLogout}>LOGOUT</SecondaryButton>
            </div>
        </div>
    );
}


const queryClient = new QueryClient();


function App() {

    
    const dispatchTrackingEvent = data => {
        const { event, ...dataWithoutEvent } = data;
        Mixpanel.track(event, dataWithoutEvent);
        /*myAxiosInstance.post('/api/track-frontend/', {"email": "", "content": JSON.stringify(data)})
          .then(response => {
          })
          .catch(error => {
          });*/
      };

    const { Track } = useTracking(
        {},
        { dispatch: data => dispatchTrackingEvent(data) }
      );


    return (
        <Track>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider>
                    <GlobalStyle />
                    <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={() => { window.location.href = '/dashboard'; }}
                    >
                        <AuthProvider>
                            <NotificationsProvider>
                                <RouterWithRoutes />
                                <ReactQueryDevtools initialIsOpen={false} />
                            </NotificationsProvider>
                        </AuthProvider>
                    </ErrorBoundary>
                </ThemeProvider>
            </QueryClientProvider>
        </Track>
    );
}


function RouterWithRoutes() {
    const { isLoading } = useAuth();

    if (isLoading) {
        return <MainLoading />;
    }

    return (
        <Router>
            <Routes>
                {/* Maintainance */}
                {/* <Route path="/finish-registration" element={<UnderMaintenance />} />
                <Route path="/dashboard" element={<UnderMaintenance />} /> */}

                {/* Dashboard Redirects */}
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/home" element={<Navigate to="/dashboard" />} />

                {/* Registration Redirects */}
                <Route path="/join" element={<Navigate to="/join/signup" />} />
                <Route path="/signup" element={<Navigate to="/join/signup" replace />} />
                <Route path="/signin" element={<Navigate to="/join/signin" replace />} />
                <Route path="/password-reset-email" element={<Navigate to="/join/password-reset-email" replace />} />
                <Route path="/password-reset/confirm/:slug/:verificationKey" element={<Navigate to="/join/password-reset/confirm/:slug/:verificationKey" replace />} />
                <Route path="/finish-registration" element={<Navigate to="/finish-registration/name" />} />

                {/* Signing out */}
                <Route path="/signing-out" element={<MainLoading />} />

                {/* Registration Routes */}
                <Route path="/join" element={<RegistrationLayout />}>
                    <Route path="signin" element={<ProtectedRoute requiredStage={0} signin={true}><SignIn /></ProtectedRoute>} />
                    <Route path="signup" element={<ProtectedRoute requiredStage={0} signin={true}><SignUp /></ProtectedRoute>} />
                    <Route path="password-reset-email" element={<ProtectedRoute requiredStage={0} signin={true}><PasswordResetEmail /></ProtectedRoute>} />
                    <Route path="password-reset/confirm/:slug/:verificationKey" element={<ProtectedRoute requiredStage={0} signin={true}><PasswordReset /></ProtectedRoute>} />
                </Route>

                {/* Verify Email Redirect */}
                <Route path="/verify/:verificationKey" element={<EmailVerification />} />
            
                {/* Finish Registration Routes */}
                <Route path="/finish-registration" element={
                    <ProtectedRoute requiredStage={1}>
                        <RegistrationLayout />
                    </ProtectedRoute>
                }>
                    <Route path="name" element={<FinishName />} />
                    <Route path="university" element={<FinishUniversity />} />
                    <Route path="company" element={<FinishCompany />} />
                    <Route path="phone" element={<FinishPhone />} />
                    <Route path="customize" element={<FinishCustomize />} />
                    <Route path="verify-email" element={<FinishVerifyEmail />} /> 
                </Route>

                {/* Get Started Page */}
                <Route path="/finish-registration" element={
                    <ProtectedRoute requiredStage={3} > 
                        <RegistrationLayout /> 
                    </ProtectedRoute>
                }>
                    <Route path="get-started" element={<GetStarted />} />
                </Route>

                {/* Test */}
                <Route path="/dashboard" element={
                    <Dashboard />
                }>
                    <Route path="test" element={<Home />} />
                </Route>
        
                {/* Dashboard Routes */}
                <Route path="/dashboard" element={
                    <ProtectedRoute requiredStage={3}>
                        <Dashboard />
                    </ProtectedRoute>
                }>
                    <Route index element={<Home/>} />
                    <Route path="home" element={<Home />} />
                    <Route path="finances" element={<Finances />} />
                    <Route path="user/:slug" element={<UserProfile />} />
                    <Route path="meeting/:slug" element={<MeetingPage />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="faqs" element={<FAQs />} />
                    <Route path="portfolio" element={<PortfolioPage />} />
                    <Route path="search" element={<SearchPage />} />
                    <Route path="pools" element={<Pools />} />
                    <Route path="pools/:slug" element={<PoolPage />} />
                    <Route path="inbox" element={<ChatPage />} />
                    <Route path="notifications-page" element={<NotificationsSmall />} />
                    <Route path="member-code-of-conduct" element={<MemberCodeOfConduct />} />                    
                </Route>
                
                {/* Terms, privacy ... routes */}
                <Route path="/termsofuse" element={<TermsofUse />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/signed-success-pool" element={<SigningCongratulations />} />
                <Route path="/agreement-confidentiality" element={<ConfidentialityAndIPAcknowledgment />} />
                <Route path="*" element={<NotFoundPage />} />
                

                {/* Application, Survey, Banners, Career Tool */}
                <Route path="/partnerships/:school/:club_name" element={<SurveyPage />} />
                {/* <Route path="/berkeley/application" element={<Application />} /> */}
                <Route path="/ref/:referal_code" element={<ApplicationRedirect />} />
                <Route path="/banner" element={<BerkeleyRedirect />} />

                <Route path="/berkeley/application" element={
                    <ApplicationProvider> 
                        <ApplicationLayout />
                    </ApplicationProvider>
                }>
                    <Route index element={<ScreenOne />} />
                    <Route path="1" element={<ScreenOne />} />
                    <Route path="2" element={<ScreenTwo />} />
                    <Route path="3" element={<ScreenThree />} />
                    <Route path="4" element={<ScreenReadOne />} />
                    <Route path="5" element={<ScreenReadTwo />} />
                    <Route path="6" element={<ScreenReadThree />} />
                    <Route path="7" element={<ScreenReadFour />} />
                    <Route path="8" element={<ScreenEight />} />
                    <Route path="9" element={<ScreenNine />} />
                    <Route path="video" element={<VideoScreen />} />
                    <Route path="thank-you" element={<ThankYouScreen />} />
                    <Route path="start" element={<Application />} />
                </Route>


                <Route path="/career-ai" element={<CareerTool />} />

                <Route path="/invite/:name/:date" element={<InviteEnvelopeWrapper />} />

                <Route path="/berkeley/application" element={<Application />} />
                <Route path="/ref/:referal_code" element={<ApplicationRedirect />} />
                <Route path="/banner" element={<BerkeleyRedirect />} />

            </Routes>
        </Router>
        
    );
}


export default App;


