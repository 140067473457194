import React, { useState, useRef, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';

import { SecondaryButton, PrimaryButton } from '../common/Buttons';
import PoolTimeLine from './PoolTimeLine';
import PoolTerms from './PoolTerms';
import SigningInterface from './SigningInterface';
import { myAxiosInstance } from '../../api/axiosConfig';

import { ArrowDown } from '../../assets/icons/Icons';
import { ReactComponent as ProspinityLogo } from '../../assets/icons/logo.svg';
import { ReactComponent as ProfileCheck } from '../../assets/icons/profileCheck.svg';
import { ReactComponent as ThreeDots } from "../../assets/icons/threeDots.svg";
import { ReactComponent as InviteIcon } from "../../assets/icons/Invite.svg";
import { ReactComponent as DownloadIcon } from '../../assets/icons/downloadIcon.svg';


const PoolHeader = ({ pool }) => {
    const theme = useTheme();

    // dropdown menue
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    // Signing modal
    const [signingModal, setSigningModal] = useState(false);

    const handleDownloadAgreement = async () => {
        try {
            const response = await myAxiosInstance.get(`/api/download-document/${pool.public_details.slug}/`, {
                responseType: 'blob', // critical to handle binary PDF
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${pool.public_details.name}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download error");
        }
    };
      
      

    const renderButtonGroup = () => {
        const status = pool.user_info.status;
        if (status === "member" || status === "accepted_signed") {
            return (
                <>
                    <SecondaryButton 
                        style={{ color: theme.textSecondary, borderColor: theme.textSecondary, pointerEvents: "none", opacity: 0.7 }}
                    >
                        <ProfileCheck width="19.32" height="14.28" strokeWidth={4} />
                        JOINED
                    </SecondaryButton>

                    <DropdownWrapper>
                        <ThreeDotsButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setMenuOpen(!menuOpen);
                            }}
                            ref={buttonRef}
                        >
                            <ThreeDots fill={theme.textSecondary} />
                        </ThreeDotsButton>

                        {menuOpen && (
                            <PortfolioDropdownContainer theme={theme} ref={menuRef}>
                                <PortfolioDropdownOption>
                                    <InviteIcon width={21} style={{ marginTop: "0.1rem" }} /> 
                                    INVITE MEMBER
                                </PortfolioDropdownOption>
                                <PortfolioDropdownOption onClick={handleDownloadAgreement}>
                                    <DownloadIcon fill={theme.textPrimary} width={21} /> 
                                    DOWNLOAD AGREEMENT
                                </PortfolioDropdownOption>
                            </PortfolioDropdownContainer>
                        )}
                    </DropdownWrapper>
          
                </>
            );
        }
    
        if (status === "accepted_not_signed") {
            return (
                <PrimaryButton onClick={() => setSigningModal(true)}>
                    REVIEW AND JOIN
                </PrimaryButton>
            );
        }
    
        if (status === "not_associated") {
            return (
                <PrimaryButton>
                    REQUEST TO JOIN
                </PrimaryButton>
            );
        }
    
        return null;
    };


    // Close dropdown when clicking outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setMenuOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);


    return (
        <HeaderWrapper>
            {/* Pool logo + title */}
            <TitleSection>
                {/* Left side: Logo + name + captain */}
                <LeftSection>
                    <LogoCircle src="https://images.prospinity.com/favicons/transparent-white-logo.png" alt="Pool Logo" />

                    <PoolInfo>
                        <PoolName>{pool?.public_details?.name?.toUpperCase() || "POOL"}</PoolName>
                        <CaptainWrapper>
                            <CaptainLabel>Captain:</CaptainLabel>
                            <ProspinityLogo width="95.106" height="23.595" style={{ marginLeft: "0.5rem" }} />
                        </CaptainWrapper>
                    </PoolInfo>
                </LeftSection>

                {/* Right side: Buttons */}
                <RightSection>
                    {renderButtonGroup()}
                </RightSection>
            </TitleSection>

            {/* Timeline section */}
            <div style={{  marginTop: "1.5rem"}}>
                <PoolTimeLine pool={pool} />
            </div>
            
            {signingModal && 
                <SigningInterface pool={pool} onClose={() => setSigningModal(false)} />
            }

        </HeaderWrapper>
    );
}

export default PoolHeader;


const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    width: 100%;
`;

const TitleSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    flex-wrap: wrap;
    gap: 1rem;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
`;

const LeftSection = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 1rem;
    }
`;

const LogoCircle = styled.img`
    width: 7.5rem;
    height: 7.5rem;
    aspect-ratio: 1;
    border-radius: 50%;
    object-fit: cover;

    @media (max-width: 600px) {
        width: 4.5rem;
        height: 4.5rem;
    }
`;

const PoolInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 600px) {
        align-items: center;
        text-align: center;
        gap: 0.5rem;
    }
`;

const PoolName = styled.h1`
    font-size: 2rem;
    font-weight: 500;
    margin: 0;

    @media (max-width: 600px) {
        font-size: 1.25rem;
    }
`;

const CaptainWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.95rem;
    color: ${({ theme }) => theme.textSecondary};
`;

const CaptainLabel = styled.span`
    font-weight: 400;
    font-size: 1rem;
`;

const RightSection = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media (max-width: 600px) {
        width: 100%;
        justify-content: center;
        gap: 0.75rem;
    }
`;

const ThreeDotsButton = styled(SecondaryButton)`
    color: ${({ theme }) => theme.textSecondary};
    border-color: ${({ theme }) => theme.textSecondary};
    border-radius: 50%;
    width: 2.3rem;
    height: 2.3rem;
    aspect-ratio: 1;
    padding: 0;
    position: relative;

    @media (max-width: 600px) {
        flex: 0 0 auto;
    }
`;

const DropdownWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const PortfolioDropdownContainer = styled.div`
    position: absolute;
    top: calc(100% + 0.75rem); /* ⬇️ Appears below the button with spacing */
    right: 0; /* ⬅️ Aligns left edge with button */
    background: ${({ theme }) => theme.white};
    border-radius: 1rem;
    /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); */
    box-shadow: 0px 8px 20px 0px rgba(29, 29, 38, 0.08);
    width: max-content; /* Shrinks to fit content width */
    min-width: 10rem; /* Or set a fixed width if needed */
    padding: 0;
    z-index: 100;
    overflow: hidden;
`;


const PortfolioDropdownOption = styled.button`
    display: flex;
    gap: 0.5rem;
    width: 100%;
    padding: 0.75rem 1.25rem;
    font-size: 0.875rem;
    background: none;
    border: none;
    color: ${({ theme }) => theme.textPrimary};
    font-weight: 500;
    text-align: left;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.backgroundDarker}; /* Apply hover */
    }
`;




// //fix button centering

// //add the slug to captain
// //make click hoverable
// //change so that user_status not status
// import { useRef } from "react";
// import { Card, ListGroup, Row, Accordion, Button, Col, Container } from "react-bootstrap";
// import PoolDeadlineTimer from "./PoolDeadlineTimer";
// import { useNavigate } from "react-router-dom";
// import { StyledBorderCard, StyledButton, StyledAccordion } from "../common/StyledBootstrap";
// import { useAuth } from "../../contexts/AuthContext";
// import '../../styles/Pool.css';
// import { pastDeadline, stylePoolSlug, convertPackageToGreek } from "../common/CriticalFunctions";
// import { useTheme } from "styled-components";


// // Cards for the pools page
// const PoolPreviewCard = ({ data }) => {

//   const { accountStage } = useAuth();
//   const themeMode = useTheme();

//   const cardRef = useRef(null);


//   const buttonOptions = [
//     { text: 'Accept Invite' },
//     { text: 'Invite Accepted' },
//     { text: 'View Pool' },
//     { text: 'Manage Pool' },
//     { text: 'View Agreement' },
//     { text: 'Request' },
//     { text: 'Verify Account' },
//     { text: 'Evaluate' },
//     { text: 'Message' }
//   ];
//   const navigate = useNavigate();


//   const profilePictureStyle = {
//     width: '4rem', // Adjust width for less height
//     height: '4rem', // Adjust height for less height
//     borderRadius: '50%',
//     marginRight: '0.5rem'
//   };



//   let buttonOptionsIndex = 5; // Updated logic using the local variable
//   if (data.user_status === "invited_not_accepted") {
//     buttonOptionsIndex = 0;
//   } else if (data.user_status === "invited_accepted") {
//     if (data.is_captain) {
//       buttonOptionsIndex = 3;
//     } else {
//       buttonOptionsIndex = 1;
//     }
//   } else if (data.user_status === "accepted_not_signed") {
//     if (accountStage == 3) {
//       if (data.evaluate) {
//         buttonOptionsIndex = 7;
//       }
//       else {
//         buttonOptionsIndex = 2;
//       }

//     }
//     else {
//       buttonOptionsIndex = 5;
//     }
//   } else if (data.user_status === "accepted_signed") {
//     buttonOptionsIndex = 4;
//   }
//   else if (data.user_status === "member") {
//     buttonOptionsIndex = 8;
//   }

//   const handleDeadlineExpiry = () => //need to change (maybe reload api call based on slug)
//   {
//     return null;
//   };

//   const handleHeaderClick = () => {
//     navigate('/dashboard/pool/' + data.slug);
//   };


//   const handleButtonClick = (event) => //change this
//   {
//     event.stopPropagation();
//     navigate('/dashboard/pool/' + data.slug, { state: { actionButtonClicked: true } });
//   }


//   const handleCaptainClick = () => {
//     if (data.hasOwnProperty('captain_slug')) {
//       navigate('/dashboard/user/' + data.captain_slug);
//     }
//   }

//   return (

//     <>
//       <StyledBorderCard ref={cardRef} style={{
//         width: '21rem',
//         transition: 'transform 0.3s ease',
//         transform: 'scale(1)',
//         boxShadow: '0 0 10px rgba(0,0,0,0.1)',
//         marginBottom: '0rem', // Adjusted margin bottom to reduce space between cards
//         cursor: 'pointer'

//       }}
//         onMouseEnter={() => {
//           cardRef.current.style.transform = 'scale(1.03)';
//           cardRef.current.style.boxShadow = '0 0 20px rgba(0,0,0,0.2)';
//         }}
//         onMouseLeave={() => {
//           cardRef.current.style.transform = 'scale(1)';
//           cardRef.current.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
//         }}
//         onClick={handleHeaderClick}>
//         <StyledBorderCard.Body style={{ marginTop: '-0.5rem' }}>
//           <div className='d-flex flex-column justify-content-center'>
//             <div className="d-flex align-items-center">
//               <img
//                 src={'https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp'}
//                 alt={"Preview"}
//                 onError={(e) => { e.target.src = 'https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp'; }}
//                 style={profilePictureStyle}
//               />
//               <div className='d-flex flex-column justify-content-center' style={{ minWidth: 0, width: '100%' }}>
//                 <div style={{
//                   cursor: 'pointer',
//                   whiteSpace: 'nowrap',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                   fontSize: '1.3rem',
//                   marginTop: '0.3em',
//                   minWidth: 0
//                 }}>
//                   <strong style={{
//                     cursor: 'pointer',
//                     fontWeight: 'bold',
//                     textDecoration: 'none',
//                     color: 'inherit',
//                   }}
//                     onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
//                     onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
//                     onClick={() => navigate('/dashboard/pool/' + data.slug)}
//                     >{data.name}</strong>
//                 </div>
//                 <div style={{ display: 'flex', alignItems: 'center', marginTop: '-0.1rem' }}>
//                   <div
//                     style={{
//                       width: '0.5rem',
//                       height: '0.5rem',
//                       backgroundColor: pastDeadline(data.sign_deadline) ? 'green' : 'orange',
//                       borderRadius: '50%',
//                       marginRight: '0.2rem',
//                     }}
//                   />
//                   <div
//                     style={{
//                       overflow: 'hidden',
//                       textOverflow: 'ellipsis',
//                       whiteSpace: 'nowrap',
//                       minWidth: 0,
//                       fontSize: '0.8rem',
//                     }}
//                   >
                    
//                     {/*pastDeadline(data.sign_deadline) ? <strong>Status: </strong> : pastDeadline(data.invitation_deadline) ? <strong>Sign Deadline: </strong> : <strong>Invitation Deadline: </strong>*/}
//                     <PoolDeadlineTimer invitationDeadline={data.invitation_deadline} signDeadline={data.sign_deadline} onDeadlineExpire={handleDeadlineExpiry}></PoolDeadlineTimer>                  </div>
//                 </div>
//                 {/*<div style={{
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis', whiteSpace: 'nowrap', minWidth: 0, fontSize: '0.8rem'
//                 }}>
//                   <i>{"20 members"}</i>
//                 </div>*/}

//               </div>
//             </div>
//             <div className='mt-1 mx-1' style={{ fontSize: '0.9rem' }}>
//               <strong>Members:</strong> {data.member_count} people
//             </div>
//             <div className='mt-1 mx-1' style={{ fontSize: '0.9rem' }}>
//               <strong>Percentage:</strong> {data.contribution_percent}%
//             </div>
//             <div className='mt-1 mx-1' style={{ fontSize: '0.9rem' }}>
//               <strong>Horizon:</strong> {data.time_horizon} years
//             </div>

//             <div className='w-100 d-flex justify-content-center mx-1 mt-3'>
//               <StyledButton style={{ fontSize: '0.8rem', minWidth: '100%' }} onClick={handleButtonClick}>
//                 {buttonOptions[buttonOptionsIndex].text}
//               </StyledButton>
//             </div>

//           </div>
//         </StyledBorderCard.Body>
//       </StyledBorderCard >
      
//     </>
//   );
// };

// export default PoolPreviewCard;


// // Cards for the home page
// export const HomePoolPreviewCard = ({ data }) => {
//   const { accountStage } = useAuth();
//   const themeMode = useTheme();
//   const cardRef = useRef(null);
//   const navigate = useNavigate();
//   const isInvitationPeriod = !pastDeadline(data.invitation_deadline)

//   const profilePictureStyle = {
//     width: '4rem',
//     height: '4rem',
//     borderRadius: '50%',
//     marginTop: '0.5rem',
//     marginRight: '1rem'
//   };

//   const handleHeaderClick = () => {
//     navigate('/dashboard/pool/' + data.slug);
//   };

//   const handleButtonClick = (event) => {
//     event.stopPropagation();
//     navigate('/dashboard/pool/' + data.slug, { state: { actionButtonClicked: isInvitationPeriod ? true : false} });
//   };

//   const handleCaptainClick = () => {
//     if (data.hasOwnProperty('captain_slug')) {
//       navigate('/dashboard/user/' + data.captain_slug);
//     }
//   };

//   const handleDeadlineExpiry = () => //need to change (maybe reload api call based on slug)
//   {
//     return null;
//   };


//   return (
//     <StyledBorderCard
//       ref={cardRef}
//       style={{
//         width: '21rem',
//         transition: 'transform 0.3s ease',
//         transform: 'scale(1)',
//         boxShadow: '0 0 10px rgba(0,0,0,0.1)',
//         marginBottom: '0rem',
//         cursor: 'pointer'
//       }}
//       onMouseEnter={() => {
//         cardRef.current.style.transform = 'scale(1.03)';
//         cardRef.current.style.boxShadow = '0 0 20px rgba(0,0,0,0.2)';
//       }}
//       onMouseLeave={() => {
//         cardRef.current.style.transform = 'scale(1)';
//         cardRef.current.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
//       }}
//       onClick={handleHeaderClick}
//     >
//       <StyledBorderCard.Body style={{ marginTop: '-1.0rem' }}>
//         <div className='d-flex flex-column justify-content-center'>
//           <div className="d-flex align-items-center">
//             <img
//               src={isInvitationPeriod ? data.captain_picture : 'https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp'}
//               alt={"Preview"}
//               onError={(e) => { e.target.src = 'https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp'; }}
//               style={profilePictureStyle}
//               onClick={(event) => {
//                 if (isInvitationPeriod){
//                   event.stopPropagation();  // Prevent the click from bubbling to the card's onClick
//                   navigate('/dashboard/user/' + data.captain_slug);
//                 }
//               }}
//             />
//             <div className='d-flex flex-column justify-content-center' style={{ minWidth: 0, width: '100%'}}>
//               <div style={{
//                 cursor: 'pointer',
//                 whiteSpace: 'nowrap',
//                 overflow: 'hidden',
//                 textOverflow: 'ellipsis',
//                 fontSize: '1.3rem',
//                 marginTop: '0.3em',
//                 minWidth: 0
//               }}>
//                 <strong
//                   style={{
//                     cursor: 'pointer',
//                     fontWeight: 'bold',
//                     textDecoration: 'none',
//                     color: 'inherit',
//                   }}
//                   onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
//                   onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
//                   onClick={(event) => {
//                     if (isInvitationPeriod){
//                       event.stopPropagation();  // Prevent the click from bubbling to the card's onClick
//                       navigate('/dashboard/user/' + data.captain_slug);
//                     }
//                   }}
//                 >
//                   {isInvitationPeriod ? `${data.captain_first_name} ${data.captain_last_name}` : data.name}
//                 </strong>
//               </div>
              

//               <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '2.5rem' }}>
//                 <div style={{ display: 'flex', alignItems: 'center', marginTop: isInvitationPeriod ? '-0.1rem' : '-0.3rem' }}>
//                   <div style={{
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                     whiteSpace: 'nowrap',
//                     minWidth: 0,
//                     fontSize: '1.0rem',
//                   }}>
//                     {isInvitationPeriod ? (
//                       <>
//                         {data.captain_university}
//                         {data.captain_company ? (
//                           <>
//                             <span style={{
//                               margin: '0 0.2rem',
//                               lineHeight: '1',
//                               display: 'inline-block',
//                               verticalAlign: 'middle',
//                               transform: 'translateY(-10%)'
//                             }}>•</span>
//                             {data.captain_company}
//                           </>
//                         ) : null}
//                       </>
//                     ) : (
//                       <>
//                         Members: {data.member_count} {data.member_count > 1 ? "people" : "person"}
//                       </>
//                     )}
//                   </div>
//                 </div>

//                 {isInvitationPeriod ? null : (
//                   <div style={{ display: 'flex', alignItems: 'center', marginTop: '-0.1rem' }}>
//                     <div
//                       style={{
//                         width: '0.5rem',
//                         height: '0.5rem',
//                         backgroundColor: 'orange',
//                         borderRadius: '50%',
//                         marginRight: '0.2rem',
//                       }}
//                     />
//                     <div style={{
//                       overflow: 'hidden',
//                       textOverflow: 'ellipsis',
//                       whiteSpace: 'nowrap',
//                       minWidth: 0,
//                       fontSize: '0.8rem',
//                     }}>
//                       <span style={{ fontStyle: 'italic' }}>
//                         pending
//                       </span>
//                     </div>
//                   </div>
//                 )}
//               </div>


              
//             </div>
//           </div>

//           <div style={{ marginTop: '1rem', fontSize: '1rem' }}>
//             {isInvitationPeriod ? (
//               <>
//                 Invited you to join a pool, <strong>{data.name}</strong>.
//               </>
//             ) : (
//               <>
//                 You have <strong><PoolDeadlineTimer invitationDeadline={data.invitation_deadline} signDeadline={data.sign_deadline} onDeadlineExpire={handleDeadlineExpiry} /></strong> to join.
//               </>
//             )}
//           </div>

//           <div className='w-100 d-flex justify-content-center mt-3'>
//             <StyledButton style={{ fontSize: '0.8rem', minWidth: '100%' }} onClick={handleButtonClick}>
//               {'View Pool'}
//             </StyledButton>
//           </div>
//         </div>
//       </StyledBorderCard.Body>
//     </StyledBorderCard>
//   );
// };
