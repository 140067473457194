import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { useErrorBoundary } from "react-error-boundary";
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import { MainContainer } from "../components/common/Containers";
import UserProfileSection from "../components/users/UserProfileSection";
import { useAuth } from "../contexts/AuthContext";
import { myAxiosInstance } from "../api/axiosConfig";
import Loading from "../components/common/Loading";
import { usePersistedDevicePreferences } from "@stream-io/video-react-sdk";


const UserProfile = () => {
    // API
    const [userData, setUserData] = useState(null); 

    // Update the user
    const updateUser = (updatedUser) => {
        setUserData(updatedUser); // Update profile data after editing
    };

    // Auth
    const { reload, setReload } = useAuth();

    // Params
    const { slug } = useParams();

    // Loading
    const [isLoading, setIsLoading] = useState(true);

    // Errors
    const { showBoundary } = useErrorBoundary();

    // Get the theme
    const theme = useTheme();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // Get the api data
    useEffect(() => {
        const getUserData = async () => {
            try {
                const response = await myAxiosInstance.get('/api/public-profile/' + slug + '/');

                setUserData(response.data); // Store the data
            } catch (error) {
                showBoundary(error);
            } finally {
                setIsLoading(false);
            }
        };

        getUserData();
    }, [reload, slug]);

    // Loading
    if (isLoading) {
        return (
            <div style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <Loading />
            </div>
        );
    }

    return (
        <MainContainer>
            <UserProfileSection user={userData} updateUser={updateUser} />

            {/* More sections can be added here */}
        </MainContainer>
    );
};

export default UserProfile;





// //graceful error handling for unknown user
// import React, { useState, useEffect, useRef } from 'react';
// import { useParams, useNavigate, Link } from 'react-router-dom';
// import axios from 'axios';
// import { Row, Col, Container, Card, Button, Image, Table, Form } from 'react-bootstrap';
// import { useAuth } from '../contexts/AuthContext';
// import Edit from '../components/users/UserProfileEdit';
// import { ProfilePicture } from '../components/common/MiscComponents';
// import { PatchCheck, Pencil } from 'react-bootstrap-icons';
// import { stringToColor, formatGraduationDateSummer } from '../components/common/CriticalFunctions';
// import { useErrorBoundary } from "react-error-boundary";
// import { StyledCard, StyledH5, StyledParagraph } from '../components/common/StyledBootstrap';
// import { myAxiosInstance } from '../api/axiosConfig';
// //import { BioModal } from './BioModal';
// import '../styles/UserProfile.css';
// import ProfilePictureUpload from '../components/forms/ProfilePictureUpload';
// import Loading from '../components/common/Loading';
// import { Linkedin, Envelope, Telegram } from 'react-bootstrap-icons';
// import { StyledButton } from '../components/common/StyledBootstrap';
// import ScheduleSelector from 'react-schedule-selector';
// import Scheduler from '../components/meetings/Scheduler';
// import { useLocation } from 'react-router-dom';
// import { useTheme } from 'styled-components';
// import MeetingTimer from '../components/meetings/MeetingTimer';
// import { useMediaQuery } from 'react-responsive';

// function UserProfile() {
//     const { slug } = useParams();
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [fullUserData, setFullUserData] = useState(null);
//     const [isBottomLoading, setIsBottomLoading] = useState(true);
//     const { userSlug, reload, setReload, setActiveChannel, setChatVisible } = useAuth();
//     const [bottomReload, setBottomReload] = useState(1);
//     const themeMode = useTheme();

//     const [addToPortfolio, setAddToPortfolio] = useState(false);

//     useEffect(() => {
//         if (location.state && location.state.addToPortfolio) {
//             setAddToPortfolio(true); // Set addToPortfolio if passed from another page
//         }
//     }, []);

//     const { showBoundary } = useErrorBoundary();

//     const [showBioModal, setShowBioModal] = useState(false);

//     const [modalShow, setModalShow] = useState(false);
//     const [modalMessage, setModalMessage] = useState(null);
//     const [modalEditData, setModalEditData] = useState(null);

//     const [pictureSize, setPictureSize] = useState(0);
//     const cardRef = useRef(null);

//     const [isIndividualMessageLoading, setIsIndividualMessageLoading] = useState(false);

//     const [schedule, setSchedule] = useState([]);
//     const [sendPortfolioRequestLoading, setSendPortfolioRequestLoading] = useState(false);

//     const [buttonIndex, setButtonIndex] = useState(7);
//     const buttonLabels = ["Add to Portfolio", "Sent Portfolio Request", "Received Portfolio Request", "Meeting Confirmed", "Sent Reschedule Request", "Received Reschedule Request", "Join Meeting"];

//     const bubbleStyle = (color) => ({
//         display: 'inline-block',
//         padding: '5px 10px',
//         margin: '5px',
//         borderRadius: '15px',
//         backgroundColor: color,
//         color: 'black',
//         fontSize: '0.8rem',
//     });


//     const modalOptions = [
//         { modalTitle: 'Edit Name' },
//         { modalTitle: 'Edit Graduation Date' },
//         { modalTitle: 'Edit Program(s) of Study' },
//         { modalTitle: 'Edit University' },
//         { modalTitle: 'Edit School/Residential College' },
//         { modalTitle: 'Edit Organizations' },
//         { modalTitle: 'Edit Bio' },
//         { modalTitle: 'Edit LinkedIn' },
//     ];

//     const profilePictureStyle = {
//         width: '6.5rem', // Adjust width for less height
//         height: '6.5rem', // Adjust height for less height
//         borderRadius: '50%',
//     };

//     const [modalOptionsIndex, setModalOptionsIndex] = useState(null);


//     const handlePencilClick = (index) => {
//         setModalOptionsIndex(index);
//     };

//     const handleClose = () => {
//         setModalShow(false);
//         setModalMessage(null);
//         setModalOptionsIndex(null);
//         setModalEditData(fullUserData);
//     }

//     useEffect(() => {
//         if (modalOptionsIndex != null) {
//             setModalShow(true);
//         }
//     }, [modalOptionsIndex]);

//     const handleMessageButtonClick = async () => {
//         try {
//             setIsIndividualMessageLoading(true);
//             const response = await myAxiosInstance.post('/api/create-channel/', { recipient_slug: slug });
//             setActiveChannel(response.data.channel_id);
//             setChatVisible(true);

//         }
//         catch (error) {}
//         finally {
//             setIsIndividualMessageLoading(false);
//         }

//     };

//     const handleSendPortfolioRequestClick = async () => {
//         try {
//             setSendPortfolioRequestLoading(true);
//             let data = schedule.map((timeSlot) => (timeSlot.toISOString()));
//             const response = await myAxiosInstance.post('/api/request-meeting/', { receiving_user: fullUserData.email, date_options: { "date_options": data } });
//             navigate("/dashboard");

//         }
//         catch (error) {}
//         finally {
//             setSendPortfolioRequestLoading(false);
//         }

//     };

//     const handlePortfolioButtonClick = () => {
//         if (buttonIndex == 0) {
//             setAddToPortfolio(true);
//         }
//         else {
//             navigate('/dashboard/meeting/' + fullUserData.meeting_slug);
//         }

//     }


//     useEffect(() => {
//         const getUserDetails = async () => {
//             try {
//                 setModalShow(false);
//                 setModalMessage(null);
//                 setModalOptionsIndex(null);
//                 setIsBottomLoading(true);
//                 setButtonIndex(7);
//                 const response = await myAxiosInstance.get('/api/public-profile/' + slug + '/');
//                 setFullUserData({ ...response.data, university: [response.data.university], company: [response.data.company] });
//                 setModalEditData({ ...response.data, university: [response.data.university], company: [response.data.company] });
//                 if (response.data.meeting_status === "not_meeting") {
//                     setButtonIndex(0);
//                 }
//                 else if (response.data.meeting_status === "you_requested") {
//                     setButtonIndex(1);
//                 }
//                 else if (response.data.meeting_status === "you_received") {
                    
//                     setButtonIndex(2);
//                 }
//                 else if (response.data.meeting_status === "confirmed" || response.data.meeting_status === "in_progress") {
//                     const now = new Date();
//                     const meetingTime = new Date(response.data.meeting_date);
//                     if (now >= meetingTime || response.data.meeting_status === "in_progress") {
//                         setButtonIndex(6);
//                     }
//                     setButtonIndex(3);
//                 }
//                 else if (response.data.meeting_status === "you_rescheduled") {
//                     setButtonIndex(4);
//                 }
//                 else if (response.data.meeting_status === "other_user_rescheduled") {
//                     setButtonIndex(5);
//                 }
                

//             } catch (error) {
//                 showBoundary(error);
//             } finally {
//                 setIsBottomLoading(false);
//             }
//         };

//         getUserDetails();


//     }, [slug, bottomReload]);


//     useEffect(() => {
//         const updateCardSize = () => {
//             if (cardRef.current) {
//                 const cardWidth = cardRef.current.offsetWidth;

//                 const size = cardWidth * 0.3;
//                 setPictureSize(size);
//             }
//         };

//         updateCardSize();

//         window.addEventListener('resize', updateCardSize);

//         return () => window.removeEventListener('resize', updateCardSize);
//     }, [isBottomLoading]);


//     const isMdOrLarger = useMediaQuery({ minWidth: 768 });


//     return (
//         <>
//             {isBottomLoading ? (
//                 <Loading />
//             ) : (
//                 addToPortfolio ?
//                     <Row className='justify-content-center align-items-center' style={{ minWidth: 0, width: '100%', minHeight: 0, height: '100%'}}>
//                         <Col className='col-lg-6 col-md-8'>
//                             <StyledCard ref={cardRef} className='profile-joyrride my-2'>
//                                 <StyledCard.Body>
//                                     <Container fluid="sm" className="d-flex flex-column justify-content-center">
//                                         <Row className='align-items-center mb-3'>
//                                             <Col >
//                                                 <StyledH5>Scheduling a 10-minute meeting with {fullUserData.first_name} to add them to your portfolio.</StyledH5>
//                                                 <StyledParagraph>Propose times to meet in the next few days below:</StyledParagraph>
//                                             </Col>
//                                         </Row>
//                                         <Scheduler status={"propose"} proposedTimes={schedule} setProposedTimes={setSchedule} />
//                                         <Row className='d-flex justify-content-center mt-3'>
//                                             <Col md={6} className='d-flex justify-content-center'>
//                                                 <StyledButton className='w-40 mr-1' onClick={() => setAddToPortfolio(false)} style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }}>Back</StyledButton>
//                                                 <StyledButton className='w-40 ml-1' onClick={handleSendPortfolioRequestClick}>{sendPortfolioRequestLoading ? <Loading /> : <>Send</>}</StyledButton>
//                                             </Col>
//                                         </Row>
//                                     </Container>
//                                 </StyledCard.Body>
//                             </StyledCard>
//                         </Col>
//                     </Row>

//                     :

//                     <Row className='justify-content-center align-items-center' style={{ minWidth: 0, minHeight: 0, width: isMdOrLarger ? '100%' : 'auto', height: '100%', marginTop: isMdOrLarger ? '4.0rem' : '4.0rem'}} >
//                         <Col className='col-lg-4 col-md-6 col-sm-8'>
                            
//                             <StyledCard ref={cardRef} className='profile-joyrride user-profile-picture-container my-2'>
                                
//                                 <div className='d-flex justify-content-center'>
//                                     <img
//                                         src={fullUserData.profile_picture ? fullUserData.profile_picture :  'https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp'}
//                                         alt={"Preview"}
//                                         onError={(e) => { e.target.src = 'https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp'; }}
//                                         style={profilePictureStyle}
//                                         className='user-profile-picture'
//                                     />
//                                 </div>

//                                 {userSlug === slug && (
//                                     <div className='w-100 d-flex justify-content-end py-2 px-2'>
//                                         <div className='icon-container'><Pencil onClick={() => handlePencilClick(0)} /></div>
//                                     </div>
//                                 )}

//                                 <StyledCard.Body style={{ borderRadius: '1.5rem' }}>
//                                     <Container fluid="sm" className="d-flex flex-column justify-content-center">

//                                         <Row className='justify-content-center mb-3'>
//                                         </Row>


//                                         <div className='d-flex justify-content-center mt-3'>
//                                             <StyledH5>{fullUserData.first_name + " " + fullUserData.last_name}</StyledH5>

//                                         </div>
//                                         <div className='d-flex justify-content-center mt-3'>
//                                             <div>{"Portfolio Size: " + fullUserData.portfolio_count}</div>

//                                         </div>
//                                         <div className='d-flex justify-content-center mt-1'>
//                                             <div>
//                                                 {fullUserData.company[0] !== "" ? fullUserData.company[0] : ""}
//                                             </div>

//                                         </div>
//                                         <div className='d-flex justify-content-center mt-1'>
//                                             <div>
//                                                 {fullUserData.university[0]}
//                                             </div>

//                                         </div>
//                                         <div className='d-flex justify-content-center mt-1'>
//                                             <div>

//                                                 {fullUserData.major_or_grad_program.map((major) => (
//                                                     <>{major}, </>
//                                                 ))}
//                                                 {formatGraduationDateSummer(fullUserData.graduation_date)}

//                                             </div>

//                                         </div>




//                                         {fullUserData.bio.length > 0 ?
//                                             <div className='d-flex mt-1 mb-2'>


//                                                 <div>{fullUserData.bio}

//                                                 </div>



//                                             </div> : <div className='d-flex mt-1 mb-2'>


//                                                 <div>{fullUserData.first_name} is a student at {fullUserData.university}, studying {fullUserData.major_or_grad_program[0]}. They will graduate in {formatGraduationDateSummer(fullUserData.graduation_date)}. {fullUserData.company[0] !== "" ? <>They have work experience at {fullUserData.company[0]}.</> : <></>}

//                                                 </div>



//                                             </div>}
//                                         {buttonIndex !== 7 &&
//                                             <Row className='justify-content-center mt-2 mb-3'>
//                                                 <div className='d-flex justify-content-center align-items-center'>
//                                                     {fullUserData.portfolio_status === "in_portfolio" ?
//                                                         <StyledButton style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }} onClick={handleMessageButtonClick}>Message</StyledButton> :
//                                                         <StyledButton onClick={handlePortfolioButtonClick}>{fullUserData.meeting_status === "confirmed" ? <MeetingTimer meetingTime={fullUserData.meeting_date} onTime={() => { setBottomReload(bottomReload + 1) }} /> : buttonLabels[buttonIndex]}</StyledButton>}

//                                                 </div>
//                                             </Row>}
//                                     </Container>
//                                 </StyledCard.Body>
                                
//                             </StyledCard>

//                             {
//                                 modalOptionsIndex != null ? <Edit show={modalShow}
//                                     setShow={setModalShow}
//                                     modalMessage={modalMessage}
//                                     setModalMessage={setModalMessage}
//                                     bottomReload={bottomReload}
//                                     setBottomReload={setBottomReload}
//                                     reload={reload}
//                                     setReload={setReload}
//                                     modalTitle={modalOptions[modalOptionsIndex].modalTitle}
//                                     data={fullUserData}
//                                     handleClose={handleClose}
//                                     modalEditData={modalEditData}
//                                     setModalEditData={setModalEditData} /> : <></>
//                             }

//                         </Col>
//                     </Row>

//             )}
//         </>
//     );
// }

// export default UserProfile;

