import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import InviteTypeWriter from './InviteTypeWriter';
import envelopeAnimation from '../../assets/misc/envelope-open.json'
import whiteLogo from '../../assets/images/white_logo.png';
import miniLogo from '../../assets/icons/minilogo.svg';

const InviteEnvelopeWrapper = () => {
  const { name: nameParam, date: dateParam } = useParams();
  const [opened, setOpened] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);
  const [showLogo, setShowLogo] = useState(false);

  // For debugging
  console.log('Name param:', nameParam);
  console.log('Date param:', dateParam);

  // Validate parameters - simplified validation
  const isValidName = nameParam && nameParam.split('-').every(part => /^[a-z]+$/i.test(part));
  const isValidDate = dateParam && /^\d{2}-\d{2}-\d{4}$/.test(dateParam);
  
  // For debugging
  console.log('Is valid name:', isValidName);
  console.log('Is valid date:', isValidDate);

  let name, date;
  
  if (isValidName && isValidDate) {
    // Capitalize each word
    name = nameParam
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
      
    const [month, day, year] = dateParam.split('-');
    date = new Date(year, month - 1, day);
    
    // Additional date validation
    if (isNaN(date.getTime())) {
      isValidDate = false;
    }
  }

  // Set expiration to 11:59 PM PST/PDT on the given date
  const getExpirationTime = () => {
    const [month, day, year] = dateParam.split('-');
    // Create date in local timezone
    const expirationDate = new Date(year, month - 1, day);
    // Set to last minute of the day in PT
    expirationDate.setHours(23, 59, 59, 999);
    return expirationDate;
  };

  // Calculate time left in seconds
  const calculateTimeLeft = () => {
    const now = new Date();
    const expiration = getExpirationTime();
    const diff = expiration - now;
    return Math.floor(diff / 1000); // Convert to seconds
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isExpired, setIsExpired] = useState(timeLeft <= 0);

  const sendTrackingData = (status) => {
    const data = new FormData();
    data.append("unique_id", nameParam + "/" + dateParam);
    const now = new Date();
    
    const screenMap = {
      "landed": "screen1",
      "envelope open": "screen2",
      "interview button": "screen3",
      "expired envelope wrapper": "screen4",
      "expired invitation": "screen5",
      "something went wrong": "screen6"
    };

    if (screenMap[status]) {
      // Format date as MM/DD/YYYY HH:MM:SS AM/PM
      const formattedDate = now.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit', 
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
      data.append(screenMap[status], formattedDate);
      const BASE_URL = process.env.REACT_APP_API_URL;
      navigator.sendBeacon(`${BASE_URL}/api/track-application/`, data);
    } else {
      console.warn(`Invalid tracking status: ${status}`);
    }
  };

  // Track landing - only once
  useEffect(() => {
    sendTrackingData("landed");
  }, []);

  // Check initial expiration
  useEffect(() => {
    const initialTimeLeft = calculateTimeLeft();
    if (initialTimeLeft <= 0) {
      setIsExpired(true);
      sendTrackingData("expired envelope wrapper");
    }
  }, []);

  // Timer effect
  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(Math.max(newTimeLeft, 0));
      if (newTimeLeft <= 0 && !isExpired) {
        setIsExpired(true);
        sendTrackingData("expired envelope wrapper");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isExpired]);

  // Add this useEffect near your other tracking effects
  useEffect(() => {
    if (!isValidName || !isValidDate) {
      sendTrackingData("something went wrong");
    }
  }, [isValidName, isValidDate]);

  const formatTime = (secs) => {
    const hours = String(Math.floor(secs / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((secs % 3600) / 60)).padStart(2, '0');
    const seconds = String(secs % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const handleEnvelopeClick = () => {
    if (!isExpired) {
      setIsPlaying(true);
      if (playerRef.current) {
        playerRef.current.play();
        setTimeout(() => {
          setShowLogo(true);
        }, 1000);
        setTimeout(() => {
          playerRef.current.pause();
          setOpened(true);
          sendTrackingData("envelope open");
        }, 2000);
      }
    }
  };

  // Error screen when parameters are invalid
  if (!isValidName || !isValidDate) {
    return (
      <div style={{
        minHeight: '100vh',
        backgroundColor: '#111827',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontFamily: 'Space Grotesk, sans-serif',
        padding: '20px',
        textAlign: 'center'
      }}>
        <motion.img
          src={whiteLogo}
          alt="Logo"
          style={{ width: '200px', marginBottom: '30px' }}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        />
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          style={{ marginBottom: '20px' }}
        >
          Something went wrong
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          style={{ 
            color: '#aaa',
            maxWidth: '400px',
            lineHeight: '1.6'
          }}
        >
          This invite link appears to be invalid. Please make sure you're using the exact link that was provided to you.
        </motion.p>
      </div>
    );
  }

  // Regular render with valid parameters
  return (
    <div
      style={{
        minHeight: '100vh',
        backgroundColor: '#111827',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <AnimatePresence mode="wait">
        {!opened && (
          <motion.div
            key="envelope"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 1.2 }}
            transition={{ duration: 0.8 }}
            style={{
              cursor: isExpired ? 'default' : 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
              maxWidth: '80vw',
              margin: '0 auto',
              padding: '40px 20px',
              opacity: isExpired ? 0.7 : 1,
            }}
            onClick={handleEnvelopeClick}
          >
            <motion.img
              src={whiteLogo}
              alt="Logo"
              style={{ width: '40%', maxWidth: '280px', marginBottom: '15px' }}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            />
            <motion.p
              style={{
                color: '#fff',
                fontSize: '24px',
                fontWeight: '500',
                marginBottom: '20px',
                letterSpacing: '0.5px',
                fontFamily: 'Space Grotesk, sans-serif'
              }}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              {name}
            </motion.p>
            <div style={{ position: 'relative', width: '80%', maxWidth: '400px' }}>
              <Player
                ref={playerRef}
                autoplay={false}
                loop={false}
                src={envelopeAnimation}
                style={{ width: '100%', height: 'auto' }}
              />
              {showLogo && (
                <motion.img
                  src={miniLogo}
                  alt="Prospinity Logo"
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  style={{
                    position: 'absolute',
                    width: '14%',
                    top: '17%',
                    left: '45%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2
                  }}
                />
              )}
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0 }}
              style={{
                marginTop: '20px',
                padding: '12px 24px',
                backgroundColor: isExpired ? 'rgba(255, 78, 78, 0.1)' : 'rgba(255, 255, 255, 0.1)',
                borderRadius: '10px',
                border: `1px solid ${isExpired ? 'rgba(255, 78, 78, 0.2)' : 'rgba(255, 255, 255, 0.2)'}`,
                backdropFilter: 'blur(10px)',
                boxShadow: isExpired ? '0 0 20px rgba(255, 78, 78, 0.1)' : '0 0 20px rgba(255, 255, 255, 0.1)',
                transition: 'all 0.3s ease',
              }}
              whileHover={!isExpired ? { 
                scale: 1.02,
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
                boxShadow: '0 0 30px rgba(255, 255, 255, 0.2)',
              } : {}}
              whileTap={!isExpired ? { scale: 0.95 } : {}}
            >
              <motion.p
                style={{
                  color: isExpired ? '#ff4e4e' : '#fff',
                  fontSize: '16px',
                  fontWeight: '500',
                  letterSpacing: '0.5px',
                  textTransform: 'uppercase',
                  fontFamily: 'Space Grotesk, sans-serif',
                  margin: 0,
                }}
              >
                {isExpired ? (
                  'Invitation Expired'
                ) : (
                  `Open invite (Expires in ${formatTime(timeLeft)})`
                )}
              </motion.p>
            </motion.div>
          </motion.div>
        )}

        {opened && (
          <motion.div
            key="invite"
            initial={{ opacity: 0, y: 60 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.8 }}
            style={{
              width: '100%',
              maxWidth: '100%',
              position: 'relative',
            }}
          >
            <InviteTypeWriter name={name} date={date} sendTrackingData={sendTrackingData}/>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default InviteEnvelopeWrapper;