import { useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { Row, Container } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import logoBlack from '../../assets/icons/logo.svg';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";



function MainLoading() {

    const themeMode = useTheme();
    return (
        <Container fluid style={{ height: '100vh', backgroundColor: themeMode.background}} className='d-flex flex-column justify-content-center'>
        <Row className='justify-content-center text-center'>
            <div >
                <Spinner animation="grow"></Spinner>
            </div>
        </Row>
        </Container>
        
    );
}


function Loading() {

    return (
        
        <Row className='justify-content-center text-center'>
            <div >
                <Spinner animation="grow"></Spinner>
            </div>
        </Row>
        
    );
}


function UnderMaintenance() {
    useEffect(() => {
        setTimeout(() => {
            window.location.href = "/join";  // 🔥 Forces a full reload!
        }, 3000);
    }, []);

    return (
        <StyledContainer>
            <Card>
                <Title>Under Maintenance</Title>
                <Message>We’re making some updates. Please check back soon. For urgent requests, please email support@prospinity.com.</Message>
                <Loader />
            </Card>
        </StyledContainer>
    );
}


// Styled Components
const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: ${({ theme }) => theme.background};
`;

const Card = styled.div`
    text-align: center;
    padding: 3rem;
    background: ${({ theme }) => theme.surface};
    border-radius: 1rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
`;

const Title = styled.h1`
    font-size: 1.75rem;
    font-weight: 600;
    color: ${({ theme }) => theme.textPrimary};
    margin-bottom: 1rem;
`;

const Message = styled.p`
    font-size: 1rem;
    color: ${({ theme }) => theme.textSecondary};
    margin-bottom: 2rem;
`;

// Animated Loader
const Loader = styled.div`
    width: 32px;
    height: 32px;
    border: 4px solid ${({ theme }) => theme.primary};
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;

    @keyframes spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    }
`;


export default Loading;
export {MainLoading, UnderMaintenance};
