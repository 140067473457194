import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import MutualConnections from "./MutualConncetions";
import EditProfile from "./UserProfileEdit";
import { PrimaryButtonLight, SecondaryButtonLight } from "../common/Buttons";
import { myAxiosInstance } from "../../api/axiosConfig";
import LinkedInIcon from "../../assets/icons/LinkedIn.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/addIcon.svg";
import { ReactComponent as MessageIcon } from "../../assets/icons/messageIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/editIcon.svg";


export default function UserProfileSection({ user, updateUser }) {
    
    // Get the theme
    const theme = useTheme();

    // Navigate
    const navigate = useNavigate();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 600 });

    // Majors
    const majorList = user.major_or_grad_program;
    const formattedMajors = majorList.length === 1
        ? majorList[0]
        : `${majorList.slice(0, -1).join(", ")} and ${majorList[majorList.length - 1]}`;

    // Default About Text if bio is empty
    const defaultAbout = `${user.first_name} ${user.last_name} is a student at ${user.university} studying ${formattedMajors}.`;

    // Profile picture fallback
    const profilePicture =
        user.profile_picture_url !== ""
        ? user.profile_picture_url
        : "https://storage.googleapis.com/prosp_profile_img/favicons/favicon.svg";

    // University img fallback
    const universityImg =
        user.university_img?.trim() !== ""
        ? user.university_img
        : "https://storage.googleapis.com/prosp_profile_img/favicons/favicon.svg";

    // Portfolio Status
    const [portfolioStatus, setPortfolioStatus] = useState(user.portfolio_status);

    // Editing
    const [isEditing, setIsEditing] = useState(false);

    // Update protfokio status if the user changes.
    useEffect(() => {
        setPortfolioStatus(user.portfolio_status);
    }, [user]);

    // Button Actions
    const handleSendRequest = async (e) => {
        e.stopPropagation(); // Prevents navigation when clicking the button

        try {
        await myAxiosInstance.post(`/api/make-portfolio-request/`, {
            receiving_user: user.slug,
        });

        setPortfolioStatus("portfolio_request_sent");

        } catch (error) {
        console.error("Error sending request:", error);
        }

    };

    const handleUndoRequest = async (e) => {
        e.stopPropagation();

        try {
        await myAxiosInstance.post(`/api/manage-portfolio-request/${user.slug}/`);
    
        // Reset state back to not_portfolio
        setPortfolioStatus("not_porfolio");
    
        } catch (error) {
        console.error("Error undoing request:", error);
        }
    } 

    // Portfolio accept and reject (defined here for correct rerendering)
    const handleAcceptRequest = async (e) => {
        try {
            await myAxiosInstance.post(`/api/manage-portfolio-request/${user.slug}/`, { accept: true });
            setPortfolioStatus("in_portfolio");
            
        } catch (error) {
            console.error("Failed to accept request:", error);
        }
    };
        
    const handleRejectRequest = async (e) => {
        try {
            await myAxiosInstance.post(`/api/manage-portfolio-request/${user.slug}/`, { accept: false });
    
            setPortfolioStatus("not_porfolio");
        } catch (error) {
            console.error("Failed to reject request:", error);
        }
    };

    const handleMessageButtonClick = async () => {
        try {
            const response = await myAxiosInstance.post('/api/create-channel/', { recipient_slug: user.slug });
            const { channel_id } = response.data;
            navigate("/dashboard/inbox/", { state: { openChannelId: channel_id } });
        }
        catch (error) {
        }
    };

    const renderButtonSection = () => {
        switch (portfolioStatus) {
            case "self":
                return (
                    <ButtonSection>
                        <SecondaryButtonLight size={isMobile ? "md" : "lg"} onClick={() => setIsEditing(true)}>
                            <EditIcon width="18.75" height="18.75" fill={theme.white} style={{ marginTop: "0.3rem", flexShrink: 0, display: "inline-block" }} />
                            EDIT INFORMATION
                        </SecondaryButtonLight>
                    </ButtonSection>
                );

            case "in_portfolio":
                return (
                    <ButtonSection>
                        <SecondaryButtonLight size={isMobile ? "md" : "lg"} onClick={() => handleMessageButtonClick()}>
                            <MessageIcon width="20" height="19" fill={theme.white} />
                            MESSAGE
                        </SecondaryButtonLight>
                    </ButtonSection>
                );
            
            case "not_porfolio":
                return (
                    <ButtonSection>
                        <PrimaryButtonLight size={isMobile ? "md" : "lg"} onClick={handleSendRequest}>
                            <AddIcon fill={theme.textPrimary} />
                            ADD TO PORTFOLIO
                        </PrimaryButtonLight>
                    </ButtonSection>
                );
                
            case "portfolio_request_sent":
                return (
                    <ButtonSection>
                        <SecondaryButtonLight size={isMobile ? "md" : "lg"} disabled={true} style={{ pointerEvents: "none" }}>
                            SENT
                        </SecondaryButtonLight>
                    </ButtonSection>
                );

            case "portfolio_request_pending":
                return (
                    <ButtonSection>
                        <SecondaryButtonLight size={isMobile ? "md" : "lg"} onClick={handleRejectRequest}>
                            REMOVE
                        </SecondaryButtonLight>
                        <PrimaryButtonLight size={isMobile ? "md" : "lg"} onClick={handleAcceptRequest}>
                            <AddIcon fill={theme.textPrimary} />
                            ADD
                        </PrimaryButtonLight>
                    </ButtonSection>
                );

            default:
                return null;
        }
    };

    return (
        <>
            <ProfileCard>
                {/* BACKGROUND GRID */}
                <BackgroundGrid/>

                {/* TOP SECTION */}
                <TopSection>
                    {/* Name Section */}
                    <NameSection>
                        {/* Profile Image */}
                        <ProfileImage 
                            src={profilePicture} 
                            alt="Profile" 
                            onError={(e) => {
                                e.target.onerror = null; // Prevent infinite loop
                                e.target.src = "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW_20250320180317.webp";
                              }} 
                            />

                        {/* Details */}
                        <Details>
                            {/* LinkedIn + Mutuals */}
                            <ConnectionsRow>
                                {user.linkedin_url && (
                                    <LinkedInButton href={user.linkedin_url} target="_blank" rel="noopener noreferrer">
                                        <LinkedInLogo src={LinkedInIcon} alt="LinkedIn" />
                                        <LinkedInText>LinkedIn</LinkedInText>
                                    </LinkedInButton>
                                )}

                                {portfolioStatus !== "self" && <MutualConnections mutuals={user.mutual_connections} themeStyle="dark" />}
                            </ConnectionsRow>

                            {/* Name */}
                            <UserName>
                                {user.first_name} {user.last_name}
                            </UserName>

                            {/* Tagline */}
                            {user.company && <Tagline style={{fontStyle: "normal", opacity: 1}}>{user.role ? `${user.role} at ${user.company}` : user.company}</Tagline>}
                            {user.tag_line && <Tagline>“{user.tag_line}”</Tagline>}
                        </Details>
                    </NameSection>

                    {/* Button Section */}
                    { renderButtonSection() }
                </TopSection>

                {/* BODY SECTION */}
                <BodyContainer>
                    {/* About Section */}
                    <Section>
                        <SectionTitle>ABOUT</SectionTitle>
                        <AboutBox>
                            {user.bio && user.bio.trim() !== "" ? user.bio : defaultAbout}
                        </AboutBox>
                    </Section>

                    {/* Education Section */}
                    <Section>
                        <SectionTitle>EDUCATION</SectionTitle>
                        <EducationBox>
                            <div style={{ 
                                background: theme.white, 
                                borderRadius: "50%",
                            }}>
                                <EduLogo src={universityImg} />
                            </div>
                            
                            {isMobile ? 
                                <EduText>
                                    <EduTitle>{user.university}</EduTitle>
                                    <EduRow>
                                        <EduSubtitle>{formattedMajors} <Divider /> Exp {user.graduation_date} Graduate</EduSubtitle>
                                    </EduRow>
                                </EduText>

                                :

                                <EduText>
                                    <EduRow>
                                        <EduTitle>{user.university}</EduTitle>
                                        <Divider />
                                        <EduSubtitle>Exp {user.graduation_date} Graduate</EduSubtitle>
                                    </EduRow>
                                    <EduSubtitle>{formattedMajors}</EduSubtitle>
                                </EduText>
                            }
                        </EducationBox>
                    </Section>

                </BodyContainer>

                {/* CLUB SECTION */}
                <ClubSection>
                    <SectionTitle>CLUBS</SectionTitle>

                    <ClubContainer>
                        {user.club.map((club, index) => (
                            <ClubCard key={index}>{club}</ClubCard>
                        ))}
                    </ClubContainer>
                </ClubSection>
            
            </ProfileCard>

            {/* Edit Profile */}
            <EditProfile show={isEditing} onClose={() => setIsEditing(false)} user={user} updateUser={updateUser} />
        </>
        
    );
}

const BackgroundGrid = () => {
    return (
      <Wrapper>
        {/* Ellipse in the top-right corner */}
        <EllipseBottom />
        <EllipseBottom />
        <EllipseTop />
        <EllipseTop />

        {/* Grid Overlay */}
        <GridOverlayTop />
        <GridOverlayBottom />

      </Wrapper>
    );
  };
    
  // ---------------------- 🔹 Styled Components 🔹 ----------------------
  
const Wrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    overflow: hidden;
    border-radius: 1.5rem; /* Ensure rounded corners match your card */
`;
  
  const EllipseBottom = styled.div`
    position: absolute;
    width: 50%;
    height: 50%;
    background: ${({ theme }) => theme.primary };
    filter: blur(100px); /* Adjusted for a better effect */
    border-radius: 630px;
    opacity: 0.5;
    
    bottom: -31px;
    left: -150px;

    @media (max-width: 600px) { 
        height: 240px;
    }
`;

const EllipseTop = styled.div`
    position: absolute;
    width: 50%;
    height: 50%;
    background: ${({ theme }) => theme.primary };
    filter: blur(100px); /* Adjusted for a better effect */
    border-radius: 630px;
    opacity: 0.5;

    top: -31px;
    right: -150px;

    @media (max-width: 600px) { 
        height: 240px;
    }
`;

const GridOverlayTop = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: 50%;
    background-image: 
        linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 1px, transparent 1px),  /* Stronger at the top */
        linear-gradient(to right, rgba(255, 255, 255, 0.6) 1px, transparent 1px);
    background-size: 91.625px 91.625px; /* Adjust grid size */
    opacity: 0.5; /* Make the grid slightly stronger overall */
    
    mask-image: radial-gradient(
        circle at top right,  /* Originates from top-right */
        rgba(0, 0, 0, 1) 0%,  
        rgba(0, 0, 0, 0.6) 20%,
        rgba(0, 0, 0, 0.2) 40%,
        rgba(0, 0, 0, 0) 60%
    );

    -webkit-mask-image: radial-gradient(
        circle at top right,
        rgba(0, 0, 0, 1) 0%,  
        rgba(0, 0, 0, 0.6) 20%,  
        rgba(0, 0, 0, 0.2) 40%,  
        rgba(0, 0, 0, 0) 60%
    );

    @media (max-width: 600px) { 
        width: 50%;
        height: 40%;
        background-size: 60px 60px; /* Adjust grid size */

        mask-image: radial-gradient(
            circle at top right,  /* Originates from top-right */
            rgba(0, 0, 0, 1) 0%,  
            rgba(0, 0, 0, 0.6) 10%,
            rgba(0, 0, 0, 0.2) 30%,
            rgba(0, 0, 0, 0) 50%
        );

        -webkit-mask-image: radial-gradient(
            circle at top right,
            rgba(0, 0, 0, 1) 0%,  
            rgba(0, 0, 0, 0.6) 10%,  
            rgba(0, 0, 0, 0.2) 30%,  
            rgba(0, 0, 0, 0) 50%
        );
    }
`;

const GridOverlayBottom = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60%;
    height: 50%;
    background-image: 
        linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 1px, transparent 1px),  /* Stronger at the top */
        linear-gradient(to left, rgba(255, 255, 255, 0.6) 1px, transparent 1px);
    background-size: 91.625px 91.625px; /* Adjust grid size */
    opacity: 0.5; /* Make the grid slightly stronger overall */
    
    mask-image: radial-gradient(
        circle at bottom left,  /* Originates from top-right */
        rgba(0, 0, 0, 1) 0%,  
        rgba(0, 0, 0, 0.6) 20%,
        rgba(0, 0, 0, 0.2) 40%,
        rgba(0, 0, 0, 0) 60%
    );

    -webkit-mask-image: radial-gradient(
        circle at bottom left,
        rgba(0, 0, 0, 1) 0%,  
        rgba(0, 0, 0, 0.6) 20%,  
        rgba(0, 0, 0, 0.2) 40%,  
        rgba(0, 0, 0, 0) 60%
    );

    @media (max-width: 600px) { 
        width: 50%;
        height: 40%;
        background-size: 60px 60px; /* Adjust grid size */

        mask-image: radial-gradient(
            circle at bottom left,  /* Originates from top-right */
            rgba(0, 0, 0, 1) 0%,  
            rgba(0, 0, 0, 0.6) 10%,
            rgba(0, 0, 0, 0.2) 30%,
            rgba(0, 0, 0, 0) 50%
        );

        -webkit-mask-image: radial-gradient(
            circle at bottom left,
            rgba(0, 0, 0, 1) 0%,  
            rgba(0, 0, 0, 0.6) 10%,  
            rgba(0, 0, 0, 0.2) 30%,  
            rgba(0, 0, 0, 0) 50%
    );
    }
`;



// ---------------------- 🔹 Styled Components 🔹 ----------------------

const ProfileCard = styled.div`
    position: relative;
    z-index: 2;
    background-color: ${({ theme }) => theme.buttonPrimary};
    color: ${({ theme }) => theme.white};
    border-radius: 1.5rem;
    padding: 2rem 2rem 4rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (max-width: 600px) { 
        padding: 2rem 1.25rem 2rem 1.25rem;
    }
`;

/* FLEX: ROW, SPACE-BETWEEN */
const TopSection = styled.div`
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;

    @media (max-width: 1000px) { 
        flex-direction: column;
        align-items: flex-start;
    }

    @media (max-width: 600px) { 
        flex-direction: column;
        gap: 1rem;
        justify-content: flex-start;
        align-items: center;
    }

`;

/* FLEX: ROW */
const NameSection = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;

    @media (max-width: 600px) { 
        flex-direction: column;
        gap: 1rem;
        justify-content: flex-start;
        align-items: center;
    }
`;

/* PROFILE PICTURE */
const ProfileImage = styled.img`
    width: 9rem;
    height: 9rem;
    aspect-ratio: 1;
    border-radius: 1rem;
    object-fit: cover;

    @media (max-width: 600px) { 
        width: 4.5rem;
        height: 4.5rem;
        aspect-ratio: 1;
    }
`;

/* FLEX: COLUMN, FLEX-START, 0.75rem GAP */
const Details = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;

    @media (max-width: 600px) { 
        display: grid; /* ✅ Enables grid for reordering */
        align-items: center;
        text-align: center;
        gap: 0.75rem;

        & > h1 { order: 1; } /* ✅ Name first */
        & > div { order: 2; } /* ✅ Connections second */
        & > p { order: 3; } /* ✅ Tagline last */
    }
`;

/* FLEX: ROW */
const ConnectionsRow = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (max-width: 812px) { 
        flex-direction: column;
        align-items: flex-start;
    }

    @media (max-width: 600px) { 
        flex-direction: row;
        justify-content: center;
        gap: 1.2rem;
    }
`;

/* ✅ Styled LinkedIn Button to match your image */
const LinkedInButton = styled.a`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: ${({ theme }) => theme.white + "1A"};
    padding: 0.4rem 1rem;
    border-radius: 999px; /* ✅ Rounded pill shape */
    color: white;
    font-size: 0.875rem;
    font-weight: bold;
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;

    &:hover {
        opacity: 0.87;
        text-decoration: none;
    }

    @media (max-width: 600px) { 
        padding: 3.5px 10.5px;
        gap: 3.5px;
    }

`;

/* LinkedIn Logo inside the button */
const LinkedInLogo = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    aspect-ratio: 1;

    @media (max-width: 600px) { 
        width: 1rem;
        height: 1rem;
    }
`;

/* Text inside the LinkedIn button */
const LinkedInText = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: white;

  @media (max-width: 600px) { 
        font-size: 0.875rem;
        line-height: 26px;
    }
`;

/* USER NAME */
const UserName = styled.h1`
    font-size: 2rem;
    line-height: 40px;
    text-transform: uppercase;
    margin: 0;

    @media (max-width: 600px) { 
        font-size: 1.25rem;
        line-height: 26px;
    }
`;

/* TAGLINE */
const Tagline = styled.p`
  font-size: 1rem;
  font-style: italic;
  opacity: 0.8;
  margin: 0;
`;

/* FLEX: ROW, 1.5rem GAP */
const ButtonSection = styled.div`
    display: flex;
    gap: 1rem;
    white-space: nowrap;

    @media (max-width: 1150px) { 
        flex-direction: column-reverse;
        align-items: center; /* ✅ Start at the top */
        width: 50%;
  
        & > button {
            align-self: stretch; /* ✅ Makes buttons the same width */
            width: 100%; /* ✅ Ensures full width */
        }
    }

    @media (max-width: 1000px) { 
        flex-direction: row;
    }

    @media (max-width: 600px) { 
        width: 100%;
    }
`;

const BodyContainer = styled.div`
    z-index: 3;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 1rem;
    align-items: stretch;

    @media (max-width: 600px) { 
        flex-direction: column;
        justify-content: flex-start;
        gap: 1.5rem;
        align-items: flex-start;;
    }
`;

const Section = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: 100%;

    @media (max-width: 600px) { 
        flex: unset;
        min-height: unset;
    }
`;

const SectionTitle = styled.h2`
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: ${({ theme }) => theme.white + "A3"};
    margin: 0;

    @media (max-width: 600px) { 
        font-size: 0.875rem;
    }
`;

const AboutBox = styled.div`
    background-color: ${({ theme }) => theme.white + "0F"};
    padding: 1.5rem 1rem;
    border-radius: 1.5rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    height: 100%;

    @media (max-width: 600px) { 
        padding: 1.5rem 1.25rem;
    }
`;

const EducationBox = styled.div`
    display: flex;
    flex-direction: column; /* ✅ Puts logo above text */
    align-items: flex-start; /* ✅ Centers everything */
    background-color: ${({ theme }) => theme.white + "0F"};
    padding: 1.5rem 1rem;
    border-radius: 1.5rem;
    gap: 1rem;
    height: 100%;

    @media (max-width: 600px) { 
        padding: 1.5rem 1.25rem;
    }
`;

const EduLogo = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    aspect-ratio: 1;
`;

const EduText = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 600px) { 
        gap: 0.5rem;
    }
`;

const EduRow = styled.div`
    display: flex;
    align-items: center; /* Ensures vertical centering */
    gap: 0.3rem;
    flex-wrap: wrap; /* Moves whole block instead of breaking mid-line */
`;

/* ✅ Styled Divider to match the second image */
const Divider = styled.span`
    width: 1px;
    height: 1.2em; /* Matches text height */
    background-color: ${({ theme }) => theme.border};
    display: inline-block;
    margin: 0 0.2rem;

    @media (max-width: 600px) { 
        vertical-align: middle;
        opacity: 0.64;
    }
`;

const EduTitle = styled.h3`
    font-size: 1.0625rem;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    flex-wrap: wrap;
`;

const EduSubtitle = styled.p`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.white + "A3"};
    margin: 0;
`;

const ClubSection = styled.div`
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const ClubContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    @media (max-width: 600px) { 
        flex-direction: column;
        align-items: flex-start;
    }
`;

const ClubCard = styled.div`
    background-color: ${({ theme }) => theme.white + "0F"};
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: 500;
    max-width: 420px; /* Ensures max width */
    white-space: nowrap; /* Keeps text on one line */
    overflow: hidden; /* Hides overflowing text */
    text-overflow: ellipsis; /* Shows '...' when text is too long */
    display: block; /* Fixes issue where flexbox prevented ellipsis */
    min-width: 0; /* Prevents flexbox from forcing overflow */
    text-align: center; /* Keeps text centered */

    @media (max-width: 600px) { 
        padding: 1rem 1.25rem;
        max-width: 100%;
        width: 100%;
        flex: unset;
        text-align: left;
    }
`;

