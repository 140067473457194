import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";

import { useNotifications } from "../../contexts/NotificationsContext";
import { myAxiosInstance } from "../../api/axiosConfig";
import { NotificationsIcon } from "../../assets/icons/Icons";

export default function Notifications({ onShowNotifications }) {
    const { notifications, markAllAsRead, readNotification } = useNotifications();
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const notificationRef = useRef(null);

    // Polling Interval (5 minutes)
    const POLLING_INTERVAL = 5 * 60 * 1000; // 5 min in milliseconds

    const getTimeAgo = (utcTime) => {
        const now = new Date();
        const createdDate = new Date(utcTime); // Convert string to Date object
        const diffInSeconds = Math.floor((now - createdDate) / 1000); // Difference in seconds
    
        if (diffInSeconds < 60) return "now"; // Less than a minute
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        if (diffInMinutes < 60) return `${diffInMinutes}m`; // Less than an hour
        const diffInHours = Math.floor(diffInMinutes / 60);
        if (diffInHours < 24) return `${diffInHours}h`; // Less than a day
        const diffInDays = Math.floor(diffInHours / 24);
        return `${diffInDays}d`; // More than a day
    };
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <NotificationWrapper ref={notificationRef}>
            <NotificationIconWrapper onClick={() => setIsOpen(!isOpen)} theme={theme}>
                <NotificationsIcon theme={theme} />
                {notifications.some(n => !n.read) && <NotificationDot theme={theme} />}
            </NotificationIconWrapper>

            {isOpen && (
                <NotificationDropdown theme={theme}>
                    <Header>
                        <SeeAll onClick={() => { onShowNotifications(); setIsOpen(false) }}>SEE ALL</SeeAll>
                        <MarkAsRead onClick={markAllAsRead}>Mark as read</MarkAsRead>
                    </Header>

                    {notifications.slice(0, 4).map(notification => (
                        <NotificationItem
                            key={notification.pk}
                            onClick={() => { readNotification(notification.pk); navigate("/" + notification.redirect_link); setIsOpen(false) }}
                            read={notification.read}
                            theme={theme}
                        >
                            <NotificationRow>
                                <NotificationTitle theme={theme}>{notification.title}</NotificationTitle>
                                <NotificationMeta>
                                    <TimeAgo theme={theme}>{getTimeAgo(notification.created_at)}</TimeAgo>
                                    {!notification.read && <UnreadDot theme={theme} />}
                                </NotificationMeta>
                            </NotificationRow>
                            
                            <NotificationMessage theme={theme}>{notification.message}</NotificationMessage>
                        </NotificationItem>
                    ))}
                </NotificationDropdown>
            )}
        </NotificationWrapper>
    );
}

// Styled Components
const NotificationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
`;

const NotificationIconWrapper = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.white};
    border-radius: 50%;
`;

const NotificationDot = styled.div`
    position: absolute;
    top: 27%;
    right: 27%;
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.red};
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.white};
    z-index: 1;
`;

const NotificationDropdown = styled.div`
    position: absolute;
    top: 52px;
    right: 0;
    width: 338px;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.divider};
    border-radius: 1.5rem;
    padding: 1.125rem 0;
    z-index: 1005;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0rem 1.5rem;
    margin-bottom: 0.75rem;
`;

const SeeAll = styled.span`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.textSecondary};
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.textTertiary};
    }
`;

const MarkAsRead = styled.span`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.primary};
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.primaryHover};
    }
`;

const NotificationItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.2rem 1.5rem;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.backgroundLight};
    }
`;

const NotificationRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const NotificationTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 25px;
  color: ${({ theme }) => theme.textPrimary};
`;

const NotificationMessage = styled.div`
  font-size: 0.875rem;
  line-height: 18px;
  color: ${({ theme }) => theme.textSecondary};
`;

const NotificationMeta = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 5.35px;
  align-items: center;
  margin-top: 0.25rem;
  min-width: 30px;
`;

const TimeAgo = styled.span`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.textSecondary};
`;

const UnreadDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
`;










// import { useState, useEffect } from "react";
// import { useTheme } from "styled-components";
// import { myAxiosInstance } from "../../api/axiosConfig";
// import { StyledDropdown, StyledH5, StyledButton } from "../common/StyledBootstrap";
// import { Nav } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBell } from "@fortawesome/free-solid-svg-icons";
// import { useErrorBoundary } from "react-error-boundary";
// import { Tooltip, OverlayTrigger } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { timeSince } from "../common/CriticalFunctions";




// function Notifications() {
//     const themeMode = useTheme();
//     const {showBoundary} = useErrorBoundary();
//     const pollingInterval = 3600000;
//     const [notifications, setNotifications] = useState([]);
//     const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
//     const [shouldFetchNotifications, setShouldFetchNotifications] = useState(true);
//     const [dropdownOpen, setDropdownOpen] = useState(false);

//     const notificationCountStyle = {
//         position: 'absolute',
//         top: '0.25rem',
//         right: '0.25rem',
//         padding: '0rem 0rem',
//         borderRadius: '50%',
//         background: themeMode.secondary,
//         height: '1.5rem',
//         width: '1.5rem',
//         color: themeMode.onSecondary,
//         fontSize: '1rem',
//         fontWeight: 'bold',

//         // Flexbox properties to center content
//         display: 'flex',
//         alignItems: 'center',  // Vertical alignment
//         justifyContent: 'center', // Horizontal alignment
//     };


//     const fetchNotifications = async () => {

//         try {
//             const response = await myAxiosInstance.get('/api/notifications/');
//             setNotifications(response.data);
//             setUnreadNotificationsCount(response.data.filter(notification => !notification.read).length)

//         } catch (error) {
//             showBoundary(error);
//         }


//     };


//     useEffect(() => {
//         // Function to fetch notifications


//         // Initial fetch
//         fetchNotifications();

//         // Set up polling with setInterval
//         const intervalId = setInterval(fetchNotifications, pollingInterval);

//         // Clear interval on component unmount
//         return () => clearInterval(intervalId);
//     }, [shouldFetchNotifications]);



//     const handleNotificationClick = async (notificationId) => {
//         try {
//             // Call the API to mark the notification as read
//             await myAxiosInstance.post(`/api/notifications/mark-read/${notificationId}/`);

//             // Update the state to reflect the read status of the notification
//             setNotifications(notifications.map(notification =>
//                 notification.pk === notificationId
//                     ? { ...notification, read: true }
//                     : notification
//             ));
//             fetchNotifications();
//         } catch (error) {}
//     };


//     const handleMarkAllAsRead = async () => {
//         try {
//             // Call the API to mark the notification as read
//             await myAxiosInstance.post("/api/read-notifications/");

//             // Update the state to reflect the read status of the notification
//             setNotifications(notifications.map(notification =>

//                 ({ ...notification, read: true })

//             ));
//             fetchNotifications();
//         } catch (error) {}
//     };


//     const handleNotificationDropdownToggle = async (isOpen) => {
//         setDropdownOpen(isOpen);
//         setShouldFetchNotifications(!isOpen);
//     };



//     return (
//         <StyledDropdown onToggle={handleNotificationDropdownToggle} show={dropdownOpen} className='d-flex flex-column justify-content-center align-items-center'>
//             <StyledDropdown.Toggle as={Nav.Link} className='no-caret-icon'>
//                 <FontAwesomeIcon icon={faBell} fontSize={'1.9rem'} />
//                 {unreadNotificationsCount > 0 && (
//                     <span style={notificationCountStyle}>{
//                         unreadNotificationsCount
//                     }</span>
//                 )}
//             </StyledDropdown.Toggle>
//             <StyledDropdown.Menu align={'end'} style={{ maxWidth: '27rem', overflow: 'hidden', maxHeight: '30rem', overflowY: 'auto' }}>
//                 <>
//                     <div className='d-flex justify-content-between align-items-center' style={{ textAlign: 'center', width: '100%' }}><StyledH5 className='ml-3'>Notifications</StyledH5><StyledButton onClick={handleMarkAllAsRead} size="sm" className='mr-1' inline>Mark All As Read</StyledButton></div>
//                     {notifications.length === 0 ? (
//                         <StyledDropdown.Item>No notifications</StyledDropdown.Item>
//                     ) : (
//                         notifications.map((notification, index) => (
//                             <OverlayTrigger
//                                 key={index}
//                                 placement="left"
//                                 overlay={
//                                     <Tooltip id={`tooltip-${index}`}>
//                                         {notification.message}
//                                     </Tooltip>
//                                 }
//                             >
//                                 <StyledDropdown.Item key={index} as={Link} to={"/" + notification.redirect_link} onClick={() => handleNotificationClick(notification.pk)} className={notification.read ? '' : 'unread-notification'} title={notification.message}>
//                                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px' }}>
//                                         <div style={{ flex: '1 1 auto', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
//                                             {notification.message}
//                                         </div>
//                                         <div style={{ flex: '0 0 auto', fontSize: '0.75rem', color: '#007bff', whiteSpace: 'nowrap' }}>
//                                             {timeSince(notification.created_at)}
//                                         </div>
//                                     </div>
//                                 </StyledDropdown.Item>
//                             </OverlayTrigger>
//                         ))
//                     )}</>
//             </StyledDropdown.Menu>
//         </StyledDropdown>);

// }

// export default Notifications;