import styled, { useTheme } from "styled-components";
import { useMediaQuery } from "react-responsive";

import { ErrorText } from "./MiscComponents";

export default function FormTextarea({ label, value, placeholder, onChange, error }) {
    // Get the theme
    const theme = useTheme();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <InputContainer>
            <InputWrapper theme={theme}>
                <StyledTextarea 
                    value={value} 
                    onChange={onChange}
                    placeholder={placeholder}
                    theme={theme}
                    isMobile={isMobile}
                />
                <FloatingLabel theme={theme}>{label}</FloatingLabel>
            </InputWrapper>

            {error && <ErrorText>{error}</ErrorText>}
        </InputContainer>
    );
}

// Styled Components
const InputContainer = styled.div`
    width: 100%;
    position: relative;
`;

const InputWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const StyledTextarea = styled.textarea`
    width: 100%;
    height: ${({ isMobile }) => isMobile? "4rem" : "6rem"}; /* Fixed height */
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 1rem;
    outline: none;
    background: ${({ theme }) => theme.white}; 
    color: ${({ theme }) => theme.textPrimary};
    resize: none; /* Prevents resizing */
    overflow-y: auto; /* Enables scrolling when text overflows */
    line-height: 1.4;

    &::placeholder {
        color: ${({ theme }) => theme.textSecondary};
        opacity: 0.6;
    }
`;

const FloatingLabel = styled.label`
    position: absolute;
    left: 1.5rem;
    transform: translateY(-50%);
    padding: 0 0.3rem;
    background: ${({ theme }) => theme.white};
    font-size: 0.75rem;
    color: ${({ theme }) => theme.textSecondary};
    z-index: 2;
`;

// Adjust label positioning when text is entered
// StyledTextarea.defaultProps = {
//     onFocus: (e) => {
//         e.target.previousSibling.style.top = "-0.6rem";
//         e.target.previousSibling.style.fontSize = "0.75rem";
//     },
//     onBlur: (e) => {
//         if (!e.target.value) {
//             e.target.previousSibling.style.top = "0.8rem";
//             e.target.previousSibling.style.fontSize = "1rem";
//         }
//     }
// };
