import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import logo from '../../assets/images/white_logo.png';
import typewriterSound from '../../assets/misc/typewriter.mp3';



const replaceLinks = (text) => {
  return text
    .replace(
      'Business Insider',
      `<span class="custom-link" onclick="window.open('https://www.businessinsider.com/prospinity-income-sharing-raise-2-million-slow-ventures-2024-12', '_blank')">Business Insider</span>`
    )
    .replace(
      'website',
      `<span class="custom-link" onclick="window.open('https://prospinity.com', '_blank')">our website</span>`
    );
};

const InviteTypeWriter = ({ name, date, sendTrackingData }) => {
  // Set expiration to 11:59 PM PST on the given date
  const getExpirationTime = () => {
    const expirationDate = new Date(date);
    expirationDate.setHours(23, 59, 59, 999);
    return expirationDate;
  };

  // Calculate initial time left in seconds
  const calculateTimeLeft = () => {
    const now = new Date();
    const expiration = getExpirationTime();
    const diff = expiration - now;
    return Math.floor(diff / 1000); // Convert to seconds
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isExpired, setIsExpired] = useState(timeLeft <= 0);
  
  const rawText = `Hey ${name.split(' ')[0]},

<b>Congratulations.</b> Very few — or perhaps none — of your peers have received this invite. Your profile was selected by one of our campus representatives from the limited number of spots allocated to them.

We're <b>Prospinity</b>, and we build <b>Success Pools</b>.

Success Pools are <b>index funds of talent</b> — groups of high-potential students who agree to share a small percentage of their future earnings. 

It's a way to <b>diversify your career</b>, <b>redistribute financial upside</b> if someone "hits it big," and <b>build deep connections</b> with other exceptional students through aligned incentives.

We're selective about who we invite onto our platform. Our members come from top-tier universities — schools like Harvard, Yale, and Princeton — and are building ambitious ventures or heading to some of the world's most competitive firms, including Blackstone, Citadel, McKinsey, and SpaceX. 

Feel free to read more about us in Business Insider and on our website.

This is an invitation to <b>interview with us</b> for <b>access to the Prospinity platform</b>. The interview serves two purposes:

  A) To help us understand whether you're a good fit for the platform.

  B) To give you a chance to decide if this is something you're genuinely interested in.

Joining the platform does not mean joining a Success Pool, but it gives you the opportunity to connect with similarly talented students and receive invitations to the Pools we're building — both existing and new.

<b>This invitation will expire soon.</b> If you're interested, please book your interview below before your campus rep offers the spot to someone else.

Any questions can be sent to applications@prospinity.com.

Hope to speak with you soon.

Best,  
The Prospinity Team`;

const paragraphs = rawText.split('\n\n');
const [typedParagraphs, setTypedParagraphs] = useState([]);
const [currentParagraphIndex, setCurrentParagraphIndex] = useState(0);
const [currentCharIndex, setCurrentCharIndex] = useState(0);
const scrollRef = useRef(null);
const audioRef = useRef(null);

const colors = ['#111827', '#0e1a2b', '#101f30', '#121d33', '#111827'];

  useEffect(() => {
    let typingTimeout;

    let paragraph = paragraphs[currentParagraphIndex];

    const typeNextChar = () => {
      if (currentCharIndex < paragraph.length) {
        typingTimeout = setTimeout(() => {
          const updated = [...typedParagraphs];
          const current = updated[currentParagraphIndex] || '';
          updated[currentParagraphIndex] = current + paragraph[currentCharIndex];
          setTypedParagraphs(updated);
          setCurrentCharIndex((prev) => prev + 1);

          if (audioRef.current && currentCharIndex === 0) {
            audioRef.current.volume = 1;
            audioRef.current.playbackRate = 1.0;
            audioRef.current.loop = true;
            audioRef.current.play().catch(() => {});
          }
        }, 16);
      } else {
        if (audioRef.current) {
          audioRef.current.pause();
        }

        const pauseDuration = paragraph.length * 5;
        setTimeout(() => {
          scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
          setCurrentParagraphIndex((prev) => prev + 1);
          setCurrentCharIndex(0);
        }, pauseDuration);
      }
    };

    if (currentParagraphIndex < paragraphs.length) {
      typeNextChar();
    }

    return () => clearTimeout(typingTimeout);
  }, [currentCharIndex, currentParagraphIndex]);

  useEffect(() => {
    // Check initial expiration
    const initialTimeLeft = calculateTimeLeft();
    if (initialTimeLeft <= 0) {
      setIsExpired(true);
      sendTrackingData("expired invitation");
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(Math.max(newTimeLeft, 0));
      if (newTimeLeft <= 0 && !isExpired) {
        setIsExpired(true);
        sendTrackingData("expired invitation");
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isExpired]);

  const formatTime = (secs) => {
    const hours = String(Math.floor(secs / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((secs % 3600) / 60)).padStart(2, '0');
    const seconds = String(secs % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div style={{ ...styles.container, backgroundColor: colors[currentParagraphIndex % colors.length] }}>
      <audio ref={audioRef} src={typewriterSound} preload="auto" />
      <div style={styles.inner}>
        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          style={styles.textBox}
        >
          <img src={logo} alt="Prospinity Logo" style={styles.logo} />
          <h2 style={{...styles.heading, fontSize: '14px', margin: '8px 0', opacity: 0.9}}>{name}</h2>
          <p style={styles.timer}>
            {isExpired ? (
              <span style={{...styles.timerValue, color: '#ff4e4e'}}>Expired</span>
            ) : (
              <>Expires in: <span style={styles.timerValue}>{formatTime(timeLeft)}</span></>
            )}
          </p>
          <h1 style={styles.heading}>
            {isExpired ? 'Invitation Expired' : 'Invite to Prospinity'}
          </h1>

          {isExpired ? (
            <motion.p
              style={styles.text}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              This invitation has expired. If you believe this is an error, please contact applications@prospinity.com.
            </motion.p>
          ) : (
            <>
              {typedParagraphs.map((typed, idx) => {
                const isDone = idx < currentParagraphIndex;
                const raw = isDone
                  ? replaceLinks(paragraphs[idx])
                  : typed + '<span class="blinking-cursor">|</span>';
                return (
                  <motion.p
                    key={idx}
                    style={styles.text}
                    initial={{ opacity: 0, scale: 0.98 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8, ease: 'easeOut' }}
                    dangerouslySetInnerHTML={{ __html: raw }}
                  />
                );
              })}

              {currentParagraphIndex === paragraphs.length && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 }}
                  style={styles.buttonWrapper}
                >
                  <motion.button
                    style={styles.calendlyButton}
                    onClick={() => {
                      window.Calendly.initPopupWidget({
                        url: 'https://calendly.com/d/cqzk-hdp-xmc/prospinity-membership-interview-invitation-only',
                      });
                      sendTrackingData("interview button");
                    }}
                    whileHover={{ 
                      scale: 1.02,
                      backgroundColor: 'rgba(255, 255, 255, 0.15)',
                      boxShadow: '0 0 30px rgba(255, 255, 255, 0.2)',
                    }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Book Interview (Expires in {formatTime(timeLeft)})
                  </motion.button>
                </motion.div>
              )}
            </>
          )}
          <div ref={scrollRef} />
        </motion.div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    color: '#fff',
    minHeight: '100vh',
    padding: '40px 20px',
    fontFamily: `'Space Grotesk', sans-serif`,
    display: 'flex',
    justifyContent: 'center',
    transition: 'background-color 0.5s ease',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '800px',
  },
  textBox: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    padding: '40px',
    borderRadius: '16px',
    boxShadow: '0 0 20px rgba(78, 166, 255, 0.1)',
    marginBottom: '40px',
    marginTop: '60px',
    scrollMarginTop: '60px',
  },
  logo: {
    width: '120px',
    marginBottom: '10px',
  },
  heading: {
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '10px',
  },
  timer: {
    fontSize: '14px',
    color: '#aaa',
    marginBottom: '20px',
  },
  timerValue: {
    color: '#4ea6ff',
    fontWeight: 'bold',
  },
  text: {
    fontSize: '16px',
    lineHeight: '1.7',
    whiteSpace: 'pre-wrap',
    marginBottom: '16px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    width: '100%',
  },
  calendlyButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: '#ffffff',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: '10px',
    padding: '14px 28px',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    backdropFilter: 'blur(10px)',
    boxShadow: '0 0 20px rgba(255, 255, 255, 0.1)',
    width: '100%',
    ':hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
      boxShadow: '0 0 30px rgba(255, 255, 255, 0.2)',
      transform: 'translateY(-2px)',
    }
  },
};

// Cursor + link styles
const styleTag = document.createElement('style');
styleTag.innerHTML = `
  .blinking-cursor {
    font-weight: 100;
    font-size: 18px;
    color: #4ea6ff;
    animation: blink 1s step-start infinite;
  }
  @keyframes blink {
    50% { opacity: 0; }
  }

  .custom-link {
    text-decoration: underline;
    color: #4ea6ff;
    cursor: pointer;
  }
`;
document.head.appendChild(styleTag);

export default InviteTypeWriter;