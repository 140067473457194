import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence } from "framer-motion";
import QuotePage, {Transition} from "./QuotePage";
import ApplicationQuestions from './ApplicationQuestions';
import FinishPage from './FinishPage';

export default function Application() {
  const [currentPage, setCurrentPage] = React.useState("Application");
  const [userEmail, setUserEmail] = React.useState("");
  
  const navigateToApplicationQuestions = () => setCurrentPage(() => "Application");
  const navigateToFinish = (email) => {
    setUserEmail(email); // Store the email when navigating to Finish
    setCurrentPage(() => "FinishPage");
  };

  // Set the background
    useEffect(() => {
        // Set background color for the body when the SurveyPage is rendered
        document.body.style.backgroundColor = "#000000"; // Your desired background color

        // Cleanup function to reset the background color
        return () => {
            document.body.style.backgroundColor = ""; // Reset to default
        };
    }, []);

  return (
    <>
      <AnimatePresence mode="wait">  
        {currentPage === "Application" && (
          <ApplicationQuestions
            key="Application"
            navigateToFinish={navigateToFinish}
          />
        )}
        {currentPage === "FinishPage" && (
          <FinishPage
            key="FinishPage"
            email={userEmail}
          />
        )}
      </AnimatePresence>
    </>
  );
}

