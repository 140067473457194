import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie";
import { motion, AnimatePresence } from "framer-motion";

import { SimpleTextPage } from "./SurveyScreens";
import { SurveyOptionContainer, SurveyOptionButton } from "./SurveyComponents";
import { useApplication } from "../../contexts/ApplicationContext";


export default function ScreenThree() {
  const [isTypingDone, setIsTypingDone] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const navigate = useNavigate();
  const typingSpeed = 85;
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  const { formData, updateFormData } = useApplication();


  useEffect(() => {
    if (!formData.uniqueId) {
      const uniqueId = uuidv4();
      sessionStorage.setItem("unique_id", uniqueId);

      // Use sendBeacon if supported for better reliability
      const data = new FormData();
      data.append("unique_id", uniqueId);
      const BASE_URL = process.env.REACT_APP_API_URL
      navigator.sendBeacon(`${BASE_URL}/api/track-application/`, data);
    
    }
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "black";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const handleTypingDone = () => {
    setIsTypingDone(true);
  };

  const handleRead = () => {
    setIsFadingOut(true);

    // Track
    let uniqueId = formData.uniqueId;

    if (!uniqueId) {
      uniqueId = uuidv4();
      sessionStorage.setItem("unique_id", uniqueId);
    }

    // Use sendBeacon if supported for better reliability
    const data = new FormData();
    data.append("unique_id", uniqueId);
    data.append("screen3", "Read");
    const BASE_URL = process.env.REACT_APP_API_URL
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, data);

    setTimeout(() => navigate("/berkeley/application/4"), 600); // Wait for animation before navigating
  };

  const handleWatch = () => {
    setIsFadingOut(true);

    // Track
    let uniqueId = formData.uniqueId;

    if (!uniqueId) {
      uniqueId = uuidv4();
      sessionStorage.setItem("unique_id", uniqueId);
    }

    // Use sendBeacon if supported for better reliability
    const data = new FormData();
    data.append("unique_id", uniqueId);
    data.append("screen3", "Video");
    const BASE_URL = process.env.REACT_APP_API_URL
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, data);

    setTimeout(() => navigate("/berkeley/application/video"), 600); // Wait for animation before navigating
  };

  const firstPageText = [
    {
      sequences: [
        { content: 700, wrapper: "span", style: {} },
        { content: "Perfect. ", wrapper: "span", style: {} },
        { content: 700, wrapper: "span", style: {} },
        { content: "We’re Prospinity, a startup launching on campus, and we’re about to introduce you to something you’ve never heard of - ", wrapper: "span", style: {} },
        { content: "Success Pools.", wrapper: "strong", style: {} },
      ],
    },
    {
      sequences: [
        { content: 700, wrapper: "span", style: {} },
        { content: "Last year, the first investors in Robinhood, Venmo, and Sam Altman invested $2M into it.", wrapper: "span", style: {} },
      ],
    },
    {
      sequences: [
        { content: 700, wrapper: "span", style: {} },
        { content: "Do you prefer to read, or watch a video? Both take two minutes.", wrapper: "span", style: {} },
        { content: 700, wrapper: "span", style: {} },
      ],
    },
  ];
  
  return (
    <AnimatePresence>
      {!isFadingOut && (
        <motion.div
          key="screen-one"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }} // Smooth fade-out
          transition={{ duration: 0.6, ease: "easeOut" }} // Smooth easing
        >
          {/* Text content */}
          <SimpleTextPage
            paragraphs={firstPageText}
            typing={true}
            typingSpeed={typingSpeed}
            onTypingDone={handleTypingDone} // Ensures we track typing completion
          />
  
          {/* Buttons (only appear after typing is done) */}
          {isTypingDone && (
            <motion.div
              key="buttons"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 0 }} // Ensure buttons fade with text
              transition={{ duration: 0.6 }}
              style={{ width: "100%" }}
            >
              <SurveyOptionContainer isMdOrLarger={isMdOrLarger}>
                <SurveyOptionButton
                  key="VIDEO"
                  onClick={handleWatch}
                  isMdOrLarger={isMdOrLarger}
                >
                  {"VIDEO"}
                </SurveyOptionButton>
                <SurveyOptionButton
                  key="READ"
                  onClick={handleRead}
                  isMdOrLarger={isMdOrLarger}
                >
                  {"READ"}
                </SurveyOptionButton>
              </SurveyOptionContainer>
            </motion.div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
 }  
