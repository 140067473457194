import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "styled-components";
import { useMediaQuery } from 'react-responsive';

import { myAxiosInstance } from "../../api/axiosConfig";
import { SearchIcon, ArrowDown } from "../../assets/icons/Icons";


export default function SearchUsers() {
    const [inputValue, setInputValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const searchRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);

    // Some responsiveness
    const isSmallDesktop = useMediaQuery({ maxWidth: 900 });

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
        if (inputValue.trim()) {
            fetchOptions(inputValue).then((data) => {
            setSuggestions(data);
            setIsOpen(true);
            });
        } else {
            setSuggestions([]);
            setIsOpen(false);
        }
        }, 150); // Debouncing to limit API calls

        return () => clearTimeout(debounceTimer);
    }, [inputValue]);

    useEffect(() => {
        const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setIsOpen(false);
        }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const fetchOptions = async (query) => {
        try {
        const response = await myAxiosInstance.get(`/api/search-users/?q=${query}&limit=20`);
        return response.data.users;
        } catch (error) {
        return [];
        }
    };

    const handleSelect = (userSlug) => {
        navigate(`user/${userSlug}`);
        setIsOpen(false);
        setInputValue("");
    };

    return (
        <SearchContainer ref={searchRef}>
            <SearchBar theme={theme} isActive={isFocused}>
                <SearchIcon theme={theme} />
                <SearchInput
                    type="text"
                    placeholder="Search members"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onFocus={() => {
                        setIsFocused(true);
                        if (!isOpen) setIsOpen(true);
                    }}
                    onBlur={() => setIsFocused(false)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && inputValue.trim()) {
                            // setIsOpen(false);
                            // setIsFocused(false);
                            // navigate(`search?q=${inputValue}`);
                        }
                    }}
                    theme={theme}
                />
            </SearchBar>

            {isOpen && suggestions.length > 0 && (
                <SearchResults theme={theme}>
                    {suggestions.slice(0, 3).map((user) => (
                        <ResultItem key={user.id} onClick={() => handleSelect(user.slug)} theme={theme}>
                            <ProfileImage 
                                src={user.profile_picture?.trim() !== "" ? user.profile_picture : "https://storage.googleapis.com/prosp_profile_img/favicons/favicon.svg"} 
                                alt={user.name} 
                                onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop
                                    e.target.src = "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW_20250320180317.webp";
                                  }} 
                                />
                            <UserInfo>
                                <UserName theme={theme}>{user.first_name} {user.last_name}</UserName>
                                <UserDetails theme={theme}>
                                    {user.university} | {user.company ? user.company : `Exp. ${user.graduation_date} graduate`}
                                </UserDetails>

                                <DegreeContainer>
                                    {user.major_or_grad_program.slice(0, isSmallDesktop ? 1 : 2).map((degree, index) => (
                                        <DegreeTag key={index} theme={theme}>
                                            {degree}
                                        </DegreeTag>
                                    ))}
                                </DegreeContainer>
                            </UserInfo>
                        </ResultItem>
                    ))}
                    {/* <SeeAll theme={theme} onClick={() => { setIsOpen(false); navigate(`search?q=${inputValue}`) }}>
                        See All Results <span style={{ marginLeft: "0.3rem" }}><ArrowDown fill={theme.textSecondary} fillOpacity={0.8} stroke="0" /></span>
                    </SeeAll> */}
                </SearchResults>
            )}
        </SearchContainer>
    );
}


const SearchContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 650px;
    margin-right: 1rem;
`;

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 1.3rem;
    border-radius: 80px;
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme, isActive }) => (isActive ? theme.primary : "transparent")};
`;

const SearchInput = styled.input`
    flex: 1;
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 400;
    font-weight: 400;
    color: ${({ theme }) => theme.textSecondary};
    background-color: transparent;
    margin-left: 0.8rem;
`;

const SearchResults = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 52px;
    width: min(100%, 520px);
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.divider};
    border-radius: 1.5rem;
    padding: 1rem 0rem 1rem 0rem;
    z-index: 1000;
    /* gap: 1.125rem; */

    & > * {
        margin-bottom: 1.125rem;
    }

    & > *:nth-last-child(2) {
        margin-bottom: 0.6rem;
    }

    & > *:nth-last-child(1) {
        margin-bottom: 0;
    }
`;

const ResultItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    cursor: pointer;
    gap: 21px;
    height: 5rem;
    padding: 0 1rem;

    &:hover {
        background: ${({ theme }) => theme.backgroundLight};
    }
`;

const ProfileImage = styled.img`
    height: 5rem;
    width: 5rem;
    aspect-ratio: 1;
    border-radius: 1rem;
    object-fit: cover;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
`;

const UserName = styled.span`
    font-size: 1.25rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
    flex: 1; /* Ensures it takes up available space */
    min-width: 0; /* Allows it to shrink within flex container */
    white-space: nowrap; /* Prevents wrapping */
    overflow: hidden; /* Ensures text does not overflow */
    text-overflow: ellipsis; /* Adds '...' when text is too long */
`;

const UserDetails = styled.span`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.textSecondary};
    flex: 1; /* Ensures it takes up available space */
    min-width: 0; /* Allows it to shrink within flex container */
    white-space: nowrap; /* Prevents wrapping */
    overflow: hidden; /* Ensures text does not overflow */
    text-overflow: ellipsis; /* Adds '...' when text is too long */
`;

const DegreeContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 0.5rem;
    margin-top: 0.35rem;
`;

const DegreeTag = styled.span`
    font-size: 0.75rem;
    font-weight: 400;
    color: ${({ theme }) => theme.primary};
    background: ${({ theme }) => theme.primaryLight};
    padding: 0.1rem 0.625rem;
    border-radius: 2.75rem;
    display: inline-block;

    max-width: 30ch; /* Limits to 30 characters */
    white-space: nowrap; /* Prevents wrapping */
    overflow: hidden; /* Ensures text does not overflow */
    text-overflow: ellipsis; /* Adds '...' when text is too long */

    @media (max-width: 1024px) {
        max-width: 15ch; /* Move '...' closer as screen gets smaller */
    }

`;

const SeeAll = styled.div`
    padding: 1rem 1rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.textSecondary};
    cursor: pointer;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;

    &:hover {
        background: ${({ theme }) => theme.backgroundLight};
    }
`;



// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import { stringToColor } from '../common/CriticalFunctions';
// import { formatGraduationDate } from '../common/CriticalFunctions';
// import { useAuth } from '../../contexts/AuthContext';
// import { useNavigate } from 'react-router-dom';
// import { StyledDropdown, StyledForm, StyledFormGroup, StyledFormControl } from '../common/StyledBootstrap';
// import { useTheme } from 'styled-components';
// import { myAxiosInstance } from '../../api/axiosConfig';
// import { ProfilePicture } from '../common/MiscComponents';

// const bubbleStyle = (color) => ({
//     display: 'flex',
//     alignItems: 'center',
//     padding: '5px', // Adjust padding for less height
//     margin: '0px',
//     borderRadius: '0px',
//     color: 'black',
//     fontSize: '0.8rem',
//     cursor: 'pointer',
//     wordWrap: 'break-word',
//     whiteSpace: 'normal',
//     width: '100%'
// });

// const profilePictureStyle = {
//     width: '40px', // Adjust width for less height
//     height: '40px', // Adjust height for less height
//     borderRadius: '50%',
//     marginRight: '10px'
// };

// const customDropdownItemStyle = {
//     width: '100%',
//     backgroundColor: 'white',
//     border: 'none',
//     display: 'block',
//     padding: '5px', // Adjust padding for less height
//     cursor: 'pointer'
// };

// const customDropdownItemHoverStyle = {
//     backgroundColor: '#e0e0e0'
// };

// const handleMouseEnter = (e) => {
//     e.currentTarget.style.backgroundColor = customDropdownItemHoverStyle.backgroundColor;
// };

// const handleMouseLeave = (e) => {
//     e.currentTarget.style.backgroundColor = customDropdownItemStyle.backgroundColor;
// };

// function SearchUsers() {
//     const [inputValue, setInputValue] = useState('');
//     const [suggestions, setSuggestions] = useState([]);
//     const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
//     const [showDropdown, setShowDropdown] = useState(false);
//     const wrapperRef = useRef(null);

//     const navigate = useNavigate();
//     const { userSlug } = useAuth();
//     const themeMode = useTheme();

//     const fetchOptions = async (query) => {
//         try {
//             const response = await myAxiosInstance.get(`/api/search-users/?q=${query}&limit=20`);
//             return response.data.users;
//         } catch (error) {
//             return [];
//         }
//     };

//     useEffect(() => {
//         function handleClickOutside(event) {
//             if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
//                 setShowDropdown(false);
//                 setInputValue('');
//                 setActiveSuggestionIndex(0);
//             }
//         }
//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, [wrapperRef]);

//     useEffect(() => {
//         const debounceTimer = setTimeout(() => {
//             if (inputValue) {
//                 fetchOptions(inputValue).then(data => {
//                     setSuggestions(data);
//                 });
//             } else {
//                 setSuggestions([]);
//             }
//         }, 100);

//         return () => {
//             clearTimeout(debounceTimer);
//         };
//     }, [inputValue]);

//     const handleInputChange = (event) => {
//         setInputValue(event.target.value);
//         setShowDropdown(true);
//         setActiveSuggestionIndex(0);
//     };

//     const handleSelect = (item) => {
//         setInputValue('');
//         setActiveSuggestionIndex(0);
//         setShowDropdown(false);
//         openUser(item.slug);
//     };

//     const handleKeyDown = (event) => {
//         if (event.key === 'Enter' && suggestions.length > 0) {
//             event.preventDefault();
//             handleSelect(suggestions[activeSuggestionIndex]);
//         } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
//             setActiveSuggestionIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
//         } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
//             setActiveSuggestionIndex((prevIndex) => (prevIndex + suggestions.length - 1) % suggestions.length);
//         }
//     };

//     const openUser = (slug) => {
//         navigate('/dashboard/user/' + slug + '/');
//     };

//     return (
//         <>
//             <StyledForm className='mt-3 search-joyrride w-100'>
//                 <StyledFormGroup ref={wrapperRef} className='d-flex flex-column'>
//                     <StyledFormControl
//                         type="text"
//                         value={inputValue}
//                         placeholder={"Search Users"}
//                         onChange={handleInputChange}
//                         onKeyDown={handleKeyDown}
//                         style={{ minWidth: '320px' }} // Adjust the width here
//                         className='search-users-small'
//                         noShadow
//                     />
//                     <StyledDropdown show={showDropdown && suggestions.length > 0}>
//                         <StyledDropdown.Menu align={'start'} style={{
//                             position: 'absolute',
//                             left: 0,
//                             right: 0,
//                             top: '100%',
//                             width: '100%', // Ensure dropdown menu spans 120% width of the search box
//                             maxHeight: '400px', // Set max height for scrolling
//                             overflowY: 'auto' // Enable vertical scrolling
//                         }}>
//                             {suggestions.map((item, index) => (
//                                 <StyledDropdown.Item
//                                     key={index}
//                                     active={index === activeSuggestionIndex}
//                                     onClick={() => handleSelect(item)}
//                                     style={{
//                                         ...customDropdownItemStyle,
//                                         ...(index === activeSuggestionIndex ? customDropdownItemHoverStyle : {})
//                                     }}
//                                     className={`${index === activeSuggestionIndex ? 'active-suggestion' : ''}`}
//                                     onMouseEnter={handleMouseEnter}
//                                     onMouseLeave={handleMouseLeave}
//                                 >
//                                     <div style={{
//                                         ...bubbleStyle(stringToColor(item.first_name + " " + item.last_name))
//                                     }}>
//                                         {/*<img src={item.profile_picture} alt="Profile" style={profilePictureStyle} />*/}
//                                         <img
//                                             src={item.profile_picture ? item.profile_picture : 'https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp'}
//                                             alt={"Preview"}
//                                             onError={(e) => { e.target.src = 'https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp'; }}
//                                             style={profilePictureStyle}
//                                         />
//                                         <span>
//                                             {item.first_name + " " + item.last_name}
//                                             {` '${formatGraduationDate(item.graduation_date)}, ${item.university}, ${item.major_or_grad_program.map(program => program).join(", ")}`}
//                                             {item.company ? `, ${item.company}` : ""}
//                                         </span>
                                        
//                                     </div>
//                                 </StyledDropdown.Item>
//                             ))}
//                         </StyledDropdown.Menu>
//                     </StyledDropdown>
//                 </StyledFormGroup>
//             </StyledForm>
//         </>
//     );
// }

// export default SearchUsers;
