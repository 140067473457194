import React, { useEffect, useState, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { useErrorBoundary } from "react-error-boundary";
import { useMediaQuery } from 'react-responsive';

import PortfolioPreviewCard from './UserPortfolioCard';


const UserPortfolio = ({ users, onRemove, customTitle="YOUR PORTFOLIO", showCount=true }) => {
    const isMdOrLarger = useMediaQuery({ minWidth: 1024 });
  
    const theme = useTheme();
  
    return (
        <SectionWrapper isEmpty={users.length === 0} style={{ marginTop: "2rem" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                <SectionHeader theme={theme}>{customTitle}</SectionHeader>
                {showCount && <ConnectionCount theme={theme} style={{ color: theme.textSecondary }}>{users.length}</ConnectionCount>}
            </div>
    
            <PortfolioGrid>
                {users.map((user, index) => (
                    <PortfolioPreviewCard key={index} user={user} onRemove={onRemove} />
                ))}
            </PortfolioGrid>
        </SectionWrapper>
    );
};
export default UserPortfolio;

// Main Wrapper
const SectionWrapper = styled.div`
    background: ${({ theme }) => theme.white};
    border-radius: 1.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ isEmpty }) => isEmpty ? "0" : "2rem"};
    width: calc(100vw - 260px - 5rem);

    @media (max-width: 768px) {
        padding: 1.5rem 1.25rem;
        width: 100%;
        gap: ${({ isEmpty }) => isEmpty ? "0" : "1.25rem"};
    }
`;

// Title
const SectionHeader = styled.span`
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textSecondary};
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Space between text and count */

        @media (max-width: 768px) {
        font-size: 0.875rem;
    }
`;


// Circular Badge for Count
const ConnectionCount = styled.span`
  background: ${({ theme }) => theme.backgroundDarker};
  color: ${({ theme }) => theme.textSecondary};
  font-size: 1rem;
  font-weight: 500;
  padding: 0.3rem;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  @media (max-width: 768px) {
    font-size: 0.875rem;
    padding: 0.25rem;
  }
`;


// Grid layout for portfolio cards
const PortfolioGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(200px, 1fr)); /* 4 cards per row initially */
    gap: 1rem;
    width: 100%;
    justify-content: center;

    @media (max-width: 1280px) {
        grid-template-columns: repeat(3, minmax(200px, 1fr)); /* 3 cards per row */
    }

    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, minmax(200px, 1fr)); /* 2 per row */
    }

    @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr); /* Full width */
        place-items: center; 
    }
`;
