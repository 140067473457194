import { useState, useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";
import { ChevronDown, ChevronUp, X } from "react-bootstrap-icons";

import { ErrorText } from "./MiscComponents";
import { myAxiosInstance } from "../../api/axiosConfig";

export default function SingleSelect({ label, endpoint, value, onChange, placeholder, error }) {
    const [inputValue, setInputValue] = useState(value || "");
    const [isOpen, setIsOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const dropdownRef = useRef(null);
    const theme = useTheme();

    // Ensure inputValue updates when the prop value changes
    useEffect(() => {
        setInputValue(value || ""); // Update inputValue when value prop changes
    }, [value]);

    // Fetch suggestions from API with debounce
    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            fetchOptions(inputValue);
        }, 200); // Debounce delay

        return () => clearTimeout(debounceTimer);
    }, [inputValue]);

    // API call to fetch options
    const fetchOptions = async (query) => {
        try {
            const response = await myAxiosInstance.get(`/api/${endpoint}/?q=${query}`);
            setSuggestions(response.data); // Store objects directly, not just names
        } catch (error) {
            setSuggestions([]);
        }
    };

    // Handle selection
    const handleSelect = (item) => {
        if (!item || !item.name) return;
        setInputValue(item.name);
        onChange(item.name);
        setSuggestions([]);
        setIsOpen(false);
    };

    // Handle clearing input
    const handleClear = () => {
        setInputValue("");
        onChange("");
    };

    // Handle dropdown open/close logic
    const handleClick = () => {
        fetchOptions(inputValue);
        if (!isOpen) setIsOpen(true); // Rule 1: Open when clicking if closed
    };

    // Close dropdown if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <Container ref={dropdownRef}>
            <Wrapper theme={theme} isOpen={isOpen} onClick={handleClick}>
                <StyledInput
                    value={inputValue}
                    onChange={(e) => {setInputValue(e.target.value); onChange(e.target.value)}}
                    placeholder={placeholder || ""}
                    theme={theme}
                />

                {inputValue && <ClearIcon onClick={handleClear}><X /></ClearIcon>}

                <DropdownIcon onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click
                    setIsOpen((prev) => !prev); // Toggle dropdown
                }}>
                    {isOpen ? <ChevronUp /> : <ChevronDown />}
                </DropdownIcon>

                <FloatingLabel theme={theme}>{label}</FloatingLabel>
            </Wrapper>

            {isOpen && suggestions.length > 0 && (
                <Dropdown theme={theme}>
                    {suggestions.map((item, index) => (
                        <DropdownItem key={index} theme={theme} onClick={() => handleSelect(item)}>
                            {item.name}
                        </DropdownItem>
                    ))}
                </Dropdown>
            )}

            {error && <ErrorText>{error}</ErrorText>}

        </Container>
    );
}


function SingleSelectFixedOptions({ label, options, value, onChange, placeholder, error }) {
    const [inputValue, setInputValue] = useState(value || "");
    const [filteredOptions, setFilteredOptions] = useState(options); // Stores filtered options
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const theme = useTheme();


    // Handle input change & filter options dynamically
    const handleInputChange = (e) => {
        const query = e.target.value.toLowerCase();
        setInputValue(e.target.value);
        onChange(e.target.value);

        if (!query) {
            setFilteredOptions(options); // Reset if empty
        } else {
            setFilteredOptions(options.filter(item =>
                item.toLowerCase().includes(query) // Case-insensitive search
            ));
        }
    };

    // Handle selection
    const handleSelect = (item) => {
        if (!item) return;
        setInputValue(item);
        onChange(item);
        setIsOpen(false);
    };

    // Handle clearing input
    const handleClear = () => {
        setInputValue("");
        setFilteredOptions(options); // Reset options
        onChange("");
    };

    // Handle dropdown open/close logic
    const handleClick = () => {
        if (!isOpen) setIsOpen(true); // Rule 1: Open when clicking if closed
    };

    // Ensure inputValue updates when the prop value changes
    useEffect(() => {
        setInputValue(value || ""); // Update inputValue when value prop changes
    }, [value]);

    // Close dropdown if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <Container ref={dropdownRef}>
            <Wrapper theme={theme} isOpen={isOpen} onClick={handleClick}>
                <StyledInput
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={placeholder || ""}
                    theme={theme}
                />

                {inputValue && <ClearIcon onClick={handleClear}><X /></ClearIcon>}

                <DropdownIcon onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click
                    setIsOpen((prev) => !prev); // Toggle dropdown
                }}>
                    {isOpen ? <ChevronUp /> : <ChevronDown />}
                </DropdownIcon>

                <FloatingLabel theme={theme}>{label}</FloatingLabel>
            </Wrapper>

            {isOpen && options.length > 0 && (
                <Dropdown theme={theme}>
                    {filteredOptions.length > 0 && filteredOptions.map((item, index) => (
                        <DropdownItem key={index} theme={theme} onClick={() => handleSelect(item)}>
                            {item}
                        </DropdownItem>
                    ))}
                </Dropdown>
            )}

            {error && <ErrorText>{error}</ErrorText>}

        </Container>
    );
}

export { SingleSelectFixedOptions };


// Styled Components
const Container = styled.div`
    width: 100%;
    position: relative;
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 1rem;
    background: ${({ theme }) => theme.white};
    display: flex;
    align-items: center;
    padding: 0.8rem 1.5rem;
    cursor: pointer;
`;

const StyledInput = styled.input`
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    color: ${({ theme }) => theme.textPrimary};
    font-size: 1rem;
`;

const FloatingLabel = styled.label`
    position: absolute;
    top: -0.6rem;
    left: 1.5rem;
    background: ${({ theme }) => theme.white};
    padding: 0 0.3rem;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.textSecondary};
`;

const ClearIcon = styled.div`
    cursor: pointer;
    margin-right: 0.5rem;
`;

const DropdownIcon = styled.div`
    cursor: pointer;
    font-size: 1.125rem;
`;

const Dropdown = styled.div`
    position: absolute;
    top: 110%;
    width: 99%;
    left: 2px;
    background: ${({ theme }) => theme.white};
    border-radius: 1rem;
    max-height: 210px;
    overflow-y: auto;
    z-index: 10;
    padding: 0 0.8rem;
    box-shadow: 0px 2px 10px rgba(29, 29, 38, 0.1);
`;

const DropdownItem = styled.div`
    width: 100%; /* Ensures full width */
    padding: 0.8rem 1rem; /* Add horizontal padding */
    cursor: pointer;
    font-size: 0.875rem;
    border-bottom: 1px solid ${({ theme }) => theme.divider};
    color: ${({ theme }) => theme.textPrimary};

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background: ${({ theme }) => theme.backgroundLight};
        border-radius: 0.5rem; /* Slight rounding for a smoother effect */
    }
`;



//maybe put in the form control itself
//better grid navigation
//border selected elements with something

// import React, { useState, useEffect } from 'react';
// import { FormControl, Form } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import { stringToColor } from '../common/CriticalFunctions';
// import { useTheme } from 'styled-components';
// import { StyledFormLabel, StyledFormControl, StyledFormGroup } from '../common/StyledBootstrap';
// import { myAxiosInstance } from '../../api/axiosConfig';

// const bubbleStyle = (color) => ({
//   display: 'inline-block',
//   padding: '5px 10px',
//   margin: '5px',
//   borderRadius: '15px',
//   backgroundColor: color,
//   color: 'black',
//   fontSize: '0.8rem',
// });

// const closeButtonStyle = {
//   marginLeft: '0.3rem',
//   cursor: 'pointer'
// };

// const suggestionListStyle = {
//   listStyleType: 'none',
//   padding: 0,
//   margin: 0,
//   display: 'flex',
//   flexWrap: 'wrap'
// };






// function SingleSelect({ endpoint, value, onChange, label, extra="" }) {
//   const [inputValue, setInputValue] = useState('');
//   const [suggestions, setSuggestions] = useState([]);
//   const [selectedItems, setSelectedItems] = useState(
//     value[0]==="" ? [] : value.map(item => ({ name: item, color: stringToColor(item) }))
//   );
//   const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
//   const fetchOptions = async (query) => {
//     try {
//       const response = await myAxiosInstance.get('/api/' + endpoint + '/' + '?q=' + query);
//       return response.data;
//       /*return endpoint.filter(item =>
//         item.label.toLowerCase().startsWith(query.toLowerCase())

//       );*/


//     } catch (error) {
//       return [];
//     }
//   };

//   const themeMode = useTheme();


//   useEffect(() => {
//     const debounceTimer = setTimeout(() => {
//       if (inputValue) {

//         fetchOptions(inputValue).then(data => {
//             const filteredData = data.filter(item =>
//             !selectedItems.some(selectedItem => selectedItem.name === item.name)
//           );

//           setSuggestions(filteredData);
//         });
//       } else {
//         setSuggestions([]);
//       }
//     }, 100); // 300ms debounce time

//     return () => {
//       clearTimeout(debounceTimer);
//     };
//   }, [inputValue]);



//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//     setActiveSuggestionIndex(0);
//   };

//   const handleSelect = (item) => {
//     if (!selectedItems.some(selectedItem => selectedItem.name === item.name)) {
//       const updatedItems = [{ ...item, color: stringToColor(item.name) }];
//       setSelectedItems(updatedItems);
//       setInputValue('');
//       setActiveSuggestionIndex(0);
//       onChange(updatedItems.map(item => item.name));
//     }

//     /*const updatedItems = [...selectedItems, { ...item, color: stringToColor(item.label) }];
//     setSelectedItems(updatedItems);
//     setInputValue('');
//     //setSuggestions([]);
//     setActiveSuggestionIndex(0);
//     onChange(updatedItems.map(item => item.label)); // Call the onChange prop with new value*/
//   };


//   const handleRemoveItem = (name) => {
//     const updatedItems = selectedItems.filter(item => item.name !== name);
//     setSelectedItems(updatedItems);
//     onChange(updatedItems.map(item => item.name)); // Update the parent component
//   };

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter') {
//       event.preventDefault();
//       if (suggestions.length > 0)
//       {
//         handleSelect(suggestions[activeSuggestionIndex]);
//       }
//     } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
//       setActiveSuggestionIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
//     } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
//       setActiveSuggestionIndex((prevIndex) => (prevIndex + suggestions.length - 1) % suggestions.length);
//     }
//   };

//   return (
//     <StyledFormGroup>
//       <StyledFormLabel>{label}{extra}</StyledFormLabel>
//       <div>

//         <StyledFormControl
//           type="text"
//           value={inputValue}
//           placeholder={"Search " + label}
//           onChange={handleInputChange}
//           onKeyDown={handleKeyDown}
//         />
//         {suggestions.length > 0 && (
//           <ul style={suggestionListStyle}>
//             {suggestions.map((item, index) => (
//               <li key={index}
//                 style={{
//                   display: 'flex', // Using flex to center the content
//                   justifyContent: 'flex-start',
//                   alignItems: 'center',
//                   marginBottom: '5px',
//                 }}
//                 onClick={() => handleSelect(item)}
//               >
//                 <span
//                   style={{
//                     ...bubbleStyle(stringToColor(item.name)),
//                     cursor: 'pointer',
//                     border: index === activeSuggestionIndex ? `2px solid ${themeMode.onSurface}` : 'none',
//                     boxShadow: index === activeSuggestionIndex ? '0px 0px 10px rgba(0, 0, 0, 0.2)' : 'none',
//                   }}
//                 >
//                   {item.name}
//                 </span>
//               </li>
//             ))}
//           </ul>


//         )}
//         <div>
//           {selectedItems.map((item, index) => (
//             <span key={index} style={bubbleStyle(item.color)}>
//               {item.name}
//               <span
//                 style={closeButtonStyle}
//                 onClick={() => handleRemoveItem(item.name)}
//               >
//                 <FontAwesomeIcon icon={faTimes} />
//               </span>
//             </span>
//           ))}
//         </div>

//       </div>
//     </StyledFormGroup>
//   );
// }

// export default SingleSelect;