import { useTheme } from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// From my app
import { RegistrationContainer } from "../../components/common/Containers";
import FormInput from "../../components/forms/FormInput";
import { Form } from "../../components/forms/Form";
import { PrimaryButton } from "../../components/common/Buttons";
import { RegistrationHeader, Subtitle, PromptWrapper, Prompt, MobileBottomSection } from "../../components/forms/MiscComponents";
import { myAxiosInstance } from "../../api/axiosConfig";
import { useAuth } from "../../contexts/AuthContext";

function SignUp() {

    const [step, setStep] = useState(1); 

    // Step 1
    const [email, setEmail] = useState("");

    // Step 2
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    // Other
    const [errors, setErrors] = useState({ email: "", password1: "", password2: ""});
    const [loading, setLoading] = useState(false);

    // Login Auth
    const { handleLogin } = useAuth();

    // Get the theme
    const theme = useTheme();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // Email validation function
    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    // Handle email submission
    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        let newErrors = { email: "" };
    
        // Frontend validation
        if (!email.trim()) {
            newErrors.email = "Please enter a valid email address.";
        } else if (!isValidEmail(email)) {
            newErrors.email = "Please enter a valid email address.";
        }

        if (newErrors.email) {
            setErrors(newErrors);
            return; // Stop execution if frontend validation fails
        }
                  
        try {
            // Send API request
            setLoading(true);
            await myAxiosInstance.post("/prospinity-auth/registration/email-check/", { email });

            // Move on
            setStep(2);

        } catch (error) {
            // Handle API errors
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data.email) {
                        setErrors({ ...newErrors, email: error.response.data.email[0] });
                    } else {
                        setErrors({ ...newErrors, email: "You don't have an invitation to join Prospinity." });
                    }
                } else {
                    setErrors({ ...newErrors, password: "Something went wrong. Please try again later." });
                }
            } else {
                setErrors({ ...newErrors, password: "Something went wrong. Please try again later." });
            }
        } finally {
            setLoading(false);
        }
    };

    // Handle final submission
    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        let newErrors = { password1: "", password2: "" };

        if (!password1.trim()) {
            newErrors.password1 = "Please enter a password.";
        } else if (password1.length < 8) {
            newErrors.password1 = "Password must be at least 8 characters.";
        }

        if (password1 !== password2) {
            newErrors.password2 = "Passwords do not match.";
        }

        if (newErrors.password1 || newErrors.password2) {
            setErrors(newErrors);
            return;
        }

        try {
            setLoading(true);
            await myAxiosInstance.post("/prospinity-auth/registration/", {
                email, 
                password1, 
                password2,
            });

            // Login
            handleLogin();

        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data.password1) {
                        setErrors({ ...newErrors, password1: error.response.data.password1[0] });
                    } else if (error.response.data.password2) {
                        setErrors({ ...newErrors, password2: error.response.data.password2[0] });
                    } else {
                        setErrors({ ...newErrors, email: "Something went wrong. Please try again later." });
                    }
                } else {
                    setErrors({ ...newErrors, password: "Something went wrong. Please try again later." });
                }
            } else {
                setErrors({ ...newErrors, password: "Something went wrong. Please try again later." });
            }
        } finally {
            setLoading(false);
        }
    };


    // Desktop
    if (step === 1)
        return (
            <RegistrationContainer isMobile={isMobile}>
                <RegistrationHeader isMobile={isMobile}>WELCOME TO{isMobile ? " " : <br />}PROSPINITY</RegistrationHeader>
                <Subtitle isMobile={isMobile} theme={theme}>Enter your university email below</Subtitle>

                <Form onSubmit={handleEmailSubmit}>
                    <FormInput 
                        label="University email" 
                        type="email" 
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors((prev) => ({ ...prev, email: "" })); // Clear error when typing
                        }}
                        error={errors.email}
                    />

                        {isMobile ?
                            <MobileBottomSection>
                                <PrimaryButton size="lg" type="submit" disabled={loading} style={{ padding: "1rem", width: "100%"}} >
                                    {loading ? "Checking email..." : "GET STARTED"}
                                </PrimaryButton>
                                <PromptWrapper isMobile={isMobile}>
                                    <Prompt theme={theme}>
                                        Already have an account? <Link to="/join/signin">Login</Link>
                                    </Prompt>
                                </PromptWrapper>
                            </MobileBottomSection>
                            :
                            <PrimaryButton type="submit" disabled={loading} style={{ padding: "1rem", marginTop: "2rem" }} >{loading ? "Checking email..." : "GET STARTED"}</PrimaryButton>
                        }
                </Form>

                {/* Secondary prompt onlly for desktop */}
                {!isMobile && 
                    <PromptWrapper isMobile={isMobile}>
                        <Prompt theme={theme}>
                            Already have an account? <Link to="/join/signin">Login</Link>
                        </Prompt>
                    </PromptWrapper>
                }
                
            </RegistrationContainer>
        );
    
    else
        return (
            <RegistrationContainer isMobile={isMobile}>
                <RegistrationHeader isMobile={isMobile}>CREATE YOUR<br />PASSWORD</RegistrationHeader>
                <Subtitle isMobile={isMobile} theme={theme}>Your password must be at least 8 characters long</Subtitle>

                <Form onSubmit={handlePasswordSubmit}>
                    <FormInput 
                        label="Password" 
                        type="password" 
                        value={password1}
                        onChange={(e) => {
                            setPassword1(e.target.value);
                            setErrors((prev) => ({ ...prev, password1: "" })); // Clear error when typing
                        }}
                        showEyeIcon
                        error={errors.password1}
                    />

                    <div style={{ marginTop: "2rem" }}>
                        <FormInput 
                            label="Confirm Password" 
                            type="password" 
                            value={password2}
                            onChange={(e) => {
                                setPassword2(e.target.value);
                                setErrors((prev) => ({ ...prev, password2: "" })); // Clear error when typing
                            }} 
                            showEyeIcon
                            error={errors.password2}
                        />
                    </div>

                    {isMobile ?
                        <MobileBottomSection>
                            <PrimaryButton type="submit" disabled={loading} style={{ padding: "1rem", width: "100%" }} >
                                {loading ? "Checking password..." : "NEXT"}
                            </PrimaryButton>
                            <PromptWrapper isMobile={isMobile}>
                                <Prompt theme={theme}>
                                    Signing up as {email}.{" "}{isMobile ? <br /> : " "}
                                    <Link 
                                        to="#" 
                                        onClick={(e) => { 
                                            e.preventDefault(); // Prevents navigation
                                            setStep(1); // Resets to email input
                                        }}
                                    >
                                        Not you?
                                    </Link>
                                </Prompt>
                            </PromptWrapper>
                        </MobileBottomSection>
                        :
                        <PrimaryButton type="submit" disabled={loading} style={{ padding: "1rem",  marginTop: "2rem" }} >{loading ? "Checking password..." : "NEXT"}</PrimaryButton>
                    }

                </Form>

                {/* Secondary prompt onlly for desktop */}
                {!isMobile && 
                    <PromptWrapper isMobile={isMobile}>
                        <Prompt theme={theme}>
                            Signing up as {email}.{" "}
                            <Link 
                                to="#" 
                                onClick={(e) => { 
                                    e.preventDefault(); // Prevents navigation
                                    setStep(1); // Resets to email input
                                }}
                            >
                                Not you?
                            </Link>
                        </Prompt>
                    </PromptWrapper>
                }
                
            </RegistrationContainer>
        );
}

export default SignUp;






