import React, { useState, useEffect, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { useParams } from 'react-router-dom';
import { useErrorBoundary } from "react-error-boundary";
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@tanstack/react-query';

import { useAuth } from '../contexts/AuthContext';
import PoolHeader from '../components/pools/PoolHeader';
import { MainContainer } from '../components/common/Containers';
import Banner from '../components/common/Banners';
import UserPoolCard from '../components/users/UserPoolCard';
import { PoolMeeting, PoolGroupMe, SectionLocked, AboutPool } from '../components/pools/PoolSection';
import { PoolTimeGraphic } from '../components/pools/PoolGraphic';
import PoolFAQ from '../components/pools/PoolFAQ';
import Loading from '../components/common/Loading';
import { myAxiosInstance } from '../api/axiosConfig';
import { ReactComponent as NothingYetIcon } from "../assets/icons/nothingYet.svg"; // Import SVG as ReactComponent
import NotFound from "../assets/images/NotFound.png";


export default function PoolPage() {

    // The slug
    const { slug } = useParams();

    // Auth
    const { reload, setReload, userFirstName } = useAuth();

    // Get the theme
    const theme = useTheme();

    // Errors
    const { showBoundary } = useErrorBoundary();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // States
    // const [isLoading, setIsLoading] = useState(true);
    // const [poolData, setPoolData] = useState(null);
    // const [userStatus, setUserStatus] = useState(null);
    const [activeTab, setActiveTab] = useState("about");

    const tabOptions = ["about", "members", "faqs", "vote"];
    
    // useEffect(() => {
    //     const getPoolData = async () => {
    //         try {
    //             const response = await myAxiosInstance.get('/api/pools/' + slug + '/');
    //             setPoolData(response.data); // Store the data
    //             setUserStatus(response.data.user_info.status);
    //         } catch (error) {
    //             showBoundary(error);
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     };
        
    //     getPoolData();
    // }, [reload]);

    const { data: poolData, isLoading, error } = useQuery({
        queryKey: ['poolData', slug],
        queryFn: () =>
            myAxiosInstance.get(`/api/pools/${slug}/`).then(res => res.data),
        staleTime: 5 * 60 * 1000, // 5 minutes
    });

    const userStatus = useMemo(() => {
        return poolData?.user_info?.status ?? null;
      }, [poolData]);
                

    if (isLoading) {
        return (
            <div style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <Loading />
            </div>
        );
    }

    if (error) {
        showBoundary(error);
        return null;
      }

    return (
        <PageWrapper theme={theme}>
            <PoolHeaderWrapper>
                <PoolHeader pool={poolData} />
        
                {/* Tabs */}
                <Tabs>
                    {tabOptions.map((tab) => (
                        <Tab
                            key={tab}
                            isActive={activeTab === tab}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab.toUpperCase()}
                        </Tab>
                    ))}
                </Tabs>
            </PoolHeaderWrapper>
    
            {/* Tab Content (just placeholder for now) */}
            <MainContainer>
                {userStatus === "not_associated" ? (
                    <NotAssociatedMessage />
                ) : (
                    <>
                        {activeTab === "about" && 
                            <>
                                {userStatus === "member" && (
                                    <>
                                        {/* <div style={{ width: "100%", display: "flex", flexDirection: isMobile ? "column" : "row", marginTop: isMobile ? "1rem" : "1.5rem", gap: isMobile ? "1rem" : "1.5rem" }}>
                                            <PoolMeeting />
                                            <PoolGroupMe />
                                        </div>

                                        <div style={{ width: "100%", marginTop: isMobile ? "1rem" : "1.5rem" }}>
                                            <AboutPool pool={poolData} />
                                        </div> */}

                                        <div style={{ width: "100%", marginTop: isMobile ? "1rem" : "1.5rem" }}>
                                            <PoolTimeGraphic />
                                        </div>
                                    </>
                                )}

                                {userStatus === "accepted_not_signed" && (
                                    <>
                                        <Banner bannerType="matched" fullBanner={true} userName={userFirstName} pool={poolData} />

                                        <div style={{ width: "100%", marginTop: isMobile ? "1rem" : "1.5rem" }}>
                                            <AboutPool pool={poolData} />
                                        </div>

                                        <div style={{ width: "100%", display: "flex", flexDirection: isMobile ? "column" : "row", marginTop: isMobile ? "1rem" : "1.5rem", gap: isMobile ? "1rem" : "1.5rem" }}>
                                            <SectionLocked text="Pool next meeting is locked" />
                                            <SectionLocked text="Pool group chat is locked" />
                                        </div>

                                        <div style={{ width: "100%", marginTop: isMobile ? "1rem" : "1.5rem" }}>
                                            <SectionLocked text="Pool group chat is locked" />
                                        </div>
                                    </>
                                )}

                                {userStatus === "accepted_signed" && (
                                    <>
                                        <Banner bannerType="matched" fullBanner={true} userName={userFirstName} pool={poolData} />

                                        <div style={{ width: "100%", marginTop: isMobile ? "1rem" : "1.5rem" }}>
                                            <AboutPool pool={poolData} />
                                        </div>

                                        <div style={{ width: "100%", display: "flex", flexDirection: isMobile ? "column" : "row", marginTop: isMobile ? "1rem" : "1.5rem", gap: isMobile ? "1rem" : "1.5rem" }}>
                                            <SectionLocked text="Pool next meeting is locked" />
                                            <SectionLocked text="Pool group chat is locked" />
                                        </div>

                                        <div style={{ width: "100%", marginTop: isMobile ? "1rem" : "1.5rem" }}>
                                            <SectionLocked text="Pool group chat is locked" />
                                        </div>
                                    </>
                                )}
                            </>
                        }

                        {activeTab === "members" && 
                            <PortfolioGrid>
                                {poolData.other_users.map((user, index) => (
                                    <UserPoolCard key={index} user={user} />
                                ))}
                            </PortfolioGrid>
                        }

                        {activeTab === "faqs" && 
                            <PoolFAQ />
                        }

                        {activeTab === "vote" && 
                            <ContentWrapper>
                                <Content>
                                    <ClockIcon />
                                    <Text style={{ fontWeight: "500" }}>
                                        No requests yet
                                    </Text>
                                    <Subtext>People who request to join {poolData.public_details.name} will appear here</Subtext>
                                </Content>
                            </ContentWrapper>
                        }
                    </>
                )}
            </MainContainer>
        </PageWrapper>
    );
}


const NotAssociatedMessage = () => {

    return (
        <Container>
            <Image src={NotFound} alt="Not Part of Pool" />
            <Title>You're not part of this pool</Title>
            <Description>
                Request to join this pool to unlock details about its members, timeline, meetings, and other features.
            </Description>
            {/* <PrimaryButton size="lg">
                RETURN HOME
            </PrimaryButton> */}
        </Container>
    );
};


const PageWrapper = styled.div`
    background: ${({ theme }) => theme.backgroundDarker};
    min-height: 100vh;
`;

const PoolHeaderWrapper = styled.div`
    background: ${({ theme }) => theme.white};
    padding: 0 2.5rem;

    @media (max-width: 600px) {
        padding: 0 1rem;
    }
`;

const Tabs = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1rem;
    gap: 0.5rem;

    @media (max-width: 600px) {

    }
`;

const Tab = styled.div`
    font-size: 1.125rem;
    padding: 1rem 0.75rem;
    color: ${({ isActive, theme }) =>
        isActive ? theme.textPrimary : theme.textSecondary};
    font-weight: ${({ isActive }) => (isActive ? 500 : 400)};
    cursor: pointer;
    position: relative;

    &:after {
        content: "";
        display: ${({ isActive }) => (isActive ? "block" : "none")};
        height: 2px;
        background: ${({ theme }) => theme.primary};
        width: 100%;
        position: absolute;
        bottom: -1px;
        left: 0;
    }

    @media (max-width: 600px) {
        padding: 1rem 0.5rem;
        font-size: 1rem;
    }
`;


// Grid layout for portfolio cards
const PortfolioGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(260px, 1fr)); /* 3 cards per row initially */
    gap: 1rem;
    width: 100%;
    justify-content: center;

    @media (max-width: 1280px) {
        grid-template-columns: repeat(2, minmax(260px, 1fr)); /* 3 cards per row */
    }

    @media (max-width: 960px) {
        grid-template-columns: repeat(1, minmax(260px, 1fr)); /* 2 per row */
        place-items: center; 
    }

    @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr); /* Full width */
        place-items: center; 
    }
`;

const ClockIcon = styled(NothingYetIcon)`
    circle {
        fill: ${({ theme }) => theme.white};
        r: 23;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-top: 4.5rem;

    @media (max-width: 600px) {
        margin-top: 0rem;
        margin-bottom: 0.5rem;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const Text = styled.span`
    font-size: 1.25rem;
    color: ${({ theme }) => theme.textPrimary};
`;

const Subtext = styled.span`
    font-size: 1rem;
    color: ${({ theme }) => theme.textSecondary};
    margin-top: 0.25rem;
`;

// Styled components not being in a pool 
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 2rem;

    @media (max-width: 600px) {
        padding: 1rem;
    }
`;

const Image = styled.img`
    width: 6rem;
    height: auto;
    margin-bottom: 1rem;

    @media (max-width: 600px) {
        width: 4.5rem;
    }
`;

const Title = styled.h2`
    font-size: 1.375rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
    margin-bottom: 0.75rem;

    @media (max-width: 600px) {
        font-size: 1.125rem;
    }
`;

const Description = styled.p`
    font-size: 1rem;
    color: ${({ theme }) => theme.textSecondary};
    max-width: 420px;
    line-height: 1.5;
    margin-bottom: 1rem;

    @media (max-width: 600px) {
        font-size: 0.875rem;
        margin-bottom: 0rem;
    }
`;




// import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle  } from 'react';
// import { FormControl, InputGroup } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import { stringToColor } from '../common/CriticalFunctions';
// import { myAxiosInstance } from '../../api/axiosConfig';
// import { formatGraduationDate } from '../common/CriticalFunctions';
// import { useAuth } from '../../contexts/AuthContext';
// import { useTheme } from 'styled-components';
// import { StyledDropdown, StyledFormGroup, StyledFormControl, StyledFormLabel } from '../common/StyledBootstrap';
// import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
// import { name } from '@stream-io/video-react-sdk';

// const profilePictureStyle = {
//     width: '40px',
//     height: '40px',
//     borderRadius: '50%',
//     marginRight: '10px'
// };

// const customDropdownItemStyle = {
//     width: '100%',
//     backgroundColor: 'white',
//     padding: '5px',
//     cursor: 'pointer'
// };

// const customDropdownItemHoverStyle = {
//     backgroundColor: '#e0e0e0'
// };



// const AddUserToPool = forwardRef(({ otherUsers, value, onChange, label, endpoint, deleteUser }, ref) => 
// {
//     const [emailInput, setEmailInput] = useState(value.email);
//     //const [emailInput, setEmailInput] = useState('');
//     const [nameInput, setNameInput] = useState(value.first_name !== '' ? value.first_name + " " + value.last_name : '');
//     //const [nameInput, setNameInput] = useState('');
//     const [suggestions, setSuggestions] = useState([]);
//     const [selectedUser, setSelectedUser] = useState(value || null);
//     //const [selectedUser, setSelectedUser] = useState(null);
//     const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
//     const [showDropdown, setShowDropdown] = useState(false);
//     const wrapperRef = useRef(null);


//     useImperativeHandle(ref, () => ({
//         getData: () => emailInput
//     }));




//     const themeMode = useTheme();
//     const { userSlug } = useAuth();

//     useEffect(() => {
//         function handleClickOutside(event) {
//             if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
//                 setShowDropdown(false);
//             }        }
//         document.addEventListener('mousedown', handleClickOutside);
//         return () => document.removeEventListener('mousedown', handleClickOutside);
//     }, []);

//     const fetchOptions = async (query) => {
//         try {
//             if (endpoint) {
//                 const response = await myAxiosInstance.get(`/api/search-users/?q=${query}&limit_users=true`);
//                 return response.data.users;
//             }
//             return otherUsers.filter(item =>
//                 (item.first_name + " " + item.last_name).toLowerCase().startsWith(query.toLowerCase())
//             );
//         } catch (error) {
//             return [];
//         }
//     };

//     useEffect(() => {
//         const debounceTimer = setTimeout(() => {
//             if (emailInput || nameInput) {
//                 fetchOptions(emailInput || nameInput).then(data => {
//                     setSuggestions(data.filter(item => item.slug !== selectedUser?.slug));
//                 });
//             } else {
//                 setSuggestions([]);
//             }
//         }, 100);

//         return () => clearTimeout(debounceTimer);
//     }, [emailInput, nameInput]);

//     const handleEmailChange = (event) => {
//         const email = event.target.value;
//         setEmailInput(email);
//         setShowDropdown(true);
//         setActiveSuggestionIndex(0);
//         setSelectedUser(null);
//         let tempItem = {email: email}
//         onChange(tempItem);
//     };

//     const handleNameChange = (event) => {
//         const name = event.target.value;
//         setNameInput(name);
//         setShowDropdown(true);
//         setActiveSuggestionIndex(0);
//         setSelectedUser(null);
//         let tempItem = {first_name: name, last_name: ''}
//         onChange(tempItem);
//     };

//     const handleSelect = (item) => {
//         setSelectedUser(item);
//         setEmailInput(item.email);
//         setNameInput(`${item.first_name} ${item.last_name}`);
//         setShowDropdown(false);
//         onChange(item);
//     };

//     const handleRemoveSelection = () => {
//         setSelectedUser(null);
//         setEmailInput('');
//         setNameInput('');
//         onChange({
//             company: '',
//             email: '',
//             first_name: '',
//             graduation_date: '',
//             is_verified: '',
//             last_name: '',
//             major_or_grad_program: '',
//             pooled: '',
//             portfolio_count: '',
//             profile_picture: '',
//             slug: '',
//             university: ''});
//     };

//     return (
//         <StyledFormGroup className='mb-3'>
//             <StyledFormLabel>
//             <FontAwesomeIcon
//                     icon={faTimes}
//                     onClick={deleteUser}
//                     style={{
//                         cursor: 'pointer',
//                         marginRight: '5px',
//                         color: themeMode.primary,
//                         fontSize: '1rem',
//                     }}
//                     title="Remove this user"
//                 />
//                 Member {label}
                
//             </StyledFormLabel>

            

//             {/* Second FormControl for Full Name */}
//             <InputGroup  className="mb-2">
//                 <StyledFormControl
//                     type="text"
//                     value={nameInput}
//                     placeholder="Enter name (first and last)"
//                     onChange={handleNameChange}
//                     readOnly={false}  // Allow manual entry if no selection
//                 />
//             </InputGroup>

//             {/* First FormControl for Email */}
//             <InputGroup>
//                 <StyledFormControl
//                     type="text"
//                     value={emailInput}
//                     placeholder="Enter university email"
//                     onChange={handleEmailChange}
//                     readOnly={false}  // Allow manual entry if no selection
//                 />
//                 {selectedUser && (
//                     <InputGroup.Text onClick={handleRemoveSelection} style={{ cursor: 'pointer' }}>
//                         <FontAwesomeIcon icon={faTimes} />
//                     </InputGroup.Text>
//                 )}
//             </InputGroup>
//             <div ref={wrapperRef}>
//                 <StyledDropdown show={showDropdown && suggestions.length > 0}>
//                     <StyledDropdown.Menu align={'start'} style={{
//                         position: 'absolute',
//                         left: 0,
//                         right: 0,
//                         top: '100%',
//                         width: '100%',
//                         maxHeight: '400px',
//                         overflowY: 'auto',
//                     }}>
//                         {suggestions.map((item, index) => (
//                             <StyledDropdown.Item
//                                 key={item.slug}
//                                 active={index === activeSuggestionIndex}
//                                 onClick={() => handleSelect(item)}
//                                 style={{
//                                     ...customDropdownItemStyle,
//                                     ...(index === activeSuggestionIndex ? customDropdownItemHoverStyle : {})
//                                 }}
//                                 onMouseEnter={(e) => e.currentTarget.style.backgroundColor = customDropdownItemHoverStyle.backgroundColor}
//                                 onMouseLeave={(e) => e.currentTarget.style.backgroundColor = customDropdownItemStyle.backgroundColor}
//                             >
//                                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                                     <img
//                                         src={item.profile_picture || 'https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp'}
//                                         alt="Profile"
//                                         style={profilePictureStyle}
//                                     />
//                                     <span style={{
//                                         whiteSpace: 'nowrap',
//                                         overflow: 'hidden',
//                                         textOverflow: 'ellipsis',
//                                         maxWidth: 'calc(100% - 50px)'  // Adjust based on padding/margins
//                                     }}>
//                                         {`${item.first_name} ${item.last_name} '${formatGraduationDate(item.graduation_date)}, ${item.university}, ${item.major_or_grad_program.map(program => program).join(", ")}${item.company ? `, ${item.company}` : ""}`}
//                                     </span>
//                                 </div>
//                             </StyledDropdown.Item>
//                         ))}
//                     </StyledDropdown.Menu>
//                 </StyledDropdown>
//             </div>
//         </StyledFormGroup>
//     );
// });

// export default AddUserToPool;


// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import { useParams, useNavigate, Link, useLocation } from 'react-router-dom'; // import useNavigate if using React Router v6
// import PoolDeadlineTimer from "../components/pools/PoolDeadlineTimer";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faL, faStar } from '@fortawesome/free-solid-svg-icons';
// import { Row, Col, Card, ListGroup, Button } from 'react-bootstrap';
// import CustomModal from '../components/common/CustomModal';
// import { apiP } from '../components/common/CriticalFunctions';
// import HomeUserPreviewCard from '../components/users/UserPreviewCard';
// import ManagePool from '../components/pools/ManagePool';
// import Loading from '../components/common/Loading';
// import '../styles/Pool.css';
// import { useAuth } from '../contexts/AuthContext';
// import { useErrorBoundary } from "react-error-boundary";
// import { StyledButton, StyledCard, StyledListGroup, StyledH5, StyledH4, StyledParagraph } from '../components/common/StyledBootstrap';
// import { pastDeadline } from '../components/common/CriticalFunctions';
// import { myAxiosInstance } from '../api/axiosConfig';
// import { StyledH3 } from '../components/common/StyledBootstrap';
// import { useTheme } from 'styled-components';
// import { stylePoolSlug, convertPackageToGreek } from '../components/common/CriticalFunctions';
// import { useMediaQuery } from 'react-responsive';
// import PoolRecapTutorial from '../components/pools/PoolRecapTutorial';
// import SigningRecap from '../components/pools/SigningRecap';
// import AddUserToPool from '../components/pools/AddUserToPool';
// import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';


// const FullScreenYouTubePopup = ({ onClose, videoId }) => {
//     const iframeRef = useRef(null);
//     const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  
//     useEffect(() => {
//       let player;
  
//       const handleVideoEnd = (event) => {
//         if (event.data === window.YT.PlayerState.ENDED) {
//           onClose();
//         }
//       };
  
//       const initializePlayer = () => {
//         if (iframeRef.current) {
//           player = new window.YT.Player(iframeRef.current, {
//             videoId: videoId,
//             playerVars: {
//               autoplay: 1,
//               controls: 1,
//               rel: 0,
//               modestbranding: 1,
//               iv_load_policy: 3,
//               mute: 1,
//             },
//             events: {
//               onStateChange: handleVideoEnd,
//             },
//           });
//         }
//       };
  
//       if (window.YT && window.YT.Player) {
//         initializePlayer();
//       } else {
//         window.onYouTubeIframeAPIReady = initializePlayer;
//         const script = document.createElement("script");
//         script.src = "https://www.youtube.com/iframe_api";
//         document.body.appendChild(script);
//       }
  
//       return () => {
//         if (player) player.destroy();
//       };
//     }, [videoId, onClose]);
  
//     return (
//       <div
//         style={{
//           position: "fixed",
//           top: 0,
//           left: 0,
//           width: "100vw",
//           height: "100vh",
//           backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent overlay
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           zIndex: 9999,
//         }}
//       >
//         {/* Modal Container */}
//         <div
//           style={{
//             position: "relative",
//             width: isMdOrLarger ? "65vw" : "95vw", // Responsive modal width
//             backgroundColor: "white", // White modal background
//             borderRadius: "12px", // Rounded corners
//             padding: isMdOrLarger ? "0.6rem 2rem 2rem 2rem" : "0.2rem 0.5rem 0.5rem 0.5rem", // Padding around the video container
//             boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)", // Subtle shadow for depth
//             display: "flex",
//             flexDirection: "column", // Stack close button and video vertically
//           }}
//         >
//           {/* Close Button */}
//           <button
//             onClick={onClose}
//             style={{
//               alignSelf: "flex-end", // Align button to the top-right corner
//               marginBottom: isMdOrLarger ? "0.5rem" : "0", // Add space between button and video
//               color: "black", // Black text
//               background: "transparent", // Transparent background
//               border: "none", // No border
//               cursor: "pointer",
//               fontSize: "1.5rem", // Font size for ✖
//             }}
//             onMouseEnter={(e) => {
//               e.target.style.color = "gray";
//             }}
//             onMouseLeave={(e) => {
//               e.target.style.color = "black";
//             }}
//           >
//             ✖
//           </button>
  
//           {/* Video Container */}
//           <div
//             style={{
//               width: "100%", // Take full width of the modal
//               aspectRatio: "16 / 9", // Maintain 16:9 aspect ratio
//               backgroundColor: "black", // Fallback background for loading
//               borderRadius: "8px", // Slight rounding for video corners
//               overflow: "hidden", // Prevent overflow
//             }}
//           >
//             <div
//               ref={iframeRef}
//               style={{
//                 width: "100%",
//                 height: "100%",
//               }}
//             ></div>
//           </div>
//         </div>
//       </div>
//     );
//   };


// //change invitee look
// //weird total spots going down error

// function PoolPage() {
//     const { slug } = useParams();
//     const [reload, setReload] = useState(1);
//     const [userInfo, setUserInfo] = useState(null);
//     const [publicDetails, setPublicDetails] = useState(null);
//     const [privateDetails, setPrivateDetails] = useState(null);
//     const [extraPrivateDetails, setExtraPrivateDetails] = useState(null);
//     const [captainInfo, setCaptainInfo] = useState(null);
//     const [otherUsers, setOtherUsers] = useState([]);
//     const [otherNonUsers, setOtherNonUsers] = useState([]);
//     const [isBottomLoading, setIsBottomLoading] = useState(true);
//     const [poolMessageLoading, setPoolMessageLoading] = useState(false);

//     const themeMode = useTheme();

//     const location = useLocation();

//     const [editPoolData, setEditPoolData] = useState(null);
//     const [delPool, setDelPool] = useState(false);
//     const [removeInvitees, setRemoveInvitees] = useState([]);
//     const [addInvitees, setAddInvitees] = useState([]);
//     const [inviteEmails, setInviteEmails] = useState([]);

//     const [modalShow, setModalShow] = useState(false);
//     const [modalMessage, setModalMessage] = useState(null);
//     const [manageReload, setManageReload] = useState(false);



//     const [showTutorial, setShowTutorial] = useState(false); // To show or hide the tutorial modal
//     const [tutorialPart, setTutorialPart] = useState(1); // To track the current part of the tutorial


//     const [showRecap, setShowRecap] = useState(false); // To show or hide the tutorial modal
//     const [recapPart, setRecapPart] = useState(1); // To track the current part of the tutorial


//     const [showAddModal, setShowAddModal] = useState(false); // To show or hide the add modal 


//     const [showIframeModal, setShowIframeModal] = useState(false);


//     const handleNextRecap = () => {
//         if (recapPart < 1) {
//             setRecapPart(recapPart + 1);
//         } else {
//             setShowRecap(false); // Hide the tutorial after the last part
//             //markTutorialCompleted();
//             setModalShow(true);

//         }
//     };


//     const handleBackRecap = () => {
//         if (recapPart > 1) {
//             setRecapPart(recapPart - 1);
//         }
//     };

//     const handleSkipRecap = () => {
//         setShowRecap(false); // Hide the tutorial
//     };


//     const handleNextTutorial = () => {
//         if (tutorialPart < 1) {
//             setTutorialPart(tutorialPart + 1);
//         } else {
//             setShowTutorial(false); // Hide the tutorial after the last part
//             //markTutorialCompleted();

//         }
//     };


//     const handleBackTutorial = () => {
//         if (tutorialPart > 1) {
//             setTutorialPart(tutorialPart - 1);
//         }
//     };

//     const handleSkipTutorial = () => {
//         setShowTutorial(false); // Hide the tutorial
//         //markTutorialCompleted();
//     };

//     const [signingInterface, setSigningInterface] = useState(null);

//     const profilePictureStyle = {
//         width: '5rem', // Adjust width for less height
//         height: '5rem', // Adjust height for less height
//         borderRadius: '50%',
//         marginRight: '0.7rem'
//     };



//     const handlePoolChatButtonClick = async () => {
//         try {
//             setPoolMessageLoading(true);
//             setActiveChannel(slug);
//             setChatVisible(true);

//         }
//         catch (error) {}
//         finally {
//             setPoolMessageLoading(false);
//         }



//     };

    
//     const handlePoolAddButtonClick = async () => {
//         setShowAddModal(true);
//     };

//     const handleCloseAddButton = async () => {
//         setShowAddModal(false);
//     };

//     const handleSaveInvites = async () => {
//         setIsBottomLoading(true);
//         alert('yay');
//         // api
//         // reload
//         setReload(reload+1);
//         setIsBottomLoading(false);
//     }
//     const poolMembers = useRef([{
//         company: '',
//         email: '',
//         first_name: '',
//         graduation_date: '',
//         is_verified: '',
//         last_name: '',
//         major_or_grad_program: '',
//         pooled: '',
//         portfolio_count: '',
//         profile_picture: '',
//         slug: '',
//         university: ''}]);

//     const bottomRef = useRef(null);
//     const [triggerRender, setTriggerRender] = useState(0);


//     const { showBoundary } = useErrorBoundary();

//     const { accountStage, setActiveChannel, setChatVisible } = useAuth();

//     const navigate = useNavigate();

//     const buttonOptions = [
//         { buttonText: 'Accept Invite', action: async () => { apiPoolAction('accept-pool-invite', "post", null, true) }, title: 'Accept Invite', modalButtonText: 'Accept Invite', modalText: 'Please confirm you would like to accept the invite to this pool.' },
//         { buttonText: 'Invite Accepted', action: async () => { apiPoolAction('leave-pool', "post", null, true) }, title: 'Invite Accepted', modalButtonText: 'Leave', modalText: 'You have accepted the invite to this pool. Only click "Leave" below if you are sure you would like to leave the pool.' },
//         { buttonText: 'Join Pool', action: () => signExternally(), title: 'Review and join the pool...', modalButtonText: 'View Externally', modalText: '' },
//         { buttonText: 'Manage Pool', action: async () => { handleManagePoolModalButtonClick() }, title: 'Manage Pool', modalButtonText: 'Save Changes to Pool', modalText: 'Please review your changes carefully before saving them.' },
//         { buttonText: 'View Agreement', action: () => signExternally(), title: 'View Signed Agreement', modalButtonText: 'View Agreement', modalText: 'Here is the signed agreement.' },
//         { buttonText: 'Verify Account', action: () => navigate("/dashboard/settings"), title: 'Verify Account Before Signing', modalButtonText: 'Go to Settings', modalText: 'Please verify your identity before signing the contract.' },
//         {
//             buttonText: 'Request to Join',
//             action: () => {
//                 // Assuming captainInfo contains the email address of the captain
//                 const captainEmail = captainInfo ? captainInfo.email : "defaultEmail@example.com";
//                 const captainName = captainInfo ? captainInfo.first_name : "Captain";
//                 const subject = encodeURIComponent("Request to Join Pool ");
//                 const body = encodeURIComponent("Dear " + captainName + ",\n\nI would like to request to join your pool. Please let me know if there's any further information you need from me.\n\nBest regards,");
//                 window.location.href = `mailto:${captainEmail}?subject=${subject}&body=${body}`;
//             },
//             title: 'Request to Join Pool',
//             modalButtonText: 'Email Captain',
//             modalText: 'Email the captain of this pool with your request.'
//         },
//         {
//             buttonText: 'Evaluate',
//             action: () => {
//                 // Assuming captainInfo contains the email address of the captain
//                 const captainEmail = captainInfo ? captainInfo.email : "defaultEmail@example.com";
//                 const captainName = captainInfo ? captainInfo.first_name : "Captain";
//                 const subject = encodeURIComponent("Evaluation Period Request ");
//                 const body = encodeURIComponent("Dear " + captainName + ",\n\n[Request]\n\nBest regards,");
//                 window.location.href = `mailto:${captainEmail}?subject=${subject}&body=${body}`;
//             },
//             title: 'Pool is in Evaluation Period',
//             modalButtonText: 'Email Captain',
//             modalText: 'Click here to email the captain of this pool if you have a comment, question, or late request to join during the evaluation period.'
//         }];
//     const [buttonOptionsIndex, setButtonOptionsIndex] = useState(null);

//     const handleCloseIframeModal = () => {
//         setShowIframeModal(false);
//     };


//     useEffect(() => {
//         const getPoolData = async () => {
//             try {
//                 setModalShow(false);
//                 setShowAddModal(false);

//                 setModalMessage(null);
//                 setButtonOptionsIndex(null);
//                 setSigningInterface(null);

//                 setIsBottomLoading(true);
//                 setShowIframeModal(false);
//                 const response = await myAxiosInstance.get('/api/pools/' + slug + '/');

//                 const data = response.data;
//                 setUserInfo(data.user_info);
//                 setPublicDetails(data.public_details);
//                 setPrivateDetails(data.hasOwnProperty('private_details') ? data.private_details : null);
//                 setExtraPrivateDetails(data.hasOwnProperty('extra_private_details') ? data.extra_private_details : null);
//                 setCaptainInfo(data.hasOwnProperty('captain_info') ? data.captain_info : null);
//                 setOtherUsers(data.hasOwnProperty('other_users') ? data.other_users : []);
//                 setOtherNonUsers(data.hasOwnProperty('other_non_users') ? data.other_non_users : []);
//                 const poolKey = `pool_${slug}_first_time`;

//                 // Check if this is the first time visiting this pool
//                 if (!localStorage.getItem(poolKey) && data.user_info.status !== "not_associated") {
//                     // Set local storage flag to prevent future triggers
//                     localStorage.setItem(poolKey, 'true');

//                     // Logic to set iframe source or perform actions
//                     setShowIframeModal(true); // Show modal with iframe
//                 }

//                 let currentIndex = 6; // Updated logic using the local variable
//                 if (data.user_info.status === "invited_not_accepted") {
//                     currentIndex = 0;
//                     setShowTutorial(true);
//                 } else if (data.user_info.status === "invited_accepted") {
//                     if (data.user_info.is_captain) {
//                         currentIndex = 3;
//                     } else {
//                         currentIndex = 1;
//                     }
//                 } else if (data.user_info.status === "accepted_not_signed") {
//                     setShowTutorial(true);
//                     if (accountStage == 3) {
//                         if (data.private_details.evaluate) {
//                             currentIndex = 7;
//                         }
//                         else {
//                             currentIndex = 2;
//                         }

//                     }
//                     else {
//                         currentIndex = 5;
//                     }

//                 } else if (data.user_info.status === "accepted_signed" || data.user_info.status === "member") {
//                     currentIndex = 4;
//                 }

//                 setButtonOptionsIndex(currentIndex); // Setting the index after determination
//                 if (reload == 1 && location.state && location.state.actionButtonClicked) {
//                     handleButtonClick(currentIndex);
//                 }

//             } catch (error) {
//                 showBoundary(error);
//             } finally {
//                 setIsBottomLoading(false);
//             }
//         };

//         getPoolData();
//     }, [slug, reload]);

//     useEffect(() => {
//         if (bottomRef.current) {
//             bottomRef.current.scrollIntoView({ behavior: 'smooth' });
//         }
//     }, [poolMembers]); // Scrolls to the bottom each time an invite is added


//     const handleAddInvite = () => {
//         poolMembers.current.push({
//             company: '',
//             email: '',
//             first_name: '',
//             graduation_date: '',
//             is_verified: '',
//             last_name: '',
//             major_or_grad_program: '',
//             pooled: '',
//             portfolio_count: '',
//             profile_picture: '',
//             slug: '',
//             university: ''
//         });
//         setTriggerRender(prev => prev + 1); // Force re-render
//         //addSubComponentRef();
//     }



//     const handleDeleteUser = (index) => {
//         poolMembers.current = poolMembers.current.filter((_, i) => i !== index);
//         //subComponentRefs.current = subComponentRefs.current.filter((_, i) => i !== index);
//         setTriggerRender(prev => prev + 1); // Force re-render
//     };




//     const editPoolMember = (selected, index) => {
//         poolMembers.current[index] = {...poolMembers.current[index], ...selected}; // Directly update ref without triggering re-render
//     };


//     const handleDeadlineExpiry = () => //need to change (maybe reload api call based on slug)
//     {
//         setReload(reload + 1);
//     };

//     const handleCaptainClick = () => {
//         if (captainInfo) {
//             navigate('/dashboard/user/' + captainInfo.slug)
//         }
//     }

//     const handleButtonClick = (tempIndex) => //change this
//     {
//         if (tempIndex == 2 || tempIndex == 4) {
//             //setSigningInterface("temp");
//             getInterfaceURL();
//         }
//         if (tempIndex == 2)
//         {
//             setShowRecap(true);
//         }
//         else{
//             setModalShow(true);
//         }
        
//     }




//     const handleClose = () => {
//         setModalShow(false)
//         setModalMessage(null);
//         setManageReload(!manageReload);
//     };


//     const handleViewModalButtonClick = () => //change this
//     {
//         setModalShow(false);
//     }

//     const getInterfaceURL = async () => {
//         let response = await myAxiosInstance.get('/api/get-document/' + slug + '/');
//         setSigningInterface(response.data.document_link);
//     }

//     const signExternally = async () => {
//         setModalMessage(<Loading />);
//         let response = await myAxiosInstance.get('/api/get-document/' + slug + '/');
//         const newWindow = window.open(response.data.document_link, '_blank', 'noopener,noreferrer');
//         //const newWindow = window.open("https://app.prospinity.com/contract-not-live", '_blank', 'noopener,noreferrer');
//         if (newWindow) newWindow.opener = null;
//         setModalMessage(null);

//     }

//     /////FIX REGISTRATION
//     const apiPoolAction = async (endpoint, requestType, data, shouldReload) => {
//         return apiP(data, endpoint + "/" + slug + "/", setModalMessage, "Done!", null, null, shouldReload, reload, setReload, requestType);
//     }

//     const handleManagePoolModalButtonClick = async () => {
//         if (delPool) {
//             const response = await apiPoolAction('update-delete-pools', "delete", null, false);
//             if (response) {
//                 navigate('/dashboard/home');
//             }

//         }
//         else { //determine order of this
//             let final_users = editPoolData.invite_emails;
//             if (removeInvitees.length != 0) {
//                 const removedUsers = removeInvitees.map(invitee => invitee.email);
//                 final_users = final_users.filter(item => !removedUsers.includes(item));
//             }
//             if (addInvitees.length != 0) {
//                 const addedUsers = addInvitees.map(invitee => invitee.email);
//                 final_users = [...final_users, ...addedUsers];
//             }
//             const data = { ...editPoolData, invite_emails: [...final_users, ...inviteEmails] };
//             const response = await apiPoolAction('update-delete-pools', "put", data, false);
//             if (response) {
//                 setReload(reload + 1);
//                 navigate('/dashboard/pool/' + response.data.slug + "/");
//             }
//             if (response == null) {
//                 //setManageReload(!manageReload);
//             }
//         }

//     }


//     const isMdOrLarger = useMediaQuery({ minWidth: 768 });


//     return (
//         isBottomLoading ?
//             <Loading />
//             :
//             <>
//                 {showIframeModal && 
//                     <FullScreenYouTubePopup videoId={"VYDAj3I00MI"} onClose={handleCloseIframeModal}/>
//                 }
                
//                 <div className='d-flex justify-content-center' style={{ backgroundColor: themeMode.background, minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>
//                     <div className='home-joxyrride mx-2' style={{ minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>
//                         {!isMdOrLarger && 
//                             <div className='d-flex w-100 justify-content-between align-items-center mt-3'>
//                             <div>
//                             <StyledH5><strong>Pool Highlights</strong></StyledH5>
//                             </div>
//                             <div>
//                             {buttonOptionsIndex !== null && (
//                                 <StyledButton onClick={() => handleButtonClick(buttonOptionsIndex)} style={{borderRadius: '1.5rem', fontSize: '0.8rem'}} className='px-4'>
//                                     {buttonOptions[buttonOptionsIndex].buttonText}
//                                 </StyledButton>
//                             )}
//                             </div>
//                             </div>
//                         }
//                         <StyledCard className='mt-3'>
//                             <StyledCard.Body>
//                                 <div className='d-flex w-100 justify-content-between align-items-center' style={{marginTop: '-0.5rem'}}>
//                                     <div className='d-flex align-items-center' style={{maxWidth: isMdOrLarger ? '90%' : '100%'}}>
//                                         <img
//                                             src={'https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp'}
//                                             alt={"Preview"}
//                                             onError={(e) => { e.target.src = 'https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW.webp'; }}
//                                             style={profilePictureStyle}
//                                         />
//                                         <div className='d-flex flex-column justify-content-center' style={{ minWidth: 0, maxWidth: '100%' }}>
//                                             <div style={{
//                                                 cursor: 'pointer',
//                                                 whiteSpace: 'nowrap',
//                                                 overflow: 'hidden',
//                                                 textOverflow: 'ellipsis',
//                                                 fontSize: '1.8rem',
//                                                 minWidth: 0
//                                             }}>
//                                                 <strong style={{
//                                                     cursor: 'pointer',
//                                                     fontWeight: 'bold',
//                                                     textDecoration: 'none',
//                                                     color: 'inherit',
//                                                 }}
//                                                     onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
//                                                     onMouseLeave={(e) => e.target.style.textDecoration = 'none'}

//                                                 >{privateDetails ? privateDetails.name : stylePoolSlug(publicDetails.slug.toUpperCase())}</strong>
//                                             </div>
//                                             <div style={{
//                                                 cursor: 'pointer',
//                                                 minWidth: 0,
//                                                 marginTop: '-0.3rem'
//                                             }}>
//                                                 <span style={{
//                                                     cursor: 'pointer',
//                                                     textDecoration: 'none',
//                                                     color: 'inherit',
//                                                 }}
//                                                     onMouseEnter={(e) => e.target.style.textDecoration = 'underline'}
//                                                     onMouseLeave={(e) => e.target.style.textDecoration = 'none'}

//                                                 >Captain: {captainInfo ? captainInfo.first_name + " " + captainInfo.last_name : "Private"}</span>
//                                             </div>
//                                             <div style={{ display: 'flex', alignItems: 'center', marginTop: '-0.1rem' }}>
//                                                 <div
//                                                     style={{
//                                                         width: '0.5rem',
//                                                         height: '0.5rem',
//                                                         backgroundColor: pastDeadline(publicDetails.sign_deadline) ? 'green' : 'orange',
//                                                         borderRadius: '50%',
//                                                         marginRight: '0.2rem',
//                                                     }}
//                                                 />
//                                                 <div
//                                                     style={{
//                                                         overflow: 'hidden',
//                                                         textOverflow: 'ellipsis',
//                                                         whiteSpace: 'nowrap',
//                                                         minWidth: 0,
//                                                         fontSize: '0.8rem',
//                                                     }}
//                                                 >

//                                                     {/*pastDeadline(data.sign_deadline) ? <strong>Status: </strong> : pastDeadline(data.invitation_deadline) ? <strong>Sign Deadline: </strong> : <strong>Invitation Deadline: </strong>*/}
//                                                     <PoolDeadlineTimer invitationDeadline={publicDetails.invitation_deadline} signDeadline={publicDetails.sign_deadline} onDeadlineExpire={handleDeadlineExpiry}></PoolDeadlineTimer>                  </div>
//                                             </div>

//                                         </div>
//                                     </div>
//                                     {isMdOrLarger && 
//                                     <div style={{marginTop: "0.7rem"}}>
//                                         {(userInfo.status === "invited_not_accepted" || userInfo.status === "invited_accepted" || userInfo.status === "accepted_signed" || userInfo.status === "accepted_not_signed" ) && <StyledButton className='mr-2'   onClick={handlePoolAddButtonClick}>{"Add Members"}</StyledButton>}
//                                         {buttonOptionsIndex !== null && (
//                                             <StyledButton onClick={() => handleButtonClick(buttonOptionsIndex)} className='px-4'>
//                                                 {buttonOptions[buttonOptionsIndex].buttonText}
//                                             </StyledButton>
//                                         )}
//                                     </div>}
//                                 </div>
//                             </StyledCard.Body>
//                         </StyledCard>
//                         {isMdOrLarger && 
//                         <StyledH5 className='mt-4'><strong>Pool Highlights</strong></StyledH5>
//                         }
//                         <StyledCard className='mt-2'>
//                             <StyledCard.Body>
//                                 <div className='d-flex justify-content-between'>
//                                     <div className='d-flex flex-column justify-content-center align-items-center'  style={{ flex: '1 1 0' }}>
//                                     <div><StyledH5><strong>{publicDetails.member_count}</strong></StyledH5></div>
//                                     <div style={{fontSize: '0.8rem', marginTop: '-0.4rem'}}>Members</div>
//                                     </div>
//                                     <div className='d-flex flex-column justify-content-center align-items-center'  style={{ flex: '1 1 0' }}>
//                                         <div><StyledH5><strong>{publicDetails.contribution_percent}%</strong></StyledH5></div>
//                                         <div style={{fontSize: '0.8rem', marginTop: '-0.4rem'}}>Future Earnings</div>
//                                     </div>
//                                     <div className='d-flex flex-column justify-content-center align-items-center'  style={{ flex: '1 1 0' }}>
//                                         <div><StyledH5><strong>{publicDetails.time_horizon} years</strong></StyledH5></div>
//                                         <div style={{fontSize: '0.8rem', marginTop: '-0.4rem'}}>Time Horizon</div>
//                                     </div>
//                                     {/*extraPrivateDetails == null || privateDetails.evaluate ? <></> :
//                                                 <Col className='d-flex flex-column justify-content-center align-items-center' xs={3}>
//                                                     <strong>Activation</strong>
//                                                     <div>{extraPrivateDetails.percent_signitures_to_activate}%</div>
//                                     </Col>*/}
//                                 </div>
//                             </StyledCard.Body>
//                         </StyledCard>
//                         {isMdOrLarger ? <StyledH5 className='mt-4 mb-2'><strong>Members</strong></StyledH5> :
//                                 <div className='d-flex w-100 justify-content-between align-items-center mt-1'>
//                                     <div>
//                                     <StyledH5 className='mt-3 mb-4'><strong>Members</strong></StyledH5>
//                                     </div>
//                                     {(userInfo.status === "invited_not_accepted" || userInfo.status === "invited_accepted" || userInfo.status === "accepted_signed" || userInfo.status === "accepted_not_signed" ) && 
//                                         <div>
//                                             <StyledButton style={{borderRadius: '1.5rem', fontSize: '0.8rem'}} className='px-4 mb-1' onClick={handlePoolAddButtonClick}>{"Add Members"}</StyledButton>
//                                         </div>
//                                     }
//                                 </div>
//                         }
//                         {
//                             userInfo.status === 'not_associated' ? <StyledCard><StyledCard.Body><div className='w-100 d-flex justify-content-center align-items-center' style={{minHeight: isMdOrLarger ? '40vh' : '15vh'}}><div style={{fontSize: '1.2rem'}}>The captain has made this pool <strong>private</strong>.</div></div></StyledCard.Body></StyledCard> : privateDetails.evaluate ? <StyledCard><StyledCard.Body><>This pool is now locked in the evaluation period. Prospinity will either move the pool forward to the Signing Period, or assign you to a new pool later. You will be notified when a decision is made. Stay tuned!</></StyledCard.Body></StyledCard> :
//                                 <StyledCard style={{minHeight: '40vh'}}>
//                                     <StyledCard.Body className='px-4'>
                                        
//                                             {
//                                                 userInfo.status === "invited_not_accepted" || userInfo.status === "invited_accepted" ?
//                                                     userInfo.is_captain ?
//                                                         <>
                                                            
                                                            
//                                                             <div style={{ textAlign: 'center' }} className='w-100 mb-1'>Accepted Invite</div>
                                                            
//                                                                 <div className='w-100 d-flex flex-wrap justify-content-center'>
                                                                    
//                                                                     {otherUsers.filter(otherUserInfo => otherUserInfo.has_accepted_invite === true).map((otherUserInfo, index) => (
//                                                                         <div className='my-1 mx-1'>
//                                                                             <HomeUserPreviewCard preview_object={otherUserInfo}></HomeUserPreviewCard>
//                                                                         </div>
//                                                                     ))}
//                                                                 </div>
                                                            
                                                            
//                                                             <div style={{ textAlign: 'center' }} className='w-100 mb-1'>Waiting</div>
//                                                                 <div className='w-100 d-flex flex-wrap justify-content-center'>
                                                                    
//                                                                     {otherUsers.filter(otherUserInfo => otherUserInfo.has_accepted_invite === false).map((otherUserInfo, index) => (
//                                                                         <div className='my-1 mx-1'>
//                                                                             <HomeUserPreviewCard preview_object={otherUserInfo}></HomeUserPreviewCard>
//                                                                         </div>
//                                                                     ))}
//                                                                 </div>
                                                            
//                                                         </>
//                                                         :
//                                                         <>
//                                                         <div style={{ textAlign: 'center' }} className='w-100 mb-1'>Invitees</div>
//                                                             <div className='w-100 d-flex flex-wrap justify-content-center'>
                                                                
//                                                                 {otherUsers.map((otherUserInfo, index) => (
//                                                                     <div className='my-1 mx-1'>
//                                                                         <HomeUserPreviewCard preview_object={otherUserInfo}></HomeUserPreviewCard>
//                                                                     </div>
//                                                                 ))}
//                                                             </div>
//                                                         </>
//                                                     :
                                                    
//                                                         <div className='w-100 d-flex flex-wrap justify-content-center'>

//                                                             {otherUsers.map((otherUserInfo, index) => (
//                                                                 <div className='my-1 mx-1'>
//                                                                     <HomeUserPreviewCard preview_object={otherUserInfo}></HomeUserPreviewCard>
//                                                                 </div>
//                                                             ))}
//                                                             {otherNonUsers.map((otherUserInfo, index) => (
//                                                                 <div className='my-1 mx-1'>
//                                                                     <HomeUserPreviewCard preview_object={otherUserInfo} nonUser={true}></HomeUserPreviewCard>
//                                                                 </div>
//                                                             ))}
//                                                         </div>
                                                    
//                                             }

                                        
//                                     </StyledCard.Body>


//                                 </StyledCard>}
//                     </div>
//                 </div >


//                 {showAddModal == true && 
                    
//                     <CustomModal
//                         show={showAddModal}
//                         setShow={setShowAddModal}
//                         title={"Add people to pool"}
//                         message={""}
//                         setMessage={setShowAddModal}
//                         actionText="Close"
//                         actionFunction={handleCloseAddButton}
//                         handleClose={handleCloseAddButton}
//                         showCloseButton={false}
//                     >
//                         <StyledParagraph>After you join your pool and it activates (in  <PoolDeadlineTimer invitationDeadline={publicDetails.invitation_deadline} signDeadline={publicDetails.sign_deadline} onDeadlineExpire={handleDeadlineExpiry}></PoolDeadlineTimer>), you will be able to propose new members, vote them in, and start growing your success pool. 
 
//                         You will also be able to change your pool name.</StyledParagraph>
//                     </CustomModal>

//                 }


//                 {buttonOptionsIndex != null ?
//                     <CustomModal show={modalShow}
//                         setShow={setModalShow}
//                         title={buttonOptions[buttonOptionsIndex].title}
//                         actionText={buttonOptions[buttonOptionsIndex].modalButtonText}
//                         message={modalMessage}
//                         setMessage={setModalMessage}
//                         actionFunction={buttonOptions[buttonOptionsIndex].action}
//                         handleClose={handleClose}
//                     >
//                         {
//                             buttonOptionsIndex === 0 ?
//                                 (
//                                     <>
//                                         By accepting this invite to view sections of our platform, you agree to keep all content,
//                                         including the Success Pooling Agreement, confidential and acknowledge that it is the
//                                         intellectual property of Prospinity. Accepting this invite does not constitute a binding legal
//                                         commitment to any terms other than the confidentiality and intellectual property
//                                         acknowledgment itself. For full details, see our <a href="/agreement-confidentiality">Confidentiality and Intellectual Property Agreement</a>.
//                                     </>
//                                 ) :
//                                 buttonOptions[buttonOptionsIndex].modalText
//                         }
//                         {
//                             buttonOptionsIndex == 3 ?

//                                 <ManagePool
//                                     editPoolData={editPoolData}
//                                     setEditPoolData={setEditPoolData}
//                                     setModalMessage={setModalMessage}
//                                     slug={slug}
//                                     setDelPool={setDelPool}
//                                     delPool={delPool}
//                                     removeInvitees={removeInvitees}
//                                     setRemoveInvitees={setRemoveInvitees}
//                                     addInvitees={addInvitees}
//                                     setAddInvitees={setAddInvitees}
//                                     manageReload={manageReload}
//                                     inviteEmails={inviteEmails}
//                                     setInviteEmails={setInviteEmails}
//                                 ></ManagePool>

//                                 : <></>}

//                         {buttonOptionsIndex == 2 || buttonOptionsIndex == 4 ? signingInterface ? (
//                             <div style={{ height: '80vh', width: '100%' }}>
//                                 <iframe src={signingInterface} style={{ border: 'none', width: '100%', height: '100%' }} title="Signing Interface"></iframe>
//                             </div>
//                         ) : <Loading /> : <></>}
//                     </CustomModal>
//                     :
//                     <></>
//                 }
                
                
//                 <SigningRecap
//                     show={showRecap}
//                     tutorialPart={recapPart}
//                     onNext={handleNextRecap}
//                     onSkip={handleSkipRecap}
//                     onBack={handleBackRecap}
//                     poolDetails={{name: privateDetails.name, contribution_percent: publicDetails.contribution_percent, time_horizon: publicDetails.time_horizon, member_count: publicDetails.member_count, captain_first_name: captainInfo.first_name, captain_last_name: captainInfo.last_name}}
//                 />
//             </>

//     );
// }

// export default PoolPage;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { convertUTCtoLocalDateTime } from '../common/CriticalFunctions';
// import { Accordion, Card, Form } from 'react-bootstrap';
// import MultiSelectUsers from '../forms/MultiSelectUsers';
// import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { myAxiosInstance } from '../../api/axiosConfig';
// import MultiSelectEmails from '../forms/MultiSelectEmails';

// import { StyledAccordion, StyledForm, StyledFormGroup, StyledFormLabel, StyledFormControl, StyledFormCheck, StyledParagraph } from '../common/StyledBootstrap';


// //make manage invitations separate, or update otherusers
// //what happes if close after some updates
// //anyway to make all of it one put request


// function ManagePool({ editPoolData, setEditPoolData, slug, setModalMessage, setDelPool, delPool, removeInvitees, setRemoveInvitees, addInvitees, setAddInvitees, manageReload, inviteEmails, setInviteEmails }) {

//     const [isBottomLoading, setIsBottomLoading] = useState(true);
//     const [otherUsers, setOtherUsers] = useState([]);


//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setEditPoolData({ ...editPoolData, [name]: value });
//     };

//     useEffect(() => {
//         const getPoolData = async () => {
//             try {
//                 setDelPool(false);
//                 setRemoveInvitees([]);
//                 setAddInvitees([]);
//                 setOtherUsers([]);
//                 setIsBottomLoading(true);
//                 const response = await myAxiosInstance.get('/api/pools/' + slug + '/');
//                 const data = response.data.public_details; // Storing the response data in a local variable
//                 setEditPoolData({ ...data, name: response.data.private_details.name, invite_emails: [...response.data.other_users.map(user => user.email)], percent_signitures_to_activate: response.data.extra_private_details.percent_signitures_to_activate });
//                 setOtherUsers(response.data.other_users);
//                 setInviteEmails(response.data.extra_private_details.invited_users_emails);
//             } catch (error) {
//                 setModalMessage('Error fetching pool details');
//             } finally {
//                 setIsBottomLoading(false);
//             }
//         };

//         getPoolData();
//     }, [slug, manageReload]);



//     return (
//         <>
//             {
//                 isBottomLoading ?
//                     <div>
//                         <StyledParagraph>Loading...</StyledParagraph>
//                     </div>

//                     :

//                     <StyledAccordion defaultActiveKey="0">
//                         {/* Edit Pool Formation Settings */}

//                         <StyledAccordion.Item eventKey="0">
//                             <StyledAccordion.Header>Edit Pool Formation Settings <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledAccordion.Header>
//                             <StyledAccordion.Body>
//                                 <StyledForm>
//                                     {/* Fields for Pool Formation Settings */}
//                                     <StyledFormGroup>
//                                         <StyledFormLabel>Name</StyledFormLabel>
//                                         <StyledFormControl
//                                             type="text"
//                                             name="name"
//                                             value={editPoolData.name || ''}
//                                             onChange={handleInputChange}
//                                         />
//                                     </StyledFormGroup>
//                                     <StyledFormGroup>
//                                         <StyledFormLabel>Invitation Deadline (in your timezone)</StyledFormLabel>
//                                         <StyledFormControl
//                                             type="datetime-local"
//                                             name="invitation_deadline"
//                                             value={convertUTCtoLocalDateTime(editPoolData.invitation_deadline) || ''}
//                                             onChange={e => setEditPoolData({ ...editPoolData, invitation_deadline: new Date(e.target.value).toISOString() })}
//                                         />
//                                     </StyledFormGroup>
//                                     <StyledFormGroup>
//                                         <StyledFormLabel>Sign Deadline (in your timezone)</StyledFormLabel>
//                                         <StyledFormControl
//                                             type="datetime-local"
//                                             name="sign_deadline"
//                                             value={convertUTCtoLocalDateTime(editPoolData.sign_deadline) || ''}
//                                             onChange={e => setEditPoolData({ ...editPoolData, sign_deadline: new Date(e.target.value).toISOString() })}
//                                         />
//                                     </StyledFormGroup>
//                                     <StyledFormGroup className="mb-3">
//                                         <StyledFormLabel>Percent Signatures to Activate (in percent):</StyledFormLabel>
//                                             <StyledFormControl
//                                                 type="number"
//                                                 name="percent_signitures_to_activate"
//                                                 value={editPoolData.percent_signitures_to_activate || 0}
//                                                 onChange={handleInputChange}
//                                             />
//                                     </StyledFormGroup>
                                    
//                                 </StyledForm>


//                             </StyledAccordion.Body>
//                         </StyledAccordion.Item>
//                         <StyledAccordion.Item eventKey="1">
//                             <StyledAccordion.Header>Edit Pool Terms  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledAccordion.Header>
//                             <StyledAccordion.Body>

//                                 <StyledForm>
//                                     {/* Fields for Pool Terms */}
//                                     <StyledFormGroup>
//                                         <StyledFormLabel>Threshold ($)</StyledFormLabel>
//                                         <StyledFormControl
//                                             type="text"
//                                             name="threshold"
//                                             value={editPoolData.threshold || 0}
//                                             onChange={handleInputChange}
//                                         />
//                                     </StyledFormGroup>
//                                     <StyledFormGroup>
//                                         <StyledFormLabel>Contribution Percent (%)</StyledFormLabel>
//                                         <StyledFormControl
//                                             type="number"
//                                             name="contribution_percent"
//                                             value={editPoolData.contribution_percent || 0}
//                                             onChange={handleInputChange}
//                                         />
//                                     </StyledFormGroup>
//                                     <StyledFormGroup>
//                                         <StyledFormLabel>Time Horizon (years)</StyledFormLabel>
//                                         <StyledFormControl
//                                             type="number"
//                                             name="time_horizon"
//                                             value={editPoolData.time_horizon || 0}
//                                             onChange={handleInputChange}
//                                         />
//                                     </StyledFormGroup>
//                                     <StyledFormGroup>
//                                         <StyledFormLabel>Minimum Income ($)</StyledFormLabel>
//                                         <StyledFormControl
//                                             type="text"
//                                             name="minimum_income"
//                                             value={editPoolData.minimum_income || 0}
//                                             onChange={handleInputChange}
//                                         />
//                                     </StyledFormGroup>

//                                 </StyledForm>

//                             </StyledAccordion.Body>
//                         </StyledAccordion.Item>

//                         <StyledAccordion.Item eventKey="2">
//                             <StyledAccordion.Header>Manage Invitations  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledAccordion.Header>
//                             <StyledAccordion.Body>
//                                 <MultiSelectUsers
//                                     otherUsers={otherUsers}
//                                     value={removeInvitees}
//                                     onChange={(selected) => {
//                                         setRemoveInvitees(selected);
//                                     }}
//                                     label="Remove Invitees"
//                                     endpoint={false}
//                                 />
//                                 <MultiSelectUsers
//                                     otherUsers={otherUsers}
//                                     value={addInvitees}
//                                     onChange={(selected) => {
//                                         setAddInvitees(selected);
//                                     }}
//                                     label="Add Invitees"
//                                     endpoint={true}
//                                 />
//                                 <MultiSelectEmails
//                                     value={inviteEmails}
//                                     label="Emails"
//                                     onChange={(selected) => { setInviteEmails(selected) }}
//                                 />
//                             </StyledAccordion.Body>
//                         </StyledAccordion.Item>
//                         <StyledAccordion.Item eventKey="3">
//                             <StyledAccordion.Header>Delete Pool  <FontAwesomeIcon icon={faAngleDown} className="ms-auto" style={{ transform: 'translateY(-50%)', position: 'absolute', right: '1rem', top: '50%' }} /></StyledAccordion.Header>
//                             <StyledAccordion.Body>
//                                 <StyledFormGroup>
//                                     <div>
//                                         <StyledFormCheck
//                                             type="checkbox"
//                                             name="reset_if_pool_fails"
//                                             id="reset_if_pool_fails"
//                                             label="Before saving changes, make sure you are absolutely certain."
//                                             checked={delPool}
//                                             onChange={e => setDelPool(!delPool)}
//                                         />
//                                     </div>
//                                 </StyledFormGroup>
//                             </StyledAccordion.Body>
//                         </StyledAccordion.Item>
//                     </StyledAccordion>
//             }
//         </>


//     );
// }

// export default ManagePool;


// import { Nav, Form, FormControl, Row, Col, Container, InputGroup, Dropdown, Card, Stack, Button, FormGroup, FormLabel } from 'react-bootstrap';
// import React, { useEffect, useState, useRef } from 'react';
// import { apiP } from '../components/common/CriticalFunctions';
// import { useNavigate } from 'react-router-dom';
// import MultiSelectUsers from '../components/forms/MultiSelectUsers';
// import MultiSelectEmails from '../components/forms/MultiSelectEmails';
// import { convertUTCtoLocalDateTime } from '../components/common/CriticalFunctions';
// import "react-datepicker/dist/react-datepicker.css";
// import { StyledParagraph, StyledCard, StyledForm, StyledFormCheck, StyledFormControl, StyledFormLabel, StyledFormSelect, StyledFormGroup, StyledButton, StyledModal, StyledH4 } from '../components/common/StyledBootstrap';
// import { useAuth } from '../contexts/AuthContext';
// import { myAxiosInstance } from '../api/axiosConfig';
// import AddUserToPool from '../components/pools/AddUserToPool';
// import { useTheme } from 'styled-components';
// import { useMediaQuery } from 'react-responsive';
// import { StyledH2 } from '../components/common/StyledBootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
// import Loading from '../components/common/Loading';


// function StreamLinedCreatePool() {
//     const [message, setMessage] = useState('');
//     //const [error, setError] = useState({ hasError: false, message: '', step: 1 });


//     const { userFirstName } = useAuth();

//     const themeMode = useTheme();
//     const isMdOrLarger = useMediaQuery({ minWidth: 768 });
//     const bottomRef = useRef(null);

//     const [error, setError] = useState(false);

//     const packages = [
//         {
//             name: "Alpha",
//             description: "Balanced (most popular)",
//             contribution: "1%",
//             timeHorizon: "10 years"
//         },
//         {
//             name: "Gamma",
//             description: "Community focused",
//             contribution: "2%",
//             timeHorizon: "5 years"
//         },
//         {
//             name: "Delta",
//             description: "Real diversification",
//             contribution: "5%",
//             timeHorizon: "15 years"
//         }
//     ];


//     const starNames = [
//         "Andromeda", "Perseus", "Orion", "Callisto", "Athena", "Apollo", "Selene", "Helios",
//         "Elektra", "Zephyros", "Pandora", "Hermes", "Nyx", "Asteria", "Eos", "Hyperion",
//         "Phoebe", "Dione", "Pallas", "Artemis", "Astraeus", "Gaia", "Icarus", "Theseus",
//         "Achilles", "Hestia", "Hercules", "Cronus", "Triton", "Hesperos", "Aegis", "Euryale",
//         "Atlas", "Boreas", "Orpheus", "Calypsa", "Clytemnestra", "Leda", "Ariadne", "Thalassa",
//         "Chiron", "Nemesis", "Pegasus", "Castor", "Pollux", "Erebus", "Eudora", "Phaedra",
//         "Hades", "Iris", "Helene", "Oceanus", "Theia", "Psyche", "Melpomene", "Circe",
//         "Medusa", "Typhon", "Pleiades", "Daphne", "Selene", "Deimos", "Phobos", "Eros",
//         "Hypnos", "Thanatos", "Kronos", "Prometheus", "Adonis", "Persephone", "Dionysus"
//     ];


//     const [poolName, setPoolName] = useState("");
//     const [poolPackage, setPoolPackage] = useState(0);
//     const poolMembers = useRef([{
//         company: '',
//         email: '',
//         first_name: '',
//         graduation_date: '',
//         is_verified: '',
//         last_name: '',
//         major_or_grad_program: '',
//         pooled: '',
//         portfolio_count: '',
//         profile_picture: '',
//         slug: '',
//         university: ''}]);
//     const [triggerRender, setTriggerRender] = useState(0);
//     /*const subComponentRefs = useRef([]);
//     const addSubComponentRef = (ref) => {
//         if (ref && !subComponentRefs.current.includes(ref)) {
//             subComponentRefs.current.push(ref);
//         }
//     };*/


//     /*const changePoolMembersLength = (len) => {
//         if (poolMembers.current.length < len) {
//             const newMembers = [...poolMembers.current];
//             for (let i = 0; i < (len - poolMembers.current.length); i++) {
//                 newMembers.push({});
//             }
//             poolMembers.current = newMembers;
//             setTriggerRender(prev => prev + 1); // Force re-render
//         } else if (poolMembers.current.length > len) {
//             poolMembers.current = poolMembers.current.slice(0, len);
//             setTriggerRender(prev => prev + 1); // Force re-render
//         }
//     };*/



//     useEffect(() => {
//         if (bottomRef.current) {
//             bottomRef.current.scrollIntoView({ behavior: 'smooth' });
//         }
//     }, [poolMembers]); // Scrolls to the bottom each time an invite is added


//     const handleAddInvite = () => {
//         poolMembers.current.push({
//             company: '',
//             email: '',
//             first_name: '',
//             graduation_date: '',
//             is_verified: '',
//             last_name: '',
//             major_or_grad_program: '',
//             pooled: '',
//             portfolio_count: '',
//             profile_picture: '',
//             slug: '',
//             university: ''
//         });
//         setTriggerRender(prev => prev + 1); // Force re-render
//         //addSubComponentRef();
//     }



//     const handleDeleteUser = (index) => {
//         poolMembers.current = poolMembers.current.filter((_, i) => i !== index);
//         //subComponentRefs.current = subComponentRefs.current.filter((_, i) => i !== index);
//         setTriggerRender(prev => prev + 1); // Force re-render
//     };




//     const editPoolMember = (selected, index) => {
//         poolMembers.current[index] = {...poolMembers.current[index], ...selected}; // Directly update ref without triggering re-render
//     };

//     const getDefaultDeadline = (daysToAdd = 7) => {
//         const now = new Date();
//         now.setDate(now.getDate() + daysToAdd); // Add default days to current date
//         return now.toISOString().slice(0, 16); // Convert to 'YYYY-MM-DDTHH:mm' format
//     };

//     const { userSlug } = useAuth();


//     const navigate = useNavigate();
//     const [currentStep, setCurrentStep] = useState(0);
    



//     const [isBottomLoading, setIsBottomLoading] = useState(false);



//     const goToNextStep = () => {
//         if (currentStep == 3)
//         {
//             handleInfoSubmit();
//         }
//         else{
//             setCurrentStep(prev => prev + 1);

//         }
        
        
//     };

//     // Function to navigate to the previous form step
//     const goToPreviousStep = () => {
//         setCurrentStep(prev => prev - 1);
//     };





//     const prepareData = () => {
//         let data = {
//             name: poolName === "" ? userFirstName + "'s Pool" : poolName,
//             description: '',
//             threshold: '0',
//             contribution_percent: poolPackage == 0 ? 1 : poolPackage == 1 ? 2 : 5,
//             time_horizon: poolPackage == 0 ? 10 : poolPackage == 1 ? 5 : 15,
//             invitees: poolMembers.current.map((member, index) => ({email: member.email, full_name: member.first_name + " " + member.last_name})),
//             invitation_deadline: getDefaultDeadline(8),
//             sign_deadline: getDefaultDeadline(8),
//             percent_signitures_to_activate: 50,
//             minimum_income: 0
//         }


//         //data.invitation_deadline = data.sign_deadline;

//         return data;
//     };


//     const handleInfoSubmit = async () => {
//         const data = prepareData();
//         setIsBottomLoading(true);

//         try {
//             const response  = await myAxiosInstance.post('/api/create-pools/', data);
//             navigate('/dashboard/home');
//         }
//         catch (error) 
//         {
//             setError(true);
//         }

//         /*await apiP(
//             data,
//             'create-pools/', // Make sure this endpoint is correct
//             setMessage,
//             'Pool Created! Taking you to your pools...',
//             navigate,
//             '/dashboard/home', // Update this navigation path as needed
//             null, // Update or remove these parameters as needed
//             null,
//             null,
//             'post'
//         );*/
//         setIsBottomLoading(false);
//     };


//     /*const gatherDataFromSubComponents = () => {
//         const allData = subComponentRefs.current.map(ref => ref?.getData());
//     };*/


//     return (
//         <Row className='justify-content-center'>
//             <Col className='col-lg-8 col-md-10'>
//                 <StyledCard>
//                     <StyledCard.Body>
//                         {currentStep === 0 && (
//                             <>

//                                 <StyledH4 className='mb-2'><strong>Create your own success pool</strong></StyledH4>
//                                 <StyledParagraph>
//                                     It takes just a few minutes. As the pool captain, you have the privilege of choosing the terms and members to invite.
//                                 </StyledParagraph>
//                                 <StyledParagraph>
//                                     You should have <i>at least one member in mind</i> to invite and create the initial agreement (after that, if you would like, existing members can vote other people in).
//                                 </StyledParagraph>
//                                 <StyledParagraph>
//                                     Members don’t already have to be on the Prospinity platform to be invited to a pool! We can send an invite to their university email address so they can create an account.
//                                 </StyledParagraph>
//                             </>
//                         )}

//                         {currentStep === 1 && (
//                             <>
//                                 <StyledH4 className='mb-3'><strong>What should we call it?</strong></StyledH4>

//                                 <StyledFormGroup className="mb-3">
//                                     <InputGroup>
//                                         <StyledFormControl
//                                             value={poolName}
//                                             onChange={(e) => setPoolName(e.target.value)}
//                                             placeholder='Enter your pool name'
//                                         />
//                                     </InputGroup>
//                                 </StyledFormGroup>

//                                 <StyledParagraph className='ml-2' style={{ fontSize: '0.7rem' }}>
//                                     Can't think of a name? <a
//                                         onClick={() => {
//                                             const randomStarName = starNames[Math.floor(Math.random() * starNames.length)];
//                                             setPoolName(randomStarName);
//                                         }}
//                                         style={{ cursor: 'pointer', color: themeMode.primary, textDecoration: 'underline' }}
//                                     >
//                                         Generate one
//                                     </a>
//                                 </StyledParagraph>

//                             </>
//                         )}
//                         {currentStep === 2 && (

//                             <>
//                                 <StyledH4 className='mb-3'><strong>Select a pool type...</strong></StyledH4>
//                                 {!isMdOrLarger &&
//                                     packages.map((pkg, index) => (
//                                         <StyledCard
//                                             key={index}
//                                             className='my-2'
//                                             onClick={() => setPoolPackage(index)}
//                                             style={{
//                                                 width: '100%',
//                                                 boxShadow: poolPackage === index ? '0 6px 12px rgba(0, 0, 0, 0.2)' : '0 4px 8px rgba(0, 0, 0, 0.1)',
//                                                 transition: 'transform 0.3s ease, background-color 0.3s ease',
//                                                 backgroundColor: poolPackage === index ? themeMode.primary : themeMode.surface,
//                                                 color: poolPackage === index ? themeMode.onPrimary : themeMode.onSurface,
//                                                 transform: poolPackage === index ? 'scale(1.04)' : 'scale(1)',
//                                                 cursor: 'pointer', // Added to make it clear that the card is clickable
//                                                 border: poolPackage === index ? `2px solid ${themeMode.primaryVariant}` : '2px solid transparent'
//                                             }}
//                                             onMouseEnter={(e) => {
//                                                 e.currentTarget.style.transform = 'scale(1.04)'; // Hover effect to make it more clickable
//                                             }}
//                                             onMouseLeave={(e) => {
//                                                 e.currentTarget.style.transform = poolPackage === index ? 'scale(1.04)' : 'scale(1)';
//                                             }}
//                                         >
//                                             <StyledCard.Body>
//                                                 <StyledCard.Title><strong>{pkg.name}</strong></StyledCard.Title>
//                                                 <StyledCard.Text>
//                                                     {pkg.description}<br />
//                                                     <strong>{pkg.contribution}</strong><br />
//                                                     <strong>{pkg.timeHorizon}</strong>
//                                                 </StyledCard.Text>
//                                             </StyledCard.Body>
//                                         </StyledCard>
//                                     ))
//                                 }
//                                 {isMdOrLarger &&
//                                     <Row className="justify-content-center mb-3">
//                                         {packages.map((pkg, index) => (
//                                             <Col md={4} key={index} className="d-flex">
//                                                 <StyledCard
//                                                     onClick={() => setPoolPackage(index)}
//                                                     style={{
//                                                         width: '400px',
//                                                         boxShadow: poolPackage === index ? '0 6px 12px rgba(0, 0, 0, 0.2)' : '0 4px 8px rgba(0, 0, 0, 0.1)',
//                                                         transition: 'transform 0.3s ease, background-color 0.3s ease',
//                                                         backgroundColor: poolPackage === index ? themeMode.primary : themeMode.surface,
//                                                         color: poolPackage === index ? themeMode.onPrimary : themeMode.onSurface,
//                                                         transform: poolPackage === index ? 'scale(1.04)' : 'scale(1)',
//                                                         cursor: 'pointer', // Added to make it clear that the card is clickable
//                                                         border: poolPackage === index ? `2px solid ${themeMode.primaryVariant}` : '2px solid transparent'
//                                                     }}
//                                                     onMouseEnter={(e) => {
//                                                         e.currentTarget.style.transform = 'scale(1.04)'; // Hover effect to make it more clickable
//                                                     }}
//                                                     onMouseLeave={(e) => {
//                                                         e.currentTarget.style.transform = poolPackage === index ? 'scale(1.04)' : 'scale(1)';
//                                                     }}
//                                                 >
//                                                     <StyledCard.Body>
//                                                         <StyledCard.Title><strong>{pkg.name}</strong></StyledCard.Title>
//                                                         <StyledCard.Text>
//                                                             {pkg.description}<br />
//                                                             <strong>{pkg.contribution}</strong><br />
//                                                             <strong>{pkg.timeHorizon}</strong>
//                                                         </StyledCard.Text>
//                                                     </StyledCard.Body>
//                                                 </StyledCard>
//                                             </Col>
//                                         ))}
//                                     </Row>
//                                 }
//                             </>

//                         )}

//                         {/*currentStep === 3 && (
//                             <>
//                                 <StyledH4 className='mb-3'><strong>Approximately, how many people would you initially like to invite?</strong></StyledH4>
//                                 <StyledFormGroup className="mb-3">
//                                     <InputGroup>
//                                         <StyledFormControl
//                                             type="number"
//                                             value={poolMembers.length === 0 ? '' : poolMembers.length} // Allow empty input
//                                             onChange={(e) => {
//                                                 const value = e.target.value;
//                                                 if (value === '' || /^\d+$/.test(value)) { // Allow empty or numeric input only
//                                                     changePoolMembersLength(value === '' ? 0 : parseInt(value, 10));
//                                                 }
//                                             }}
//                                         />
//                                     </InputGroup>
//                                 </StyledFormGroup>
//                             </>
//                         )*/}


//                         {currentStep == 3 &&
//                             <>
//                                 <StyledH4 className='mb-3'><strong>Fill in members below...</strong></StyledH4>
//                                 <StyledParagraph>If you can't find someone, go ahead and type in their full name and university email, and we will send them an invite to the platform and the pool.</StyledParagraph>
//                                 <div className='px-2' style={{ height: "50vh", overflowY: 'auto' }}>
//                                 {poolMembers.current.map((member, i) => (
//                                         <AddUserToPool
//                                             key={member.email ? member.email : `pool-member-${i}`} //// Unique key
//                                             otherUsers={[]}
//                                             value={member}
//                                             onChange={(selected) => {
//                                                 editPoolMember(selected, i);
//                                             }}
//                                             label={i + 1}
//                                             endpoint={true}
//                                             deleteUser={() => { handleDeleteUser(i) }}
//                                         />
//                                     ))}
//                                     {/* Bottom marker for scrolling */}
//                                     <div className='w-100 d-flex justify-content-center mb-2'>
//                                         <StyledButton style={{fontSize: '0.8rem'}} onClick={handleAddInvite}>
//                                             <FontAwesomeIcon icon={faPlus} /> Add Member
//                                         </StyledButton>
//                                     </div>

//                                     <div ref={bottomRef} />
//                                 </div>


//                                 {/*<MultiSelectEmails
//                                     value={inviteEmails}
//                                     label="Emails"
//                                     onChange={(selected) => { setInviteEmails(selected) }}
//                                 />*/}


//                                 {/*<StyledParagraph style={{ fontSize: '0.5rem' }}>
//                                     <>
//                                         By clicking "Create Pool" and viewing certain sections of our platform, you agree to keep all content,
//                                         including the Success Pooling Agreement, confidential and acknowledge that it is the
//                                         intellectual property of Prospinity. Creating this pool does not constitute a binding legal
//                                         commitment to any terms other than the confidentiality and intellectual property
//                                         acknowledgment itself. For full details, see our <a href="/agreement-confidentiality">Confidentiality and Intellectual Property Agreement</a>.
//                                     </>
//                                 </StyledParagraph>*/}

//                             </>
//                         }

//                         {currentStep != 4 ?
//                             <Row className='d-flex justify-content-center mt-1'>
//                                 <div>
//                                 {error && currentStep == 3 ? <StyledParagraph className='mb-2 text-center' style={{ fontSize: '0.7rem', color: themeMode.error }}>Please make sure you have entered all of the pool information correctly.</StyledParagraph> : <></>}
//                                 </div>
//                                 <Col md={8} className='d-flex justify-content-center'>
//                                     {currentStep == 0 ?
//                                         <StyledButton onClick={goToNextStep} className='w-100'>
//                                             Get Started
//                                         </StyledButton>
//                                         : <></>}
//                                     {currentStep != 0 ?
//                                         <>
//                                             <StyledButton onClick={goToPreviousStep} className='mr-2 w-50' style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }}>
//                                                 Back
//                                             </StyledButton>
//                                             <StyledButton onClick={goToNextStep} className='ml-2 w-50'>
//                                                 {currentStep == 3 ? isBottomLoading ? <Loading /> : <>Send Invites</> : <>Next</>}
//                                             </StyledButton>
//                                         </>
//                                         : <></>}

//                                 </Col>

//                             </Row> : <></>}
                        
//                         {currentStep == 4 &&
//                             <>
//                                 <StyledParagraph className='mt-2' style={{ fontSize: '0.7rem' }}>
//                                     *If you are a student org leader, please email <a
//                                         href="mailto:partnerships@prospinity.com"
//                                         style={{ color: themeMode.primary, textDecoration: 'underline', cursor: 'pointer' }}
//                                     >
//                                         partnerships@prospinity.com
//                                     </a> if you have any questions about your partnership.
//                                 </StyledParagraph>
//                                 <StyledParagraph style={{ fontSize: '0.7rem' }}>
//                                     *Want more customization? Send an email to <a
//                                         href="mailto:pools@prospinity.com"
//                                         style={{ color: themeMode.primary, textDecoration: 'underline', cursor: 'pointer' }}
//                                     >
//                                         pools@prospinity.com
//                                     </a> with your request, and we are happy to set up a call and a custom solution for you.
//                                 </StyledParagraph></>}






//                         {/*<StyledModal show={error.hasError && error.message !== ''} centered>
//                             <StyledModal.Header>{isBottomLoading ? <>Creating Pool...</> : <>Pool Creation Failed</>}</StyledModal.Header>


//                             <StyledModal.Body>{error.message}</StyledModal.Body>
//                             <StyledModal.Footer>
//                                 {isBottomLoading ? <></> : <StyledButton onClick={() => {

//                                     setCurrentStep(error.step);
//                                     setError({ hasError: false, message: '', step: 1 })
//                                 }}>Fix</StyledButton>}

//                             </StyledModal.Footer>

//                         </StyledModal>*/}

//                     </StyledCard.Body>
//                 </StyledCard>
//             </Col>
//         </Row >

//     );
// }



// export default StreamLinedCreatePool;