import { useTheme } from "styled-components";
import styled from "styled-components";
import { PrimaryButton } from "../common/Buttons";
import { useMediaQuery } from 'react-responsive';

const GroupInMind = () => {
    const theme = useTheme();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });


    return (
        <Wrapper theme={theme}>

            <TextContainer>
                <Title theme={theme}>HAVE A GROUP IN MIND?</Title>
                <Description theme={theme}>
                    If you already have a group in mind, submit a request to create a custom pool. We will review the request and set up the pool for you if it is a good fit.
                </Description>
                <PrimaryButton size={isMobile ? "sm" : "lg"} style={{ marginTop: isMobile ? "0.75rem" : "1.5rem" }} onClick={() => window.open("https://o0bf65aidiq.typeform.com/to/cAMyJdcD", "_blank")}>CREATE A POOL</PrimaryButton>
            </TextContainer>

            {/* Mobile-specific image */}
            {isMobile && (
                <ImageContainer>
                    <img src="/assets/poolPageBackgroundMobile.png" alt="Pool Graphic" />
                </ImageContainer>
            )}
        </Wrapper>
    );
};

export default GroupInMind;

const Wrapper = styled.div`
    width: 100%;
    background-image: url("/assets/poolPageBackground.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 1.5rem;
    padding: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;

    @media (max-width: 768px) {
        background-image: none;
        background-color: ${({ theme }) => theme.white};
        flex-direction: column;
        align-items: center;
        padding: 1.5rem 1.25rem;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    img {
        max-width: 280px; /* Ensures it's not too big */
        width: 100%;
        height: auto;
    }
`;


const TextContainer = styled.div`
    max-width: 500px;

    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const Title = styled.h2`
    font-size: 2rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
    margin: 0;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const Description = styled.p`
    font-size: 1rem;
    color: ${({ theme }) => theme.textSecondary};
    margin: 0;
    margin-top:  0.75rem;
    line-height: 1.6;

    @media (max-width: 768px) {
        font-size: 0.875rem;
    }
`;
