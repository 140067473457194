import { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";

import { ErrorText } from "./MiscComponents";

export default function ProfilePictureUpload({ image, setImage, error, showExplainText=true }) {
    const theme = useTheme();
    const [preview, setPreview] = useState(image); // ✅ Store preview URL

    // Update preview when receiving an API image URL
    useEffect(() => {
        if (typeof image === "string") {
            setPreview(image); // ✅ Use API-provided image URL
        }
    }, [image]);

    // Handle image selection
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file); // ✅ Store actual file to send to API
            setPreview(URL.createObjectURL(file)); // ✅ Show preview for selected image
        }
    };

    return (
        <ContainerWrapper>
            <Container>
                <ImageWrapper>
                    <Label htmlFor="profile-upload">
                        {preview ? (
                            <ProfileImage src={preview} alt="Profile" />
                        ) : (
                            <Placeholder theme={theme}>
                                <GalleryIcon src="/assets/Gallery.svg" alt="Gallery Icon" />
                            </Placeholder>
                        )}
                    </Label>
                    <FileInput id="profile-upload" type="file" accept="image/*" onChange={handleImageChange} />
                </ImageWrapper>

                {showExplainText && 
                    <TextContainer>
                        <UploadText theme={theme}>Add a profile picture</UploadText>
                        <SizeText theme={theme}>50MB max</SizeText>
                    </TextContainer>
                }               
            </Container>

        {error && <ErrorText>{error}</ErrorText>}

        </ContainerWrapper>
    );
}

// Styled Components

const ContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const ImageWrapper = styled.div`
    position: relative;
    width: 5rem;
    height: 5rem;
`;

const Label = styled.label`
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
`;

const Placeholder = styled.div`
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.primaryLightActive};
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s ease-in-out;

    &:hover {
        background: ${({ theme }) => theme.primaryLightHover};
    }
`;

const GalleryIcon = styled.img`
    margin: 0.2rem 0 0 0.2rem;
`;

const FileInput = styled.input`
    display: none;
`;

const ProfileImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;

    &:hover {
        opacity: 0.92;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 5rem;
    flex: 1;
`;

const UploadText = styled.span`
    font-size: 1rem;
    font-weight: normal;
    color: ${({ theme }) => theme.textPrimary};
`;

const SizeText = styled.span`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.textSecondary};
    margin-top: 4px;
`;