//multiple refresh attempts are made before logging out, maybe overall, reqeusts are made after
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';


/**/
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;

export const myAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  });


/*
let isRefreshingToken = false;
let requestsQueue = [];
let tokenRefreshFailed = false;

const processQueue = (error) => {
    requestsQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve();
        }
    });

    requestsQueue = [];
};

//let cancelTokenSource = axios.CancelToken.source();

axios.interceptors.request.use(config => {
    if (tokenRefreshFailed) {
        return new Promise(() => {}); // Return a never-resolving Promise to halt further execution
    }
    //config.cancelToken = cancelTokenSource.token;
    return config;
}, error => {
    return Promise.reject(error);
});

const responseInterceptorId = axios.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;

        if (tokenRefreshFailed) {
            return Promise.reject(error);
        }

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            if (isRefreshingToken) {
                return new Promise((resolve, reject) => {
                    requestsQueue.push({ resolve, reject });
                })
                .then(() => {
                    return axios(originalRequest);
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
            }


            isRefreshingToken = true;
            axios.interceptors.response.eject(responseInterceptorId);
            return axios.post('/dj-rest-auth/token/refresh/')
                .then(() => {
                    isRefreshingToken = false;
                    axios.interceptors.response.use(responseInterceptorId);
                    //cancelTokenSource = axios.CancelToken.source();
                    processQueue(null);
                    return axios(originalRequest);
                })
                .catch((err) => {
                    tokenRefreshFailed = true;
                    //cancelTokenSource.cancel("Session expired: All requests cancelled.");
                    alert("Session expired. Please log in again.");
                    localStorage.removeItem('userToken'); // Or any other logout procedure
                    
                    window.location = '/signin';
                    //cancelTokenSource = axios.CancelToken.source();
                });
        }
        return Promise.reject(error);
    }
);*/

const refreshAuthLogic = (failedRequest) =>
        axios.post('/prospinity-auth/token/refresh/').then((tokenRefreshResponse) => {
        return Promise.resolve();
    }).catch((error) => {
        // Handle token refresh errors here
        // This could involve redirecting the user to the login page, showing an error message, etc.

        // Example: Redirect to login page
        localStorage.removeItem('userToken');
        // alert("Session expired.");
        window.location.href = '/signin';
        return Promise.reject(error);

    });

// Instantiate the interceptor
createAuthRefreshInterceptor(myAxiosInstance, refreshAuthLogic);


/*const refreshAuthLogic = failedRequest => axios.post('/dj-rest-auth/token/refresh/')
    .then(tokenRefreshResponse => {
        // Extract your new token from the response. 
        // This assumes the new token is in the response body directly. Adjust based on your API response structure.


        // Now that the new token is applied, retry the original request.
        return Promise.resolve();
    })
    .catch(error => {
        // If token refresh fails, clear any stored session and redirect to login for re-authentication.
        localStorage.removeItem('userToken'); // Assuming you're storing token in localStorage.
        window.location.href = '/signin';
        return Promise.reject(error); // Propagate the rejection so subsequent code knows the refresh failed.
    });
    createAuthRefreshInterceptor(axios, refreshAuthLogic);*/