// In ProtectedRoute.js
//import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

import Spinner from 'react-bootstrap/Spinner';

import { Container, Row, Col } from 'react-bootstrap';
import Loading from '../components/common/Loading';

import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
//import SignIn from './SignIn';
//import SignUp from './SignUp';
//import StyledModalClose from './StyledModalClose';
import { StyledModal, StyledButton, StyledParagraph, StyledH4 } from '../components/common/StyledBootstrap';
import { useNavigate } from 'react-router-dom';
//import PublicHome from './OldPublicHome';
import { useTheme } from 'styled-components';


export const ProtectedRoute = ({ children, requiredStage = null, signin = null }) => {
    const { token, accountStage, registrationStage, firstTime } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();


    //const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const themeMode = useTheme();


    useEffect(() => {
        if (!token) {
            //setIsLoading(true); // Show loading when no token
            // Simulate a delay to show the message and loading component
            const timeout = setTimeout(() => {
                setIsLoading(false); // Turn off loading after delay
            }, 1000); // Adjust delay time as needed

            return () => clearTimeout(timeout); // Clean up timeout on unmount or state change
        }
    }, [token]); // Run effect when token changes

    if (!signin && isLoading && !token) {
        // While loading, show a loading component or message
        return <Container fluid style={{ height: '100vh', backgroundColor: themeMode.background }} className='d-flex flex-column justify-content-center'>
            <Row className='justify-content-center text-center'>
                <div >
                    <StyledH4 className='mt-3'>Taking you to log in...</StyledH4>
                    <Spinner animation="grow"></Spinner>
                </div>
            </Row>
        </Container>; // Example loading component
    }

    if (!signin && !token && !isLoading) {
        // Redirect to sign in if not authenticated
        return <Navigate to="/signin" state={{ from: location }} replace />;
    }

  
    // Redirect to an appropriate page based on account stage
    if (requiredStage !== null && accountStage !== requiredStage) {
        if (accountStage == '1') {
            const stageRoutes = [
                "/finish-registration/name",      // 1 - Name
                "/finish-registration/university",  // 2 - University
                "/finish-registration/company",     // 3 - Company (optional)
                "/finish-registration/phone",     // 4 - Phone
                "/finish-registration/customize",   // 5 - Profile Picture, Tags, Clubs 
                "/finish-registration/verify-email",  // 6 = Verify Email
            ];
            return <Navigate to={"/finish-registration/name"} replace />;
        }

        // If verify-email, always redirect to get-started
        if ((accountStage === 3 || accountStage == '2') && location.pathname === "/finish-registration/verify-email" && firstTime) {
            return <Navigate to="/finish-registration/get-started" replace />;
        }

        if (accountStage == '3' || accountStage == '2') {
            return <Navigate to="/dashboard" replace />;
        }
    }

    // If registrationStage is 6, always redirect to verify-email
    if (requiredStage !== null && accountStage === 1) {
        if (registrationStage === 6 && location.pathname !== "/finish-registration/verify-email"){
            return <Navigate to="/finish-registration/verify-email" replace />;
        }
    }

    // If verify-email, always redirect to get-started
    if ((accountStage === 3 || accountStage == '2') && location.pathname === "/finish-registration/verify-email" && firstTime) {
        return <Navigate to="/finish-registration/get-started" replace />;
    }

    // If not first time get-started, go to dashboard
    if ((accountStage === 3 || accountStage == '2') && location.pathname === "/finish-registration/get-started" && !firstTime) {
        return <Navigate to="/dashboard/home" replace />;
    }

    // Automatic redirect to dashboard
    if (signin && token)
    {
        return <Navigate to="/dashboard" replace />;
    }

    return children;
};
