import React, { useState, useEffect, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { useErrorBoundary } from "react-error-boundary";
import { useMediaQuery } from "react-responsive";

import { MainContainer } from '../components/common/Containers';
import { RegistrationHeader, Subtitle, MobileBottomSection } from "../components/forms/MiscComponents";
import { Form } from '../components/forms/Form';
import FormInput from '../components/forms/FormInput';
import PhoneInput from '../components/forms/PhoneInput';
import VerificationCodeInput from "../components/forms/VerificationCodeInput";
import { PrimaryButton, SecondaryButton, ToggleButton } from '../components/common/Buttons';
import { myAxiosInstance } from "../api/axiosConfig";
import { useSettingsContext } from '../contexts/SettingsContext';

import { ReactComponent as EditProfile } from '../assets/icons/editProfile.svg';
import { ReactComponent as NotificationsIcon } from '../assets/icons/notificationSettings.svg';
import { ReactComponent as Key } from '../assets/icons/key.svg';
import { ReactComponent as Delete } from '../assets/icons/delete.svg';


function SettingsSidebar() {
    // Settings context
    const { sections, activeSetting, setActiveSetting } = useSettingsContext();

    // Theme
    const theme = useTheme();

    // Responsive
    const isNotDesktop = useMediaQuery({ maxWidth: 950 });

    // Settings Links
    const settingsLinks = [
        { id: "personal-info", label: isNotDesktop ? "Edit" : 'Edit personal information', icon: EditProfile },
        { id: "notifications", label: isNotDesktop ? "Notifications" : 'Notification settings', icon: NotificationsIcon },
        { id: "password", label: isNotDesktop ? "Password" : 'Change password', icon: Key },
        { id: "delete-account", label: isNotDesktop ? "Delete" : 'Delete account', icon: Delete },
    ];

    return (
        <div
            style={{
                width: isNotDesktop ? '200px' : '300px',
                height: '100vh',
                backgroundColor: theme.white,
                display: 'flex',
                flexDirection: 'column',
                padding: '1.5rem',
                borderRight: `1px solid ${theme.divider}`,
                position: 'fixed',
                top: 0,
                left: "260px",
                gap: "0.2rem",
                zIndex: 1000,
            }}
        >
            {/* Settings Title */}
            <div
                style={{
                    fontSize: '1.75rem',
                    fontWeight: '500',
                    color: theme.textPrimary,
                    marginBottom: '2rem',
                    marginLeft: '0.5rem',
                }}
            >
                Settings
            </div>

            {/* Settings Links */}
            {settingsLinks.map((link) => (
                <SettingsSidebarLink 
                    key={link.id} 
                    label={link.label} 
                    icon={link.icon} 
                    theme={theme} 
                    isActive={activeSetting === link.id} 
                    onClick={() => {
                        setActiveSetting(link.id);
                        sections[link.id]?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    }}
                />
            ))}
        </div>
    );
}

// Sidebar Link Component
const SettingsSidebarLink = ({ label, icon: Icon, theme, isActive, onClick }) => {
    return (
        <div
            className="nav-item d-flex align-items-center justify-content-start py-2 mb-2"
            style={{
                borderRadius: '1.5rem',
                padding: '0.7rem 1.1rem', // 🔥 Increased left/right padding for better spacing
                cursor: 'pointer',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                backgroundColor: isActive ? theme.primaryLight : 'transparent', // 🔥 Active state background
                color: isActive ? theme.textPrimary : theme.textSecondary, 
                display: 'flex',
                alignItems: 'center',
            }}
            onClick={onClick}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = theme.primaryLight)}
            onMouseLeave={(e) => {
                if (!isActive) {
                    e.currentTarget.style.backgroundColor = 'transparent';
                }
            }}
        >
            {/* Icon */}
            <div
                style={{
                    width: "24px", // 🔥 Increased icon width for better spacing
                    height: "24px", // 🔥 Increased icon height for better spacing
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '1rem', // 🔥 Increased spacing between icon and text
                }}
            >
                <Icon />
            </div>

            {/* Label */}
            <span style={{ color: isActive ? theme.textPrimary : theme.textSecondary, fontSize: "0.875rem"}}>
                {label}
            </span>
        </div>
    );
};

export { SettingsSidebar };


export default function Settings() {
    const { sections } = useSettingsContext();

    const [errors, setErrors] = useState({ email: '', university_email: '', phone: '', email_notification: '', text_notification: '' });
    const [settingsData, setSettingsData] = useState({
        email: '',
        university_email: '',
        phone: '',
        email_notification: true,
        text_notification: true,
        push_notification: true,
        date_of_birth: null,
    });

    const [initialSettings, setInitialSettings] = useState({
        email: '',
        university_email: '',
        phone: '',
        email_notification: true,
        text_notification: true,
        push_notification: true,
        date_of_birth: null,
    });

    // Phone verification
    const [verificationCode, setVerificationCode] = useState("");
    const [sessionUUID, setSessionUUID] = useState("");
    const [showVerification, setShowVerification] = useState(false);

    // Email verification
    const [emailVerification, setEmailVerification] = useState(0);
    const [universityEmailVerification, setUniversityEmailVerification] = useState(0);

    // Email notification
    const emailNotificationRef = useRef(true);

    // Password
    const [passwordData, setPasswordData] = useState({
        old_password: "",
        new_password1: "",
        new_password2: ""
    });
    const [passwordErrors, setPasswordErrors] = useState({
        old_password: "",
        new_password1: "",
        new_password2: ""
    });
    const [passwordSaved, setPasswordSaved] = useState(false);

    // Theme
    const theme = useTheme();

    // Loading
    const [loading, setLoading] = useState(true);
    const [passwordLoading, setPasswordLoading] = useState(true);

    // Erros
    const { showBoundary } = useErrorBoundary();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // Resend email
    const sendVerificationEmail = async (university) => {
        try {
            if (university && universityEmailVerification === 1) {
                await myAxiosInstance.post('/prospinity-auth/university-email/resend-verification/');
                setUniversityEmailVerification(2);
            } else if (emailVerification == 1) {
                await myAxiosInstance.post('/prospinity-auth/email/resend-verification/ ');
                setEmailVerification(2);
            }
        }
        catch (error) {
            showBoundary(error);
        }
    }

    // Submit
    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        
        let newErrors = {};

        if (!settingsData.email.includes('@')) newErrors.email = "Please enter a valid email address.";
        if (!settingsData.university_email.includes('@')) newErrors.university_email = "Please enter a valid email address.";

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            // Send API request
            setLoading(true);

            if (showVerification) {
                const response = await myAxiosInstance.put('/prospinity-auth/phone-number/verify/', { "session_uuid": sessionUUID, "verification_code": verificationCode });

                setShowVerification(false);
            } else {
                const response = await myAxiosInstance.put("/api/user-settings/", {
                    ...settingsData, 
                    email_notification: emailNotificationRef.current // Always up-to-date
                });

                // Phone number changed
                if (response.data.session_uuid) {
                    setSessionUUID(response.data.session_uuid);
                    setShowVerification(true);
                } 

                // Email changed
                if (initialSettings.email !== settingsData.email) {
                    setEmailVerification(1);
                }

                if (initialSettings.university_email !== settingsData.university_email) {
                    setUniversityEmailVerification(1);
                }

                // Update initial settings
                setInitialSettings((prev) => ({
                    ...settingsData, 
                    phone: prev.phone, // Keep the old phone number
                }));

            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data.email) {
                        setErrors({ ...newErrors, email: error.response.data.email });
                    } else if (error.response.data.university_email) {
                        setErrors({ ...newErrors, university_email: error.response.data.university_email });
                    } else if (error.response.data.phone) {
                        setErrors({ ...newErrors, phone: error.response.data.phone });
                    } else if (error.response.data.verification_code) {
                        setErrors({ ...newErrors, verificationCode: error.response.data.verification_code });
                    } else if (error.response.data.non_field_errors) {
                        setErrors({ ...newErrors, [showVerification ? "verificationCode" : "phone"]: error.response.data.non_field_errors });
                    } else {
                        setErrors({ ...newErrors, [showVerification ? "verificationCode" : "phone"]: "Something went wrong. Please try again later." });
                    }
                } else {
                    setErrors({ ...newErrors, [showVerification ? "verificationCode" : "phone"]: "Something went wrong. Please try again later." });
                }
            } else {
                setErrors({ ...newErrors, [showVerification ? "verificationCode" : "phone"]: "Something went wrong. Please try again later." });
            }
        } finally {
            setLoading(false);
        }
    }

    // Password submit
    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        let newErrors = {};

        if (!passwordData.old_password.trim()) {
            newErrors.old_password = "Please enter a password.";
        }
        
        if (!passwordData.new_password1.trim()) {
            newErrors.new_password1 = "Please enter a password.";
        } else if (passwordData.new_password1.length < 8) {
            newErrors.new_password1 = "Password must be at least 8 characters.";
        }

        if (passwordData.new_password1 !== passwordData.new_password2) {
            newErrors.new_password2 = "Passwords do not match.";
        }

        if (Object.keys(newErrors).length > 0) {
            setPasswordErrors(newErrors);
            return;
        }

        // Api request
        try {
            setLoading(true);

            const response = await myAxiosInstance.post('/prospinity-auth/password/change/', passwordData);

            setPasswordSaved(true);

        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data.old_password) {
                        setPasswordErrors({ ...newErrors, old_password: error.response.data.old_password });
                    } else if (error.response.data.new_password1) {
                        setPasswordErrors({ ...newErrors, new_password1: error.response.data.new_password1 });
                    } else if (error.response.data.new_password2) {
                        setPasswordErrors({ ...newErrors, new_password2: error.response.data.new_password2 });
                    } else if (error.response.data.non_field_errors) {
                        setPasswordErrors({ ...newErrors, new_password2: error.response.data.non_field_errors });
                    } else {
                        setPasswordErrors({ ...newErrors, new_password2: "Something went wrong. Please try again later." });
                    }
                } else {
                    setPasswordErrors({ ...newErrors, new_password2: "Something went wrong. Please try again later." });
                }
            } else {
                setPasswordErrors({ ...newErrors, new_password2: "Something went wrong. Please try again later." });
            }
        } finally {
            setPasswordLoading(false);
        }

    }

    // Fetch user data on load
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await myAxiosInstance.get('/api/user-settings/');
                setSettingsData(response.data);
                setInitialSettings(response.data);
                emailNotificationRef.current = response.data.email_notification;
            } catch (error) {
                showBoundary(error);
            } finally {
                setLoading(false);
                setPasswordLoading(false);
            }
        };

        fetchUserData();
    }, []);

    // Handle form changes
    const handleChange = (field, value) => {
        setSettingsData((prev) => ({ ...prev, [field]: value }));
        setErrors((prev) => ({ ...prev, [field]: "" })); // Clear errors when typing
    };

    // Handle form changes
    const handlePasswordChange = (field, value) => {
        setPasswordData((prev) => ({ ...prev, [field]: value }));
        setPasswordErrors((prev) => ({ ...prev, [field]: "" })); // Clear errors when typing
    };

    // Reset to original values
    const handleCancel = () => {
        setShowVerification(false);
        if (initialSettings) {
            setSettingsData(initialSettings);
            setErrors({});
        }
    };

    // Reset to original values
    const handlePasswordCancel = () => {
        setPasswordData({
            old_password: "",
            new_password1: "",
            new_password2: "",
        });
        setPasswordErrors({});
        setPasswordSaved(false);
    };

    return (
        <MainContainer theme={theme} style={{ gap: "1.5rem", padding: "1.5rem" }}>

            <Form onSubmit={handleSubmit}>
                <Section ref={sections["personal-info"]} data-section="personal-info">
                    <Header>
                        <h2 style={{ color: theme.textPrimary, fontSize: isMobile ? "1.15rem": "1.5rem" }}>UPDATE YOUR INFORMATION</h2>
                        <p style={{ color: theme.textSecondary }}>This is your information that was collected by Prospinity. Let's make sure everything is correct.</p>
                    </Header>

                    <FormFields>
                        {/* Emails */}
                        <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                            <FormInput
                                label="Email"
                                value={settingsData.email}
                                onChange={(e) => handleChange("email", e.target.value)}
                                error={errors.email}
                            />
                            {emailVerification !== 0 && (
                                <span style={{ color: theme.textSecondary, fontSize: "0.875rem" }}>
                                    Verification email sent. 
                                    {emailVerification === 1 && <span 
                                        style={{
                                            color: theme.primary,
                                            cursor: "pointer",
                                            fontWeight: 500,
                                            marginLeft: "0.3rem",
                                            transition: "color 0.2s ease-in-out",
                                        }}
                                        onMouseEnter={(e) => e.target.style.color = theme.primaryHover}
                                        onMouseLeave={(e) => e.target.style.color = theme.primary}
                                        onClick={async () => { await sendVerificationEmail(false); }}
                                    >
                                        Resend
                                    </span>}
                                </span>
                            )}
                        </div>

                        <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                            <FormInput
                                label="University Email"
                                value={settingsData.university_email}
                                onChange={(e) => handleChange("university_email", e.target.value)}
                                error={errors.university_email}
                            />
                            {universityEmailVerification !== 0 && (
                                <span style={{ color: theme.textSecondary, fontSize: "0.875rem" }}>
                                    Verification email sent. 
                                    {universityEmailVerification === 1 && <span 
                                        style={{
                                            color: theme.primary,
                                            cursor: "pointer",
                                            fontWeight: 500,
                                            marginLeft: "0.3rem",
                                            transition: "color 0.2s ease-in-out",
                                        }}
                                        onMouseEnter={(e) => e.target.style.color = theme.primaryHover}
                                        onMouseLeave={(e) => e.target.style.color = theme.primary}
                                        onClick={async () => { await sendVerificationEmail(true); }}
                                    >
                                        Resend
                                    </span>}
                                </span>
                            )}
                        </div>
                        
                        {/* Phone Number */}
                        <PhoneInput
                            label="Phone Number"
                            value={settingsData.phone}
                            onChange={(value) => handleChange("phone", value)}
                            error={errors.phone}
                        />

                        {showVerification && (
                            <div>
                                <Subtitle style={{ margin: "0 0 0.5rem 0" }} isMobile={isMobile} theme={theme}>Enter the six digit code we sent to your device</Subtitle>
                                <VerificationCodeInput 
                                    value={verificationCode}
                                    onChange={setVerificationCode}
                                    error={errors.verificationCode}
                                />
                            </div>
                        )}        
                    </FormFields>

                    <ButtonSection>
                        <SecondaryButton type="button" size="lg" disabled={loading} style={{ flex: 1, padding: "0.7rem 0" }} onClick={handleCancel}>
                            CANCEL
                        </SecondaryButton>
                        <PrimaryButton type="submit" size="lg" disabled={loading} style={{ flex: 1, padding: "0.7rem 0" }}>
                            {loading ? (showVerification ? "Verifying..." : "Saving...") : (showVerification ? "VERIFY" : "SAVE")}
                        </PrimaryButton>
                    </ButtonSection>
                </Section>
                
            </Form>

            {/* Notifications settings */}
            <Section ref={sections["notifications"]} data-section="notifications">
                <Header>
                    <h2 style={{ color: theme.textPrimary, fontSize: isMobile ? "1.15rem": "1.5rem" }}>NOTIFICATION SETTINGS</h2>
                    <div style={{ 
                        display: "flex", 
                        flexDirection: "row", 
                        alignItems: "center",
                        justifyContent: "space-between", // ✅ Text left, toggle right
                        width: "100%" // ✅ Ensures full width alignment
                    }}>
                        <p style={{ color: theme.textSecondary, margin: "0" }}>Email notifications</p>
                        <ToggleButton 
                            isChecked={emailNotificationRef.current} 
                            onToggle={() => {
                                emailNotificationRef.current = !emailNotificationRef.current;

                                setSettingsData(prevSettings => ({
                                    ...prevSettings, 
                                    email_notification: emailNotificationRef.current
                                }));

                                // Call handleSubmit (uses the latest ref value)
                                handleSubmit();
                            }}
                        />
                    </div>
                </Header>
            </Section>

            {/* Password Reset */}
            <Form onSubmit={handlePasswordSubmit}>
                <Section ref={sections["password"]} data-section="password">
                    <Header>
                        <h2 style={{ color: theme.textPrimary, fontSize: isMobile ? "1.15rem": "1.5rem" }}>CHANGE YOUR PASSWORD</h2>
                        <p style={{ color: theme.textSecondary }}>Set up a new password here.</p>
                    </Header>

                    <FormFields>
                        <FormInput
                            label="Current password"
                            type="password"
                            value={passwordData.old_password}
                            showEyeIcon
                            onChange={(e) => handlePasswordChange("old_password", e.target.value)}
                            error={passwordErrors.old_password}
                        />

                        <FormInput
                            label="Type in your new password"
                            type="password"
                            value={passwordData.new_password1}
                            showEyeIcon
                            onChange={(e) => handlePasswordChange("new_password1", e.target.value)}
                            error={passwordErrors.new_password1}
                        />

                        <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                            <FormInput
                                label="Confirm your new password"
                                type="password"
                                value={passwordData.new_password2}
                                showEyeIcon
                                onChange={(e) => handlePasswordChange("new_password2", e.target.value)}
                                error={passwordErrors.new_password2}
                            />
                            {passwordSaved  && (
                                <span style={{ color: theme.green, fontSize: "0.875rem" }}>
                                    Password successfully changed.
                                </span>
                            )}
                        </div>
                    </FormFields>

                    <ButtonSection>
                        <SecondaryButton type="button" size="lg" disabled={passwordLoading} style={{ flex: 1, padding: "0.7rem 0" }} onClick={handlePasswordCancel}>
                            CANCEL
                        </SecondaryButton>
                        <PrimaryButton type="submit" size="lg" disabled={passwordLoading} style={{ flex: 1, padding: "0.7rem 0" }}>
                            {passwordLoading ? (showVerification ? "Verifying..." : "Saving...") : (showVerification ? "VERIFY" : "SAVE")}
                        </PrimaryButton>
                    </ButtonSection>
                </Section>
            </Form>


            {/* Delete account */}
            <Section ref={sections["delete-account"]} data-section="delete-account" style={{ marginBottom: isMobile ? "5rem" : "0" }}>
                <Header>
                    <h2 style={{ color: theme.textPrimary, fontSize: isMobile ? "1.15rem": "1.5rem" }}>DELETE YOUR ACCOUNT</h2>
                    <div style={{ 
                        display: "flex", 
                        flexDirection: "row", 
                        alignItems: "center",
                        justifyContent: "space-between", // ✅ Text left, toggle right
                        width: "100%" // ✅ Ensures full width alignment
                    }}>
                        <p style={{ color: theme.textSecondary, margin: "0" }}>Please email support@prospinity.com to delete your account.</p>
                    </div>
                </Header>
            </Section>
            
        </MainContainer>
    );
}


// Styled Components
const Section = styled.div`
    width: "100%";
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem;
    background: ${({ theme }) => theme.white};;
    padding: 2rem;
    border-radius: 1.5rem;
    flex-wrap: wrap; /* Allow items to wrap on small screens */
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
`;

const FormFields = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
    flex-wrap: wrap; /* Allow items to wrap on small screens */
`;

const ButtonSection = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap; /* Allow items to wrap on small screens */
`;









// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, ListGroup, Modal, Form, Button, Card, FormCheck } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../styles/Settings.css';
// import Persona from 'persona';
// import { useAuth } from '../contexts/AuthContext';
// import axios from 'axios';
// import { myAxiosInstance } from '../api/axiosConfig';
// import { useNavigate } from 'react-router-dom';
// import { apiP } from '../components/common/CriticalFunctions';
// import CustomModal from '../components/common/CustomModal';
// import PhoneInput from 'react-phone-number-input';
// import 'react-phone-number-input/style.css';
// import { ChevronRight } from 'react-bootstrap-icons';
// import Loading from '../components/common/Loading';
// import { useErrorBoundary } from "react-error-boundary";
// import { StyledListGroup, StyledH2, StyledCard, StyledFormCheck, StyledFormControl, StyledFormGroup, StyledFormLabel, StyledH5, StyledForm, StyledPhoneInput, StyledParagraph, StyledButton } from '../components/common/StyledBootstrap';
// import { useTheme } from '../contexts/ThemeContext';
// //CHANGE notificaitnos, personal informatino, verification




// function Settings() {
//     const [notificationsEnabled, setNotificationsEnabled] = useState(false);
//     const { userSlug, emailVerified, universityEmailVerified } = useAuth();

//     const [modalShow, setModalShow] = useState(false);
//     const [modalMessage, setModalMessage] = useState(null);
//     const [modalEditData, setModalEditData] = useState(null);
//     const [modalChangePassword, setModalChangePassword] = useState({ "old_password": "", "new_password1": "", "new_password2": "" });
//     const [fullSettings, setFullSettings] = useState(null);


//     const [resentEmailVerification, setResentEmailVerification] = useState(0);
//     const [resentUniversityEmailVerification, setResentUniversityEmailVerification] = useState(0);

//     const [bottomReload, setBottomReload] = useState(1);

//     const [isBottomLoading, setIsBottomLoading] = useState(true);

//     const {showBoundary} = useErrorBoundary();

//     const navigate = useNavigate();


    // const sendVerificationEmail = async (university) => {
    //     try {
            
    //         if (university && resentUniversityEmailVerification==0)
    //         {
    //             setResentUniversityEmailVerification(1);
    //             await myAxiosInstance.post('/api/resend-verification-university-email/');
    //             setResentUniversityEmailVerification(2);

    //         }
    //         else if (resentEmailVerification == 0) {
    //             setResentEmailVerification(1);
    //             await myAxiosInstance.post('/api/resend-verification-email/');
    //             setResentEmailVerification(2);
    //         }

    //     }
    //     catch (error) {
    //         showBoundary(error);
    //     }

//     }



//     const modalOptions = [
//         'Edit Personal Information',
//         'Edit Email Notifications',
//         'Change Password',
//         'Delete Account',
//         'Change Theme'
//     ];

//     const [modalOptionsIndex, setModalOptionsIndex] = useState(null);


//     const handlePencilClick = (index) => {
//         setModalOptionsIndex(index);
//     };

//     const handleClose = () => {
//         setModalShow(false);
//         setModalMessage(null);
//         setModalOptionsIndex(null);
//         setModalEditData(fullSettings);
//         setModalChangePassword({ "new_password1": "", "new_password2": "" });
//         setNotificationsEnabled(fullSettings.email_verification);
//     }

//     useEffect(() => {
//         if (modalOptionsIndex != null) {
//             setModalShow(true);
//         }
//     }, [modalOptionsIndex]);

  
//     const ThemeToggleSwitch = () => {
//         const { theme, toggleTheme } = useTheme(); // Destructure theme and toggleTheme
    
//         return (
//             <StyledForm>
//                 <StyledFormCheck 
//                     type="switch"
//                     id="custom-switch"
//                     label="Dark Mode"
//                     checked={theme === 'dark'}
//                     onChange={toggleTheme}
//                     className='ml-3'
//                 />
//             </StyledForm>
//         );
//     };
    


//     useEffect(() => {
//         const getUserSettings = async () => {
        
//             try {
//                 setModalShow(false);
//                 setModalMessage(null);
//                 setModalOptionsIndex(null);
//                 setModalChangePassword({ "new_password1": "", "new_password2": "" });
//                 const response = await myAxiosInstance.get('/api/user-settings/');
//                 setFullSettings(response.data);
//                 setModalEditData(response.data);
//                 setNotificationsEnabled(response.data.email_verification);



//             } catch (error) {
//                 showBoundary(error);
//             } finally {
//                 setIsBottomLoading(false);
//             }
        
//         };

//         getUserSettings();

//     }, [bottomReload]);


//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setModalEditData({ ...modalEditData, [name]: value });
//     };


//     const handleChangePasswordChange = (e) => {
//         const { name, value } = e.target;
//         setModalChangePassword({ ...modalChangePassword, [name]: value });
//     };

//     const handlePhoneChange = (value) => {
//         // Custom handler for phone number input

//         setModalEditData(prevData => ({
//             ...prevData,
//             phone: value ? value : "",
//         }));
//     };

//     const handleNotificationToggle = () => {
//         setNotificationsEnabled(!notificationsEnabled);
//         setModalEditData({...modalEditData, email_verification: !notificationsEnabled});
//     };

//     const handleSaveChanges = async (e) => {
//         if (modalOptions[modalOptionsIndex] === "Change Password") {
//             const response = await apiP(modalChangePassword, "change-password/", setModalMessage, "Done!", null, null, true, bottomReload, setBottomReload, "post");
//         }
//         else if (modalOptions[modalOptionsIndex] === "Delete Account" || modalOptions[modalOptionsIndex] === "Change Theme")
//         {
//             handleClose();
//         }
//         else {
//             let data = { ...modalEditData}; //Change
//             const response = await apiP(data, "user-settings/", setModalMessage, "Done!", null, null, true, bottomReload, setBottomReload, "put");
//         }

//     };





    

//     return (
//         <>
//             {isBottomLoading ? (
//                 <Loading/>
//             ) : (
//                 <Row className='justify-content-center'>
//                     <Col className='col-md-6'>
//                         <StyledCard>
//                             <StyledCard.Body>

//                                 <div className='text-center w-100'>
//                                     <StyledH2>Settings</StyledH2>
//                                 </div>

//                                 <StyledListGroup variant="flush">
                                    

//                                     <StyledListGroup.Item action onClick={() => handlePencilClick(0)}>
//                                         <div className="d-flex justify-content-between align-items-center">
//                                             <StyledH5>Edit Personal Information</StyledH5>
//                                             <ChevronRight />
//                                         </div>
//                                     </StyledListGroup.Item>
//                                     <StyledListGroup.Item action onClick={() => handlePencilClick(1)}>
//                                         <div className="d-flex justify-content-between align-items-center">
//                                             <StyledH5>Notification Settings</StyledH5>
//                                             <ChevronRight />
//                                         </div>
//                                     </StyledListGroup.Item>
//                                     <StyledListGroup.Item action onClick={() => handlePencilClick(2)}>
//                                         <div className="d-flex justify-content-between align-items-center">
//                                             <StyledH5>Change Password</StyledH5>
//                                             <ChevronRight />
//                                         </div>
//                                     </StyledListGroup.Item>
//                                     <StyledListGroup.Item action onClick={() => handlePencilClick(4)}>
//                                         <div className="d-flex justify-content-between align-items-center">
//                                             <StyledH5>Change Theme</StyledH5>
//                                             <ChevronRight />
//                                         </div>
//                                     </StyledListGroup.Item>
//                                     <StyledListGroup.Item action onClick={() => handlePencilClick(3)}>
//                                         <div className="d-flex justify-content-between align-items-center">
//                                             <StyledH5>Delete Account</StyledH5>
//                                             <ChevronRight />
//                                         </div>
//                                     </StyledListGroup.Item>
//                                 </StyledListGroup>


//                                 <CustomModal title={modalOptions[modalOptionsIndex]} actionText={"Save Changes"} actionFunction={handleSaveChanges} show={modalShow} setShow={setModalShow} message={modalMessage} setMessage={setModalMessage} handleClose={handleClose}>
//                                     <StyledForm>

//                                         {(modalOptions[modalOptionsIndex] === 'Edit Personal Information') &&
//                                             <>
//                                                 <StyledFormGroup>
//                                                     <StyledFormLabel>Phone Number</StyledFormLabel>
//                                                     <StyledPhoneInput
//                                                         placeholder="Enter phone number"
//                                                         value={modalEditData.phone}
//                                                         onChange={handlePhoneChange}
//                                                         defaultCountry="US"
//                                                         name="phoneNumber"
//                                                         className="custom-phone-input"
//                                                     />
//                                                 </StyledFormGroup>
//                                                 <StyledFormGroup>
//                                                     <StyledFormLabel>Email</StyledFormLabel>
//                                                     <StyledFormControl
//                                                         type="email"
//                                                         value={modalEditData.email}
//                                                         onChange={handleInputChange}
//                                                         name="email"
//                                                     >

//                                                     </StyledFormControl>
//                                                     {emailVerified ? <></> : <StyledButton style={{fontSize: '0.7rem'}} className='mt-2' onClick={() => sendVerificationEmail(false)}>{resentEmailVerification==0 ? <>Resend Verification</> : resentEmailVerification==1 ? <Loading /> : <>Check Your Email</>}</StyledButton>}
//                                                 </StyledFormGroup>
                                                
//                                                 <StyledFormGroup>
//                                                     <StyledFormLabel>University Email</StyledFormLabel>
//                                                     <StyledFormControl
//                                                         type="email"
//                                                         value={modalEditData.university_email}
//                                                         onChange={handleInputChange}
//                                                         name="university_email"
//                                                     >

//                                                     </StyledFormControl>
//                                                     {universityEmailVerified ? <></> : <StyledButton style={{fontSize: '0.7rem'}} className='mt-2' onClick={() => sendVerificationEmail(true)}>{resentUniversityEmailVerification==0 ? <>Resend Verification</> : resentUniversityEmailVerification==1 ? <Loading /> : <>Check Your University Email</>}</StyledButton>}
//                                                 </StyledFormGroup>
//                                                 <StyledFormGroup>
//                                                     <StyledFormLabel>Date of Birth</StyledFormLabel>
//                                                     <StyledFormControl
//                                                         type="date"
//                                                         value={modalEditData.date_of_birth}
//                                                         onChange={handleInputChange}
//                                                         name="date_of_birth"
//                                                     >

//                                                     </StyledFormControl>
//                                                 </StyledFormGroup>
//                                             </>
//                                         }

//                                         {modalOptions[modalOptionsIndex] == 'Change Password' &&
//                                             <>
//                                                 <StyledFormGroup>
//                                                     <StyledFormLabel>Type in Your Old Password</StyledFormLabel>
//                                                     <StyledFormControl
//                                                         type="password"
//                                                         value={modalChangePassword.old_password}
//                                                         onChange={handleChangePasswordChange}
//                                                         name="old_password"
//                                                     >

//                                                     </StyledFormControl>
//                                                 </StyledFormGroup>
//                                                 <StyledFormGroup>
//                                                     <StyledFormLabel>Type in Your New Password</StyledFormLabel>
//                                                     <StyledFormControl
//                                                         type="password"
//                                                         value={modalChangePassword.new_password1}
//                                                         onChange={handleChangePasswordChange}
//                                                         name="new_password1"
//                                                     >

//                                                     </StyledFormControl>
//                                                 </StyledFormGroup>
//                                                 <StyledFormGroup>
//                                                     <StyledFormLabel>Again: </StyledFormLabel>
//                                                     <StyledFormControl
//                                                         type="password"
//                                                         value={modalChangePassword.new_password2}
//                                                         onChange={handleChangePasswordChange}
//                                                         name="new_password2"
//                                                     >

//                                                     </StyledFormControl>
//                                                 </StyledFormGroup>
//                                             </>
//                                         }

//                                         {modalOptions[modalOptionsIndex] === 'Edit Email Notifications' &&
//                                             <div>
//                                                 <StyledFormCheck
//                                                     type="switch"
//                                                     id="notification-switch"
//                                                     inline
//                                                     label={notificationsEnabled ? "On" : "Off"}
//                                                     checked={notificationsEnabled}
//                                                     onChange={handleNotificationToggle}
//                                                 /></div>
//                                         }

//                                         {modalOptions[modalOptionsIndex] === 'Delete Account' && <StyledParagraph>Please email <a href={"mailto:support@prospinity.com"}>support@prospinity.com</a> to delete your account.</StyledParagraph>}

//                                         {modalOptions[modalOptionsIndex] === 'Change Theme' &&
//                                             <ThemeToggleSwitch/>
//                                         }
//                                     </StyledForm>
//                                 </CustomModal>


//                             </StyledCard.Body>
//                         </StyledCard>
//                     </Col>
//                 </Row>
//             )}


//         </>
//     );
// }

// export default Settings;
