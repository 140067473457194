import React, { useState } from "react";
import styled, { useTheme } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { useAuth } from "../../contexts/AuthContext";
import { SecondaryButton } from "./Buttons";
import CenteredModal from "./Modals";
import BlueBanner from '../../assets/images/blueBanner.png';
import BlueBannerMobile from '../../assets/images/blueBannerMobile.png';
import GreenBanner from '../../assets/images/greenBanner.png';
import FullGreenBanner from '../../assets/images/fullGreenBanner.png';
import FullGreenBannerMobile from '../../assets/images/fullGreenBannerMobile.png';
import GreenBannerMobile from '../../assets/images/greenBannerMobile.png';
import TimeRemaining from "../pools/PoolDeadlineTimer";
import SigningInterface from "../pools/SigningInterface";
import { ReactComponent as BannerTimer } from "../../assets/icons/bannerTimer.svg";


const Banner = ({ bannerType, userName, pool, fullBanner=false  }) => {

    // Modal
    const [showModal, setShowModal] = useState(false);

    // Get the theme
    const theme = useTheme();

    // User's slug
    const { userSlug } = useAuth();

    // Navigate
    const navigate = useNavigate();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 600 });

    // Signing modal
    const [signingModal, setSigningModal] = useState(false);

    const isMatched = bannerType === "matched";

    // Button Section
    const renderButtons = () => {
        return (
            <SectionThree>
                {isMatched ? (
                    <>
                        <ButtonWrapper>
                            <TimerWrapper>
                                <Divider />
                                <BannerTimer />
                                <div>
                                    <p style={{ marginBottom: "0", fontWeight: "400" }}>Time left:</p>
                                    <strong>
                                        <TimeRemaining
                                            deadline={pool.public_details.sign_deadline}
                                            onExpire={() => window.location.reload()}
                                        />    
                                    </strong>
                                </div>
                            </TimerWrapper>
                            {fullBanner ? pool.user_info.status === "accepted_signed" ? <></> : <Button onClick={() => setSigningModal(true)} >REVIEW AND JOIN</Button> : <Button onClick={() => navigate(`/dashboard/pools/${pool.public_details.slug}`)}>SEE MY POOL</Button>}
                            
                        </ButtonWrapper>
                    </>
                ) : (
                    <>
                        <Button outline onClick={() => navigate(`/dashboard/user/${userSlug}`)}>UPDATE INFO</Button>
                        <Button onClick={() => setShowModal(true)}>WHAT'S A POOL</Button>
                    </>
                )}
            </SectionThree>
        )
    }

    return (
        <BannerWrapper bgImage={isMatched ? (isMobile ? fullBanner ? FullGreenBannerMobile : GreenBannerMobile : fullBanner ? FullGreenBanner : GreenBanner) : (isMobile ? BlueBannerMobile : BlueBanner)}>
            <BannerContainer>
                <FlexContainer>
                    
                    {/* Search Icon Section */}
                    <SectionOne>
                        <SearchIconWrapper isMatched={isMatched}>
                            <img src={isMatched ? "/assets/bannerCheckIcon.svg" : "/assets/bannerSearchIcon.svg"}  alt="Icon" />
                        </SearchIconWrapper>
                    </SectionOne>

                    {/* Main Text Section */}
                    <SectionTwo>
                        <h2>
                            {isMatched 
                                ? "YOU'VE BEEN MATCHED IN A POOL" 
                                : isMobile ? "WE ARE WORKING HARD TO FIND A PERFECT POOL FOR YOU" : "OUR TEAM IS WORKING HARD TO MATCH YOU WITH A PERFECT POOL"}
                        </h2>
                        <p>
                            {isMatched 
                                ? "Sign the contract to join the pool you've been matched to." 
                                : isMobile ? "It’s important to make sure your information and profile stay up to date throughout the process." : "We'll send you an invite to a pool—or in some cases, multiple pools—that we believe are a strong fit for you. Make sure to keep your profile and information up to date."}
                        </p>
                    </SectionTwo>

                    {/* Buttons */}
                    { !(fullBanner && isMobile) && renderButtons() }
                    
                </FlexContainer>

            </BannerContainer>

            {fullBanner && 
                <>
                
                    <BlurredOverlayBox>
                        <div style={{ flex: "1", fontSize: "1.0625rem", fontWeight: "500", lineHeight: "1.5rem", color: theme.white }}>
                            <p>Congratulations, {userName}!</p>
                            <p>The Prospinity team has selected you to join the success pool {pool.public_details.name}.</p>
                        </div>
                        <div style={{ flex: "1", fontSize: "0.875rem", fontWeight: "400", lineHeight: "1.25rem", color: theme.white + "A3" }}>
                            <p>To complete the process, click Join. You will see a quick recap of the most important things to remember. And then you will have the opportunity to view the Success Pooling Agreement for this pool and sign it. Remember to sign before the deadline (otherwise you will not get the opportunity to join).</p>
                        </div>
                    </BlurredOverlayBox>

                    { isMobile && renderButtons() }
                </>  
            }

            {/* Modals */}
            <CenteredModal 
                show={showModal} 
                onClose={() => setShowModal(false)} 
                media={"https://images.prospinity.com/videos/pool-tutorial.mp4"} 
                title={"WHAT'S A SUCCESS POOL"} 
                subtitle={"A Success Pool is a selected group of high-potential students who agree to evenly split a small percentage of their earnings every year after graduation."} 
                buttonsType={"one"} 
                primaryText={"EXPLORE PROSPINITY"} 
                alignCenter={true} 
            />

            {signingModal && 
                <SigningInterface pool={pool} onClose={() => setSigningModal(false)} />
            }

        </BannerWrapper>
    );
};

export default Banner;


const BannerWrapper = styled.div`
    width: 100%;
    background: url(${(props) => props.bgImage}) center/100% 100% no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 1.5rem;

    @media (max-width: 1024px) {
        padding: 1.5rem;
    }

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

const BannerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;


/* ✅ Inner FlexContainer */
const FlexContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: nowrap;

    @media (max-width: 1024px) {
        flex-wrap: wrap; /* Stack on smaller screens */
        justify-content: center;
    }
`;

/* ✅ Search Icon Wrapper with Background */
const SearchIconWrapper = styled.div`
    width: clamp(55px, 4.5vw, 70px);
    height: clamp(55px, 4.5vw, 70px);
    background: ${({ isMatched }) => (isMatched ? "#3BB75E" : "#3877FD")}; /* Green if matched, blue if not */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -0.5px 2px 1px rgba(255, 255, 255, 0.8);
    transition: background 0.3s ease-in-out;
`;

/* ✅ Left Section - Search Icon */
const SectionOne = styled.div`
    flex-basis: 85px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
        width: clamp(65px, 6vw, 80px); /* Scales with screen */
        height: clamp(65px, 6vw, 80px);
    }

    @media (max-width: 1024px) {
        flex-basis: 60px;
    }

    @media (max-width: 768px) {
        align-items: flex-start;
        margin-bottom: 0.5rem;
    }
`;


/* ✅ Middle Section - Title & Description */
const SectionTwo = styled.div`
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    margin: 0 0.5rem;
    min-width: 40%; /* Prevents excessive shrinking */

    h2 {
        width: 100%;
        font-size: clamp(1rem, 1.5vw, 1.125rem);
        font-weight: 500;
        white-space: normal;
        text-align: left;
        line-height: 1.4;
    }

    p {
        width: 100%;
        font-size: clamp(0.75rem, 1.2vw, 0.875rem);
        font-weight: 400;
        opacity: 0.64;
        text-align: left;
        margin-bottom: 0;
    }

    @media (max-width: 1024px) {
        text-align: center;
        align-items: center;
    }
`;

/* ✅ Right Section - Buttons */
const SectionThree = styled.div`
    flex-basis: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: clamp(0.5rem, 1vw, 1rem);
    white-space: nowrap;

    @media (max-width: 1024px) {
        justify-content: center;
        width: 100%;
        margin-top: 1rem;
    }

    @media (max-width: 768px) {
        flex-direction: row; /* ⬅️ Stack buttons */
        align-items: center;
        width: 100%; /* ⬅️ Full-width */
        gap: 0.5rem; /* Adjust spacing */
    }

    @media (max-width: 768px) {
        padding: 0.4rem 1rem;
    }

    @media (max-width: 600px) {
        flex-basis: 100%;
    }
`;

const TimerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem; /* Increase gap slightly */
    font-size: 0.875rem;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    p {
        font-size: clamp(0.75rem, 1.2vw, 0.875rem);
        font-weight: 400;
    }

    strong {
        font-size: clamp(0.875rem, 1.3vw, 1rem);
        font-weight: 500;
    }
`;

/* ✅ Divider Line */
const Divider = styled.div`
    width: 1px;
    height: 2.5rem; /* Adjust height */
    background-color: rgba(255, 255, 255, 0.4); /* Subtle white */
    margin-right: 0rem; /* Adjust spacing */
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: clamp(1rem, 2vw, 2rem);

    @media (max-width: 512px) {
        width: 100%;
    }
`;

/* ✅ Button Style */
const Button = styled.button`
    padding: clamp(0.6rem, 0.8vw, 0.6rem) clamp(0.8rem, 1.2vw, 1.3rem);
    border-radius: 2rem;
    font-size: clamp(11px, 1vw, 13px);
    font-weight: 500;
    cursor: pointer;
    border: ${({ outline }) => (outline ? "1px solid white" : "none")};
    background: ${({ outline }) => (outline ? "transparent" : "white")};
    color: ${({ outline }) => (outline ? "white" : "black")};
    transition: 'transform 0.3s ease';

    &:hover {
        opacity: ${({ outline }) => (outline ? "0.8" : "0.94")};
    }

    @media (max-width: 768px) {
        padding: 0.6rem 1rem;
    }

    @media (max-width: 600px) {
        flex: 1;
    }
`;

const BlurredOverlayBox = styled.div`
    width: 100%;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.06);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px); /* Safari */
    padding: 1.5rem;
    margin-top: 2rem;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1.5rem;

    p {
        margin: 0;
    }

    @media (max-width: 900px) {
        flex-direction: column;
    }

    @media (max-width: 600px) {
      padding: 1.25rem;
      gap: 0.5rem;
    }
`;
