import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie";
import { motion, AnimatePresence } from "framer-motion";

import { SimpleTextPage } from "./SurveyScreens";
import { SurveyOptionContainer, SurveyOptionButton } from "./SurveyComponents";
import { useApplication } from "../../contexts/ApplicationContext";


export default function ScreenReadThree() {
  const [isTypingDone, setIsTypingDone] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const navigate = useNavigate();
  const typingSpeed = 85;
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  const { formData, updateFormData } = useApplication();

  useEffect(() => {
    // Track
    let uniqueId = formData.uniqueId;

    if (!uniqueId) {
      uniqueId = uuidv4();
      sessionStorage.setItem("unique_id", uniqueId);
    }

    // Use sendBeacon if supported for better reliability
    const data = new FormData();
    data.append("unique_id", uniqueId);
    data.append("screen6", "Here");
    const BASE_URL = process.env.REACT_APP_API_URL
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, data);

  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "black";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const handleTypingDone = () => {
    setIsTypingDone(true);
  };

  const handleClick = () => {
    setIsFadingOut(true);
    setTimeout(() => navigate("/berkeley/application/7"), 600); // Wait for animation before navigating
  };

  const firstPageText = [
      {
          sequences: [
            { content: 700, wrapper: "span", style: {} },
            { content: "You graduate!", wrapper: "span", style: {} },
          ],
      },
      {
          sequences: [
            { content: 500, wrapper: "span", style: {} },
            { content: "Now, it’s ", wrapper: "span", style: {} },
            { content: "Year 1.", wrapper: "strong", style: {} },
            { content: " At the end of the year, you go through the agreed upon process.", wrapper: "span", style: {} },
          ],
      },
      {
        sequences: [
          { content: 500, wrapper: "span", style: {} },
          { content: "You contribute ", wrapper: "span", style: {} },
          { content: "5%", wrapper: "strong", style: {} },
          { content: "  of what you earn. So do your friends. The money is immediately evenly split among everyone.", wrapper: "span", style: {} },
        ],
      },
      {
          sequences: [
            { content: 500, wrapper: "span", style: {} },
            { content: "It’s your first job. You and your friends are making pretty similar amounts. ", wrapper: "span", style: {} },
            { content: 500, wrapper: "span", style: {} },
            { content: "To be honest, the pool doesn’t change much.", wrapper: "span", style: {} },
        ],
      },
      {
        sequences: [
          { content: 500, wrapper: "span", style: {} },
          { content: "But let’s fast forward a few years.", wrapper: "span", style: {} },
          { content: 500, wrapper: "span", style: {} },
        ],
      }
  ];
  
  return (
    <AnimatePresence>
      {!isFadingOut && (
        <motion.div
          key="screen-one"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }} // Smooth fade-out
          transition={{ duration: 0.6, ease: "easeOut" }} // Smooth easing
        >
          {/* Text content */}
          <SimpleTextPage
            paragraphs={firstPageText}
            typing={true}
            typingSpeed={typingSpeed}
            onTypingDone={handleTypingDone} // Ensures we track typing completion
          />
  
          {/* Buttons (only appear after typing is done) */}
          {isTypingDone && (
            <motion.div
              key="buttons"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 0 }} // Ensure buttons fade with text
              transition={{ duration: 0.6 }}
              style={{ width: "100%" }}
            >
              <SurveyOptionContainer isMdOrLarger={isMdOrLarger}>
                <SurveyOptionButton
                  key="NEXT"
                  onClick={handleClick}
                  isMdOrLarger={isMdOrLarger}
                >
                  {"FAST FORWARD..."}
                </SurveyOptionButton>
              </SurveyOptionContainer>
            </motion.div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
 }  
