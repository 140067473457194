import styled, { useTheme } from 'styled-components';
import { PeopleIcon } from '../../assets/icons/Icons';
import { ReactComponent as GraphIcon } from "../../assets/icons/graphIcon.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendarIcon.svg";

export default function PoolTerms({ poolDetails, vertical=false }) {
    const theme = useTheme();

    const terms = [
        {
            icon: <PeopleIcon width="23" height="17" fill={theme.primary} />,
            number: poolDetails?.member_count,
            label: "Members"
        },
        {
            icon: <GraphIcon width="20" height="17" />,
            number: `${poolDetails?.contribution_percent}%`,
            label: "Future earnings"
        },
        {
            icon: <CalendarIcon width="18" height="20" />,
            number: `${poolDetails?.time_horizon} YEARS`,
            label: "Time horizon"
        }
    ];

    return (
        <TermsWrapper vertical={vertical}>
            {terms.map((term, index) => (
                <TermBlock key={index}>
                    <IconCircle theme={theme}>
                        {term.icon}
                    </IconCircle>
                    <TermTextGroup>
                        <TermNumber theme={theme}>{term.number}</TermNumber>
                        <TermLabel theme={theme}>{term.label}</TermLabel>
                    </TermTextGroup>
                </TermBlock>
            ))}
        </TermsWrapper>
    );
    }


const TermsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: ${({ vertical }) => vertical ? "column" : "row" };
    width: 100%;
    gap: 1rem;

    @media (max-width: 900px) {
        flex-direction: column;
    }
`;

const TermBlock = styled.div`
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.backgroundLight};
    border-radius: 1rem;
    flex: 1;
    padding: 1rem;
    gap: 1rem;
`;

const IconCircle = styled.div`
    width: 3.5rem;
    height: 3.5rem;
    aspect-ratio: 1;
    padding: 1rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.primaryLight};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TermTextGroup = styled.div`
    display: flex;
    flex-direction: column;
`;

const TermNumber = styled.div`
    font-size: 1.0625rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
`;

const TermLabel = styled.div`
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.textSecondary};
`;
