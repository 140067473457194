import React from "react";
import styled, { useTheme } from "styled-components";
import { useMediaQuery } from 'react-responsive';

import MutualConnections from "../users/MutualConncetions";
import { ReactComponent as RightArrow } from "../../assets/icons/rightArrow.svg";



const TIMELINE_STEPS = [
    {
        title: "Created",
        subtext: "The pool was created by the captain",
    },
    {
        title: "Invitation period",
        subtext: "Users are being invited and reviewing the agreement",
    },
    {
        title: "Tryout period",
        subtext: "Pool is almost full and final confirmations are being gathered",
    },
    {
        title: "Active",
        subtext: "Members are committed to pool. In November, Prospinity will collect contributions and make distributions.",
    },
    {
        title: "First distribution cycle",
        subtext: "Members begin receiving their first distributions",
    },
];

const getCurrentStepIndex = (signDeadline) => {
    const now = new Date();
    const deadline = new Date(signDeadline); // works with ISO UTC format

    if (now < deadline) {
        return 1; // Invites
    }

    const msDiff = now - deadline; // milliseconds since deadline passed
    const daysSinceDeadline = msDiff / (1000 * 60 * 60 * 24);

    if (daysSinceDeadline < 30) {
        return 2; // Finalizing period
    }

    return 3; // Active
};
  

const PoolTimeLine = ({ pool }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const currentStepIndex = getCurrentStepIndex(pool.public_details.sign_deadline);

    const visibleSteps = isMobile ? TIMELINE_STEPS.slice(currentStepIndex, currentStepIndex + 2) : TIMELINE_STEPS;

    return (
        <TimelineCard>
            <TopRow>
                <LeftColumn>
                    <Dot/>
                    <StatusTitleSection>
                        <StatusTitle>{TIMELINE_STEPS[currentStepIndex].title}</StatusTitle>
                        <Subtext>
                            {TIMELINE_STEPS[currentStepIndex].subtext}
                        </Subtext>
                    </StatusTitleSection>    
                </LeftColumn>
        
                {pool?.other_users?.length > 0 && (
                    <RightColumn>
                        <MutualContainer>
                            <MutualConnections mutuals={pool.other_users} themeStyle="greyBg" mutualText="members" />
                        </MutualContainer>
                    </RightColumn>
                )}
            </TopRow>
    
            <Divider/>
    
            <Stepper>
                {visibleSteps.map((step, index) => {
                    const stepIndex = isMobile ? currentStepIndex + index : index;

                    return (
                    <Step key={index}>
                        {stepIndex !== 0 && (
                            <RightArrow
                                width="8.07"
                                height="14.52"
                                style={{ margin: isMobile ? "0" : "0 0.5rem" }}
                            />
                        )}

                        <StepText active={stepIndex === currentStepIndex}>
                            {stepIndex + 1}. {step.title}
                        </StepText>
                    </Step>
                    );
                })}
            </Stepper>


        </TimelineCard>
    );
};
  
export default PoolTimeLine;


const TimelineCard = styled.div`
    background: ${({ theme }) => theme.backgroundLight};
    border-radius: 1rem;
    padding: 1.5rem 1.5rem 0.75rem 1.5rem;
    width: 100%;
`;

const TopRow = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
        flex-direction: column;
        gap: 1rem;
    }
`;

const LeftColumn = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    align-items: baseline;
`;

const StatusTitleSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.2rem;
`;

const Dot = styled.div`
    width: 0.75rem;
    height: 0.75rem;
    aspect-ratio: 1;
    border-radius: 4px;
    background: ${({ theme }) => theme.green};
`;

const StatusTitle = styled.span`
    font-size: 1.0625rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
`;

const Subtext = styled.span`
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.textSecondary};
    flex-wrap: wrap;

    @media (max-width: 600px) {
        margin-left: -1.5rem;
    }
`;

const RightColumn = styled.div`
    display: flex;

    @media (max-width: 600px) {
        padding-left: 0.25rem;
    }

    @media (max-width: 600px) {
        padding-left: 0;
        border-left: none;
    }
`;


const MutualContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    border-left: 1px solid ${({ theme }) => theme.border};

    @media (max-width: 600px) {
        border-left: none;
        margin-left: -1.1rem;
    }
`;

const Divider = styled.div`
    width: 100%;
    border: none;
    border-top: 1px solid ${({ theme }) => theme.border};
    margin: 1rem 0 0.75rem 0;
`;

const Stepper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    @media (max-width: 600px) {
        gap: 0.75rem;
    }
`;

const Step = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (max-width: 600px) {
        gap: 0.75rem;
    }
`;

const StepText = styled.span`
    font-size: 0.875rem;
    font-weight: ${({ active }) => (active ? "500" : "400")};
    color: ${({ active, theme }) =>
        active ? theme.green : theme.textSecondary};
    background: ${({ active, theme }) =>
        active ? theme.green + "1A" : "transparent"};
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
`;
