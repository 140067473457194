import React, { useEffect, useState, useRef } from 'react';
import { styled, useTheme} from 'styled-components';
import { useMediaQuery } from 'react-responsive';

// From My Application
import { myAxiosInstance } from "../../api/axiosConfig";
import Loading from "../common/Loading";
import { StyledHeader } from '../common/Containers';
import HomeUserPreviewCard from "../users/UserPreviewCard";


const PeopleYouMightKnow = ({ initialUsers }) => {
    const [users, setUsers] = useState(initialUsers); // Start with 4 users from /api/home
    const [page, setPage] = useState(2); // Start lazy loading from page 2
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true); // Stops when no more users
    
    // Responsive
    const isDesktop = useMediaQuery({ minWidth: 1024 });

    // Get the theme
    const theme = useTheme();

    // For Lazy Loading
    const sentinelRef = useRef(null);
  
    const loadMoreUsers = async () => {
      if (!hasMore || loading) return; // Stop if max pages reached or already loading
  
      setLoading(true);
      try {
        const response = await myAxiosInstance.get(`/api/daily-picks/?page=${page}`);
        // const response = {"data": []};
        if (response.data.length === 0) {
          setHasMore(false); // Stop loading if no more users
        } else {
          setUsers(prevUsers => [...prevUsers, ...response.data.results]); // Append new users
          setPage(prevPage => prevPage + 1); // Move to next page
        }
      } catch (error) {
        console.error("Error loading more users:", error);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      if (!sentinelRef.current) return;
  
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            loadMoreUsers();
          }
        },
        { threshold: 1.0 }
      );
  
      observer.observe(sentinelRef.current);
  
      return () => observer.disconnect();
    }, [users]);
  
    // ✅ Separate users explicitly into Left & Right columns
    const leftColumnUsers = users.filter((_, index) => index % 2 !== 0);
    const rightColumnUsers = users.filter((_, index) => index % 2 === 0);
  
    return (
      <div>
        <StyledHeader theme={theme} style={{ marginBottom: "1.5rem" }}>
          YOUR DAILY <br /> PICKS
        </StyledHeader>
  
        {/* Desktop: Two Columns (Left & Right) */}
        {isDesktop ? (
          <ZigZagContainer>
            <Column>
              {leftColumnUsers.map((user, index) => (
                <HomeUserPreviewCard key={`left-${index}`} user={user} id={index === leftColumnUsers.length - 1 ? "last-user" : null} />
              ))}
            </Column>
  
            <RightColumn>
              {rightColumnUsers.map((user, index) => (
                <HomeUserPreviewCard key={`right-${index}`} user={user} />
              ))}
            </RightColumn>
          </ZigZagContainer>
        ) : (
          // Mobile: Single Column Layout
          <SingleColumn>
            {users.map((user, index) => (
              <HomeUserPreviewCard key={`mobile-${index}`} user={user} id={index === users.length - 1 ? "last-user" : null} />
            ))}
          </SingleColumn>
        )}
  
        {/* Invisible sentinel div for lazy loading */}
        <div ref={sentinelRef} style={{ height: "1px", width: "100%", visibility: "hidden" }}></div>
  
  
        {/* Centered Lazy Loading Spinner */}
        {loading && (
          <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "2rem",
          }}>
            <Loading />
          </div>
        )}
      </div>
    );
};
  
export default PeopleYouMightKnow;


const ZigZagContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem; /* ✅ Spacing between left & right columns */
`;
  
const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem; /* ✅ Normal spacing */
`;
  
const RightColumn = styled(Column)`
  margin-top: -6rem; /* ✅ Offset Right Column by 2rem */
  flex: 1;
`;

const SingleColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem; /* ✅ Mobile: Even spacing */
`;
  