import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';


import PoolTimeLine from './PoolTimeLine';
import TimeRemaining from "./PoolDeadlineTimer";
import { SecondaryButton } from '../common/Buttons';

import { ReactComponent as BannerTimer } from "../../assets/icons/bannerTimer.svg";


const PoolPreview = ({ pool }) => {
    // Get the theme
    const theme = useTheme();

    // Navigate
    const navigate = useNavigate();

    return (
        <Section style={{ flexDirection: "column", alignItems: "flex-start" }}>
            <Title>YOUR POOL</Title>

            <TopRow>
                <LeftInfo>
                    <PoolLogo src="https://images.prospinity.com/favicons/transparent-white-logo.png" alt="Pool Logo" />
                    <div>
                        <PoolName>{pool?.public_details?.name?.toUpperCase() || "POOL"}</PoolName>
                        <PoolDescription>
                            Investing into the members of this pool over the next {pool.public_details.time_horizon} years will see growth in your net earnings
                        </PoolDescription>
                    </div>
                </LeftInfo>

                <RightInfo>
                    {new Date(pool.public_details.sign_deadline) > new Date() && (
                        <TimerGroup>
                            <Divider />
                            <BannerTimer fill={theme.textPrimary} width={20} height={20} />
                            <div>
                                <TimerLabel>Time left:</TimerLabel>
                                <span style={{ fontSize: "0.875rem", fontWeight: "500" }}>
                                    <TimeRemaining
                                        deadline={pool.public_details.sign_deadline}
                                        onExpire={() => window.location.reload()}
                                    />
                                </span>
                            </div>
                        </TimerGroup>
                    )}
                    <SecondaryButton onClick={() => navigate(`/dashboard/pools/${pool.public_details.slug}`)}>VIEW POOL</SecondaryButton>
                </RightInfo>
            </TopRow>

            <ContentWrapper>
                <PoolTimeLine pool={pool} />
            </ContentWrapper>
        </Section>
    );
}

export default PoolPreview;


// Styled Components
const Section = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${({ theme }) => theme.white};
    padding: 1.5rem 1.25rem;
    border-radius: 1.5rem;
    flex-wrap: wrap;
    gap: 1.25rem;
    width: 100%;
`;

const Title = styled.div`
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textSecondary};
`;

const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;

    @media (max-width: 600px) {
        flex-direction: column;
        gap: 1.25rem;
        align-items: flex-start;
    }
`;

const LeftInfo = styled.div`
    display: flex;
    gap: 1.25rem;
    align-items: center;
    flex: 1;
    min-width: 200px;

    @media (max-width: 600px) {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }
`;

const PoolLogo = styled.img`
    width: 3.5rem;
    height: 3.5rem;
    aspect-ratio: 1;
    border-radius: 50%;
    object-fit: cover;

    @media (max-width: 600px) {
        width: 3.875rem;
        height: 3.875rem;
    }
`;

const PoolName = styled.h2`
    font-size: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
    margin: 0;

    @media (max-width: 600px) {
        font-size: 1.25rem;
    }
`;

const PoolDescription = styled.p`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.textSecondary};
    margin: 0.25rem 0 0 0;
 `;

const RightInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1.25rem;

    & > button {
        flex: 1 1 auto; // Allow button to shrink and grow
        min-width: 0;   // Prevent overflow weirdness
    }

    @media (max-width: 450px) {
        width: 100%;
        justify-content: space-between;
    }
`;


const TimerGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${({ theme }) => theme.textPrimary};
`;

const TimerLabel = styled.p`
    font-size: 0.875rem;
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.textSecondary};
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Divider = styled.div`
    width: 1px;
    height: 2.5rem; /* Adjust height */
    background-color: ${({ theme }) => theme.textPrimary};
    margin-right: 0.25rem; /* Adjust spacing */
    opacity: 0.2;
`;