import React, { useState, useEffect, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

import ProfileWithPopup from './ProfilePopup';
import { useNotifications } from "../../contexts/NotificationsContext";
import { myAxiosInstance } from '../../api/axiosConfig';
import { SearchIcon, NotificationsIcon } from '../../assets/icons/Icons';
import { ReactComponent as ProfileIcon } from '../../assets/icons/profileIcon.svg';
import { ReactComponent as LeftArrow } from '../../assets/icons/leftArrow.svg';
import { ReactComponent as XIcon } from '../../assets/icons/X.svg';


export default function MobileTopNavbar() {
    const { notifications } = useNotifications();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const [isSearching, setIsSearching] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const isNotificationsPage = location.pathname === "/dashboard/notifications-page";

    // Fetch users when typing (debounced API call)
    useEffect(() => {
        const fetchUsers = async () => {
            if (!searchValue.trim()) {
                setSearchResults([]);
                return;
            }
            try {
                const response = await myAxiosInstance.get(`/api/search-users/?q=${searchValue}&limit=20`);
                setSearchResults(response.data.users || []);
            } catch (error) {
                setSearchResults([]);
            }
        };

        const debounceTimeout = setTimeout(fetchUsers, 300); // Debounce API calls
        return () => clearTimeout(debounceTimeout);
    }, [searchValue]);


    const handleSearchClick = () => setIsSearching(true);
    const handleBackClick = () => {
        setIsSearching(false);
        setSearchValue("");
        setSearchResults([]);
    }
    const handleClearSearch = () => {
        setSearchValue("");
        setSearchResults([]);
    };
    
    const handleSelect = (userSlug) => {
        navigate(`user/${userSlug}`);
        setIsSearching(false);
        setSearchValue("");
        setSearchResults([]);
    };

    return (
        <>
            {/* White overlay when searching */}
            {isSearching &&
                <SearchOverlay>
                    <SearchResultsContainer>
                        {searchValue && <SectionTitle>TOP 10 RESULTS</SectionTitle>}
                        {searchResults.length > 0 && (
                            searchResults.map((user) => (
                                <ResultItem key={user.id} onClick={() => handleSelect(user.slug)} theme={theme}>
                                    <ProfileImage src={user.profile_picture?.trim() !== "" ? user.profile_picture : "https://storage.googleapis.com/prosp_profile_img/favicons/favicon.svg"} alt={user.name} />
                                    <UserInfo>
                                        <UserName theme={theme}>{user.first_name} {user.last_name}</UserName>
                                        <UserDetails theme={theme}>
                                            {user.university} | {user.company ? user.company : `${user.graduation_date}`}
                                        </UserDetails>

                                        <DegreeContainer>
                                            {user.major_or_grad_program.slice(0, 2).map((degree, index) => (
                                                <DegreeTag key={index} theme={theme}>
                                                    {degree}
                                                </DegreeTag>
                                            ))}
                                        </DegreeContainer>
                                    </UserInfo>
                                </ResultItem>
                            ))
                        )}
                    </SearchResultsContainer>
                </SearchOverlay>
            }
  
            <NavbarContainer>
                {/* Logo */}
                {isSearching ? (
                    <BackButton onClick={handleBackClick}>
                        <LeftArrow width={20} height={20} fill={theme.textPrimary} />
                    </BackButton>
                ) : (
                    <Logo src="/assets/logoBlack.svg" alt="Prospinity Logo" onClick={() => navigate('/')} />
                )}

                {/* Icons */}
                <ItemContainer>
                    {/* Search Bar */}
                    {/* onClick={() => document.getElementById("search-input").focus()} */}
                    <SearchBar onClick={handleSearchClick} isSearching={isSearching}>
                        <SearchIcon theme={theme} />
                        <SearchInput id="search-input" type="text" placeholder="Search..." value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                        {isSearching && searchValue && (
                            <ClearSearchButton onClick={handleClearSearch}>
                                <XIcon width={9} height={9} />
                            </ClearSearchButton>
                        )}
                    </SearchBar>
            

                    {/* Profile */}
                    {!isSearching &&
                        <>
                            {/* Notification */}
                            <NotificationWrapper onClick={() => navigate(isNotificationsPage ? "/dashboard/home" : "/dashboard/notifications-page")}>
                                <NotificationIconWrapper>
                                    <NotificationsIcon theme={theme} width='21' height='20' />
                                </NotificationIconWrapper>
                                {notifications.some(n => !n.read) && <NotificationDot theme={theme} />}
                            </NotificationWrapper>
                            <ProfileWithPopup onShowSettings={() => console.log(' ')} />
                        </>
                    }
                </ItemContainer>
            </NavbarContainer>
        </>
    );
}


const NavbarContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 72px;
    background: ${({ theme }) => theme.white}; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;  /* Consistent padding */
    z-index: 1000;
    border-bottom: 1px solid ${({ theme }) => theme.divider};
    gap: 1rem;
    touch-action: none;
`;

const Logo = styled.img`
    width: 24px;
    height: 24px;
`;

const BackButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const SearchBar = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.backgroundLight}; /* Theme background */
    border-radius: 999px; /* Fully rounded */
    padding: 8px 20px;
    cursor: text;
    border: ${({ isSearching, theme }) => (isSearching ? `1px solid ${theme.primary}` : "none")}; /* Add border when active */
`;

const SearchInput = styled.input`
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    min-width: 100px;
    flex: 1;
    margin-left: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${({ theme }) => theme.textPrimary}; /* Use textTertiary */

    &::placeholder {
        color: ${({ theme }) => theme.textTertiary};
    }
`;

const ClearSearchButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: ${({ theme }) => theme.divider};
    border-radius: 50%;
    cursor: pointer;
    margin-left: 8px;

    &:hover {
        background: ${({ theme }) => theme.primaryLight};
    }
`;

const ItemContainer = styled.div`
    display: flex;
    flex: 1;
    gap: 16px; /* Adjusted spacing */
    align-items: center;
`;

/* Extracted Notification Styles */
const NotificationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
`;

const NotificationIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NotificationDot = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.red};
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.white};
    z-index: 1;
`;

const SearchOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${({ theme }) => theme.white};
    z-index: 999; /* Ensure it covers everything */
`;


const SearchResultsContainer = styled.div`
    position: absolute;
    top: 72px; /* Below navbar */
    left: 0;
    width: 100%;
    background: ${({ theme }) => theme.white};
    padding: 1rem;
    max-height: calc(100vh - 72px);
    overflow-y: auto;
    padding-bottom: 7rem;
`;

const SectionTitle = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textSecondary};
    margin-bottom: 1rem;
`;

const ResultItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    cursor: pointer;
    gap: 21px;
    height: 5rem;

    &:hover {
        background: ${({ theme }) => theme.backgroundLight};
    }
`;

const ProfileImage = styled.img`
    height: 4.3rem;
    width: 4.3rem;
    aspect-ratio: 1;
    border-radius: 1rem;
    object-fit: cover;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
`;

const UserName = styled.span`
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
    flex: 1; /* Ensures it takes up available space */
    min-width: 0; /* Allows it to shrink within flex container */
    white-space: nowrap; /* Prevents wrapping */
    overflow: hidden; /* Ensures text does not overflow */
    text-overflow: ellipsis; /* Adds '...' when text is too long */
`;

const UserDetails = styled.span`
    font-size: 0.75rem;
    color: ${({ theme }) => theme.textSecondary};
    flex: 1; /* Ensures it takes up available space */
    min-width: 0; /* Allows it to shrink within flex container */
    white-space: nowrap; /* Prevents wrapping */
    overflow: hidden; /* Ensures text does not overflow */
    text-overflow: ellipsis; /* Adds '...' when text is too long */
`;

const DegreeContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 0.5rem;
    margin-top: 0.35rem;
`;

const DegreeTag = styled.span`
    font-size: 0.75rem;
    font-weight: 400;
    color: ${({ theme }) => theme.primary};
    background: ${({ theme }) => theme.primaryLight};
    padding: 0.1rem 0.625rem;
    border-radius: 2.75rem;
    display: inline-block;

    max-width: 30ch; /* Limits to 30 characters */
    white-space: nowrap; /* Prevents wrapping */
    overflow: hidden; /* Ensures text does not overflow */
    text-overflow: ellipsis; /* Adds '...' when text is too long */

    @media (max-width: 1024px) {
        max-width: 15ch; /* Move '...' closer as screen gets smaller */
    }

`;
// import axios from 'axios';
// import { useState, useEffect, useRef } from 'react';
// import { Outlet, Link, useNavigate } from 'react-router-dom';
// import { Nav, FormControl, Row, Col, Container, InputGroup, Dropdown, Navbar, Button } from 'react-bootstrap';
// import logo_white from '../../assets/images/logo_white.png';
// import logo_navy from '../../assets/images/logo_navy.png';
// import { useAuth } from '../../contexts/AuthContext';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
// import SearchUsers from './SearchUsers';
// import { faHome, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear, faArrowLeft } from '@fortawesome/free-solid-svg-icons'; // Example icons
// import { useMediaQuery } from 'react-responsive';
// import { ProfilePicture } from '../common/MiscComponents';
// import { timeSince } from '../common/CriticalFunctions';
// import { Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { useErrorBoundary } from "react-error-boundary";
// import { StyledNavbar, StyledDropdown, StyledButton, StyledH5, StyledNavLink } from '../common/StyledBootstrap';
// import { useTheme } from 'styled-components';
// import { myAxiosInstance } from '../../api/axiosConfig';
// import Notifications from './Notifications';
// import NotificationsSmall from './NotficiationsSmall';

// function NavbarSmallScreen({ setShowReferModal, setWhyModalShow }) {
//     const [expanded, setExpanded] = useState(false);
//     const themeMode = useTheme();
//     const { userSlug, handleLogout, userFirstName, userLastName, userGraduationDate } = useAuth();
//     const navigate = useNavigate();
//     const navbarRef = useRef(null);

//     const [search, setSearch] = useState(false);

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (navbarRef.current && !navbarRef.current.contains(event.target)) {
//                 setExpanded(false);
//             }
//         };

//         document.addEventListener('mousedown', handleClickOutside);

//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, [navbarRef]);

//     const logOutNavigate = () => {
//         handleLogout();
//         navigate('/signing-out');
//     };

//     const getBaseFontSize = () => {
//         const fontSize = window.getComputedStyle(document.body).fontSize;
//         return parseFloat(fontSize);
//     };

//     const toggleNavbar = () => {
//         setExpanded(!expanded);
//     };

//     // Effect to handle clicks outside the navbar
//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (navbarRef.current && !navbarRef.current.contains(event.target)) {
//                 setExpanded(false);
//             }
//         };

//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, [navbarRef]);

//     // Effect to focus the search input when search is true
//     useEffect(() => {
//         if (search) {
            
//                 const searchInput = document.querySelector('.search-users-small');
//                 if (searchInput) {
//                     searchInput.focus();
//                 }

            
//         }
//     }, [search]); // Ru

//     return (
//         <StyledNavbar ref={navbarRef} expanded={expanded} expand="lg" sticky="top">
//             {search ? 
//             <div className='d-flex w-100 align-items-center'>
//                 <div style={{fontSize: '1.5rem'}} onClick={() => setSearch(false)}>
//                     <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
//                 </div>
//                 <SearchUsers />
//             </div>
//              : <>
//             <div className='d-flex w-100 justify-content-between'>
//                 <div>
//                     <Navbar.Brand href="/dashboard/home" className="d-flex align-items-center ml-2">
//                         <img src={themeMode.theme === 'dark' ? logo_white : logo_navy} height="30rem" className="d-inline-block align-top" alt="Logo" />
//                     </Navbar.Brand>
//                 </div>
//                 <div className='d-flex align-items-center'>
//                     <div style={{fontSize: '1.5rem'}} onClick={() => setSearch(true)} className='search-joyrride'>
//                     <FontAwesomeIcon icon={faSearch} className="mr-2" />
//                     </div>
//                     {/* <NotificationsSmall className='d-flex justify-content-end' /> */}
//                     <Navbar.Toggle onClick={toggleNavbar} aria-controls="responsive-navbar-nav" style={{ border: '0px' }} className='profile-dropdown-joyrride'/>
//                 </div>
//             </div>
//             <Navbar.Collapse id="responsive-navbar-nav">
//                 {/*<Nav.Link as={Link} to="create-pool" style={{ color: themeMode.onSurface }} className='create-joyrride'>Create Pool</Nav.Link>*/}
//                 <Nav.Link onClick={() => setShowReferModal(true)} style={{ color: themeMode.onSurface }}>Invite</Nav.Link>
//                 <Nav.Link as={Link} to={"user/" + userSlug} style={{ color: themeMode.onSurface }}>Profile</Nav.Link>
//                 <Nav.Link as={Link} to="faqs" style={{ color: themeMode.onSurface }}>FAQs</Nav.Link>
//                 <Nav.Link onClick={logOutNavigate} style={{ color: themeMode.onSurface }}>Logout</Nav.Link>
//             </Navbar.Collapse></>}
//         </StyledNavbar>
//     );
// }

// export default NavbarSmallScreen;


