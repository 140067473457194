import styled, { useTheme } from 'styled-components';
import { FlexContainer } from '../common/Containers';
import { useNavigate } from 'react-router-dom';

import { useAuth } from "../../contexts/AuthContext";


// TaskProgress Component
const TaskProgress = ({profileCompleted, portfolioCompleted, poolCompleted, poolJoinCompleted, peopleSectionRef}) => {
  // Navigation
  const navigate = useNavigate();

  // User's slug
  const { userSlug } = useAuth();

  const tasks = [
    { label: "Complete Profile", description: "Add a tagline, bio, LinkedIn profile, etc.", completed: profileCompleted, icon: "/assets/profileTaskNotDone.svg", completedIcon: "/assets/profileTask.svg", redirect: `/dashboard/user/${userSlug}`},
    { label: "Build Out Network", description: "Make five requests (even cold) to start.", completed: portfolioCompleted, icon: "/assets/portfolioTaskNotDone.svg", completedIcon: "/assets/portfolioTask.svg", redirect: "scrollToPeopleSection"},
    { label: "View Pool Invite(s)", description: "Review the members and the terms.", completed: poolCompleted, icon: "/assets/reviewPoolNotDone.svg", completedIcon: "/assets/reviewPool.svg", redirect: "/dashboard/pools"},
    { label: "Join Your First Success Pool", description: "Enter into 30-day Pool Trial Period.", completed: poolJoinCompleted, icon: "/assets/joinPoolNotDone.svg", completedIcon: "/assets/joinPool.svg", redirect: "/dashboard/pools"},
  ];

  // Get the theme
  const theme = useTheme();

  return (
    <FlexContainer direction="column" gap="1.5rem" style={{ background: theme.white, borderRadius: "1.5rem", padding: "2rem" }}>
      <TaskHeader theme={theme}>FINISH YOUR ONBOARDING</TaskHeader>
      <TaskContainer>
        <TaskLine theme={theme} />
        {tasks.map((task, index) => (
          <TaskItem 
            key={index} 
            onClick={() => {
              if (task.redirect === "scrollToPeopleSection" && peopleSectionRef?.current) {
                  peopleSectionRef.current.scrollIntoView({ behavior: "smooth", block: "center" }); // ✅ Scrolls smoothly
              } else {
                  navigate(task.redirect);
              }
          }}
          >
            <img src={task.completed ? task.completedIcon : task.icon} style={{width: "2.5rem"}}></img>
            <TaskTextWrapper> {/* NEW WRAPPER */}
              <TaskLabel>{task.label}</TaskLabel>
              <TaskDescription>{task.description}</TaskDescription>
            </TaskTextWrapper>
          </TaskItem>
        ))}
      </TaskContainer>
    </FlexContainer>
  );
};

export default TaskProgress;


// ✅ Header Styling (Updated)
const TaskHeader = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textSecondary}; /* ✅ textSecondary */

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

// ✅ Task Container (Flexbox for horizontal layout)
const TaskContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 2rem;
  padding: 0 2rem;

  @media (max-width: 1024px) {
    flex-wrap: wrap; /* ✅ Allows content to flow properly */
    justify-content: center;
  }

  @media (max-width: 768px) {
    padding: 0;
    flex-direction: column; /* Stack tasks vertically */
    align-items: flex-start; /* Align everything to the left */
    gap: 1.25rem; /* Adjust spacing */
  }
`;

// ✅ Full Dashed Line Behind Icons (Always Stays Centered)
const TaskLine = styled.div`
  position: absolute;
  top: 2.15rem; /* ✅ Half the icon height (50px + 16px padding) */
  width: 70%; /* ✅ Matches the container width */
  height: 1px;
  background: repeating-linear-gradient(
    to right,
    ${({ theme }) => theme.divider} 0px,
    ${({ theme }) => theme.divider} 5px,
    transparent 5px,
    transparent 10px
  );
  
  /* ✅ Ensures the line is centered inside the container */
  left: 0;
  right: 0;
  margin: 0 auto;

  @media (max-width: 1024px) {
    display: none; /* ✅ Hide when wrapping */
  }
`;

// Individual Task Item (With Correct Dashed Line)
const TaskItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  min-width: 120px;
  flex: 1;

  cursor: pointer; /* Makes it clickable */
  transition: background 0.3s ease-in-out;
  padding: 1rem;
  border-radius: 1.5rem;
  &:hover {
    background: ${({ theme }) => theme.backgroundLight}; /* Changes background on hover */
  }

  @media (max-width: 768px) {
    flex-direction: row;
    width: 100%; /* Full width */
    padding: 0;
    min-width: 100%;
  }

  @media (max-width: 768px) {
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      left: 18.8px; /* Center it exactly */
      top: 40px; /* Starts slightly below the icon */
      width: 1px;
      height: calc(100% + 10px); /* Extends down to next icon */
      background: repeating-linear-gradient(
        to bottom,
        ${({ theme }) => theme.divider} 0px,
        ${({ theme }) => theme.divider} 5px,
        transparent 5px,
        transparent 10px
      );
    }
  }

`;


// ✅ Task Label
const TaskLabel = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0.4rem;
  color: ${({ theme }) => theme.textSecondary};

  @media (max-width: 768px) {
    margin: 0;
  }
`;

// ✅ Task Description
const TaskDescription = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.textTertiary};
  font-weight: 400;
  margin-bottom: 0;
`;

// Wrap Label + Description in a Left-Aligned Container
const TaskTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-left: 0.75rem;
    align-items: flex-start; /* Ensure text aligns left */
    justify-content: flex-start;
    text-align: left;
    width: 100%;
  }
`;

