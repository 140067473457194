import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { HomeIcon, PoolsIcon, PeopleIcon, ChatIcon, FinancesIcon, HomeIconActive, PoolsIconActive, PeopleIconActive, ChatIconActive, FinancesIconActive } from '../../assets/icons/Icons';


export default function MobileBottomNavbar() {
  const theme = useTheme();
  const location = useLocation();

  const links = [
      { label: 'Home', to: '/dashboard/home', icon: HomeIcon, iconActive: HomeIconActive },
      { label: 'Pools', to: '/dashboard/pools', icon: PoolsIcon, iconActive: PoolsIconActive },
      { label: 'People', to: '/dashboard/portfolio', icon: PeopleIcon, iconActive: PeopleIconActive},
      { label: 'Inbox', to: '/dashboard/inbox', icon: ChatIcon, iconActive: ChatIconActive},
      { label: 'Finances', to: '/dashboard/finances', icon: FinancesIcon, iconActive: FinancesIconActive },
  ];

  return (
      <NavbarContainer>
          {links.map(({ label, to, icon: Icon, iconActive: IconActive }) => {
              const isHome = to === "/dashboard/home";
              const isActive = isHome
                  ? location.pathname === "/dashboard" || 
                    location.pathname.startsWith("/dashboard/home")
                  : to === "/dashboard/portfolio" ? 
                    location.pathname.startsWith('/dashboard/portfolio') ||
                    location.pathname.startsWith('/dashboard/user') || 
                    location.pathname.startsWith('/dashboard/search')
                  : location.pathname.startsWith(to);

              return (
                  <NavItemWrapper key={to}>
                      <NavItem to={to} className={isActive ? "active" : ""}>
                          <IconWrapper>
                              {isActive ? 
                                  <IconActive fill={theme.white} />
                                  :
                                  <Icon fill={theme.white} fillOpacity={0.64} />
                              }
                          </IconWrapper>
                          {label}
                      </NavItem>
                  </NavItemWrapper>
              );
          })}
      </NavbarContainer>
  );
}



const NavbarContainer = styled.nav`
  position: fixed;
  bottom: 1.6rem;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 500px;
  background: ${({ theme }) => theme.textPrimary};
  border-radius: 100px;
  padding: 4px;
  display: flex;
  justify-content: space-between; /* 👈 Ensures flexible spacing */
  align-items: center;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  touch-action: none;
`;

const NavItemWrapper = styled.div`
  flex: 1; /* 👈 Ensures all items take equal space */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const NavItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  text-decoration: none !important; /* 🔥 Absolutely no underline */
  color: ${({ theme }) => theme.white} !important;
  opacity: 0.64;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  max-width: 64px;
  aspect-ratio: 1 / 1; /* Maintains perfect circle */
  user-select: none; /* 🔥 No text selection */
  outline: none !important; /* 🔥 No browser focus outline */

  /* 🔥 Blocks all browser touch/click visual effects */
  -webkit-tap-highlight-color: transparent !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  
  &:focus,
  &:focus-visible,
  &:active {
    color: ${({ theme }) => theme.white} !important;
    text-decoration: none !important;
    outline: none !important;
  }

  &.active {
    color: ${({ theme }) => theme.white} !important;
    opacity: 1;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 50%;
    text-decoration: none !important; /* 🔥 Extra safety */
  }
`;


const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;



// import { useLocation } from 'react-router-dom'; // To get the current path
// import { Nav } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHome, faUserGroup, faBullseye, faCreditCard, faMessage, faUser } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'react-router-dom';
// import { useTheme } from 'styled-components';
// import { useNavigate } from 'react-router-dom';
// import logo_white_p from '../../assets/images/logo_white_p.png'
// import logo_black_p from '../../assets/images/logo_black_p.png';
// import logo_navy_p from '../../assets/images/logo_navy_p.png';

// function BottomNavbarSmallScreen() {
//     const themeMode = useTheme();
//     const location = useLocation(); // Get the current URL path

//     // Helper function to check if a path is active
//     const isActive = (path) => location.pathname === path;

//     const navigate = useNavigate();

//     // Style for active and inactive states
//     const getNavItemStyle = (active) => ({
//         color: active ? themeMode.primary : themeMode.onSurface,
//         boxShadow: active ? '0px -2px 10px rgba(0, 0, 0, 0.2)' : 'none',
//         borderTop: active ? `2px solid ${themeMode.primary}` : 'none',
//         fontWeight: active ? 'bold' : 'normal',
//         padding: '1rem',
//         textAlign: 'center',
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         alignItems: 'center',
//         flex: 1
//     });

//     return (
//         <div style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: themeMode.surface, display: 'flex', justifyContent: 'space-around', zIndex: 1030 }}>
//     <div style={getNavItemStyle(isActive('/dashboard/home'))} onClick={() => navigate("/dashboard/home")}>
//         <FontAwesomeIcon icon={faHome} />
//         <div style={{ color: 'inherit', fontSize: '0.7rem' }}>Home</div>
//     </div>
//     <div style={getNavItemStyle(isActive('/dashboard/portfolio'))} onClick={() => navigate("/dashboard/portfolio")}>
//         <FontAwesomeIcon icon={faUserGroup} />
//         <div style={{ color: 'inherit', fontSize: '0.7rem' }}>Portfolio</div>
//     </div>
//     <div style={getNavItemStyle(isActive('/dashboard/pools'))} onClick={() => navigate("/dashboard/pools")}>
//         {/*<FontAwesomeIcon icon={faBullseye} />*/}
//         <img src={isActive('/dashboard/pools') ? logo_navy_p : themeMode.theme == 'dark' ? logo_white_p : logo_black_p} height="17rem" />
//         <div style={{ color: 'inherit', fontSize: '0.7rem' }}>Pools</div>
//     </div>
//     <div style={getNavItemStyle(isActive('/dashboard/payments'))} onClick={() => navigate("/dashboard/payments")}>
//         <FontAwesomeIcon icon={faCreditCard} />
//         <div style={{ color: 'inherit', fontSize: '0.7rem' }}>Finances</div>
//     </div>
//     <div style={getNavItemStyle(isActive('/dashboard/chat-page'))} onClick={() => navigate("/dashboard/chat-page")}>
//         <FontAwesomeIcon icon={faMessage} />
//         <div style={{ color: 'inherit', fontSize: '0.7rem' }}>Chat</div>
//     </div>
// </div>
//     );
// }

// export default BottomNavbarSmallScreen;