import React, { useRef } from 'react';
import clsx from 'clsx';

import CustomAvatar from './CustomAvatar';


const UnMemoizedChannelPreviewMessenger = (props) => {
  const {
    active,
    channel,
    className: customClassName = '',
    displayTitle,
    latestMessage,
    onSelect: customOnSelectChannel,
    setActiveChannel,
    unread,
    watchers,
  } = props;

  const channelPreviewButton = useRef(null);
  const members = Object.values(channel.state.members);
  const isGroup = members.length > 2;

  // Get the img
  const currentUserID = channel.state.membership.user.id;
  const default_img = "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW_20250320180317.webp";
  const other_user = members.length > 2 ? null : members.find((member) => member.user.id !== currentUserID)?.user;
  const imageSrc = isGroup ? default_img : other_user?.image;
  
  const title = isGroup
    ? channel.data.name || "Pool"
    : displayTitle

  const onSelectChannel = (e) => {
    if (customOnSelectChannel) {
      customOnSelectChannel(e);
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  const extractTextFromJSX = (jsx) => {
    if (typeof jsx === 'string') return jsx;
    if (Array.isArray(jsx)) return jsx.map(extractTextFromJSX).join('');
    if (typeof jsx === 'object' && jsx.props && jsx.props.children) return extractTextFromJSX(jsx.props.children);
    return '';
  };

  const truncateMessage = (message, length = 20) => {
    const textContent = extractTextFromJSX(message);
    return textContent.length > length ? `${textContent.substring(0, length)}...` : textContent;
  };

  return (
    <button
      aria-label={`Select Channel: ${displayTitle || ''}`}
      aria-selected={active}
      className={clsx(
        `str-chat__channel-preview-messenger str-chat__channel-preview`,
        active && 'str-chat__channel-preview-messenger--active',
        unread && unread >= 1 && 'str-chat__channel-preview-messenger--unread',
        customClassName,
      )}
      data-testid='channel-preview-button'
      onClick={onSelectChannel}
      ref={channelPreviewButton}
      role='option'
    >
      <div className='str-chat__channel-preview-messenger--left' style={{ paddingLeft: "1rem" }}>
        <CustomAvatar image={imageSrc} name={title} size={40} />
      </div>
      <div className='str-chat__channel-preview-messenger--right str-chat__channel-preview-end'>
        <div className='str-chat__channel-preview-end-first-row'>
          <div className='str-chat__channel-preview-messenger--name'>
            <span>{title}</span>
          </div>
          {!!unread && (
            <div className='str-chat__channel-preview-unread-badge' data-testid='unread-badge'>
              {unread}
            </div>
          )}
        </div>
        <div className='str-chat__channel-preview-messenger--last-message'>
          {truncateMessage(latestMessage || "Nothing yet...")}
        </div>
      </div>
    </button>
  );
};

/**
 * Used as preview component for channel item in [ChannelList](#channellist) component.
 * Its best suited for messenger type chat.
 */
export const CustomChannelPreview = React.memo(UnMemoizedChannelPreviewMessenger);
