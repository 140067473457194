import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";

export default function ApplicationRedirect() {
  const { referal_code } = useParams(); // Get referral code from URL
  const navigate = useNavigate();

  useEffect(() => {
    if (referal_code) {
      sessionStorage.setItem("referral_code", referal_code); // ✅ Store in sessionStorage
    }

    if (referal_code === "tiktok" && Math.random() < 0.5) {
      window.location.href = `https://o0bf65aidiq.typeform.com/to/fueSvugK#source=${referal_code}`;
      return;
    }
    
    // Redirect to application
    window.location.href = "https://app.prospinity.com/berkeley/application";
  }, [referal_code, navigate]);

  return null; // No UI needed
}