import React, { useState, useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";


const faqs = [
    {
        title: "How does everything work?",
        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
        image: "/assets/faq1.png",
    },
    {
        title: "Why this specific pool for me?",
        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
        image: "/assets/faq2.png",
    },
    {
        title: "How can I connect with other memebers?",
        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
        image: "/assets/faq3.png",
    },
];
  
const PoolFAQ = () => {
    return (
        <FAQWrapper>
            {faqs.map((faq, index) => (
                <Card key={index}>
                    <Left>
                        <Title>{faq.title}</Title>
                        <Answer>{faq.answer}</Answer>
                    </Left>

                    <Right><Image src={faq.image} alt={faq.title} /></Right>
                    {/* <Right src={faq.image} /> */}
                </Card>
            ))}
        </FAQWrapper>
    );
};
  
export default PoolFAQ;

  

const FAQWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
`;

const Card = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border-radius: 1.5rem;
    background: ${({ theme }) => theme.white};
    width: 100%;
    overflow: hidden;

    @media (max-width: 1000px) {
        width: 100%;
    }

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

const Left = styled.div`
    padding: 1.5rem 2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (max-width: 600px) {
        padding: 1.5rem 1.25rem;
    }
`;

const Title = styled.h3`
    font-size: 1.375rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
    margin-bottom: 0.5rem;

    @media (max-width: 600px) {
        font-size: 1.0625rem;
    }
`;

const Answer = styled.p`
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.textSecondary};
    line-height: 1.5rem;
    margin: 0;
`;

const Right = styled.div`
    width: 32%;
    height: auto;
    display: flex;
    align-items: stretch; /* ✅ make child (Image) stretch */
    justify-content: center;
    flex-shrink: 0; /* ✅ prevent it from shrinking smaller than the left */

    @media (max-width: 600px) {
        width: 100%;
        height: auto; /* ✅ let it be normal height on mobile */
    }
`;

// const Right = styled.div`
//     width: 40%;
//     background-image: url(${(props) => props.src});
//     background-size: cover;
//     background-position: center;
//     background-repeat: no-repeat;

//     @media (max-width: 600px) {
//         width: 100%;
//         height: 200px; /* or any value you want for mobile */
//     }
// `;

const Image = styled.img`
    width: 100%;
    height: 100%; /* ✅ fill vertical height of Right */
    object-fit: cover;
    display: block;
`;
