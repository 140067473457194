import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie";
import { motion, AnimatePresence } from "framer-motion";

import { SimpleTextPage } from "./SurveyScreens";
import { SurveyOptionContainer, SurveyOptionButton } from "./SurveyComponents";
import { useApplication } from "../../contexts/ApplicationContext";
import { myAxiosInstance } from "../../api/axiosConfig";


export default function ScreenNine() {
  const [isTypingDone, setIsTypingDone] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const navigate = useNavigate();
  const typingSpeed = 85;
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  const { formData, updateFormData } = useApplication();
  const [isSubmitting, setIsSubmitting] = useState(false);


  useEffect(() => {
    document.body.style.backgroundColor = "black";
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const handleTypingDone = () => {
    setIsTypingDone(true);
  };

  const handleYes = () => {
    setIsFadingOut(true);

    // Track
    let uniqueId = formData.uniqueId;

    if (!uniqueId) {
      uniqueId = uuidv4();
      sessionStorage.setItem("unique_id", uniqueId);
    }

    // Use sendBeacon if supported for better reliability
    const data = new FormData();
    data.append("unique_id", uniqueId);
    data.append("screen9", "Interested");
    const BASE_URL = process.env.REACT_APP_API_URL
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, data);

    setTimeout(() => navigate("/berkeley/application/start"), 600); // Wait for animation before navigating
  };

  const handleNo = async () => {
    // Set loading state
    setIsSubmitting(true);

    // Track
    let uniqueId = formData.uniqueId;

    if (!uniqueId) {
      uniqueId = uuidv4();
      sessionStorage.setItem("unique_id", uniqueId);
    }

    // Use sendBeacon if supported for better reliability
    const data = new FormData();

    const applicationData = {
      full_name: "__", // Name
      university_email: formData.email || "__", // Email
      major: "NOT INTERESTED IN PROSPINITY", // Major
      graduation_year: "9999", // Grad Year
      linkedin_url: "", // LinkedIn
      most_impressive: "", // Most impressive thing
      future_vision: "", // Future goal
      extra_links: "", // Links
      pool_recommendations: "", // People for the pool
      additional_info: "", // Extra information
      phone_number: "", // Phone number
      referral_code: formData.referral_code, // Referal cide
    };

    // Append text fields to FormData
    Object.keys(applicationData).forEach((key) => {
      data.append(key, applicationData[key]);
    });

    try {
      // Send request using Axios
      const response = await myAxiosInstance.post('api/submit-application/', data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {

    } finally {
      // Remove loading state
      setIsSubmitting(false);
    }

    data.append("unique_id", uniqueId);
    data.append("screen9", "Not Interested");
    const BASE_URL = process.env.REACT_APP_API_URL
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, data);

    setIsFadingOut(true);
    setTimeout(() => navigate("/berkeley/application/thank-you"), 600); // Wait for animation before navigating
  };

  const firstPageText = [
    {
        sequences: [
            { content: 700, wrapper: "span", style: {} },
            { content: "The Prospinity platform is ", wrapper: "span", style: {} },
            { content: "invite-only", wrapper: "strong", style: {} },
            { content: ", but applying takes just ", wrapper: "span", style: {} },
            { content: "five minutes", wrapper: "strong", style: {}},
            { content: "—anyone can do it, no matter experience.", wrapper: "span", style: {} },
            { content: 500, wrapper: "span", style: {} },
        ],
    },
    {
        sequences: [
            { content: 500, wrapper: "span", style: {} },
            { content: "Students from ", wrapper: "span", style: {} },
            { content: "Yale, Harvard, Princeton", wrapper: "strong", style: {}},
            { content: " and even some from ", wrapper: "span", style: {} },
            { content: "Berkeley", wrapper: "strong", style: {}},
            { content: " have already joined.", wrapper: "span", style: {} },
        ],
    },
    {
        sequences: [
            { content: 500, wrapper: "span", style: {} },
            { content: "If shortlisted, you’ll have a ", wrapper: "span", style: {} },
            { content: "brief interview", wrapper: "strong", style: {}},
            { content: ". After that, if selected, you can:", wrapper: "span", style: {} },
        ],
    },
    {
        sequences: [
            { content: "A) Join a curated pool", wrapper: "strong", style: {}},
            { content: " with top students.", wrapper: "span", style: {} },
            { content: 300, wrapper: "br", style: {} },
            { content: "B) Create your own pool", wrapper: "strong", style: {}},
            { content: " with friends.", wrapper: "span", style: {} },
        ],
    },
    {
        sequences: [
          { content: 800, wrapper: "span", style: {} },  // Extra spacing before this line
          { content: "Want access to success pools at Cal?", wrapper: "span", style: { display: "block", marginTop: "2rem" } }, // Italicized text
          { content: 500, wrapper: "span", style: {} },
        ],
    }
  ];
  
  return (
    <AnimatePresence>
      {!isFadingOut && (
        <motion.div
          key="screen-one"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }} // Smooth fade-out
          transition={{ duration: 0.6, ease: "easeOut" }} // Smooth easing
        >
          {/* Text content */}
          <SimpleTextPage
            paragraphs={firstPageText}
            typing={true}
            typingSpeed={typingSpeed}
            onTypingDone={handleTypingDone} // Ensures we track typing completion
          />
  
          {/* Buttons (only appear after typing is done) */}
          {isTypingDone && (
            <motion.div
              key="buttons"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 0 }} // Ensure buttons fade with text
              transition={{ duration: 0.6 }}
              style={{ width: "100%" }}
            >
              <SurveyOptionContainer isMdOrLarger={isMdOrLarger}>
                <SurveyOptionButton
                  key="Yes"
                  onClick={handleYes}
                  isMdOrLarger={isMdOrLarger}
                >
                  {"Yes, I might be interested"}
                </SurveyOptionButton>
                <SurveyOptionButton
                  key="No"
                  onClick={handleNo}
                  isMdOrLarger={isMdOrLarger}
                >
                  <span style={{ opacity: isSubmitting ? 0.5 : 1 }}>
                    {isSubmitting ? (
                      <LoaderContainer>
                        <LoaderDot variants={loaderVariants} animate="animate" />
                        <LoaderDot variants={loaderVariants} animate="animate" style={{ animationDelay: "0.2s" }} />
                        <LoaderDot variants={loaderVariants} animate="animate" style={{ animationDelay: "0.4s" }} />
                      </LoaderContainer>
                    ) : "No"}
                  </span>
                </SurveyOptionButton>
              </SurveyOptionContainer>
            </motion.div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
 }  

 

 const LoaderContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* ✅ Ensures full height to center dots */
  margin-top: 0; /* ✅ Removes extra spacing */
`;

const LoaderDot = styled(motion.div)`
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  margin: 0 4px;
`;

const loaderVariants = {
  animate: {
    opacity: [0.2, 1, 0.2], // Glow effect
    transition: {
      repeat: Infinity,
      duration: 1,
      ease: "easeInOut",
    },
  },
};