import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { FaRegCopy } from "react-icons/fa"; // FontAwesome copy icon
import md5 from "crypto-js/md5"; // Hashing library for uniqueness
import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';
import { useApplication } from "../../contexts/ApplicationContext";

import WhiteLogo from "../../assets/images/logo_white.png"; // Import your logo here

import { FaShareAlt } from "react-icons/fa";
import { IoShareOutline } from "react-icons/io5";




function ShareButton({ link }) {
  const [copied, setCopied] = useState(false);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          url: link,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      // Fallback: Copy to clipboard
      navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  return (
    <CopyableContainer style={{flexDirection:"row"}} onClick={handleShare}>
      <IoShareOutline />
      <span>Share</span>
      {copied && <CopiedMessage>Copied!</CopiedMessage>}
    </CopyableContainer>
  );
}

// **Styled Components**
const ShareContainer = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
  background: none;
  border: none;
  font-size: 1rem;
  position: relative;

  &:hover {
    color: white;
  }

  svg {
    font-size: 1.2rem;
  }
`;


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000; /* Pure black background */
  color: white;
  overflow: hidden;
  touch-action: none; /* Disables touch scrolling */
  overscroll-behavior: none; /* Prevents overscroll bounce */
`;

const Logo = styled.img`
  width: ${({ isMdOrLarger }) => (isMdOrLarger ? "20rem" : "13rem")};
  margin-bottom: 1rem;
`;

const ApplicationText = styled(motion.div)`
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.7rem" : "1.2rem")};
  line-height: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.6" : "1.4")};
  text-align: center;
  color: rgba(255, 255, 255, 0.9); /* Slightly softer white */
  padding: ${({ isMdOrLarger }) => (isMdOrLarger ? "1rem" : "0rem")};
`;

const NavigationButtons = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  font-size: ${({ isMdOrLarger }) => (isMdOrLarger ? "1.4rem" : "1.2rem")};
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;

  span {
    position: relative;
    transition: color 0.2s ease, transform 0.2s ease;
    text-decoration: none; /* Subtle underline by default */
    text-underline-offset: 1px;
  }

  span:hover {
    color: white;
    font-weight: 530;
  }

  span::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.7);
    transition: background-color 0.2s ease, height 0.2s ease;
  }

  span:hover::after {
    background-color: white;
  }
`;

const CopyableContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack elements */
  align-items: center; /* Center everything */
  justify-content: center;
  text-align: center;
  gap: 0.5rem;
  width: 100%;
  font-weight: 550;
  cursor: pointer;
`;

const LinkRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  gap: 0.5rem;
  flex-wrap: nowrap; /* Prevent wrapping */
  max-width: 100%;
`;

const ReferralLink = styled.span`
  display: inline-block;
  word-break: break-word;
  text-align: center;
  overflow-wrap: break-word;
  max-width: 100%;
`;



const CopyIcon = styled(FaRegCopy)`
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.3s;
  flex-shrink: 0; /* Prevents icon from resizing */
  line-height: 1; /* Ensures it sits at the same baseline */

  ${CopyableContainer}:hover & {
    color: white;
  }
`;

const ReferralLinkWrapper = styled.div`
  display: flex;
  align-items: center; /* Ensures vertical alignment */
  gap: 0.5rem;
  flex-wrap: nowrap; /* Ensures it stays in a single line */
  max-width: 100%; /* Prevents overflow */
`;



const CopiedMessage = styled.span`
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 0.8rem;
  padding: 0.3rem 0.6rem;
  border-radius: 5px;
  display: ${({ show }) => (show ? "inline-block" : "none")}; /* Fix visibility */
  margin-top: 5px;
  text-align: center;
`;

export default function FinishPage({ navigateToIntro, navigateToExplain, email }) {
  const [fadeOut, setFadeOut] = useState(false); // For fading out the page
  const isMdOrLarger = useMediaQuery({ minWidth: 768 });
  const [copied, setCopied] = useState(false); // Track if copied message should show
  const [venmoHandle, setVenmoHandle] = useState("");
  const [saved, setSaved] = useState(false);
  const { formData, updateFormData } = useApplication();

  useEffect(() => {
    // Track
    let uniqueId = formData.uniqueId;

    if (!uniqueId) {
      uniqueId = uuidv4();
      sessionStorage.setItem("unique_id", uniqueId);
    }

    // Use sendBeacon if supported for better reliability
    const data = new FormData();
    data.append("unique_id", uniqueId);
    data.append("pool_recommendations", "COMPLETED");
    const BASE_URL = process.env.REACT_APP_API_URL
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, data);

  }, []);

  // Generate unique referral link
  const getReferralLink = (email) => {
    if (!email) return "https://app.prospinity.com/ref/";

    const username = email.split("@")[0]; // Extracts part before '@'
    const hash = md5(email).toString().slice(0, 4); // Shortened hash for uniqueness
    return `https://app.prospinity.com/ref/${username}-${hash}`;
  };

  const forwardingLink = useRef(getReferralLink(email)); // Store generated referral link

  const handleNavigateToExplain = () => {
    setFadeOut(true); // Trigger fade-out animation
    setTimeout(() => {
      navigateToExplain(); // Navigate only after fade-out finishes
    }, 750); // Match the fade-out duration
  };

  const handleNavigateToIntro = () => {
    setFadeOut(true); // Trigger fade-out animations
    setTimeout(() => {
      navigateToIntro(); // Navigate only after fade-out finishes
    }, 750); // Match the fade-out duration
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(forwardingLink.current).then(() => {
      setCopied(true); // Show "Copied!" message
      setTimeout(() => setCopied(false), 2000); // Hide after 2 seconds
    });
  };

  const handleSaveVenmo = async () => {
    // Track
    let uniqueId = formData.uniqueId;

    if (!uniqueId) {
      uniqueId = uuidv4();
      sessionStorage.setItem("unique_id", uniqueId);
    }

    // Use sendBeacon if supported for better reliability
    const data = new FormData();
    data.append("unique_id", uniqueId);
    data.append("additional_info", venmoHandle);
    const BASE_URL = process.env.REACT_APP_API_URL
    navigator.sendBeacon(`${BASE_URL}/api/track-application/`, data);

    setTimeout(() => setSaved(true), 600);
  }

  return (

      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }} // Smooth fade-in for logo and text
          style={{ textAlign: "center" }}
        >
         
          <ApplicationText isMdOrLarger={isMdOrLarger}>
            <p>Thank you for applying. We will reach out if you are selected.</p>
            <p>Since you made it to the end, you've entered in our $2000 campus giveaway.</p>
            <p>If you know someone who might be interested, share this link with them.</p>
            <p>
              For every person who finishes the walkthrough and ends up applying using your link, you'll get <strong>$10</strong> via Venmo. Drop your Venmo handle here: 
              <span style={{ display: "inline-block", marginLeft: "10px" }}>
                
                  <VenmoInput 
                    type="text"
                    placeholder="@yourVenmo"
                    value={venmoHandle}
                    onChange={(e) => setVenmoHandle(e.target.value)}
                  />
                  <SaveButton onClick={handleSaveVenmo}>
                    {saved ? "Saved!" : "Save"}
                  </SaveButton>
              </span>
            </p>
            <p>
              Your unique forwarding link:
            </p>
            <CopyableContainer>
              {/* Link + Copy Icon Centered */}
              <LinkRow onClick={handleCopy}>
                <CopyIcon />
                <ReferralLink>{forwardingLink.current}</ReferralLink>
                
              </LinkRow>
              {copied && <CopiedMessage show={copied}>Copied!</CopiedMessage>}

              {/* Share Button Centered on New Line */}
              <ShareButton link={forwardingLink.current} />
            </CopyableContainer> 
          </ApplicationText>
        </motion.div>
      </>
  );
}


const VenmoInput = styled.input`
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 4px 8px;
  color: white;
 
  margin-top: 0.2rem;
  font-size: 1rem;
  border-radius: 5px;
  width: 150px;
  vertical-align: middle;  /* Ensures it's aligned */
  line-height: 1.4;        /* Helps maintain consistent height */
  transform: translateY(-2px); /* Slightly nudges it up */
`;


const SaveButton = styled.button`
  margin-left: 10px;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  vertical-align: middle; /* Aligns with input */
  transform: translateY(-2px); /* Matches input adjustment */

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
`;
