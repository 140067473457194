import styled from "styled-components";

export const RegistrationHeader = styled.h1`
    font-size: ${({ isMobile }) => isMobile ? "2rem" : "2.5rem"};
    font-weight: 500;
    line-height: ${({ isMobile }) => isMobile ? "2rem" : "3rem"};
    text-transform: uppercase;
    text-align: ${({ isMobile }) => (isMobile ? "center" : "left")};
    color: ${({ theme }) => theme.textPrimary};
`;

export const Subtitle = styled.p`
    font-size: 1rem;
    text-align: ${({ isMobile }) => (isMobile ? "center" : "left")};
    color: ${({ theme }) => theme.textSecondary};
    margin-top: 0.5rem; 
    margin-bottom: 2rem;
`;

export const ForgotPassword = styled.div`
    text-align: left;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.textSecondary};
    margin-top: 0.4rem;
    
    a {
        color: ${({ theme }) => theme.textSecondary};
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const PromptWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-top:  ${({ isMobile }) => isMobile ? "1.5rem" : "2rem"};
    justify-content: center; /* Centers the whole text block */
`;

export const Prompt = styled.p`
    text-align: center;
    font-size: 1rem;
    color: ${({ theme }) => theme.textPrimary};

    a {
        color: ${({ theme }) => theme.textPrimary};
        font-weight: 500;
        position: relative;
        text-decoration: none;
        text-decoration-thickness: 1px; /* Thicker underline */
        text-underline-offset: 2px; /* Bring underline closer */
        text-decoration-color: currentColor; /* Ensures underline matches text color */
    }
    
    a::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 1px; /* Slightly adjust for overlap */
        width: 100%;
        height: 1px;
        background-color: currentColor; /* Same color as text */
        z-index: -1; /* Places the underline behind the text */
    }
`;

export const ErrorText = styled.span`
    color: ${({ theme }) => theme.red};
    font-size: 0.75rem;
    margin-top: 0.2rem;
`;

export const MobileBottomSection = styled.div`
    width: 100%;
    max-width: calc(500px + 2rem);
    position: absolute;
    bottom: 1.5rem; /* Push button to bottom of form */
    left: 50%; /* Moves it to the center */
    transform: translateX(-50%); /* Centers it perfectly */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
`;