import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

// From my Application
import SideBar from '../components/navigation/SideBar';
import NotificationsSidebar from "../components/navigation/NotificationsSidebar";
import { SettingsSidebar } from "../pages/Settings";
import DesktopNavbar from '../components/navigation/DesktopNavbar';
import MobileTopNavbar from '../components/navigation/MobileTopNavbar';
import MobileBottomNavbar from '../components/navigation/MobileBottomNavbar';
import { SettingsProvider } from "../contexts/SettingsContext";
import { useAuth } from "../contexts/AuthContext";



// Each layout receives props and uses them identically
function DesktopLayout() {

    // Get the theme 
    const theme = useTheme();

    // Notifications sidebar
    const [showNotifications, setShowNotifications] = useState(false);

    // Settings sidebar
    const [showSettings, setShowSettings] = useState(false);

    // Responsive
    const isNotDesktop = useMediaQuery({ maxWidth: 950 });

    // Check for special pages
    const location = useLocation();
    const isChatPage = location.pathname.startsWith('/dashboard/inbox');
    const isSettings = location.pathname.startsWith('/dashboard/settings');
    const isPoolDetailsPage = location.pathname.startsWith('/dashboard/pools/') && location.pathname !== '/dashboard/pools' && location.pathname !== '/dashboard/pools/';

    useEffect(() => {
        if (isSettings) {
            setShowSettings(true); 
        } else {
            setShowSettings(false);
        }
    }, [location.pathname]);

    return (
        <SettingsProvider>
            <div
                style={{
                    position: 'fixed', // Keep the entire layout fixed to the viewport
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                }}
            >
                {/* Sidebar */}
                <SideBar />

                {/* Settings Sidebar (Conditional) */}
                {showSettings && <SettingsSidebar />}

                {/* Notifications Sidebar (Conditional) */}
                {showNotifications && <NotificationsSidebar onClose={() => setShowNotifications(false)} />}

                {/* Main Content Area */}
                <div
                    style={{
                        flex: 1,
                        marginLeft: showSettings ? isNotDesktop ? "460px" : "560px" : "260px", // Matches sidebar width
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: isPoolDetailsPage ? theme.white : theme.backgroundDarker, // Background color for the main area
                    }}
                >
                    {/* Fixed Navbar */}
                    {!isChatPage && !isSettings && <DesktopNavbar onShowNotifications={() => setShowNotifications(true)} onShowSettings={() => { setShowNotifications(false); setShowSettings(true); }} />}

                    {/* Scrollable Content */}
                    <div
                        style={{
                            flex: 1,
                            overflowY: 'auto', // Enable vertical scrolling
                            overflowX: 'hidden', // Prevent horizontal scrolling
                            marginTop: isChatPage || isSettings ? '0' : '80px', // Account for navbar height
                            boxSizing: 'border-box',
                        }}
                    >
                        <Outlet />
                    </div>
                </div>
            </div>
        </SettingsProvider>
    );
}


function MobileLayout() {
    // Get the theme 
    const theme = useTheme();

    // Check for special pages
    const location = useLocation();
    const isChatPage = location.pathname.startsWith('/dashboard/inbox');

    // Access the chat context to check if a channel is open
    const { activeChannel } = useAuth();

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                width: '100vw',
                overflow: 'hidden', // Prevent whole-page scrolling
                backgroundColor: theme.backgroundDarker,
            }}
        >
            {/* Fixed Top Navbar */}
            {(!isChatPage || (isChatPage && !activeChannel)) && <MobileTopNavbar />}

            {/* Scrollable Content */}
            <div
                style={{
                    flex: 1,
                    overflowY: 'auto', // This is the only scrollable section
                    overflowX: 'hidden',
                    WebkitOverflowScrolling: 'touch', // Improves mobile scrolling
                    paddingTop: (isChatPage && activeChannel) ? "0px" : "72px", // Prevents top navbar overlap
                }}
            >
                <SettingsProvider>
                    <Outlet />
                </SettingsProvider>
            </div>

            {/* Fixed Bottom Navbar */}
            {(!isChatPage || (isChatPage && !activeChannel)) && <MobileBottomNavbar />}
        </div>
    );
}

 

export default function Dashboard() {
    const location = useLocation(); 
    const isMdOrLarger = useMediaQuery({ minWidth: 768 });
    
    return (
        <>
            {isMdOrLarger ? <DesktopLayout /> : <MobileLayout />}
        </>
    );
  }
  


    // function MobileLayout({ activeTab, setActiveTab, notifications, handleNotification }) {
  //   return (
  //     <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
  //       <NavbarSmallScreen />
  //       <Outlet context={{ activeTab, notifications }} />
  //       <BottomNavbarSmallScreen activeTab={activeTab} setActiveTab={setActiveTab} />
  //     </div>
  //   );
  // }


// import axios from 'axios';
// import { useState, useEffect, useRef } from 'react';
// import { Outlet, Link, useNavigate } from 'react-router-dom';
// import { Nav, FormControl, Row, Col, Container, InputGroup, Dropdown, Navbar, Button } from 'react-bootstrap';
// import { useAuth } from '../AuthContext';
// import './Dashboard.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // If using Font Awesome for icons
// import SearchUsers from '../SearchUsers';
// import { faHome, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear, faPeopleGroup, faSwimmingPool, faEnvelope, faCircle, faBullseye } from '@fortawesome/free-solid-svg-icons'; // Example icons
// import { useMediaQuery } from 'react-responsive';
// import { ProfilePicture } from '../MiscComponents';
// import { timeSince } from '../CriticalFunctions';
// import { Tooltip, OverlayTrigger } from 'react-bootstrap';
// import { useErrorBoundary } from "react-error-boundary";
// import { StyledNavbar, StyledModal, StyledDropdown, StyledButton, StyledH5, StyledNavLink, StyledParagraph, StyledFormGroup, StyledFormLabel, StyledFormControl, TooltipBody, TooltipContent, TooltipFooter, TooltipTitle } from '../StyledBootstrap';
// import { useTheme } from 'styled-components';
// import { myAxiosInstance } from '../axiosConfig';
// import TutorialModal from '../TutorialModal';
// import Notifications from '../Notifications';
// import NavbarLargeScreen from '../NavbarLargeScreen';
// import NavbarSmallScreen from '../NavbarSmallScreen';
// import { apiP } from '../CriticalFunctions';
// import CustomModal from '../CustomModal';
// import Loading from '../Loading';
// import Messaging from '../chat/Messaging';
// import ReactJoyride, { STATUS } from 'react-joyride';
// import GlobalJoyrideStyles from '../GlobalJoyrideStyles'; // Import the global styles
// import { useLocation } from 'react-router-dom';
// import SideBar, {SideBarLink} from '../SideBar';
// import BottomNavbarSmallScreen from '../BottomNavbarSmallScreen';
// import UserPreviewCard from '../UserPreviewCard';
// import { StyledCard } from '../StyledBootstrap';
// import NavbarSmallScreen from '../components/navigation/NavbarSmallScreen';
// import BottomNavbarSmallScreen from '../components/navigation/BottomNavbarSmallScreen';

// export default function Dashboard() {
//     const navigate = useNavigate();

//     const { firstTime, userSlug } = useAuth();

//     const [showTutorial, setShowTutorial] = useState(firstTime); // To show or hide the tutorial modal
//     const [tutorialPart, setTutorialPart] = useState(1); // To track the current part of the tutorial



//     const location = useLocation();  // Get the current route
//     const [isMeetingStarted, setIsMeetingStarted] = useState(false);
//     const isMeetingPage = location.pathname.startsWith('/dashboard/meet');

//     const callIDRef = useRef(null);
//     const clientRef = useRef(null);  // Store StreamClient in a ref
//     const callRef = useRef(null);


//     const [showReferModal, setShowReferModal] = useState(false);
//     const [referModalMessage, setReferModalMessage] = useState(null);
//     const [referModalEmail, setReferModalEmail] = useState('');
//     const [referModalLoading, setReferModalLoading] = useState(true);
//     const [referInvitesRemaining, setReferInvitesRemaining] = useState(null);
//     const [referModalError, setReferModalError] = useState(false);



//     //DAILY PICKS
//     const [suggestedUserArray, setSuggestedUserArray] = useState([]);
//     const [bottomReloadDailyPicks, setBottomReloadDailyPicks] = useState(0);
//     useEffect(() => {
//         const fetchSuggestedUsers = async () => {
//             try {
//                 const response = await axios.get('/api/daily-picks/'); // Your API endpoint
//                 setSuggestedUserArray(response.data);
//             } catch (error) {}
//         };

//         // Only fetch if we're on the desired pages (e.g., /dashboard/home or /dashboard/pools)
//         if (location.pathname === '/dashboard/home' || location.pathname === '/dashboard/portfolio' || location.pathname === '/dashboard') {
//             fetchSuggestedUsers();
//         }
//     }, [location.pathname, bottomReloadDailyPicks, setBottomReloadDailyPicks]);




//     //React Joyride------------------------------------
//     const joyrideRef = useRef(null);
//     const Tooltip = ({
//         continuous,
//         index,
//         step,
//         backProps,
//         closeProps,
//         primaryProps,
//         tooltipProps,
//     }) => (
//         <TooltipBody {...tooltipProps}>
//             {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
//             <TooltipContent>{step.content}</TooltipContent>
//             <TooltipFooter>
//                 {index > 0 && (
//                     <StyledButton {...backProps} className='mr-2' style={{ backgroundColor: themeMode.background, color: themeMode.onBackground }}>
//                         Back
//                     </StyledButton>
//                 )}
//                 {continuous && (
//                     <StyledButton {...primaryProps}>
//                         {index < steps.length - 1 ? <>Next</> : <>Finish</>}
//                     </StyledButton>
//                 )}
//                 {!continuous && (
//                     <StyledButton {...closeProps}>
//                         Close
//                     </StyledButton>
//                 )}
//             </TooltipFooter>
//         </TooltipBody>
//     );
//     const checkElementPresence = (selector, callback) => {
//         const element = document.querySelector(selector);
//         if (element) {
//             callback();
//         } else {
//             setTimeout(() => checkElementPresence(selector, callback), 100);
//         }
//     };
//     const [runJoyride, setRunJoyride] = useState(false);
//     const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);
//     const [isFullyRendered, setIsFullyRendered] = useState(false);




//     const [steps, setSteps] = useState([
//         {
//             target: '.home-joyrride', // Change to the appropriate target
//             content: 'When you receive invites to pools and meetings, they will show up here, on your home page.',
//             disableBeacon: true,
//             title: 'Home',
//             placement: 'center'
//         },
//         {
//             target: '.search-joyrride', // Change to the appropriate target
//             content: 'Search users you would like to connect and pool with.',
//             disableBeacon: true,
//             title: 'Search'

//         },
//         {
//             target: '.chat-joyrride', // Change to the appropriate target
//             content: 'Chat with your pools and other users here. You can individually message them from their profiles.',
//             disableBeacon: true,
//             spotlightClicks: true,
//             title: 'Chat'
//         },
//         {
//             target: '.profile-dropdown-joyrride', // Change to the appropriate target
//             content: 'Access and update your profile and other account details here. More information -> more pool invites and meeting requests.',
//             disableBeacon: true,
//             title: 'My Account'
//         },
//         /*{
//             target: '.profile-joyrride', // Change to the appropriate target
//             content: 'Update your profile here. More information -> more pool invites and meeting requests.',
//             disableBeacon: true,
//             title: 'Profile',
//             placement: 'center',
//         },
//         {
//             target: '.invite-joyrride', // Change to the appropriate target
//             content: 'Prospinity is invite only. Feel free to invite some of your friends that you think would find this interesting (or you might consider pooling with).',
//             disableBeacon: true
//         },
//         {
//             target: '.faqs-joyrride', // Change to the appropriate target
//             content: 'Any questions? Check out our FAQs, or email support@prospinity.com.',
//             disableBeacon: true
//         }*/


//         // Add more steps as needed
//     ]);


//     const openDropDownMenu = () => {
//         const menuButton = document.querySelector('.profile-dropdown');

//         if (menuButton) {

//             const checkAndClickButton = () => {
//                 const ariaExpanded = menuButton.getAttribute('aria-expanded');
//                 if (ariaExpanded === 'false' || menuButton.classList.contains('collapsed')) {
//                     menuButton.click();
//                 }
//             };

//             // Initial check
//             checkAndClickButton();


//         }
//     };


//     const changeHamburgerMenu = (open = true) => {
//         const menuButton = document.querySelector('.hamburger-small-screen');

//         if (menuButton) {

//             const checkAndClickButton = () => {
//                 const ariaExpanded = menuButton.getAttribute('aria-expanded');
//                 if (open && (ariaExpanded === 'false' || menuButton.classList.contains('collapsed'))) {
//                     menuButton.click();
//                 }
//                 else if (!open && !(ariaExpanded === 'false' || menuButton.classList.contains('collapsed'))) {

//                     menuButton.click();
//                 }
//             };

//             // Initial check
//             checkAndClickButton();


//         }
//     };

//     /*useEffect(() => {
//         const spotlightElement = document.querySelector('.profile-dropdown-joyrride');

//         const handleSpotlightClick = () => {
//             const joyrideInstance = joyrideRef.current;
//             if (joyrideInstance) {
//                 checkElementPresence('.profile-joyrride', () => {
//                     const newIndex = joyrideStepIndex + 1;
//                     setJoyrideStepIndex(newIndex);

//                 });

//             }
//         };

//         if (spotlightElement) {
//             spotlightElement.addEventListener('click', handleSpotlightClick);
//         }

//         return () => {
//             if (spotlightElement) {
//                 spotlightElement.removeEventListener('click', handleSpotlightClick);
//             }
//         };
//     }, [joyrideStepIndex]);*/

//     const closeJoyrride = () => {
//         setRunJoyride(false);
//         markTutorialCompleted();
//         changeHamburgerMenu(false);
//         navigate('/dashboard');

//     }

//     const handleJoyrideCallback = (data) => {
//         const { status, action, index, type } = data;
//         const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
//         if (finishedStatuses.includes(status)) {
//             /*setRunJoyride(false);
//             markTutorialCompleted();
//             changeHamburgerMenu(false);
//             navigate('/dashboard');*/
//             closeJoyrride();
//         } else if (type === 'step:after' && (action === 'next' || action === 'prev')) {

//             const newIndex = index + (action === 'next' ? 1 : -1);

//             if (newIndex < steps.length) {
//                 switch (newIndex) {
//                     case 4:
//                         openDropDownMenu();
//                         break;
//                     /*case 5:
//                         navigate('/dashboard/user/' + userSlug);
//                         break;
//                     case 7:
//                         openDropDownMenu();
//                         //navigate('/dashboard/faqs');
//                         break;*/

//                     // Add more cases as needed for additional steps
//                     default:
//                         break;
//                 }
//                 checkElementPresence(steps[newIndex].target, () => {
//                     setJoyrideStepIndex(newIndex);
//                 });

//             }
//             else {
//                 /*setRunJoyride(false);
//                 markTutorialCompleted();
//                 navigate('/dashboard');*/
//                 closeJoyrride();
//             }


//         }
//     };
//     //----------------------------------


//     useEffect(() => {
//         const getReferInvites = async () => {
//             try {
//                 setReferModalLoading(true);
//                 setReferModalError(false);
//                 setReferInvitesRemaining(null);
//                 const response = await myAxiosInstance.get('/api/invite-platform/');
//                 setReferInvitesRemaining(response.data.invites_left);
//             }
//             catch (error) {
//                 setReferModalError(true);

//             }
//             finally {
//                 setReferModalLoading(false);
//             }

//         }
//         if (showReferModal) {
//             getReferInvites();
//         }


//     }, [showReferModal])

//     useEffect(() => {
//         const checkElements = () => {
//             const elementsPresent = document.querySelector(steps[0].target);
//             //const elementsPresent = steps.every(step => document.querySelector(step.target));
//             if (elementsPresent) {
//                 setIsFullyRendered(true);
//             } else {
//                 setTimeout(checkElements, 100); // Check again after 100ms
//             }
//         };
//         //checkElements();
//         //openDropDownMenu();
//         //openHamburgerMenu();


//         if (firstTime && !showTutorial) {
//             checkElements();
//         }
//     }, [firstTime, steps, showTutorial]);

//     /*useEffect(() => {
//         if (isFullyRendered) {
//             setRunJoyride(true);
//             setJoyrideStepIndex(0); // Ensure the index starts at 0
//         }
//     }, [isFullyRendered]);*/


//     const [whyModalStep, setWhyModalStep] = useState(1);
//     const handleWhyLeft = () => {
//         if (whyModalStep == 1) {
//             handleWhyClose();
//         }
//         else {
//             setWhyModalStep(1);
//         }

//     }
//     const handleWhyRight = () => {
//         if (whyModalStep == 1) {
//             setWhyModalStep(2);
//         }
//         else {
//             handleWhyClose();
//         }

//     }
//     const [whyModalShow, setWhyModalShow] = useState(false);

//     const handleWhyClose = () => {
//         setWhyModalShow(false);
//         setWhyModalStep(1);
//     };


//     async function markTutorialCompleted() {
//         try {
//             await myAxiosInstance.post('/api/complete-tutorial/');
//         }
//         catch (error) {
//             showBoundary(error);
//         }
//     }



//     async function referRequest() {
//         try {
//             const response = await apiP({ invite_email: referModalEmail }, "invite-platform/", setReferModalMessage, "Invited!", false, null, false, null, null, "put");
//             //await myAxiosInstance.post('/api/invite-platform', {invite_email: email});
//             if (response) {
//                 setShowReferModal(false);
//                 setReferModalEmail('');
//                 setReferModalMessage(null);
//             }
//         }
//         catch (error) {
//             showBoundary(error);
//         }
//     }

//     const handleNextTutorial = () => {
//         if (tutorialPart < 6) {
//             setTutorialPart(tutorialPart + 1);
//         } else {
//             setShowTutorial(false); // Hide the tutorial after the last part
//             markTutorialCompleted();

//         }
//     };


//     const handleBackTutorial = () => {
//         if (tutorialPart > 1) {
//             setTutorialPart(tutorialPart - 1);
//         }
//     };

//     const handleSkipTutorial = () => {
//         setShowTutorial(false); // Hide the tutorial
//         //markTutorialCompleted();
//     };

//     const themeMode = useTheme();


//     const { showBoundary } = useErrorBoundary();


//     const isSmallScreen = useMediaQuery({ maxWidth: 1000 });

//     useEffect(() => {
//         const hamburgerSteps = [1, 3, 4, 5, 7, 8];
//         if (isSmallScreen && hamburgerSteps.includes(joyrideStepIndex)) {
//             changeHamburgerMenu();
//         }
//         if (isSmallScreen && !hamburgerSteps.includes(joyrideStepIndex)) {
//             changeHamburgerMenu(false);
//         }

//     }, [isSmallScreen, joyrideStepIndex]);

//     const navbarRef = useRef(null);
//     const bottomNavbarRef = useRef(null);
//     const containerRef = useRef(null);
//     const colRef = useRef(null);
//     const col1Ref = useRef(null);


//     useEffect(() => {
//         const updateLayout = () => {
//             const navbarHeight = navbarRef.current ? navbarRef.current.offsetHeight : 0;
//             const bottomNavbarHeight = bottomNavbarRef.current ? bottomNavbarRef.current.offsetHeight : 0;
//             const viewportHeight = window.innerHeight;

//             const containerHeight = viewportHeight - navbarHeight - (isMdOrLarger ? 0 : navbarHeight); //figure out why bottomNavbarHeight isn't working

//             if (containerRef.current) {
//                 containerRef.current.style.marginTop = `${navbarHeight}px`; // Push content below the navbar
//                 containerRef.current.style.minHeight = `${containerHeight}px`; // Ensure it fills the remaining screen height
//             }
//             if (colRef.current) {
//                 colRef.current.style.height = `${containerHeight}px`;
//                 //colRef.current.style.top = `${navbarHeight}px`;
//                 //rowRef.current.style.height = `${containerHeight}px`;
//             }
//             if (col1Ref.current) {
//                 //col1Ref.current.style.top = `${navbarHeight}px`;
//                 //col1Ref.current.style.right = `50px`;
//                 col1Ref.current.style.height = `${containerHeight}px`;
//             }
//         };

//         updateLayout(); // Adjust layout on mount
//         window.addEventListener('resize', updateLayout); // and on window resize

//         return () => window.removeEventListener('resize', updateLayout); // Cleanup
//     }, []); // Run once on mount





//     const isMdOrLarger = useMediaQuery({ minWidth: 768 });







//     return (
//         <>
//             <GlobalJoyrideStyles />
//             <Container fluid className="px-0">
//                 {!(isMeetingPage && isMeetingStarted) ?
//                     <>
//                         <div ref={navbarRef} className='fixed-top-navbar'>
//                             {isSmallScreen ? <NavbarSmallScreen setShowReferModal={setShowReferModal} setWhyModalShow={setWhyModalShow} /> : <NavbarLargeScreen setShowReferModal={setShowReferModal} setWhyModalShow={setWhyModalShow} />}
//                         </div>
//                         <Container ref={containerRef} fluid style={{ backgroundColor: themeMode.background, minHeight: 0, height: '100%', minWidth: 0, width: '100%', overflowY: 'auto' }}>
//                             <div className='d-flex' style={{ minHeight: 0, minWidth: 0, width: '100%', height: '100%', overflowY: 'auto' }}>

//                                 {isMdOrLarger ? <div ref={colRef} xs={2} className="p-3" style={{ backgroundColor: themeMode.background, borderRight: '1px solid #ccc', minWidth: '15%' }}>
//                                     <SideBarLink label="Home" to="/dashboard/home" active={location.pathname === "/dashboard/home"}>
//                                         <FontAwesomeIcon icon={faHome} className="mr-2" />
//                                     </SideBarLink>
//                                     <SideBarLink label="Portfolio" to="/dashboard/portfolio" active={location.pathname === "/dashboard/portfolio"}>
//                                         <FontAwesomeIcon icon={faPeopleGroup} className="mr-2" />
//                                     </SideBarLink>
//                                     <SideBarLink label="Pools" to="/dashboard/pools" active={location.pathname === "/dashboard/pools"}>
//                                     <img src={themeMode.theme == 'dark' || location.pathname === "/dashboard/pools" ? logo_white_p : logo_black_p} height="17rem" className='mr-2'/>
//                                     </SideBarLink>
//                                     <SideBarLink label="Finances" to="/dashboard/payments" active={location.pathname === "/dashboard/payments"}>
//                                         <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
//                                     </SideBarLink>
//                                     <SideBarLink label="Invite a Friend" to="/dashboard/invite" active={location.pathname === "/dashboard/invite"} setShowReferModal={setShowReferModal} className='invite-joyrride'>
//                                         <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
//                                     </SideBarLink>
//                                     <SideBarLink label="Settings" to="/dashboard/settings" active={location.pathname === "/dashboard/settings"}>
//                                         <FontAwesomeIcon icon={faGear} className="mr-2" />
//                                     </SideBarLink>
//                                 </div> : <></>}
//                                 <div className='d-flex flex-column justify-content-center px-2' ref={col1Ref} xs={10} style={{ overflowY: 'auto', minHeight: 0, minWidth: 0, width: '100%', height: '100%' }}>
//                                     <Outlet context={{ setIsMeetingStarted, isMeetingStarted, callIDRef, clientRef, callRef }} />
//                                 </div>
//                                 {isMdOrLarger  && (location.pathname === '/dashboard/home' || location.pathname === '/dashboard/portfolio' || location.pathname === '/dashboard') && (
//                                     <div className="mx-2">
//                                         <StyledH5 className="mt-4"><strong>Daily Picks</strong></StyledH5>

//                                         <StyledCard style={{ height: '80vh', overflowY: 'auto' }}>
//                                             <StyledCard.Body>
//                                                 {suggestedUserArray
//                                                     .filter(otherUserInfo => otherUserInfo.first_name !== '')
//                                                     .reduce((acc, curr, index) => {
//                                                         if (index % 2 === 0) {
//                                                             // Every even index, create a new row with two elements
//                                                             acc.push([curr]);
//                                                         } else {
//                                                             // Add the current item to the last row
//                                                             acc[acc.length - 1].push(curr);
//                                                         }
//                                                         return acc;
//                                                     }, [])
//                                                     .map((pair, pairIndex) => (
//                                                         <div className="d-flex align-items-center justify-content-center" key={pairIndex}>
//                                                             {pair.map((otherUserInfo, index) => (
//                                                                 <div className="mx-1 my-2" key={index}>
//                                                                     <UserPreviewCard preview_object={otherUserInfo} buttonBoolean={true} bottomReload={bottomReloadDailyPicks} setBottomReload={setBottomReloadDailyPicks} />
//                                                                 </div>
//                                                             ))}
//                                                         </div>
//                                                     ))}

//                                             </StyledCard.Body>
//                                         </StyledCard>
//                                     </div>


//                                 )}

//                             </div>
//                             {isMdOrLarger ? <Messaging /> : <></>}
//                         </Container>
//                         {!isMdOrLarger ? <div className='fixed-bottom-navbar' ref={bottomNavbarRef}>
//                             <BottomNavbarSmallScreen />
//                         </div> : <></>}
//                     </> :
//                     <Outlet context={{ setIsMeetingStarted, isMeetingStarted, callIDRef, clientRef, callRef }} />}
//                 <ReactJoyride
//                     ref={joyrideRef}
//                     steps={steps}
//                     tooltipComponent={Tooltip}
//                     run={runJoyride}
//                     stepIndex={joyrideStepIndex}
//                     continuous={true}
//                     scrollToFirstStep={true}
//                     showProgress={true}
//                     showSkipButton={true}
//                     hideCloseButton={true}
//                     callback={handleJoyrideCallback}
//                     spotlightPadding={10}
//                     disableOverlayClose={true}
//                     styles={{
//                         options: {
//                             arrowColor: themeMode.theme === 'dark' ? themeMode.background : themeMode.surface,
//                             backgroundColor: themeMode.theme === 'dark' ? themeMode.primary : themeMode.surface,
//                             primaryColor: themeMode.theme === 'dark' ? themeMode.primary : themeMode.primary,
//                             textColor: themeMode.theme === 'dark' ? themeMode.onPrimary : themeMode.onSurface,
//                             zIndex: 1050,
//                         },
//                     }}
//                 />
//                 <TutorialModal
//                     show={showTutorial}
//                     tutorialPart={tutorialPart}
//                     onNext={handleNextTutorial}
//                     onSkip={handleSkipTutorial}
//                     onBack={handleBackTutorial}
//                 />
//                 <CustomModal title="Invite a Friend to the Prospinity Platform" actionText={"Invite"} actionFunction={referRequest} show={showReferModal} setShow={setShowReferModal} message={referModalMessage} setMessage={setReferModalMessage} handleClose={() => setShowReferModal(false)}>
//                     {referModalLoading ? <Loading /> : referModalError ? <StyledParagraph>There was an error loading the invite functionality.</StyledParagraph> : <>
//                         <StyledParagraph>You have <strong>{referInvitesRemaining}</strong> invites left. Type a student email below and click "Invite."</StyledParagraph>
//                         <StyledFormGroup>
//                             <StyledFormControl
//                                 type="email"
//                                 value={referModalEmail}
//                                 onChange={(e) => setReferModalEmail(e.target.value)}
//                             />
//                         </StyledFormGroup></>}</CustomModal>
//                 {/*<CustomModal show={showReferModal} onClose={() => setShowReferModal(false)} onRefer={referRequest}/>*/}
//                 <StyledModal show={whyModalShow} onHide={handleWhyClose} backdrop="static"
//                     keyboard={false} centered>
//                     <StyledModal.Header closeButton>
//                         <StyledModal.Title>{whyModalStep == 1 ? "Why Meet" : "Why Pool"}?</StyledModal.Title>
//                     </StyledModal.Header>
//                     <StyledModal.Body>
//                         {whyModalStep == 1 &&
//                             <>


//                                 <StyledParagraph>In today’s fast-paced world, traditional networking methods like LinkedIn are becoming increasingly ineffective. Reaching out to people, waiting for responses, and managing connections manually is time-consuming and often leaves those with busy schedules behind. Prospinity changes the game by allowing you to network on autopilot.</StyledParagraph>

//                                 <StyledParagraph><strong>Effortless Connections:</strong> Our AI matches you with peers based on your background and goals, eliminating the need to search and reach out manually.</StyledParagraph>

//                                 <StyledParagraph><strong>Time-Efficient:</strong> Save time and energy as our AI handles the networking process for you, ensuring you meet the right people without the hassle.</StyledParagraph>

//                                 <StyledParagraph><strong>Personalized Matches:</strong> Our algorithm considers your interests, goals, and experiences to find the most relevant connections, making your networking efforts more targeted and effective.</StyledParagraph>

//                                 <StyledParagraph>Join Prospinity and revolutionize the way you network. With our AI, you can build meaningful connections effortlessly and focus on what truly matters.</StyledParagraph>
//                             </>

//                         }
//                         {whyModalStep == 2 && <>
//                             <StyledParagraph><strong>Upside Access:</strong> Upside successes are often driven by unpredictable factors. Bet on the future upside potential of your peers. If one person wins, everyone wins. </StyledParagraph>

//                             <StyledParagraph><strong>Diversification:</strong> Take part socially and financially in careers and life paths you didn't get to experience. In the next years, industries will rise and fall, companies will go out of business, and new technologies will develop. We don't put all of our eggs in one basket with our investments; why do it with our career? </StyledParagraph>

//                             <StyledParagraph><strong>Unparalleled Network:</strong> Pool members share career networks, resources, opportunities, and insights. Gain access to a unique network of equally capable peers that is truly invested in you.</StyledParagraph></>}

//                     </StyledModal.Body>
//                     <StyledModal.Footer>
//                         <div>
//                             <StyledButton variant="secondary" onClick={handleWhyLeft} className='mx-3'>
//                                 {whyModalStep == 1 && <>Skip</>}
//                                 {whyModalStep == 2 && <>Back</>}
//                             </StyledButton>
//                             <StyledButton variant="secondary" onClick={handleWhyRight} className='mx-3'>
//                                 {whyModalStep == 1 && <>Next</>}
//                                 {whyModalStep == 2 && <>Close</>}
//                             </StyledButton>
//                         </div>
//                     </StyledModal.Footer>
//                 </StyledModal>
//             </Container>
//         </>


//     );
// }