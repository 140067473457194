import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useSwipeable } from "react-swipeable";

const ThreeThingsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false); // Track visibility
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const carouselRef = useRef(null); // Reference for IntersectionObserver

  const cards = [
    {
      title: "Hedge Career Risk",
      description:
        "Every Success Pool you join enhances your diversification, reducing career risk. Right now, you have 0% exposure to other life paths.",
      image: "/assets/buildPortfolio.png",
    },
    {
      title: "Supercharged Networks",
      description:
        "Success Pools are unique communities where members are directly incentivized to share resources, opportunities, and referrals.",
      image: "/assets/connection.png",
    },
    {
      title: "Multiply Success",
      description:
        "Luck and timing—factors beyond our control—determine if we “hit it big.” Each member of your Success Pool gives you another chance.",
      image: "/assets/opportunity.png",
    },
  ];

  // Handle swipe gestures
  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      const { deltaX, deltaY } = eventData;
  
      // Define a strict threshold for horizontal vs. vertical swipes
      const isHorizontal = Math.abs(deltaX) > Math.abs(deltaY) * 1.5; // Prioritize horizontal movement
  
      if (isHorizontal && Math.abs(deltaX) > 10) {
        document.body.style.overflowY = "hidden"; // Lock vertical scrolling
      } else {
        document.body.style.overflowY = "auto"; // Allow vertical scrolling
      }
    },
    onSwiped: (eventData) => {
      const { deltaX, deltaY, dir } = eventData;
  
      // Restore vertical scrolling when swipe ends
      document.body.style.overflowY = "auto";
  
      const isHorizontal = Math.abs(deltaX) > Math.abs(deltaY) * 1.5; // Ensure it's a true horizontal swipe
  
      if (isHorizontal && Math.abs(deltaX) > 20) {
        if (dir === "Left") {
          setCurrentIndex((prev) => (prev < cards.length - 1 ? prev + 1 : prev));
        } else if (dir === "Right") {
          setCurrentIndex((prev) => (prev > 0 ? prev - 1 : prev));
        }
      }
    },
    trackMouse: true, // Enable mouse swiping
    trackTouch: true, // Enable touch swiping
    preventScrollOnSwipe: false, // Allow slight vertical movement
  });
  

  // Detect if carousel is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // Trigger when at least 50% of the carousel is visible
    );

    if (carouselRef.current) {
      observer.observe(carouselRef.current);
    }

    return () => observer.disconnect();
  }, []);

  // Auto-scroll when the carousel is in view
  useEffect(() => {
    if (!isVisible) return; // Only auto-scroll if in view

    const autoScroll = setTimeout(() => {
      setCurrentIndex((prev) => (prev < cards.length - 1 ? prev + 1 : 0)); // Loop back to start
    }, 4000);

    return () => clearTimeout(autoScroll);
  }, [currentIndex, isVisible]);
  

  return (
    <SectionWrapper ref={carouselRef}>
      <SectionHeader>3 THINGS TO KNOW BEFORE YOU START</SectionHeader>

      <CarouselWrapper currentIndex={currentIndex} cardLength={cards.length} {...handlers}>
        <CardContainer currentIndex={currentIndex}>
          {cards.map((card, index) => (
            <InfoCard key={index}>
              <ImageWrapper style={{ backgroundImage: `url(${card.image})` }} />
              <ContentWrapper>
                <CardTitle>{card.title}</CardTitle>
                <CardDescription>{card.description}</CardDescription>
              </ContentWrapper>
            </InfoCard>
          ))}
        </CardContainer>
      </CarouselWrapper>

      {/* Dots Indicator */}
      {isMobile &&
        <DotsContainer>
          {cards.map((_, index) => (
            <Dot key={index} active={index === currentIndex} />
          ))}
        </DotsContainer>
      }
    </SectionWrapper>
  );
};

export default ThreeThingsSection;


const SectionWrapper = styled.div`
  background: ${({ theme }) => theme.white};
  border-radius: 1.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: 600px) {
    padding: 2rem 0;
  }

`;

// ✅ Section Title
const SectionHeader = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textSecondary};

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }

  @media (max-width: 600px) {
    margin-left: 2rem;
  }

`;

const CarouselWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  position: relative;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    padding: 0 2rem;

    /* Blurred Edges */
    &:before, &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1rem;
      z-index: 2;
      pointer-events: none;
      transition: opacity 0.3s ease;
    }

    &:before {
      left: 0;
      background: ${({ theme }) => theme.backgroundLight};
      opacity: ${({ currentIndex }) => (currentIndex > 0 ? "0.8" : "0")};
      border-top-right-radius: 1.19rem; /* Ensure left side rounding */
      border-bottom-right-radius: 1.19rem;
    }

    &:after {
      right: 0;
      background: ${({ theme }) => theme.backgroundLight};
      opacity: ${({ currentIndex, cardLength }) => (currentIndex < cardLength - 1 ? "0.8" : "0")};
      border-top-left-radius: 1.19rem; /* Ensure right side rounding */
      border-bottom-left-radius: 1.19rem;
    }
  }
`;


// ✅ Scrollable Card Container
const CardContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  flex-wrap: nowrap;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 600px) {
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    gap: 1rem;
    transform: ${({ currentIndex }) => `translateX(calc(-${currentIndex * 100}% - ${currentIndex}rem))`};
    transition: transform 0.4s ease-in-out;
  }
`;

// ✅ Individual Card
const InfoCard = styled.div`
  border-radius: 1.2rem;
  overflow: hidden;
  text-align: left;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.backgroundLight};

  @media (max-width: 600px) {
    flex: 0 0 100%;
    width: auto;
    max-width: none;
  }
`;

// ✅ Image Wrapper
const ImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1.3;
  background-size: cover;
  background-position: center;
`;

// ✅ Content Wrapper
const ContentWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background: ${({ theme }) => theme.backgroundLight};
`;

// ✅ Card Title
const CardTitle = styled.h3`
  font-size: 1.0625rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textPrimary};
`;

// ✅ Card Description
const CardDescription = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.textSecondary};
`;

// ✅ Dots Indicator Container
const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.4rem;
  margin-top: 0.75rem;
`;

// ✅ Individual Dot
const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ active, theme }) =>
    active ? theme.primary : theme.border};
  opacity: ${({ active }) => (active ? "1" : "0.4")};
  transition: background 0.3s ease;
`;

