import React, { useEffect, useState, useRef } from 'react';
import { useTheme, styled } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { AnimatePresence } from "framer-motion";

// From My Application
import { RequestingUserPreviewCard } from "./UserPreviewCard";
import { LeftArrow, RightArrow } from '../../assets/icons/Icons';
import MobileScrollCarousel from '../common/Carousels';


// Requesting users carousel
export function RequestingUserCarousel ({ users, onAccept, onReject }) {

    // Screen
    const isMobile = useMediaQuery({ maxWidth: 600 });
  
    // Get the theme
    const theme = useTheme();

    // Track the carousel state
    const isAnimating = useRef(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const cardWrapperRef = useRef(null);
    const leftSentinelRef = useRef(null);
    const rightSentinelRef = useRef(null);

    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(true);


    useEffect(() => {
      if (!cardWrapperRef.current) return;
    
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.target === leftSentinelRef.current) {
              setCanScrollLeft(!entry.isIntersecting);
            }
            if (entry.target === rightSentinelRef.current) {
              setCanScrollRight(!entry.isIntersecting);
            }
          });
        },
        { root: cardWrapperRef.current, threshold: 0.9 } // Slightly lower threshold to avoid false positives
      );
    
      if (leftSentinelRef.current) observer.observe(leftSentinelRef.current);
      if (rightSentinelRef.current) observer.observe(rightSentinelRef.current);
    
      return () => observer.disconnect();
    }, []);


    const handleNext = () => {
      if (isAnimating.current || !canScrollRight) return;
      isAnimating.current = true;
      setCurrentIndex(prev => Math.min(prev + 1, users.length - 1));
      
      setTimeout(() => {
        isAnimating.current = false;
      }, 250); // Matches transition time
    };
    
    const handlePrev = () => {
      if (isAnimating.current || !canScrollLeft) return;
      isAnimating.current = true;
      setCurrentIndex(prev => Math.max(prev - 1, 0));
    
      setTimeout(() => {
        isAnimating.current = false;
      }, 250);
    };

    return (
        <SectionWrapper isEmpty={users.length == 0}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SectionHeader theme={theme}>
                    CONNECTION REQUESTS
                    <ConnectionCount theme={theme}>{users.length}</ConnectionCount>
                </SectionHeader>
                { users.length > 0 && !isMobile && 
                  <ArrowContainer>
                    <button 
                        onClick={handlePrev} 
                        disabled={!canScrollLeft}
                        style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}>
                        <LeftArrow theme={theme} clickable={canScrollLeft} />
                      </button>

                      <button 
                        onClick={handleNext} 
                        disabled={!canScrollRight}
                        style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}>
                        <RightArrow theme={theme} clickable={canScrollRight} />
                      </button>
                  </ArrowContainer>
                }
            </div>

            { !isMobile ?
              // For desktop have a carousel
              <CardWrapper ref={cardWrapperRef} isEmpty={users.length == 0} >
                  <CardContainer currentIndex={currentIndex} totalUsers={users.length} >
                      <div ref={leftSentinelRef} style={{ width: "1px", height: "1px", display: "block" }} />

                      <AnimatePresence>
                        {users.map((user, index) => (
                            <div key={index} style={{ width: "21.2rem" }}>
                                <RequestingUserPreviewCard 
                                  key={user.slug}
                                  user={user}
                                  onAccept={() => onAccept(user)}
                                  onReject={() => onReject(user)}
                                />
                            </div>
                        ))}
                      </AnimatePresence>

                      <div ref={rightSentinelRef} style={{ width: "1px", height: "1px", display: "block" }} />
                  </CardContainer>
              </CardWrapper>

              :

              // For mobile scroll
              <MobileScrollCarousel 
                items={users.map((user) => (
                  <RequestingUserPreviewCard 
                    key={user.slug}
                    user={user}
                    onAccept={() => onAccept(user)}
                    onReject={() => onReject(user)}
                  />
                ))} 
                autoScroll={false} // Optional: Enable auto-scrolling
              />
            }
        </SectionWrapper>
    );
}



// Main Wrapper
const SectionWrapper = styled.div`
  background: ${({ theme }) => theme.white};
  border-radius: 1.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ isEmpty }) => isEmpty ? "0" : "2rem"};
  width: calc(100vw - 260px - 5rem);

  @media (max-width: 768px) {
    padding: 1.5rem 1.25rem;
    width: 100%;
    gap: ${({ isEmpty }) => isEmpty ? "0" : "1.25rem"};
  }

`;

// Title
const SectionHeader = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.textSecondary};
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Space between text and count */

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  gap: 1.75rem;
  align-items: center;
  margin-right: 0.3rem;
`;

// Circular Badge for Count
const ConnectionCount = styled.span`
  background: ${({ theme }) => theme.backgroundDarker};
  color: ${({ theme }) => theme.textSecondary};
  font-size: 1rem;
  font-weight: 500;
  padding: 0.3rem;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  @media (max-width: 768px) {
    font-size: 0.875rem;
    padding: 0.25rem;
  }
`;


const CardWrapper = styled.div`
  width: 100%;
  max-width: 100%; /* Make sure it never overflows */
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: flex-start; /* Ensures cards are always centered */
  padding: ${({ isEmpty }) => isEmpty ? "0 0" : "0.7rem 0"};
`;

const CardContainer = styled.div`
  display: flex;
  gap: 1rem;
  transition: transform 0.5s ease-in-out;
  transform: ${({ currentIndex }) => `translateX(-${currentIndex * 20}%)`};
`;



// Suggested users carousel
export function SuggestedUserCarousel ({ users, onAccept, onReject }) {

  // Get the theme
  const theme = useTheme();

  // Track the carousel state
  const isAnimating = useRef(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const cardWrapperRef = useRef(null);
  const leftSentinelRef = useRef(null);
  const rightSentinelRef = useRef(null);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);


  useEffect(() => {
    if (!cardWrapperRef.current) return;
  
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === leftSentinelRef.current) {
            setCanScrollLeft(!entry.isIntersecting);
          }
          if (entry.target === rightSentinelRef.current) {
            setCanScrollRight(!entry.isIntersecting);
          }
        });
      },
      { root: cardWrapperRef.current, threshold: 0.9 } // Slightly lower threshold to avoid false positives
    );
  
    if (leftSentinelRef.current) observer.observe(leftSentinelRef.current);
    if (rightSentinelRef.current) observer.observe(rightSentinelRef.current);
  
    return () => observer.disconnect();
  }, []);


  const handleNext = () => {
    if (isAnimating.current || !canScrollRight) return;
    isAnimating.current = true;
    setCurrentIndex(prev => Math.min(prev + 1, users.length - 1));
    
    setTimeout(() => {
      isAnimating.current = false;
    }, 250); // Matches transition time
  };
  
  const handlePrev = () => {
    if (isAnimating.current || !canScrollLeft) return;
    isAnimating.current = true;
    setCurrentIndex(prev => Math.max(prev - 1, 0));
  
    setTimeout(() => {
      isAnimating.current = false;
    }, 250);
  };

  return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem" , width: "95%", maxWidth: "1200px"}}>
          {/* Left Arrow */}
          <button 
              onClick={handlePrev} 
              disabled={!canScrollLeft}
              style={{
                  background: "transparent", 
                  border: `1px solid ${canScrollLeft ? theme.textSecondary : theme.textTertiary}`,  // Circular border matching arrow color
                  borderRadius: "50%", // Makes it circular
                  width: "44px",  // Slightly larger to center the arrow better
                  height: "44px",
                  aspectRatio: "1 / 1",
                  paddingRight: "0.5rem", // Ensures proper spacing
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: canScrollLeft ? "pointer" : "default",
                  opacity: canScrollLeft ? 1 : 0.5
              }}>
              <LeftArrow theme={theme} clickable={canScrollLeft} />
          </button>
  
          {/* CardWrapper */}
          <CardWrapper ref={cardWrapperRef} isEmpty={users.length == 0}>
              <CardContainer currentIndex={currentIndex} totalUsers={users.length}>
                  <div ref={leftSentinelRef} style={{ width: "1px", height: "1px", display: "block" }} />
  
                  <AnimatePresence>
                      {users.map((user, index) => (
                          <div key={index} style={{ width: "21.2rem", pointerEvents: "none" }}>
                              <RequestingUserPreviewCard 
                                  key={user.slug} 
                                  user={user} 
                                  onAccept={() => onAccept(user)} 
                                  onReject={() => onReject(user)} 
                                  allowReject={false}
                                  addPrimary={false}
                                  whiteBG={true}
                              />
                          </div>
                      ))}
                  </AnimatePresence>
  
                  <div ref={rightSentinelRef} style={{ width: "1px", height: "1px", display: "block" }} />
              </CardContainer>
          </CardWrapper>
  
          {/* Right Arrow */}
          <button 
              onClick={handleNext} 
              disabled={!canScrollRight}
              style={{
                  background: "transparent", 
                  border: `1px solid ${canScrollRight ? theme.textSecondary : theme.textTertiary}`,  // Circular border matching arrow color
                  borderRadius: "50%", // Makes it circular
                  width: "44px", 
                  height: "44px",
                  aspectRatio: "1 / 1",
                  paddingLeft: "0.5rem", // Ensures proper spacing
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: canScrollRight ? "pointer" : "default",
                  opacity: canScrollRight ? 1 : 0.5,
              }}>
              <RightArrow 
                  theme={theme} 
                  clickable={canScrollRight} 
                  style={{ marginLeft: "10px"  }} // Nudges arrow slightly right
              />
          </button>
      </div>
  );
  
}
