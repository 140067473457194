import React, { useState, useEffect, useRef } from 'react';
import { styled, useTheme } from "styled-components";
import { useNavigate } from 'react-router-dom';
import { isMotionValue, motion } from "framer-motion";
import { useMediaQuery } from 'react-responsive';

import { myAxiosInstance } from '../../api/axiosConfig';
import MutualConnections from './MutualConncetions';
import { PrimaryButton, SecondaryButton } from '../common/Buttons';
import { ReactComponent as AddIcon } from "../../assets/icons/addIcon.svg";


export default function HomeUserPreviewCard({ user }) {
  const theme = useTheme();
  const navigate = useNavigate();

  // Responsive
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isSmallDesktop = useMediaQuery({ maxWidth: 1023 });

  const [portfolioStatus, setPortfolioStatus] = useState(user.portfolio_status);


  const profilePicture = user.profile_picture_url?.trim() !== ""
      ? user.profile_picture_url
      : "https://storage.googleapis.com/prosp_profile_img/favicons/favicon.svg";

  // Button Actions
  const handleSendRequest = async (e) => {
    e.stopPropagation(); // Prevents navigation when clicking the button

    try {
      await myAxiosInstance.post(`/api/make-portfolio-request/`, {
        receiving_user: user.slug,
      });

      setPortfolioStatus("portfolio_request_sent");

    } catch (error) {
      console.error("Error sending request:", error);
    }

  };

  const handleUndoRequest = async (e) => {
    e.stopPropagation();

    try {
      await myAxiosInstance.post(`/api/manage-portfolio-request/${user.slug}/`);
  
      // Reset state back to not_portfolio
      setPortfolioStatus("not_porfolio");
  
    } catch (error) {
      console.error("Error undoing request:", error);
    }
  }

  const renderActionButton = () => {
    switch (portfolioStatus) {
      case "self":
        return (
          <PrimaryButton size={isMobile ? "md" : "lg"} theme={theme} style={{ padding: "0.625rem 2rem", marginLeft: "0.3rem" }} onClick={() => navigate(`/dashboard/user/${user.slug}`)}>
            VIEW
          </PrimaryButton>
        );
        
      case "in_portfolio":
        return (
          <PrimaryButton size={isMobile ? "md" : "lg"} theme={theme} style={{ padding: "0.625rem 2rem", marginLeft: "0.3rem" }} onClick={() => navigate(`/dashboard/user/${user.slug}`)}>
            VIEW
          </PrimaryButton>
        );

      case "portfolio_request_pending":
        return (
          <PrimaryButton size={isMobile ? "md" : "lg"} theme={theme} style={{ padding: "0.625rem 2rem", marginLeft: "0.3rem" }} onClick={() => navigate(`/dashboard/user/${user.slug}`)}>
            VIEW
          </PrimaryButton>
        );

      case "not_porfolio":
        return (
          <PrimaryButton size={isMobile ? "md" : "lg"} style={{ marginLeft: "0.3rem" }} theme={theme} onClick={handleSendRequest}>
            <AddIcon fill={theme.white} />
            ADD
          </PrimaryButton>
        );

      case "portfolio_request_sent":
        return (
          <SecondaryButton size={isMobile ? "md" : "lg"} style={{ marginLeft: "0.3rem", pointerEvents: "none" }} theme={theme} disabled={true}>
            SENT
          </SecondaryButton>
        );

      default:
        return null;
    }
  };


  return (
    <UserCardContainer onClick={() => { navigate(`/dashboard/user/${user.slug}`); }} theme={theme} style={{ borderRadius: "1.5rem" }}>
      <Header style={{ backgroundImage: `url(${profilePicture})` }}>
        <BlurOverlay />
      </Header>

      <ContentWrapper>
        <MainContentWrapper>

          {/* Profile Image & Name */}
          <TopSection>
            <ProfileImage 
              src={profilePicture} 
              alt="Profile" 
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop
                e.target.src = "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW_20250320180317.webp";
              }}  
            />

            <UserInfoContainer>
              <h1 style={!isSmallDesktop ? { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%", display: "block", maxWidth: `calc((100vw - 260px - 5rem) / 2 - 15rem)` } : {}}>{user.first_name} {user.last_name}</h1>

              <p style={!isSmallDesktop ? { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%", display: "block", maxWidth: `calc((100vw - 260px - 5rem) / 2 - 15rem)` } : {}}>
                {user.university} <span style={{ color: theme.divider }}> | </span> {user.company ? <span>{user.company}</span> : <span>Exp. {user.graduation_date} graduate</span>}
              </p>

              <DegreeContainer>
                {user.major_or_grad_program.slice(0, 2).map((degree, index) => (
                <DegreeTag key={index} theme={theme}>
                  {degree}
                </DegreeTag>
              ))}
              </DegreeContainer>
              
            </UserInfoContainer>
          </TopSection>

            {/* First Divider Line */}
            <DividerLine theme={theme} />

            {/* Tagline left-aligned */}
            <Tagline theme={theme} style={!isSmallDesktop ? { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%", display: "block", maxWidth: `calc((100vw - 260px - 5rem) / 2 - 5rem)` } : {}}>
                {user.tag_line ? `${user.tag_line}` : user.company ? user.role ? `${user.role} at ${user.company}` : user.company !== "Founder" ? `Work experience at ${user.company}` : `${user.company}` : `Student @ ${user.university}`}
            </Tagline>

          {/* Second Divider Line */}
          <DividerLine theme={theme} />

          {/* Mutual Connections & Button on the same line */}
          <BottomRow> 
            {/* Mutuals (LEFT SIDE) */}
            <div><MutualConnections mutuals={user.mutual_connections} themeStyle="light" /></div>

            {/* Dynamic Action Button */}
            {renderActionButton()}
          </BottomRow>
        </MainContentWrapper>
      </ContentWrapper>
    </UserCardContainer>
  );
}


// Requesting user cards
export function RequestingUserPreviewCard({ user, onAccept, onReject, allowReject=true, addPrimary=true, whiteBG=false }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const profilePicture = user.profile_picture_url?.trim() !== ""
    ? user.profile_picture_url
    : "https://storage.googleapis.com/prosp_profile_img/favicons/favicon.svg";

  const handleAccept = (e) => {
    e.stopPropagation();
    onAccept(user);
  };
  
  const handleReject = (e) => {
    e.stopPropagation();
    onReject(user);
  };


  // Mobile
  if (isMobile) {
    return (
        <UserCardContainer onClick={() => { navigate(`/dashboard/user/${user.slug}`); }} theme={theme} style={{ width: "19rem" }}>
            <Header style={{ backgroundImage: `url(${profilePicture})`, height: "4rem" }}>
                <BlurOverlay />
            </Header>

            <ContentWrapper style={{ background: whiteBG ? theme.white : theme.backgroundLight }}>
                <MainContentWrapper style={{ padding: "0.8rem 1.25rem 1.5rem 1.25rem", }}>
                    {/* Profile Image & Name */}
                    <TopSection>
                      <ProfileImage 
                        src={profilePicture} 
                        alt="Profile" 
                        style={{ marginTop: "-0.7rem" }}
                        onError={(e) => {
                          e.target.onerror = null; // Prevent infinite loop
                          e.target.src = "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW_20250320180317.webp";
                        }} 
                      />

                      <UserInfoContainer>
                        <h1>{user.first_name} {user.last_name}</h1>

                        <p>
                          {user.university} <span style={{ color: theme.divider }}> | </span> {user.company ? <span>{user.company}</span> : <span>Exp. {user.graduation_date} graduate</span>}
                        </p>

                        <DegreeContainer>
                          {user.major_or_grad_program.slice(0, 2).map((degree, index) => (
                          <DegreeTag key={index} theme={theme}>
                            {degree}
                          </DegreeTag>
                        ))}
                        </DegreeContainer>
                        
                      </UserInfoContainer>
                    </TopSection>

                    {allowReject &&
                      <>
                        {/* First Divider Line */}
                        <DividerLine theme={theme} />

                        {/* Tagline left-aligned */}
                        <Tagline theme={theme}>
                          {user.tag_line ? `${user.tag_line}` : user.company ? user.role ? `${user.role} at ${user.company}` : user.company !== "Founder" ? `Work experience at ${user.company}` : `${user.company}` : `Student @ ${user.university}`}
                        </Tagline>
                      </>
                    }

                    {/* Second Divider Line */}
                    <DividerLine theme={theme} />

                    {/* Mutual Connections & Button on the same line */}
                    <BottomRow style={{ gap: "1rem" }}> 
                        <div style={{ marginLeft: "0.5rem" }}><MutualConnections mutuals={user.mutual_connections} themeStyle={whiteBG ? "light" : "greyBg"} /></div>

                        <ButtonContainer>
                          {allowReject &&
                            <SecondaryButton
                              style={{ flex: 1, pointerEvents: "auto" }}
                              onClick={handleReject}
                              size={isMobile ? "md" : "lg"}
                            >
                              REMOVE
                            </SecondaryButton>
                          }
         
                          {addPrimary ? 
                            <PrimaryButton
                              style={{ flex: 1, pointerEvents: "auto" }}
                              onClick={handleAccept}
                              size={isMobile ? "md" : "lg"}
                            >
                              <AddIcon fill={theme.white} />
                              ADD
                            </PrimaryButton>
                            :
                            <SecondaryButton
                                style={{ flex: 1, pointerEvents: "auto" }}
                                onClick={handleAccept}
                                size={isMobile ? "md" : "lg"}
                              >
                              <AddIcon fill={theme.textPrimary} />
                              ADD
                            </SecondaryButton>
                          } 
                        </ButtonContainer>
                    </BottomRow>
                </MainContentWrapper>
            </ContentWrapper>
        </UserCardContainer>
    );
  };

  // Desktop
  return (
      <MotionUserCardContainer
          onClick={() => { navigate(`/dashboard/user/${user.slug}`); }}
          theme={theme}
          style={{ maxWidth: "22rem" }}
          initial={false}  // Disables initial animation when first appearing
          animate={{ opacity: 1}}  // Normal state (static)
          exit={{ opacity: 0 }}  // Runs animation only when removed
          transition={{ duration: 0.3 }}
      >
      <Header style={{ backgroundImage: `url(${profilePicture})`, height: "4rem" }}>
        <BlurOverlay />
      </Header>

      <ContentWrapper style={{ background: whiteBG ? theme.white : theme.backgroundLight }}>
        <MainContentWrapper style={{ padding: "0.8rem 1.25rem 1.5rem 1.25rem", }}>

          {/* Profile Image & Name */}
          <TopSection style={{ gap: "1rem" }}>
            
            <ProfileImage 
              src={profilePicture} 
              alt="Profile" 
              style={{ width: "4.5rem", height: "4.5rem", marginTop: "-1.8rem" }} 
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop
                e.target.src = "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW_20250320180317.webp";
              }} 
            />

            <UserInfoContainer>
              <h1 style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%", display: "block", maxWidth: "13rem" }}>
                {user.first_name} {user.last_name}
              </h1>
              <p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%", display: "block", maxWidth: "13rem" }}>
                {user.university}{isMobile && <> <span style={{ color: theme.divider }}> | </span> {user.company ? <span>{user.company}</span> : <span>Exp. {user.graduation_date} graduate</span>}</>}
              </p>
            </UserInfoContainer>

          </TopSection>
          
          <DividerLine theme={theme} style={{ margin: "0.75rem auto" }} />

          {/* Degree for desktop */}
          <DegreeContainer style={{ marginBottom: "0.75rem", gap: "0.5rem", flexDirection: "row" }}>
              {user.major_or_grad_program.slice(0, 1).map((degree, index) => (
                <DegreeTag key={index} theme={theme}>
                  {degree}
                </DegreeTag>
              ))}
              <DegreeTag key={"grad"} theme={theme} style={{ color: theme.textSecondary, background: `${theme.textSecondary}1A`, alignSelf: "flex-start" }}>
                {user.company ? <span>{user.company}</span> : <span>Exp. {user.graduation_date} graduate</span>}
              </DegreeTag>
          </DegreeContainer>

          {/* Tagline */}
          <Tagline style={{ marginBottom: "0.75rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%", display: "block", maxWidth: "19rem" }} theme={theme}>
              {user.tag_line ? `${user.tag_line}` : user.company ? user.role ? `${user.role} at ${user.company}` : user.company !== "Founder" ? `Work experience at ${user.company}` : `${user.company}` : `Student @ ${user.university}`}
          </Tagline>

          {/* Mutual Connections (Moved Above Divider) */}
          <div style={{ marginLeft: "0.5rem" }}><MutualConnections mutuals={user.mutual_connections} themeStyle={whiteBG ? "light" : "greyBg"} /></div>

          <DividerLine theme={theme} style={{ margin: "0.75rem auto" }} />

          {/* Full-Width Buttons */}
          <div style={{ display: "flex", gap: "0.8rem", width: "100%" }}>
            {allowReject &&
              <SecondaryButton
                style={{ flex: 1, pointerEvents: "auto" }}
                onClick={handleReject}
                size={isMobile ? "md" : "lg"}
              >
                REJECT
              </SecondaryButton>
            }

            {addPrimary ? 
                <PrimaryButton
                  style={{ flex: 1, pointerEvents: "auto" }}
                  onClick={handleAccept}
                  size={isMobile ? "md" : "lg"}
                >
                ACCEPT
              </PrimaryButton>
              :
              <SecondaryButton
                  style={{ flex: 1, pointerEvents: "auto" }}
                  onClick={handleAccept}
                  size={isMobile ? "md" : "lg"}
                >
                <AddIcon fill={theme.textPrimary} />
                ADD
              </SecondaryButton>
            } 
            
          </div>
        </MainContentWrapper>
      </ContentWrapper>
    </MotionUserCardContainer>
  );
}



const UserCardContainer = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  background: ${({ theme }) => theme.white};
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;
  flex-direction: column;

  &:hover {
    transform: scale(1.02); /* Slightly enlarges */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
  }

`;

const MotionUserCardContainer = motion(UserCardContainer); // For smoothe animation

const Header = styled.div`
  height: 5rem;
  background-size: cover;
  background-position: center;
`;

const BlurOverlay = styled.div`
  width: 100%;
  height: 100%;
  inset: 0;
  backdrop-filter: blur(1.25rem);
  -webkit-backdrop-filter: blur(1.25rem);
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 2rem 2rem 2rem;
  width: 100%;

  @media (max-width: 600px) {
    margin-top: -1.25rem;
    padding: 0rem 1.25rem 1.25rem 1.5rem;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 0.75rem;
  }
`;

const ProfileImage = styled.img`
  align-self: flex-start;
  width: 6rem;
  height: 6rem;
  aspect-ratio: 1;
  border-radius: 1rem;
  margin-top: -2.5rem;
  z-index: 2;
  object-fit: cover;

  @media (max-width: 600px) {
    width: 5rem;
    height: 5rem;
    margin-top: 0;
  }
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};

    @media (max-width: 768px) {
      margin-bottom: 0px;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px;
    color: ${({ theme }) => theme.textSecondary};
    margin-bottom: 0.5rem;

    @media (max-width: 768px) {
      font-size: 0.875rem;
      margin: 0.3rem 0 0.5rem 0;
    }
  }
`;

const DegreeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const DegreeTag = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.primaryLight};
  padding: 0.25rem 0.625rem;
  border-radius: 2.75rem;
  display: inline-block;

  max-width: 16ch; /* Limits to 30 characters */
  white-space: nowrap; /* Prevents wrapping */
  overflow: hidden; /* Ensures text does not overflow */
  text-overflow: ellipsis; /* Adds '...' when text is too long */

  @media (max-width: 600px) {
    max-width: 15ch; /* Move '...' closer as screen gets smaller */
  }
`;

const DividerLine = styled.hr`
  width: 95%;
  height: 1px; /* Thin line */
  border: none;
  background: linear-gradient(90deg, 
    ${({ theme }) => theme.divider} 0%, 
    ${({ theme }) => theme.divider} 15%, 
    ${({ theme }) => theme.divider} 50%, 
    ${({ theme }) => theme.divider} 85%, 
    ${({ theme }) => theme.divider} 100%
  );
  opacity: 1; /* Ensure visibility */
  margin: 0.9rem auto; /* Adjust spacing as needed */
`;

const BottomRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; /* ✅ Pushes items to each end */
  gap: 0.2rem;
  flex-wrap: wrap;
  align-content: center;
`;

const Tagline = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.textPrimary};
  margin: 0; /* ✅ More space before the dashed line */
  text-align: left;

  @media (max-width: 600px) {
    font-size: 0.875rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  
  & > button {
    flex: 1; /* Ensures both buttons take equal space */
  }
`;






// old
// import { Card } from 'react-bootstrap';
// import { useAuth } from '../../contexts/AuthContext';
// import { StyledBorderCard, StyledButton } from '../common/StyledBootstrap';
// import { useTracking } from 'react-tracking';
// import { ProfilePicture } from '../common/MiscComponents';
// import MeetingTimer from '../meetings/MeetingTimer';


// function UserPreviewCard({ preview_object, buttonBoolean, bottomReload, setBottomReload, nonUser }) {
//     const themeMode = useTheme();
//     const { userSlug, reload, setReload, setActiveChannel, setChatVisible } = useAuth();
//     const [isIndividualMessageLoading, setIsIndividualMessageLoading] = useState(false);

//     const slug = preview_object.slug;
//     const fullName = nonUser ? preview_object.full_name : `${preview_object.first_name} ${preview_object.last_name}`;
    
//     const majors = nonUser ? "Student" : Array.isArray(preview_object.major_or_grad_program)
//         ? preview_object.major_or_grad_program.join(', ')
//         : preview_object.major_or_grad_program;
//     const university = preview_object.university;
//     const company = nonUser ? "Student" : preview_object.company;

//     const [pictureSize, setPictureSize] = useState(0);
//     const cardRef = useRef(null);
//     const navigate = useNavigate();

//     const titleSize = pictureSize * 0.15;
//     const majorSize = pictureSize * 0.1;

//     const handleUserClick = () => {
//         !nonUser && navigate('/dashboard/user/' + preview_object.slug);
//     }

//     const handleMessageButtonClick = async () => {
//         try {
//             setIsIndividualMessageLoading(true);
//             const response = await myAxiosInstance.post('/api/create-channel/', { recipient_slug: slug });
//             setActiveChannel(response.data.channel_id);
//             setChatVisible(true);

//         }
//         catch (error) {
// }
//         finally {
//             setIsIndividualMessageLoading(false);
//         }
//     };

//     const handleUserPreviewButtonClick = () => {

//         if (preview_object.meeting_status !== "not_meeting")
//         {
//             navigate('/dashboard/meeting/' + preview_object.meeting_slug);            
//         }
//         else if (preview_object.portfolio_status === "in_portfolio"){
//             // Chat
//             handleMessageButtonClick();
//         }
//         else{
//             navigate('/dashboard/user/' + preview_object.slug, { state: { addToPortfolio: true } });
//         }
//     }

//     const buttonLabels = ["Add", "Sent", "Chat"];

//     useEffect(() => {
//         const updateCardSize = () => {
//             if (cardRef.current) {
//                 const cardWidth = cardRef.current.offsetWidth;
//                 const size = Math.min(cardWidth, cardWidth * 1.6) * 0.7; // Adjusted size when not hovered
//                 setPictureSize(size);
//             }
//         };

//         updateCardSize();
//         window.addEventListener('resize', updateCardSize);

//         return () => window.removeEventListener('resize', updateCardSize);
//     }, []);

//     return (
//         <>
//             <StyledBorderCard
//                 ref={cardRef}
                
//                 style={{
//                     width: '8rem',
//                     height: buttonBoolean ? '14.3rem' : '12rem', // Fixed size when not hovered
//                     transition: 'transform 0.3s ease',
//                     transform: 'scale(1)',
//                     boxShadow: '0 0 10px rgba(0,0,0,0.1)',
//                     marginBottom: '0rem', // Adjusted margin bottom to reduce space between cards
                    
//                 }}
//                 onMouseEnter={() => {
//                     cardRef.current.style.transform = 'scale(1.04)';
//                     cardRef.current.style.boxShadow = '0 0 20px rgba(0,0,0,0.2)';
//                 }}
//                 onMouseLeave={() => {
//                     cardRef.current.style.transform = 'scale(1)';
//                     cardRef.current.style.boxShadow = '0 0 10px rgba(0,0,0,0.1)';
//                 }}
//             >
//                 <StyledBorderCard.Body style={{ textAlign: 'center'}}>
//                     <div onClick={handleUserClick}>
//                     <div className='d-flex justify-content-center'>
//                         <ProfilePicture name={fullName} size={pictureSize} graduation_date={preview_object.graduation_date} picture_link={preview_object.profile_picture} />
//                     </div>
//                     <StyledBorderCard.Title
//                         style={{
//                             cursor: 'pointer',
//                             textAlign: 'center',
//                             whiteSpace: 'nowrap',
//                             overflow: 'hidden',
//                             textOverflow: 'ellipsis',
//                             fontSize: `${titleSize}px`,
//                             marginTop: '0.3em'
//                         }}>
//                         {fullName}
//                     </StyledBorderCard.Title>
//                     <StyledBorderCard.Text
//                         style={{
//                             textAlign: 'center',
//                             overflow: 'hidden',
//                             textOverflow: 'ellipsis',
//                             maxWidth: '100%',
//                             fontSize: `${majorSize}px`,
//                             display: '-webkit-box',
//                             WebkitBoxOrient: 'vertical',
//                             WebkitLineClamp: 1,
//                             lineHeight: '1.2em',
//                         }}>
//                         {company === "" ? majors || 'Not Specified' : company || 'Not Specified'}
//                     </StyledBorderCard.Text>
//                     <StyledBorderCard.Text
//                         style={{
//                             textAlign: 'center',
//                             overflow: 'hidden',
//                             textOverflow: 'ellipsis',
//                             maxWidth: '100%',
//                             fontSize: `${majorSize}px`,
//                             display: '-webkit-box',
//                             WebkitBoxOrient: 'vertical',
//                             WebkitLineClamp: 1,
//                             lineHeight: '1.2em',
//                         }}>
//                         {university || preview_object.email}
//                     </StyledBorderCard.Text>
                    
//                     </div>
//                     {buttonBoolean ? 
//                     <StyledButton className='mt-2' onClick={handleUserPreviewButtonClick} style={{ lineHeight: '1', fontSize: '0.75rem', minWidth: '95%', backgroundColor: preview_object.meeting_status === "you_requested" || preview_object.meeting_status === "confirmed" || preview_object.meeting_status === "you_rescheduled" ? themeMode.background : themeMode.primary, color: preview_object.meeting_status === "you_requested" || preview_object.meeting_status === "confirmed" || preview_object.meeting_status === "you_rescheduled" ? themeMode.onBackground : themeMode.onPrimary}}>
//                         {
//                             preview_object.portfolio_status === "you_requested" ? buttonLabels[1] :
//                             preview_object.portfolio_status === "you_received" ? buttonLabels[2] :
//                             preview_object.portfolio_status === "confirmed" ? <MeetingTimer meetingTime={preview_object.meeting_date} onTime={() => { setBottomReload(bottomReload + 1) }} /> :
//                             preview_object.portfolio_status === "you_rescheduled" ? buttonLabels[4] :
//                             preview_object.portfolio_status === "other_user_rescheduled" ? buttonLabels[5] :
//                             preview_object.portfolio_status === "in_progress" ? buttonLabels[6] :
//                             preview_object.portfolio_status === "in_portfolio" ? buttonLabels[7] : 
//                             preview_object.portfolio_status === "not_meeting" ? buttonLabels[0] : <></>
//                         }
//                     </StyledButton> : <></>}
//                 </StyledBorderCard.Body>
//             </StyledBorderCard>
//         </>
//     );
// }

// export default UserPreviewCard;

