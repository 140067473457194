import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { PrimaryButton } from "../components/common/Buttons";
import NotFound from "../assets/images/NotFound.png";

function NotFoundPage() {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Container>
            <Image src={NotFound} alt="No Results" />
            <Title theme={theme}>No Results Found</Title>
            <Description theme={theme}>
                Sorry, the page you are looking for cannot be found.
            </Description>
            <PrimaryButton onClick={() => navigate("/")} size="lg">RETURN HOME</PrimaryButton>
        </Container>
    );
}

export default NotFoundPage;

// Styled Components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100vh;
    padding: 1rem;
`;

const Image = styled.img`
    width: 120px; /* Ensures image has a fixed width */
    height: auto;
    margin-bottom: 1.5rem;
`;

const Title = styled.h2`
    font-size: 1.55rem;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
    margin-bottom: 0.75rem;
`;

const Description = styled.p`
    font-size: 1rem;
    color: ${({ theme }) => theme.textSecondary};
    max-width: 420px;
    line-height: 1.5;
    margin-bottom: 1.5rem;
`;
