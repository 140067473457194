import { StyledH5, StyledParagraph, StyledCard } from "../components/common/StyledBootstrap";
import { useTheme } from "styled-components";

function MemberCodeOfConduct() {
    const themeMode = useTheme();
    
    return (
        <div className='d-flex justify-content-center' style={{ backgroundColor: themeMode.background, minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>
            <div className='home-joyrride mx-2' style={{ minWidth: 0, width: '90%', minHeight: 0, height: '100%' }}>
            <StyledCard className='my-3'>
                            <StyledCard.Body>
            <StyledH5><strong>Code of Conduct for Prospinity Members...</strong></StyledH5>
            <StyledParagraph>
                As a member of a Prospinity success pool, you are part of a unique community committed to supporting 
                and empowering each other’s growth. To ensure a positive and respectful experience for everyone, all 
                members are expected to adhere to the following principles:
            </StyledParagraph>

            <StyledH5><strong>1. Respect and Inclusivity</strong></StyledH5>
            <StyledParagraph>
                - Treat all members with respect, empathy, and courtesy.<br />
                - Value diverse perspectives, backgrounds, and experiences.<br />
                - Engage in discussions constructively, avoiding any language or behavior that is offensive, 
                discriminatory, or inflammatory.
            </StyledParagraph>

            <StyledH5><strong>2. Integrity and Honesty</strong></StyledH5>
            <StyledParagraph>
                - Be truthful and transparent in your interactions, especially when discussing professional 
                experiences or financial matters.<br />
                - Honor your commitments to the pool, including financial contributions as per the pool agreement.<br />
                - Avoid any actions that could mislead, misrepresent, or manipulate other members or the pool’s 
                collective resources.
            </StyledParagraph>

            <StyledH5><strong>3. Confidentiality and Trust</strong></StyledH5>
            <StyledParagraph>
                - Respect the privacy of your fellow pool members by keeping shared information within the group.<br />
                - Do not disclose sensitive or personal information about other members outside the pool without 
                explicit consent.<br />
                - Trust is the foundation of our success pools; uphold it by maintaining confidentiality and acting in 
                the best interest of the group.
            </StyledParagraph>

            <StyledH5><strong>4. Constructive Feedback and Support</strong></StyledH5>
            <StyledParagraph>
                - Offer support and feedback to fellow members in a constructive and encouraging manner.<br />
                - Celebrate each other’s successes and offer help when challenges arise.<br />
                - Strive to build a positive and collaborative environment, promoting growth for all members.
            </StyledParagraph>

            <StyledH5><strong>5. Participation and Engagement</strong></StyledH5>
            <StyledParagraph>
                - Engage actively in group discussions, meetings, and other community activities.<br />
                - Be punctual and present in scheduled meetings, respecting the time of others.<br />
                - Contribute to the pool’s goals and work towards mutual success, as participation from all members 
                enriches the community.
            </StyledParagraph>

            <StyledH5><strong>6. Accountability and Responsibility</strong></StyledH5>
            <StyledParagraph>
                - Take responsibility for your actions and commitments within the pool.<br />
                - Address any conflicts or misunderstandings constructively and promptly.<br />
                - Should a member fall short of expectations, work with the group to find solutions that align with the 
                pool’s values.
            </StyledParagraph>

            <StyledH5><strong>7. Growth and Learning</strong></StyledH5>
            <StyledParagraph>
                - Embrace opportunities to learn from one another and share insights.<br />
                - Be open to feedback and willing to grow from constructive advice.<br />
                - Recognize that every member’s development contributes to the strength of the pool as a whole.
            </StyledParagraph>
            </StyledCard.Body>
            </StyledCard>
            </div>
            </div>
    );
}

export default MemberCodeOfConduct;