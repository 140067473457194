import styled, { useTheme } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { FlexContainer, StyledHeader } from '../common/Containers';


const WelcomeSection = ({ pools, portfolioCount, balance, userFirstName }) => {

    // Get the theme
    const theme = useTheme();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 }); 

    // Get the date
    const getCurrentDate = () => {
        const options = { weekday: 'long', month: 'short', day: 'numeric' };
        return new Date().toLocaleDateString('en-US', options).toUpperCase();
    };

    return (
        <FlexContainer justify="space-between" align="flex-start" wrap="wrap" gap="1rem" >
            <FlexContainer direction="column" align="flex-start" style={{ width: isMobile ? "100%" : "auto", alignItems: "flex-start" }} >
                <p
                    style={{
                        fontSize: isMobile ? "0.875rem" : "1rem",
                        fontWeight: '500',
                        color: theme.textSecondary,
                        lineHeight: isMobile ? "1.5rem" : "1.25rem",
                        marginBottom: '0.5rem',
                    }}
                >
                    {getCurrentDate()}
                </p>
                <StyledHeader theme={theme}>Welcome Back, {userFirstName}</StyledHeader>
            </FlexContainer>

            {/* Stats Section */}
            <StatsContainer 
                justify="space-between"
                wrap="wrap" 
                gap="0.75rem"
                style={{ paddingRight: isMobile ? "0" : "0.3rem", width: isMobile ? "100%" : "auto"}}
            >
                <Stat label="Your Pools" value={pools} theme={theme} />
                <Stat label="Portfolio Size" value={portfolioCount} theme={theme} />
                <Stat label="Net Balance" value={balance} theme={theme} />
            </StatsContainer>

        </FlexContainer>
    );
}

export default WelcomeSection;



const Stat = ({ label, value, theme }) => (
    <StatCardWrapper theme={theme}>
        <span
            style={{
                fontSize: '1.5rem',
                fontWeight: '500',
                color: theme.textPrimary,
                lineHeight: '2rem',
                marginBottom: "0.1rem",
            }}
        >
            {value}
        </span>
        <span
            style={{
                fontSize: '0.875rem',
                fontWeight: '400',
                color: theme.textSecondary,
                lineHeight: '1.25rem',
            }}
        >
            {label}
        </span>
    </StatCardWrapper>
);


const StatsContainer = styled.div`
    display: flex;
    flex-direction: 'row';
    justify-content: 'space-between';
    align-items: 'stretch';
    flex-wrap: 'wrap';
    gap: 0.75rem;
`; 


const StatCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0.625rem 0.75rem;
    background: ${({ theme }) => theme.white};
    border-radius: 12px;
    min-width: 110px;
    max-width: 150px;
    text-align: left;
    flex: 1;

    @media (max-width: 600px) {
        flex: 1;
        min-width: 60px;
    }

`;