import React, { useEffect, useState, useRef } from 'react';
import { useTheme, styled } from 'styled-components';
import { useErrorBoundary } from "react-error-boundary";
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@tanstack/react-query';

// From My Application
import { useAuth } from "../contexts/AuthContext";
import { myAxiosInstance } from "../api/axiosConfig";
import Loading from "../components/common/Loading";
import { MainContainer } from '../components/common/Containers';
import Banner from "../components/common/Banners";
import GroupInMind from '../components/pools/GroupInMind';
import PoolPreview from '../components/pools/PoolPreview';


const Pools = () => {

    // Auth context
    // const { reload, setReload, emailVerified, universityEmailVerified, userFirstName, firstTime } = useAuth();
    const { reload, setReload, emailVerified, universityEmailVerified, userFirstName, firstTime } = useAuth();

    // States
    const [isLoading, setIsLoading] = useState(true);
    const [pools, setPools] = useState([]);

    // Errors
    const { showBoundary } = useErrorBoundary();

    // Get the theme
    const theme = useTheme();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // Get the pool data
    // useEffect(() => {
    //     const getPoolData = async () => {
    //         try {
    //             const response = await myAxiosInstance.get('/api/user-pools/');
    //             setPools(response.data); // Store the data
                
    //         } catch (error) {
    //             showBoundary(error);
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     };
        
    //     getPoolData();
    // }, [reload]);
    const { data: userPools, isLoading: loadingPools, error: errorPools } = useQuery({
        queryKey: ['userPools'],
        queryFn: () => myAxiosInstance.get('/api/user-pools/').then(res => res.data),
        staleTime: 5 * 60 * 1000,
    });


    if (loadingPools) {
        return (
            <div style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <Loading />
            </div>
        );
    }

    if (errorPools) {
        showBoundary(errorPools);
        return null;
    }

    return (
        <MainContainer theme={theme} style={{ gap: "1.5rem" }}>

            {userPools.length === 0 ? (
                <>
                    {/* Banner */}
                    <Banner bannerType={"not-matched"}/>

                    {/* Group in mind */}
                    <GroupInMind/>
                </>
            ) : (
                userPools
                    .filter(pool => pool.user_info.status !== "not_associated")
                    .sort((a, b) => new Date(b.public_details.created_at) - new Date(a.public_details.created_at))
                    .map((pool, index, filteredPools) => (
                        <div key={pool.slug} style={{ marginBottom: isMobile ? "1rem" : "1.5rem" }}>
                            <PoolPreview pool={pool} />
                        </div>
                    ))
            )}

        </MainContainer>
    );
};


export default Pools;





// import Loading from "../components/common/Loading";
// import { useErrorBoundary } from "react-error-boundary";
// import {HomeUserPreviewCard} from "../components/users/UserPreviewCard";
// import { myAxiosInstance } from "../api/axiosConfig";
// import { Row, Col } from "react-bootstrap";
// import { useEffect, useState } from "react";
// import { StyledCard, StyledH5 } from "../components/common/StyledBootstrap";
// import { useTheme } from "styled-components";
// import { useMediaQuery } from "react-responsive";
// import PoolPreviewCard from "../components/pools/PoolPreviewCard";
// import { useAuth } from "../contexts/AuthContext";
// import { StyledButton } from "../components/common/StyledBootstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHome, faQuestion, faUser, faSwimmer, faFileInvoiceDollar, faSignOut, faChartLine, faCreditCard, faCheck, faSearch, faBell, faPlus, faGear, faPeopleGroup, faSwimmingPool, faEnvelope, faCircle, faBullseye } from '@fortawesome/free-solid-svg-icons'; // Example icons
// import { useNavigate } from "react-router-dom";

// const MyPools = () => {


//     const [isBottomLoading, setIsBottomLoading] = useState(true);
//     const [pools, setPools] = useState([]);
//     const [featuredPools, setFeaturedPools] = useState([]);
//     const [bottomReload, setBottomReload] = useState(0);
//     const themeMode = useTheme();
//     const { showBoundary } = useErrorBoundary();
//     const isMdOrLarger = useMediaQuery({ minWidth: 768 });
//     const [activatePoolingButtonLoading, setActivatePoolingButtonLoading] = useState(false);
//     const navigate = useNavigate();



//     useEffect(() => {
//         const getPools = async () => {
//             try {
//                 setIsBottomLoading(true);

//                 const response = await myAxiosInstance.get('/api/user-pools/');
//                 const response1 = await myAxiosInstance.get('/api/featured-pools/');
//                 setPools(response.data);
//                 setFeaturedPools(response1.data);


//             } catch (error) {
//                 showBoundary(error);
//             } finally {
//                 setIsBottomLoading(false);
//             }
//         };

        
//         getPools();


//     }, [bottomReload]);


//     return (
//         isBottomLoading ? <Loading />
//             :
            
//             <div className='d-flex justify-content-center' style={{ backgroundColor: themeMode.background, minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>
//                 <div className='mx-2' style={{ minWidth: 0, width: '100%', minHeight: 0, height: '100%' }}>


//                     <div className="d-flex justify-content-end mt-3" style={{marginBottom: '-1rem'}}>
//                     <StyledButton onClick={() => navigate("/dashboard/create-pool")} className='px-3' style={{fontSize: '0.8rem'}}><FontAwesomeIcon icon={faPlus} className="mr-1"/>Create Pool</StyledButton>
//                     </div>
//                     <div className="d-flex justify-content-between">
//                         <StyledH5><strong>My Pools</strong></StyledH5>
//                     </div>
//                     <StyledCard style={{ position: 'relative' }}>
//                         {pools.length == 0 ? <StyledCard.Body>You haven't received any pool invites. Make sure to add people to your portfolio from Daily Picks.</StyledCard.Body> :
//                             <>
//                                 <StyledCard.Body className='d-flex' style={{
//                                     overflowX: 'auto',
//                                     whiteSpace: 'nowrap',
//                                     gap: '1rem',
//                                     paddingRight: '1.5rem',
//                                     minWidth: 0,
//                                     width: '100%'  // Ensure there's space on the right for the gradient
//                                 }}>

//                                     {pools.map((pool) => (
//                                         <div className="mx-1 my-1">

//                                             <PoolPreviewCard data={pool} />
//                                         </div>
//                                     ))}
//                                 </StyledCard.Body>
//                                 {isMdOrLarger && themeMode.theme == "light" &&
//                                 <div style={{
//                                     position: 'absolute',
//                                     top: 0,
//                                     right: 0,
//                                     width: '50px',  // Adjust width as needed
//                                     height: '100%',
                                    
//                                     pointerEvents: 'none'  // Allow the scrollable content to be interacted with
//                                 }}></div>}</>}
//                     </StyledCard>
//                     {featuredPools.length == 0 ? <></> : 
//                     <>
//                     <div className="mt-3">
//                         <StyledH5><strong>Featured Pools</strong></StyledH5>
//                     </div>
//                     <StyledCard style={{ position: 'relative' }}>
//                             <>
//                                 <StyledCard.Body className='d-flex' style={{
//                                     overflowX: 'auto',
//                                     whiteSpace: 'nowrap',
//                                     gap: '1rem',
//                                     paddingRight: '1.5rem',
//                                     minWidth: 0,
//                                     width: '100%'  // Ensure there's space on the right for the gradient
//                                 }}>

//                                     {featuredPools.map((pool) => (
//                                         <div className="mx-1 my-1">

//                                             <PoolPreviewCard data={pool} />
//                                         </div>
//                                     ))}
//                                 </StyledCard.Body>
//                                 {isMdOrLarger && themeMode.theme == "light" &&
//                                 <div style={{
//                                     position: 'absolute',
//                                     top: 0,
//                                     right: 0,
//                                     width: '50px',  // Adjust width as needed
//                                     height: '100%',
//                                     background: 'linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))',
//                                     pointerEvents: 'none'  // Allow the scrollable content to be interacted with
//                                 }}></div>}</>
//                     </StyledCard></>}

//                 </div>

//             </div>

//     )

// }



// export default MyPools;