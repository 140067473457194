import React from "react";
import styled from "styled-components";

const MutualConnections = ({ mutuals, themeStyle = "light", mutualText = "mutuals" }) => {
    if (!mutuals || mutuals.length === 0) return null;

    return (
        <MutualContainer>
        {mutuals.slice(0, 2).map((mutual, index) => (
            <MutualImage
                src={
                    mutual.profile_picture_url ||
                    mutual.profile_picture ||
                    "https://images.prospinity.com/favicons/favicon.svg"
                }
                alt={`Mutual ${index + 1}`}
                key={index}
                index={index + 1}
                themeStyle={themeStyle}
                onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop
                    e.target.src = "https://images.prospinity.com/profile_pictures/main_profile_picture_prospinity-captain-O4zW_20250320180317.webp";
                }} 
            />
        ))}

        {mutuals.length > 2 && (
            <MutualCount themeStyle={themeStyle}>+{mutuals.length - 2}</MutualCount>
        )}

        <MutualText themeStyle={themeStyle}>{mutuals.length} {mutualText}</MutualText>
        </MutualContainer>
    );
};

export default MutualConnections;

// ---------------------- 🔹 Styled Components 🔹 ----------------------

const MutualContainer = styled.div`
    display: flex;
    align-items: center;
`;

const MutualImage = styled.img`
    width: 2rem;
    height: 2rem;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 0.125rem solid ${({ theme, themeStyle }) =>
        themeStyle === "dark" ? theme.buttonPrimary : themeStyle === "greyBg" ? theme.backgroundLight : "#fff"};
    margin-left: -0.5rem;
    z-index: ${({ index }) => index};
    object-fit: cover;
`;

const MutualCount = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2rem;
    height: 2rem;
    aspect-ratio: 1;
    padding-right: 0.15rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme, themeStyle }) =>
        themeStyle === "dark" ? "rgba(255, 255, 255, 0.64)" : theme.textSecondary};
    background: ${({ theme, themeStyle }) =>
        themeStyle === "dark" ? "rgba(255, 255, 255, 0.10)" : themeStyle === "greyBg" ? theme.white : theme.backgroundDarker};
    backdrop-filter: ${({ themeStyle }) =>
        themeStyle === "dark" ? "blur(6px)" : "blur(0px)"};    
    border-radius: 50%;
    border: 0.125rem solid ${({ theme, themeStyle }) =>
        themeStyle === "dark" ? theme.buttonPrimary : themeStyle === "greyBg" ? theme.backgroundLight : "#fff"};
    margin-left: -0.5rem;
    z-index: 10;

`;

const MutualText = styled.span`
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme, themeStyle }) =>
        themeStyle === "dark" ? "rgba(255, 255, 255, 0.64)" : theme.textSecondary};
    margin-left: 0.5rem;

    @media (max-width: 600px) { 
        font-size: 0.875rem;
    }
`;
