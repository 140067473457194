import { useTheme } from "styled-components";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

// From my app
import { RegistrationContainer } from "../../components/common/Containers";
import SingleSelect, { SingleSelectFixedOptions } from "../../components/forms/SingleSelect";
import MultiSelect from "../../components/forms/MultiSelect";
import { Form } from "../../components/forms/Form";
import { PrimaryButton, SecondaryButton, HorizontalButtonContainer } from "../../components/common/Buttons";
import { RegistrationHeader, Subtitle, MobileBottomSection } from "../../components/forms/MiscComponents";
import { myAxiosInstance } from "../../api/axiosConfig";
import { useAuth } from "../../contexts/AuthContext";

function FinishUniversity() {
    // Get the email
    const { userEmail } = useAuth();

    const [university, setUniversity] = useState(userEmail?.endsWith("@berkeley.edu") ? "University of California, Berkeley" : "");
    const [major, setMajor] = useState([]);
    const [graduation, setGraduation] = useState("");
    const [errors, setErrors] = useState({ university: "", major: "", graduation: "" });
    const [loading, setLoading] = useState(false);

    // Graduation years
    const graduationYears = Array.from({ length: 51 }, (_, i) => (2000 + i).toString());
    
    // Erros
    const { showBoundary } = useErrorBoundary();

    // Get the theme
    const theme = useTheme();

    // Navigate
    const navigate = useNavigate();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // Set default university if email ends with "@berkeley.edu"
    useEffect(() => {
        if (userEmail && userEmail.endsWith("@berkeley.edu")) {
            setUniversity("University of California, Berkeley");
        }
    }, [userEmail]); // Runs when userEmail changes

    // Retrieve the data
    useEffect(() => {
        const getStageDetails = async () => {
            try {
                setLoading(true);
                const response = await myAxiosInstance.get('/api/finish-registration-two/');
                
                if (response.data.university) {
                    setUniversity(response.data.university || "");
                }
                setMajor(response.data.major_or_grad_program || []);
                setGraduation(response.data.graduation_date ? response.data.graduation_date.toString() : "");

            } catch (error) {
                showBoundary(error);
            } finally {
                setLoading(false); // Set isLoading to false regardless of success or error
            }
        };

        getStageDetails();

    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        let newErrors = { university: "", major: "", graduation: "" };
    
        // Frontend validation
        if (university.trim().length < 2) {
            newErrors.university = "Please select a valid university.";
        }
    
        if (major.length === 0) {
            newErrors.major = "Please select a valid major.";
        }

        if (!graduation || isNaN(Number(graduation)) || Number(graduation) < 2000 || Number(graduation) > 2050) {
            newErrors.graduation = "Please select a valid graduation year.";
        }
    
        if (newErrors.university || newErrors.major || newErrors.graduation) {
            setErrors(newErrors);
            return; // Stop execution if frontend validation fails
        }
                  
        try {
            // Send API request
            setLoading(true);

            await myAxiosInstance.put("/api/finish-registration-two/", { "university": university, "major_or_grad_program": major, graduation_date: Number(graduation) });

            navigate("/finish-registration/company");

        } catch (error) {
            // Handle API errors
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data.university) {
                        setErrors({ ...newErrors, university: "Please select a valid university from the list." });
                    } else if (error.response.data.major) {
                        setErrors({ ...newErrors, major: "Please select a valid major from the list." });
                    } else if (error.response.data.graduation_date) {
                        setErrors({ ...newErrors, graduation: "Please select a valid graduation date from the list." });
                    } else if (error.response.data.non_field_errors) {
                        setErrors({ ...newErrors, graduation: "Something went wrong. Please try again later." });
                    } else {
                        setErrors({ ...newErrors, graduation: "Something went wrong. Please try again later." });
                    }
                } else {
                    setErrors({ ...newErrors, graduation: "Something went wrong. Please try again later." });
                }
            } else {
                setErrors({ ...newErrors, graduation: "Something went wrong. Please try again later." });
            }
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <RegistrationContainer isMobile={isMobile}>
            <RegistrationHeader isMobile={isMobile}>TELL US ABOUT YOUR EDUCATION</RegistrationHeader>
            <Subtitle isMobile={isMobile} theme={theme}>Please enter your university, major, and graduation year</Subtitle>

            <Form onSubmit={handleSubmit}>
                <SingleSelect 
                    label="University" 
                    endpoint='search-universities'
                    value={university}
                    onChange={(value) => {
                        setUniversity(value);
                        setErrors((prev) => ({ ...prev, university: "" })); // Clear error when typing
                    }}
                    error={errors.university}
                />

                <div style={{ marginTop: "2rem" }}>
                    <MultiSelect 
                        label="Major" 
                        endpoint='search-degrees'
                        value={major}
                        onChange={(value) => {
                            setMajor(value);
                            setErrors((prev) => ({ ...prev, major: "" })); // Clear error when typing
                        }}
                        error={errors.major}
                    />
                </div>

                <div style={{ marginTop: "2rem" }}>
                    <SingleSelectFixedOptions 
                        label="Graduation year" 
                        options={graduationYears}
                        value={graduation}
                        onChange={(value) => {
                            setGraduation(value);
                            setErrors((prev) => ({ ...prev, graduation: "" })); // Clear error when typing
                        }}
                        error={errors.graduation}
                    />
                </div>

                {/* Mobile bottom section */}
                {isMobile ?
                    <MobileBottomSection>
                        <HorizontalButtonContainer>
                            <SecondaryButton size="lg" type="button" disabled={loading} style={{ flex: "1", padding: "1rem"}} onClick={() => navigate("/finish-registration/name")} >
                                BACK
                            </SecondaryButton>
                            <PrimaryButton size="lg" type="submit" disabled={loading} style={{ flex: "1", padding: "1rem"}} >
                                {loading ? "Checking university..." : "CONTINUE"}
                            </PrimaryButton>
                        </HorizontalButtonContainer>
                    </MobileBottomSection>
                    :
                    <HorizontalButtonContainer>
                        <SecondaryButton size="lg" type="button" disabled={loading} style={{ flex: "1", padding: "1rem", marginTop: "2rem" }} onClick={() => navigate("/finish-registration/name")} >
                            BACK
                        </SecondaryButton>

                        <PrimaryButton size="lg" type="submit" disabled={loading} style={{ flex: "1", padding: "1rem", marginTop: "2rem" }} >
                            {loading ? "Checking university..." : "CONTINUE"}
                        </PrimaryButton>
                    </HorizontalButtonContainer>
                }

            </Form>
            
        </RegistrationContainer>
    );
}

export default FinishUniversity;
