import { useTheme } from "styled-components";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

// From my app
import { RegistrationContainer } from "../../components/common/Containers";
import FormInput from "../../components/forms/FormInput";
import { Form } from "../../components/forms/Form";
import { PrimaryButton } from "../../components/common/Buttons";
import { RegistrationHeader, Subtitle, MobileBottomSection } from "../../components/forms/MiscComponents";
import { myAxiosInstance } from "../../api/axiosConfig";

function FinishName() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [errors, setErrors] = useState({ email: "", password: "" });
    const [loading, setLoading] = useState(false);

    // Erros
    const { showBoundary } = useErrorBoundary();

    // Get the theme
    const theme = useTheme();

    // Navigate
    const navigate = useNavigate();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // Retrieve the data
    useEffect(() => {
        const getStageDetails = async () => {
            try {
                setLoading(true);
                const response = await myAxiosInstance.get('/api/finish-registration-one/');
                setFirstName(response.data.first_name);
                setLastName(response.data.last_name);

            } catch (error) {
                showBoundary(error);
            } finally {
                setLoading(false); // Set isLoading to false regardless of success or error
            }
        };

        getStageDetails();

    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        let newErrors = { firstName: "", lastName: ""}
    
        // Frontend validation
        if (firstName.trim().length < 2) {
            newErrors.firstName = "Please enter a valid first name.";
        }
    
        if (lastName.trim().length < 2) {
            newErrors.lastName = "Please enter a valid last name.";
        }
    
        if (newErrors.firstName || newErrors.lastName) {
            setErrors(newErrors);
            return; // Stop execution if frontend validation fails
        }
                  
        try {
            // Send API request
            setLoading(true);

            await myAxiosInstance.put("/api/finish-registration-one/", { "first_name": firstName, "last_name": lastName });

            navigate("/finish-registration/university");

        } catch (error) {
            // Handle API errors
            // Handle API errors
            if (error.response) {
                if (error.response.status === 400) {
                    if (error.response.data.first_name) {
                        setErrors({ ...newErrors, firstName: error.response.data.first_name });
                    } else if (error.response.data.last_name) {
                        setErrors({ ...newErrors, lastName: error.response.data.last_name });
                    } else {
                        setErrors({ ...newErrors, lastName: "Something went wrong. Please try again later." });
                    }
                } else {
                    setErrors({ ...newErrors, lastName: "Something went wrong. Please try again later." });
                }
            } else {
                setErrors({ ...newErrors, lastName: "Something went wrong. Please try again later." });
            }
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <RegistrationContainer isMobile={isMobile}>
            <RegistrationHeader isMobile={isMobile}>WHAT'S YOUR NAME?</RegistrationHeader>
            <Subtitle isMobile={isMobile} theme={theme}>Please enter your first and last name below</Subtitle>

            <Form onSubmit={handleSubmit}>
                <FormInput 
                    label="First name" 
                    type="text" 
                    value={firstName}
                    onChange={(e) => {
                        setFirstName(e.target.value);
                        setErrors((prev) => ({ ...prev, firstName: "" })); // Clear error when typing
                    }}
                    error={errors.firstName}
                />

                <div style={{ marginTop: "2rem" }}>
                    <FormInput 
                        label="Last name" 
                        type="text" 
                        value={lastName}
                        onChange={(e) => {
                            setLastName(e.target.value);
                            setErrors((prev) => ({ ...prev, lastName: "" })); // Clear error when typing
                        }} 
                        error={errors.lastName}
                    />
                </div>

                {/* Mobile bottom section */}
                {isMobile ?
                    <MobileBottomSection>
                        <PrimaryButton size="lg" type="submit" disabled={loading} style={{ padding: "1rem", width: "100%"}} >
                            {loading ? "Checking name..." : "CONTINUE"}
                        </PrimaryButton>
                    </MobileBottomSection>
                    :
                    <PrimaryButton size="lg" type="submit" disabled={loading} style={{ padding: "1rem", marginTop: "2rem" }} >
                        {loading ? "Checking name..." : "CONTINUE"}
                    </PrimaryButton>
                }

            </Form>
            
        </RegistrationContainer>
    );
}

export default FinishName;
