import { useTheme } from "styled-components";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate, Link } from "react-router-dom";

// From my app
import { RegistrationContainer } from "../../components/common/Containers";
import { RegistrationHeader, Subtitle, PromptWrapper, Prompt, MobileBottomSection } from "../../components/forms/MiscComponents";
import { PrimaryButton } from "../../components/common/Buttons";
import { ErrorText } from "../../components/forms/MiscComponents";
import { Form } from "../../components/forms/Form";
import { myAxiosInstance } from "../../api/axiosConfig";
import { useAuth } from "../../contexts/AuthContext";


function FinishVerifyEmail() {
    const [resendVerificationSent, setResendVerificationSent] = useState(false);
    const [errors, setErrors] = useState({ error: "" });
    const [loading, setLoading] = useState(false);
    
    const { userEmail, handleLogout } = useAuth();

    // Erros
    const { showBoundary } = useErrorBoundary();

    // Get the theme
    const theme = useTheme();

    // Navigate
    const navigate = useNavigate();

    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 768 });

    // Retrieve the data
    useEffect(() => {
        const getStageDetails = async () => {
            try {
                setLoading(true);
                // const response = await myAxiosInstance.get('/api/finish-registration-one/');
                // setFirstName(response.data.first_name);
                // setLastName(response.data.last_name);

            } catch (error) {
                showBoundary(error);
            } finally {
                setLoading(false); // Set isLoading to false regardless of success or error
            }
        };

        getStageDetails();

    }, []);

    // Logout on wrong email
    const logOutNavigate = () => {
        handleLogout();
        navigate('/signing-out');
    }

    // Handle form submission
    const handleReset = async (e) => {
        e.preventDefault();
        let newErrors = { error: ""};

                  
        try {
            // Send API request
            setLoading(true);

            const response = await myAxiosInstance.post('/prospinity-auth/email/resend-verification/');
            setResendVerificationSent(true);

        } catch (error) {
            // Handle API errors
            setErrors({ ...newErrors, error: "Something went wrong. Please try again later." });
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <RegistrationContainer isMobile={isMobile}>
            <RegistrationHeader isMobile={isMobile}>VERIFY YOUR EMAIL</RegistrationHeader>
            <Subtitle isMobile={isMobile} theme={theme}>Please verify your email to continue. We've sent a confirmation email to <span style={{ color: theme.primary }}>{userEmail}</span> with a verification link.</Subtitle>

            {/* Verification message */}
            { resendVerificationSent && <Subtitle isMobile={isMobile} theme={theme}>Verification email resent.</Subtitle> }

            {/* error */}
            { errors.error && <ErrorText>{errors.error}</ErrorText> }

            <Form onSubmit={handleReset}>
                    {isMobile ?
                        <MobileBottomSection>
                            <PrimaryButton size="lg" type="submit" disabled={loading} style={{ padding: "1rem", width: "100%" }} >
                                {loading ? "Resending the email." : "RESEND EMAIL"}
                            </PrimaryButton>
                            <PromptWrapper isMobile={isMobile}>
                                <Prompt theme={theme}>
                                    Not your email? <Link to="#" onClick={(e) => { e.preventDefault(); logOutNavigate(); }} >Change email</Link>
                                </Prompt>
                            </PromptWrapper>
                        </MobileBottomSection>
                        :
                        <PrimaryButton size="lg" type="submit" disabled={loading} style={{ padding: "1rem",  marginTop: "2rem" }} >{loading ? "Resending the email." : "RESEND EMAIL"}</PrimaryButton>
                    }   
                </Form>

                {/* Secondary prompt onlly for desktop */}
                {!isMobile && 
                    <PromptWrapper isMobile={isMobile}>
                        <Prompt theme={theme}>
                            Not your email? <Link to="#" onClick={(e) => { e.preventDefault(); logOutNavigate(); }} >Change email</Link>
                        </Prompt>
                    </PromptWrapper>
                }

        </RegistrationContainer>
    );
}

export default FinishVerifyEmail;
