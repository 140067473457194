import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useErrorBoundary } from "react-error-boundary";
import { useSearchParams } from 'react-router-dom';

// From My Application
import { myAxiosInstance } from "../api/axiosConfig";
import Loading from "../components/common/Loading";
import { MainContainer } from '../components/common/Containers';
import UserPortfolio from '../components/users/UserPortfolio';


const SearchPage = () => {

    // Get the query
    const [searchParams] = useSearchParams();
    const query = searchParams.get("q"); // Read 'q' from the URL

    // States
    const [isLoading, setIsLoading] = useState(true);
    const [searchResults, setSearchResults] = useState([]);

    // Errors
    const { showBoundary } = useErrorBoundary();
  
    // Get the theme
    const theme = useTheme();

    const handleRemove = async (user) => {
      try {
          await myAxiosInstance.post(`/api/remove-from-portfolio/${user.slug}/`);
  
      } catch (error) {
          console.error("Failed to reject request:", error);
        }
    };

    // Get the api data
    useEffect(() => {
      async function fetchData() {
          try {
                const response = await myAxiosInstance.get("/api/search-users/", {
                    params: { q: query, full: true },
                });
                setSearchResults(response.data.users);
          } catch (error) {
            showBoundary(error);
          } finally {
              setIsLoading(false);
          }
      }
      fetchData();
    }, [query]);

    if (isLoading) {
      return (
        <div style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Loading />
        </div>
    );
    }

    return (
        <MainContainer theme={theme} >
            <UserPortfolio users={searchResults} onRemove={handleRemove} customTitle={`TOP 10 RESULTS FOR "${query.toUpperCase()}"`} showCount={false} />
        </MainContainer>
    );

}

export default SearchPage;



